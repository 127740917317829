import GoogleMapReact from "google-map-react"
import { EnvironmentFilled } from '@ant-design/icons'

const PinGoogle = (props: any) => {
    return(
        <div className="default-pin"><EnvironmentFilled /></div>
    )
}

export const GoogleMapLocation = (props: any) =>
    <div
        style={{ width: '100%', height: '400px' }}
    >
        <GoogleMapReact
            bootstrapURLKeys={{ key: "AIzaSyAsrW_1meOXr6ozX-__m-Iz1XQ4kUfH0mg" }}
            defaultZoom={12}
            defaultCenter={{ lat: props.coordinates[0], lng: props.coordinates[1] }}
            center={props.position}
            yesIWantToUseGoogleMapApiInternals
        >
            <PinGoogle
                lat={props.coordinates[0]}
                lng={props.coordinates[1]}
            />
        </GoogleMapReact>
    </div>
