import { makeAutoObservable } from 'mobx'
import { openApi } from '../../../helpers/request'
import { FormEvent } from 'react'
import i from '../../../translations/i'
import { notification } from 'antd'

export interface CitiesInterfaces {
    id: number
    name: string
    enabled: boolean
    latitude: string
    longitude: string
    id_crm: string
    picture: any
}

class citiesController {
    list: CitiesInterfaces[] = []
    city: CitiesInterfaces = {
        id: 0,
        name: '',
        enabled: true,
        latitude: '',
        longitude: '',
        id_crm: '',
        picture: []
    }

    pagination = {
        total: 0,
        limit: 10,
        page: 1,
        offset: 0
    }

    constructor() {
        makeAutoObservable(this)
    }

    get(cityID: string | number = 0) {
        if(Number(cityID) > 0) {
            openApi('get', `/cities/${cityID}`, {})
                .then(result => {
                    console.log(result)
                    this.city = result.data
                })
        } else {
            openApi('get', `/cities?limit=${this.pagination.limit}&page=${this.pagination.page}`)
                .then(result => {
                    result = result.data
                    this.list = result.items
                    this.pagination.total = result.size
                    this.pagination.page = result.page
                    this.pagination.limit = result.limit
                })
        }
    }

    set(name: string, value: any) {
        //@ts-ignore
        this.city[name] = value
    }

    setPage(page: number) {
        this.pagination.page = page
        this.get()
    }

    save(e: FormEvent) {
        e.preventDefault()

        let data = new FormData()
        data.append('id', `${this.city.id}`)
        data.append('name', this.city.name)
        data.append('enabled', `${+ this.city.enabled}`)
        data.append('latitude', this.city.latitude ?? '')
        data.append('longitude', this.city.longitude ?? '')
        data.append('id_crm', this.city.id_crm ?? '')

        // @ts-ignore
        data.append('picture', this.city.picture)

        openApi('post', `/cities/${this.city.id}`, data, { key: 'Content-Type', value: 'multipart/form-data' })
            .then(result => {
                if(this.city.id === 0) window.location.href = `/settings/cities`
                else {
                    this.get(this.city.id)
                    notification.success({
                        message: i.t('def:success.complete'),
                        description: i.t('def:success.edit')
                    });
                }
            })
    }

    remove(cityID: string) {
        if(window.confirm(i.t('def:confirms.delete'))) {
            openApi('delete', `/cities/${cityID}`)
                .then(result => {
                    this.get()
                })
        }
    }

    upload(image: any) {
        this.city.picture = image
        return false
    }

    removeImage(cityID: string | number) {
        if(window.confirm(i.t('def:confirms.delete'))) {
            openApi('delete', `/cities/picture/${cityID}`)
                .then(result => {
                    this.get(cityID)
                })
        }
    }
}

export default new citiesController()