import React from 'react'
import { observer } from 'mobx-react-lite'
import {
    Row,
    Col,
    Radio,
    Divider,
    Select,
    Segmented,
    TreeSelect,
    Button,
    Table,
    Image,
    Space,
    Input,
    InputNumber, Pagination, Modal, Tooltip, Popconfirm
} from 'antd'
import i from '../../../../translations/i'
import { __product } from '../__product'
import { CustomProductListsLayout } from '../../custom_product_lists/CustomProductListsLayout'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { __cpl } from '../../custom_product_lists/__cpl'

export const ProductWith = observer(() => {

    return(
        <>
            <Row gutter={[20,20]}>
                <Col span={24}>
                    <Button
                        type="primary"
                        onClick={() => __product.addBlock()}
                    >{i.t('def:Add Block')}</Button>
                </Col>
                <Col span={24}>
                    <Row gutter={[15,15]}>
                        {__product.item.rel.map((block: any, index: number) => {
                            return (
                                <Col span={24} key={index}>
                                    <div className="designer__block">
                                        <div className="block__title">
                                            <div className="block__name">{block?.name ?? i.t('def:New Block')}</div>
                                            <div className="block__type">
                                                {block?.type === '' ? i.t('product:nowCategory') : i.t('def:Products Lists')}
                                            </div>
                                        </div>
                                        <div className="block__actions">
                                            <Tooltip title={i.t('def:actions.edit')}>
                                                <Button
                                                    type="dashed"
                                                    shape="circle"
                                                    icon={<EditOutlined />}
                                                    onClick={() => __product.openModal(index)}
                                                />
                                            </Tooltip>
                                            <Tooltip title={i.t('categories:delete')}>
                                                <Popconfirm
                                                    title={i.t('def:confirms.delete')}
                                                    onConfirm={() => __product.removeBlock(index)}
                                                    okText={i.t('def:yes')}
                                                    cancelText={i.t('def:no')}
                                                >
                                                    <Button
                                                        type="dashed"
                                                        shape="circle"
                                                        icon={<DeleteOutlined />}
                                                        danger
                                                    />
                                                </Popconfirm>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </Col>
                            )
                        })}
                    </Row>

                </Col>
            </Row>

            <Modal
                title={i.t('constructor:editBlock')}
                open={__product.modalOpened}
                footer={null}
                onCancel={() => __product.closeModal(true)}
                width={1000}
                centered={true}
                maskClosable={false}
            >
                <Row gutter={[20, 20]}>
                    {__cpl.selectedList === null &&
                        <>
                            <Col span={24}>
                                <label className="formLabel">{i.t('def:name')}*</label>
                                <Input
                                    value={__product.selectedBlock.name}
                                    onChange={(e) => __product.selectedBlock.name = e.target.value}
                                />
                            </Col>
                            <Col span={24}>
                                <Radio.Group
                                    options={[
                                        { label: i.t('product:nowCategory'), value: '' },
                                        { label: i.t('def:Products Lists'), value: 'lists' },
                                    ]}
                                    onChange={(e) => __product.selectedBlock.type = e.target.value}
                                    value={__product.selectedBlock.type}
                                    optionType="button"
                                />
                            </Col>
                        </>
                    }

                    {__product.selectedBlock.type === 'lists' &&
                        <Col span={24}>
                            <CustomProductListsLayout selectedElements={__product.selectedBlock.lists} />
                        </Col>
                    }

                    {__cpl.selectedList === null &&
                        <Col span={24}>
                            <Button
                                type="primary"
                                shape="round"
                                onClick={() => __product.closeModal()}
                            >{i.t('def:actions.save')}</Button>
                        </Col>
                    }
                </Row>
            </Modal>
        </>
    )
})