import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { __cpl } from './__cpl'
import i from '../../../translations/i'
import { Titles } from '../../../layouts/Titles'
import { useParams } from 'react-router-dom'
import { CustomProductListFields } from './CustomProductListFields'
import { __product } from '../product/__product'
import propertiesController from '../../../modules/shop/properties/controllers/properties.controller'
import __select from '../../../helpers/select_entity/__select.controller'

export const CustomProductListEdit = observer(() => {

    const { listID }: any = useParams()

    useEffect(() => {
        __cpl.getOne(listID)
        __product.getCategoryTree()
        propertiesController.pagination.limit = 99999
        propertiesController.getProperties()
        __select.getStatuses()
    }, [])

    return <>
        <Titles
          title={i.t('def:List Settings')}
          breadcrumb={[
              { title: i.t('def:Products Lists'), href: `/catalog/lists` }
          ]}
          buttons={[
              { title: i.t('def:actions.back'), href: `/catalog/lists`, type: 'dashed' },
              { title: i.t('def:actions.save'), type: 'primary', click: () =>  __cpl.save()}
          ]}
        />

        <CustomProductListFields />
    </>
})