import { observer } from 'mobx-react-lite'
import { Row, Col, Input, InputNumber } from 'antd'
import './verify.less'
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react'

interface VerifyActions {
    onChange: any,
    error: boolean
}

const PinInput = ({ digits, onChange, error = false }: any, ref: any) => {

    useImperativeHandle(ref, () => ({
        focus: () => {
            inputRefs.current[0].focus()
        }
    }))

    const handleChange = (index: number, newValue: any) => {
        const oldDigit = digits[index]
        const newDigit = newValue.trim().replace(oldDigit, '')

        if(newDigit < '0' || newDigit > '9') {
            return
        }
        onChange(updateArray(digits, index, newDigit))

        const inputs = inputRefs.current
        if (index < inputs.length - 1) {
            inputs[index + 1].focus()
        } else {
            inputs[index].blur()
        }

        if(inputs[0].value !== '' && inputs[1].value !== '' && inputs[2].value !== '' && inputs[3].value !== '') {
            console.log('Все')
        }
    }

    const handleKeyDown = (index: number, keyPressed: any) => {
        if(keyPressed !== 'Backspace') return
        if(digits[index]) {
            onChange(updateArray(digits, index, ''))
        } else if (index > 0) {
            inputRefs.current[index - 1].focus()
        }
    }

    const updateArray = (array: any[], index: number, newValue: any) => {
        const copy = [ ...array ]
        copy[index] = newValue
        return copy
    }

    const inputRefs = useRef(new Array(digits.length))

    return(
        <div>
            {digits.map((digit: any, index: any) => (
                <Input
                    key={index}
                    value={digit}
                    className={error ? 'error' : ''}
                    onChange={(event) => handleChange(index, event.target.value)}
                    onKeyDown={(event) => handleKeyDown(index, event.key)}
                    ref={(ref) => {
                        inputRefs.current[index] = ref
                    }}
                />
            ))}
        </div>
    )
}

const ReactPinInput = React.forwardRef(PinInput)

export const VerifyComponent = observer(({ onChange, error = false }: VerifyActions) => {

    const [digits, setDigits] = useState(['', '', '', ''])
    const ref = useRef()

    const focus = () => {
        // @ts-ignore
        ref.current?.focus()
    }

    useEffect(() => {
        onChange(digits.join(''))
    }, [digits])

    const clear = () => setDigits(['', '', '', ''])

    return(
        <>
            <Row className='verify'>
                <Col span={24}>
                    <ReactPinInput ref={ref} digits={digits} onChange={setDigits} error={error} />
                </Col>
            </Row>
        </>
    )
})