import { makeObservable, observable } from 'mobx'
import { request } from '../../helpers/request'
import { FormEvent } from 'react'
import { notification } from 'antd'
import i from '../../translations/i'

class __pages {

    list: any[] = []
    page: any = {
        id: 0,
        text: '',
        anotation: '',
        name: '',
        active: 1,
    }

    constructor() {
        makeObservable(this, {
            list: observable,
            page: observable,
        })
    }

    save(e: FormEvent) {
        e.preventDefault()

        const data = new FormData()
        data.append('id', this.page.id);
        data.append('text', this.page.text);
        data.append('anotation', this.page.anotation);
        data.append('name', this.page.name);
        data.append('active', this.page.active);

        request('post', `/info/pages/${this.page.id}`, data, true)
            .then(response => {
                if(response.code === 201){
                    document.location.href="/pages";
                }else if(response.code === 200){
                    this.getOne(this.page.id)
                    notification.success({
                        placement: "topRight",
                        message: "Успешно",
                        description: i.t('page:success'),
                    });
                }
            })
    }

    getList() {
        request('get', '/info/pages', {}, true)
            .then(response => {
                this.list = response.data[0]
            })
    }

    getOne(id: any) {
        if(id > 0)
            request('get', `/info/pages/${id}`, {}, true)
                .then(response => {
                    this.page = response.data
                })
    }
}

export default new __pages()