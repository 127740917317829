import { observer } from 'mobx-react-lite'
import { integrationsController } from './integrations.controller'
import i from '../../translations/i'
import { Titles } from '../../layouts/Titles'
import { useEffect } from 'react'
import { Row, Col, Button, Input, Modal, Space, Tooltip } from 'antd'
import { config } from '../../config/app'
import { SettingOutlined, PoweroffOutlined, CheckOutlined, DeleteOutlined, RetweetOutlined } from '@ant-design/icons'

const controller = new integrationsController()

const ElementList = observer(({ element }: any) => {

    return(
        <Row className="integration">
            <Col span={24}>
                <p className="name">{element.name}</p>
                <p className="description">{element.description}</p>
                <Space>
                    <Tooltip title={i.t('def:settings')}>
                        <Button
                            type="dashed"
                            icon={<SettingOutlined />}
                            onClick={() => controller.getOne(element.integration_id)}
                        />
                    </Tooltip>
                    {/*<Tooltip title={element.active ? i.t('def:disable') : i.t('def:enable')}>*/}
                    {/*    <Button*/}
                    {/*        type="dashed"*/}
                    {/*        icon={element.active ? <PoweroffOutlined /> : <CheckOutlined /> }*/}
                    {/*    />*/}
                    {/*</Tooltip>*/}
                    <Tooltip title={i.t('def:actions.remove')}>
                        <Button
                            type="dashed"
                            icon={<DeleteOutlined />}
                            onClick={() => controller.delete(element.id)}
                            danger
                        />
                    </Tooltip>
                </Space>
            </Col>
        </Row>
    )
})

export const Integrations = observer(() => {

    useEffect(() => {
        return () => controller.getList()
    }, [])

    const buttons = [
        { title: i.t('def:actions.docs'), type: 'dashed', target: true, href: `${config.docs}/auth`},
        { title: i.t('def:actions.create'), type: 'primary', click: () => controller.create() }
    ]

    return(
        <>
            <Titles
                title={i.t('integrations:title')}
                buttons={buttons}
                breadcrumb={[
                    { title: i.t('def:settings'), href: `/settings` },
                    { title: i.t('integrations:title') },
                ]}
            />

            <Row gutter={[20, 20]}>
                {controller.list.length > 0 && controller.list.map((el: any, index: number) => (
                    <Col span={8} key={`integration_${index}`}>
                        <ElementList element={el} />
                    </Col>
                ))}
            </Row>

            <Modal visible={controller.visible} onCancel={() => controller.close()} onOk={() => controller.save()} maskClosable={false}>
                <Row gutter={[20, 20]}>
                    <Col span={24}>
                        <h3>{i.t('integrations:setting')}</h3>
                    </Col>
                    <Col span={24}>
                        <label className="label">{i.t('integrations:name')}</label>
                        <Input
                            value={controller.element.name}
                            onChange={(e) => controller.set('name', e.target.value)}
                            required
                        />
                    </Col>
                    <Col span={24}>
                        <label className="label">{i.t('integrations:description')}</label>
                        <Input
                            value={controller.element.description}
                            onChange={(e) => controller.set('description', e.target.value)}
                            required
                        />
                    </Col>
                    {controller.element.integration_id && controller.element.integration_id !== '' &&
                        <>
                            <Col span={24}>
                                <Row>
                                    <Col span={12}>
                                        <label className="label">ClientID</label>
                                    </Col>
                                    <Col span={12} className="right">
                                        <Tooltip title={i.t('integrations:refresh')}>
                                            <Button
                                                onClick={() => controller.refreshClientId(controller.element.integration_id)}
                                                icon={<RetweetOutlined />}
                                                type="dashed"
                                                danger
                                            />
                                        </Tooltip>
                                    </Col>
                                    <Col span={24}>
                                        <code>{controller.element.client_id}</code>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={24}>
                                <label className="label">IntegrationID</label>
                                <code>{controller.element.integration_id}</code>
                            </Col>
                            <Col span={24}>
                                <label className="label">{i.t('integrations:code')}</label>
                                <Button
                                    onClick={() => controller.getCode(controller.element.integration_id)}
                                    type="dashed"
                                >
                                    {i.t('integrations:get')}
                                </Button>
                                {controller.element.code && controller.element.code !== '' && <code>{controller.element.code}</code>}
                            </Col>
                        </>
                    }
                </Row>
            </Modal>

        </>
    )
})