import { observer } from 'mobx-react-lite'
import { memo, useEffect, useState } from 'react'
import i from '../../translations/i'
import { Titles } from '../../layouts/Titles'
import __news from './__news'
import { useParams } from 'react-router-dom'
import { ParamTypes } from '../../helpers/types.interfaces'
import {
    Tabs,
    TabsProps,
    Row,
    Col,
    Switch,
    Segmented,
    Space,
    Input,
    Select,
    DatePicker,
    Button,
    Divider,
    Tag,
    Typography, Upload
} from 'antd'
import { INewsType } from './types'
import dayjs from 'dayjs'
import { WarehousesController } from '../settings/warehouses/warehouses.controller'
import { IWarehouse } from '../settings/warehouses/types'
import { ImagesHelper } from '../../helpers/ImagesHelper'

const warehouses = new WarehousesController()

export const NewsElement = observer(() => {

    const { newsID }: any = useParams<ParamTypes>()

    useEffect(() => {
        return () => {
            if(newsID) __news.getOne(newsID)
        }
    }, [])

    const items: TabsProps['items'] = [
        {
            key: 'main',
            label: i.t('newsItem:mainTab'),
            children: <NewsElementMain />,
        },
        {
            key: 'preview',
            label: i.t('newsItem:previewTab'),
            children: <NewsElementPreview />,
        },
        {
            key: 'text',
            label: i.t('newsItem:detailTab'),
            children: <NewsElementEditor />,
        },
    ]

    return(
        <>
            <form onSubmit={(e) => __news.save(e)}>
                <Titles
                    title={i.t('newsItem:edit')}
                    buttons={[
                        { title: i.t('def:actions.backList'), href: `/news`, type: 'dashed' },
                        { title: i.t('def:actions.save'), htmlType: `submit`, type: 'primary' }
                    ]}
                    breadcrumb={[
                        { title: i.t('news:list'), href: `/news` },
                        { title: i.t('newsItem:edit') },
                    ]}
                />

                <Tabs
                    items={items}
                    onChange={(e) => __news.onChangeTab(e)}
                />
            </form>
        </>
    )
})

const EditorTextarea = memo(({ value, index }: {value: string, index: number}) => {

    return (
        <>
            <Row gutter={[20, 20]}>
                <Col span={24}>
                    <EditorHeaderBlock label={i.t('news:textBlock')} index={index} />
                </Col>
                <Col span={24}>
                    <Input.TextArea
                        className="editor_textarea"
                        value={value}
                        onChange={(e) => __news.news_element.text[index].value = e.target.value}
                        autoSize={true}
                    />
                </Col>
            </Row>
        </>
    )
})

const EditorHeaderBlock = ({ label, index }: {label: string, index: number}) => (
    <Row justify="space-around" align="middle">
        <Col span={12}>
            <Typography.Title level={4}>{label}</Typography.Title>
        </Col>
        <Col span={12} className="right">
            <Space>
                <Button type="dashed" size="small" onClick={() => __news.pullUp(index)}>{i.t('news:up')} </Button>
                <Button type="dashed" size="small" onClick={() => __news.pullDown(index)}>{i.t('news:down')} </Button>
                <Button type="dashed" size="small" danger onClick={() => __news.deleteBlock(index)}>{i.t('news:delete')} </Button>
            </Space>
        </Col>
    </Row>
)

const NewsElementEditor = observer(() => {

    return(
        <>
            <Row gutter={[20, 20]}>
                {__news.news_element.text.map((el, index: number) => (
                    <>
                        <Col span={24}>
                            {el.type === 'text'
                                ? <EditorTextarea value={el.value} index={index} />
                                : <></>
                            }
                            {el.type === 'link'
                                ?   <>
                                    <Row gutter={[20, 20]}>
                                        <Col span={24}>
                                            <EditorHeaderBlock label={i.t('news:link')} index={index} />
                                        </Col>
                                        <Col span={12}>
                                            <label className="label">{i.t('news:linkName')}</label>
                                            <Input
                                                value={el.name}
                                                placeholder={i.t('news:linkExample')}
                                                onChange={(e) => __news.news_element.text[index].name = e.target.value}
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <label className="label">{i.t('news:linkValue')}</label>
                                            <Input
                                                value={el.value}
                                                placeholder="https://website.ru/"
                                                onChange={(e) => __news.news_element.text[index].value = e.target.value}
                                            />
                                        </Col>
                                    </Row>
                                </>
                                : <></>
                            }
                            {el.type === 'image'
                                ?   <>
                                    <Row>
                                        <Col span={24}>
                                            <EditorHeaderBlock label={i.t('def:image.image')} index={index} />
                                        </Col>
                                        <Col span={24}>
                                            {el.value && el.value.path
                                                ? <>
                                                    <img src={el.value.path} alt='' style={{ width: `300px` }} />
                                                </>
                                                : <>
                                                    <Upload
                                                        listType="picture-card"
                                                        multiple={false}
                                                        beforeUpload={(info, list) => {
                                                            __news.news_element.text[index].link = (Math.random() + 1).toString(36).substring(7)
                                                            __news.news_element.text[index].file = info
                                                            return false
                                                        }}
                                                    >
                                                        {i.t('def:selectFile')}
                                                    </Upload>
                                                </>
                                            }
                                        </Col>
                                    </Row>
                                </>
                                : <></>
                            }
                        </Col>
                        <Divider />
                    </>
                ))}
                <Col span={24}>
                    <Space>
                        <Button type="dashed" onClick={() => __news.addBlockText()}>{i.t('newsItem:addText')}</Button>
                        <Button type="dashed" onClick={() => __news.addBlockImage()}>{i.t('newsItem:addImage')}</Button>
                        <Button type="dashed" onClick={() => __news.addBlockLink()}>{i.t('newsItem:addLink')}</Button>
                    </Space>
                </Col>
            </Row>
        </>
    )
})

const NewsElementPreview = observer(() => {

    return(
        <>
            <Row gutter={[20, 20]}>
                <Col span={24}>
                    <ImagesHelper
                        image={__news.news_element.image ? (__news.news_element.image.path ? __news.news_element.image.path : [__news.news_element.image]) : null}
                        return_images={(e: any) => {
                            __news.news_element.image = e[0]
                        }}
                        on_delete={() => __news.news_element.image = null}
                    />
                </Col>
                <Col span={24}>
                    <label className="label">{i.t('newsItem:previewText')}</label>
                    <Input.TextArea
                        rows={4}
                        value={__news.news_element.anotation}
                        onChange={(e) => __news.news_element.anotation = e.target.value}
                    />
                </Col>
            </Row>
        </>
    )
})

const NewsElementMain = observer(() => {

    useEffect(() => {
        return () => {
            __news.getTypesList(true)
            warehouses.pagination.limit = 1000
            warehouses.get()
        }
    }, [])

    return(
        <>
            <Row gutter={[20, 20]}>
                <Col span={24} className="switch-block">
                    <Space>
                        <div className="element">
                            <div></div>
                            <Switch
                                onChange={(e) => __news.news_element.active = e}
                                checked={__news.news_element.active}
                                size="small"
                            />
                            <div>
                                <span>{i.t('newsItem:view')}</span>
                            </div>
                        </div>

                        <div className="element">
                            <div></div>
                            <Switch
                                onChange={(e) => __news.news_element.sendpush = e}
                                checked={__news.news_element.sendpush}
                                size="small"
                            />
                            <div>
                                <span>{i.t('newsItem:push')}</span>
                            </div>
                        </div>
                    </Space>
                </Col>
                <Col span={24}>
                    <label className="label">{i.t('newsItem:views')}</label>
                    <Segmented
                        options={[
                            { label: i.t('newsItem:items.one'), value: 'default' },
                            { label: i.t('newsItem:items.two'), value: 'full' },
                        ]}
                        value={__news.news_element.typeview}
                        onChange={(e) => __news.news_element.typeview = String(e)}
                    />
                </Col>
                <Col span={12}>
                    <label className="label">{i.t('newsItem:name')}</label>
                    <Input
                        value={__news.news_element.name}
                        onChange={e => __news.news_element.name = e.target.value}
                    />
                </Col>
                <Col span={12}>
                    <label className="label">{i.t('newsItem:type')}</label>
                    {__news.types_list.length
                        ? <>
                            <Select
                                style={{ width: `100%` }}
                                options={__news.types_list.map((el: INewsType) => ({ label: el.name, value: `${el.id}`, key: `type_${el.id}` }))}
                                value={__news.news_element.type}
                                onChange={(e) => __news.news_element.type = e}
                            />
                        </>
                        : <></>
                    }
                </Col>
                <Col span={12}>
                    <label className="label">{i.t('newsItem:date')}</label>
                    <Row gutter={[20, 20]}>
                        <Col span={12}>
                            <DatePicker
                                style={{ width: `100%` }}
                                format={"DD.MM.YYYY"}
                                onChange={(e) => __news.news_element.datefrom = e ? dayjs(e).unix() : 0}
                                placeholder={i.t('newsItem:dateStart')}
                                value={__news.news_element.datefrom !== 0 ? dayjs(__news.news_element.datefrom * 1000) : null}
                            />
                        </Col>
                        <Col span={12}>
                            <DatePicker
                                style={{ width: `100%` }}
                                format={"DD.MM.YYYY"}
                                onChange={(e) => __news.news_element.dateto = e ? dayjs(e).unix() : 0}
                                placeholder={i.t('newsItem:dateEnd')}
                                value={__news.news_element.dateto !== 0 ? dayjs(__news.news_element.dateto * 1000) : null}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col span={12}>
                    <label className="label">{i.t('newsItem:storage')}</label>
                    <Select
                        style={{ width: `100%` }}
                        options={warehouses.list.map((el: IWarehouse) => ({ label: el.name, value: el.id, key: `warehouse_${el.id}` }))}
                        onChange={(e) => __news.news_element.warehouse_id = e}
                        value={__news.news_element.warehouse_id}
                        onClear={() => __news.news_element.warehouse_id = 0}
                        allowClear={true}
                    />
                </Col>
            </Row>
        </>
    )
})