import { observer } from 'mobx-react-lite'
import i from '../../../../translations/i'
import { Titles } from '../../../../layouts/Titles'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { runInAction } from 'mobx'
import { Row, Col, Divider, Input, Button, Select } from 'antd'
import { SwitchBlock } from '../../../../components/SwitchBlock'
import __points from '../controllers/__points'
import { DeleteOutlined } from '@ant-design/icons'
import { ShopPropertyExample } from '../../../../modules/shop/properties/modules/ShopProperty'

export const PlacesProperty = observer(() => {

    const { propertyID }: any = useParams()

    useEffect(() => {
        if(propertyID > 0) {
            __points.getOneProperty(propertyID)
        }
    }, [])

    return <>
        <form onSubmit={(e) => __points.saveProperty(e)}>
            <Titles
                title={i.t('def:Edit Characteristic')}
                breadcrumb={[
                    { title: i.t('def:settings'), href: '/settings' },
                    { title: i.t('points:list'), href: '/settings/places' },
                    { title: i.t('def:Characteristics'), href: '/settings/places_properties' }
                ]}
                buttons={[
                    { title: i.t('def:actions.back'), href: '/settings/places_properties', type: 'dashed' },
                    { title: i.t('def:actions.save'), htmlType: 'submit', type: 'primary' }
                ]}
            />
            <Row gutter={[20, 20]}>
                <Col span={12}>
                    <Row gutter={[20, 20]}>
                        <Col span={24}>
                            <label className="formLabel">{i.t('def:name')}</label>
                            <Input
                                value={__points.item.name}
                                onChange={ (e) => __points.item.name = e.target.value }
                            />
                        </Col>
                        <Col span={24}>
                            <Select
                                value={__points.item.type}
                                style={{ width: 100 + '%' }}
                                onChange={e => __points.item.type = e}
                                options={[
                                    {label: i.t('props:example.checkbox'), value: 'checkbox'},
                                    {label: i.t('props:example.radio'), value: null}
                                ]}
                            />
                        </Col>
                        <Col span={24}>
                            <label className="formLabel">{i.t('def:Values')}</label>
                            <Button
                                type="primary"
                                onClick={() => __points.addPropertyValue()}
                            >{i.t('def:actions.add')}</Button>
                        </Col>
                        <Col span={24}>
                            <Row gutter={[10, 10]}>
                                {__points.item.values.map((value: any, index: number) => {

                                    return (<>
                                        <Col span={20}>
                                            <Input
                                                value={__points.item.values[index]['value']}
                                                onChange={ (e) => __points.item.values[index]['value'] = e.target.value }
                                            />
                                        </Col>
                                        <Col span={4}>
                                            <Button
                                                type="dashed"
                                                shape="circle"
                                                icon={<DeleteOutlined />}
                                                danger
                                                onClick={() => __points.removePropertyValue(index)}
                                            />
                                        </Col>
                                    </>)
                                })}
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col span={12}>
                    <ShopPropertyExample />
                </Col>
            </Row>
        </form>
    </>
})