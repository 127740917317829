import { observer } from 'mobx-react-lite'
import i from '../../../translations/i'
import { Titles } from '../../../layouts/Titles'
import React, { useEffect } from 'react'
import { Button, Space, Table, Tooltip, Switch, Popconfirm } from 'antd'
import {
    SettingOutlined,
    DeleteOutlined
} from '@ant-design/icons'
import { __group } from './__group'

export const GroupsList = observer(() => {
    useEffect(() => {
        return () => {
            __group.getList()
        }
    }, [])

    const columns = [
        {
            title: "ID",
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: i.t('def:name'),
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: i.t('def:actions.actions'),
            key: 'action',
            render: (group: any) => (
                <>
                    <Space>
                        <Tooltip title={i.t('def:actions.edit')}>
                            <Button type="dashed" shape="circle" href={`/catalog/group/${group.id}`} icon={<SettingOutlined />} />
                        </Tooltip>
                        <Tooltip title={i.t('def:actions.remove')} zIndex={5}>
                            <Popconfirm
                                title={i.t('def:confirms.delete')}
                                onConfirm={() => __group.deleteOne(group.id)}
                                okText={i.t('def:yes')}
                                cancelText={i.t('def:no')}
                            >
                                <Button
                                    type="dashed"
                                    shape="circle"
                                    icon={<DeleteOutlined />}
                                    danger
                                />
                            </Popconfirm>
                        </Tooltip>
                    </Space>
                </>
            ),
        },
    ]

    return <>
        <Titles
            title={i.t('def:Product Groups')}
            breadcrumb={[
                { title: i.t('def:Product Groups'), href: `/catalog/groups` }
            ]}
            buttons={[
                { title: i.t('def:Create Group'), href: `/catalog/group/0`, type: 'dashed', plan: 2 },
            ]}
        />

        <Table
            rowKey="id"
            columns={columns}
            dataSource={__group.list}
            pagination={{
                position: ['bottomLeft'],
                hideOnSinglePage: true,
                current: __group.pagination.page,
                pageSize: __group.pagination.limit,
                total: __group.pagination.total,
                showSizeChanger: false,
                onChange: (e: number) => __group.setPage(e)
            }}
        />
    </>
})