import React from 'react'
import { YMInitializer } from 'react-yandex-metrika'
import { BrowserRouter as Router } from 'react-router-dom'
import LoaderPage from './modules/template/loader/LoaderPage'
import { Layout } from './layouts/Layout'
import './assets/styles/App.less'
import loaderController from './modules/template/loader/loader.controller'
import { observer } from 'mobx-react-lite'
import { config } from './config/app'
import { useEffect } from 'react'
import { Routes } from './routes/Routes'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { ConfigProvider } from 'antd'
import { routeMiddleware } from './middleware/routeMiddleware'
import layoutController from "./layouts/controllers/layout.controller";

const App: React.FC = observer(() => {

     useEffect(() => {
      layoutController.getWL(window.location.host)

        routeMiddleware(window.location.pathname)
            .then((result) => {
                if (result.status) {
                    loaderController.onChange(true)
                } else {
                    if (result.redirect) window.location.href = result.redirect
                    return
                }
            })
            .catch(error => {
                console.log(error)
                return
            })
    }, [])

    return layoutController.wl.primary_color && layoutController.wl.primary_color !== '' ? (
        <ConfigProvider
            theme={layoutController.config}
        >
            {loaderController.load ? (
                <>
                    <HelmetProvider>
                        <Helmet>
                            <title></title>
                        </Helmet>
                        {config.shortServiceName === 'sales' && (
                            <YMInitializer
                                accounts={[84756061]}
                                options={{
                                    clickmap: true,
                                    trackLinks: true,
                                    accurateTrackBounce: true,
                                    webvisor: true
                                }}
                            />
                        )}
                        <Router>
                            <Layout content={Routes} />
                        </Router>
                    </HelmetProvider>
                </>
            ) : (
                <LoaderPage />
            )}
        </ConfigProvider>
    ) : <></>
})

export default App
