import { observer } from 'mobx-react-lite'
import { Row, Col, Button } from 'antd'
import i from '../../../translations/i'

export const VerifySuccessComponent = observer(() => {

    return(
        <>
            <Row gutter={[15, 15]}>
                <Col span={24}>
                    <h1>{i.t('auth:title.verifySuccess')}</h1>
                </Col>
                <Col span={24} className="verify-description">
                    <p>{i.t('auth:verify.success')}</p>
                </Col>
                <Col span={24} className="buttons">
                    <Row justify="space-around" align="middle" gutter={[10, 10]}>
                        <Col span={12} className="verify-block">
                            <Button
                                key={'login_form_button_submit'}
                                type="primary"
                                className="button"
                                href='/login'
                            >
                                {i.t('def:actions.enter')}
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
})