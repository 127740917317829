import userController from '../front/user/controllers/user.controller'
import { storeSession } from '../helpers/stores'
import { getUnits } from '../helpers/project.controller'

export const routeMiddleware = async (uri: string) => {
    const auth = await userController.decrypt('user')
    const project = await userController.decrypt('project')

    if(auth) {
        if(storeSession.get('forgot_step') && uri !== '/forgot') {
            return { status: false, redirect: '/forgot' }
        } else if(!project) {
            if(uri !== '/select') {
                return { status: false, redirect: '/select' }
            }
        } else {
            // if (uri === '/wait') {
            //     return { status: false, redirect: '/' }
            // }
            if (!storeSession.get('project_units')) await getUnits()
        }
    } else {
        const middle = await RouterLoginMiddleware(uri)
        if(middle) return middle
    }

    return { status: true }
}

const RouterLoginMiddleware = async (uri: string) => {
    if(storeSession.get('verification_email')) {
        if(uri !== '/verify') {
            return { status: false, redirect: '/verify' }
        } else {
            return { status: true }
        }
    }

    if(uri === '/login') {
        return { status: true }
    } else if(uri === '/register') {
        return { status: true }
    } else if(uri === '/forgot') {
        return { status: true }
    } else {
        return { status: false, redirect: '/login' }
    }
}