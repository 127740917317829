import { Space, Upload, UploadFile } from 'antd'
import { memo } from 'react'
import { RcFile } from 'antd/lib/upload'
import i from '../translations/i'

interface IImagesHelper {
    multiple?: boolean
    image: any
    return_images: any
    on_delete: any
}

export const ImagesHelper = memo(({ multiple = false, image = null, return_images, on_delete }: IImagesHelper) => {

    return(
        <>
            <label className="label">{i.t('def:image.image')}</label>
            <Space>
                <Upload
                    listType="picture-card"
                    multiple={multiple}
                    fileList={image ? (typeof image === 'string' ? [{uid: '-1', name: 'image.png', status: 'done', url: image}] : image) : []}
                    beforeUpload={(info, list) => {
                        return_images(list)
                        return false
                    }}
                    onRemove={(info) => on_delete(info)}
                >
                    {i.t('def:selectFile')}
                </Upload>
            </Space>
        </>
    )
})