import { observer } from 'mobx-react-lite'
import __points from './controllers/__points'
import React, { useEffect } from 'react'
import i from '../../../translations/i'
import { Titles } from '../../../layouts/Titles'
import { Badge, Button, Space, Table, Tooltip } from 'antd'
import __multiple from '../../../helpers/multiple_actions/__multiple'
import { IPlace } from './types'

export const PointsList = observer(() => {

    useEffect(() => {
        return () => {
            __multiple.setActions(__points.actions())
            __points.getList()
        }
    }, [])

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: i.t('points:addressPoint'),
            dataIndex: 'address',
            key: 'address',
            render: (address: any, point: IPlace) =>  (
                <>
                    <div className="point__name">{point?.name ?? ''}</div>
                    <div className="point__address">{point?.address ?? ''}</div>
                </>
            )
        },
        {
            title: i.t('def:workDays'),
            key: 'work',
            render: (point: IPlace) => (
                <Space>
                    <Tooltip placement="top" title={i.t('def:days.monday')}><Badge status={point.schedule_monday_status ? 'success' : 'error'} /></Tooltip>
                    <Tooltip placement="top" title={i.t('def:days.tuesday')}><Badge status={point.schedule_tuesday_status ? 'success' : 'error'} /></Tooltip>
                    <Tooltip placement="top" title={i.t('def:days.wednesday')}><Badge status={point.schedule_wednesday_status ? 'success' : 'error'} /></Tooltip>
                    <Tooltip placement="top" title={i.t('def:days.thursday')}><Badge status={point.schedule_thursday_status ? 'success' : 'error'} /></Tooltip>
                    <Tooltip placement="top" title={i.t('def:days.friday')}><Badge status={point.schedule_friday_status ? 'success' : 'error'} /></Tooltip>
                    <Tooltip placement="top" title={i.t('def:days.saturday')}><Badge status={point.schedule_saturday_status ? 'success' : 'error'} /></Tooltip>
                    <Tooltip placement="top" title={i.t('def:days.sunday')}><Badge status={point.schedule_sunday_status ? 'success' : 'error'} /></Tooltip>
                </Space>
            )
        },
        {
            title: i.t('orders:actions'),
            key: 'action',
            render: (point: IPlace) => (
                <>
                    <Space>
                        <Button
                            type="dashed"
                            href={`/settings/places/${point.id}`}
                        >
                            {i.t('orders:view')}
                        </Button>
                    </Space>
                </>
            )
        }
    ]

    return(
        <>
            <Titles
                title={i.t('points:list')}
                buttons={[
                    { title: i.t('def:Groups'), type: 'dashed', href: `/settings/places_groups`, plan: 1 },
                    { title: i.t('def:Characteristics'), type: 'dashed', href: `/settings/places_properties`, plan: 1 },
                    { title: i.t('def:actions.create'), type: 'primary', href: `/settings/places/0` }
                ]}
                breadcrumb={[
                    {title: i.t('def:settings'), href: '/settings'},
                    { title: i.t('points:list') },
                ]}
            />

            <Table
                rowKey="id"
                rowSelection={{
                    onSelect: (record, selected, selectedRows) => {
                        __multiple.set(selectedRows)
                    },
                    onSelectAll: (selected, selectedRows) => {
                        __multiple.set(selectedRows)
                    },
                    type: 'checkbox',
                    selectedRowKeys: __multiple.selected
                }}
                columns={columns}
                dataSource={__points.list}
                pagination={{
                    position: ['bottomLeft'],
                    hideOnSinglePage: true,
                    current: __points.page,
                    pageSize: __points.limit,
                    total: __points.total,
                    showSizeChanger: false,
                    onChange: (e: number) => __points.setPage(e)
                }}
            />
        </>
    )
})