import { makeAutoObservable } from 'mobx'
import { openApi } from '../../../helpers/request'
import { FormEvent } from 'react'
import { notification } from 'antd'
import i from '../../../translations/i'

class PushTemplatesController {

    templates: any[] = []
    template: any = {
        enabled: true
    }
    form: any = {}

    pagination = {
        limit: 1000,
        offset: 0,
        count: 0,
    }

    vars = []

    constructor() {
        makeAutoObservable(this)
    }

    getForm(type: string) {
        openApi('get', `/pushes/templates/form/${type}`)
            .then(result => {
                console.log(result)
                this.form = result.data.form
                this.vars = result.data.vars
                this.template.type = type
            })
    }

    getTemplates() {
        openApi('get', '/pushes/templates')
            .then(result => {
              console.log(result)
                this.templates = result.data
            })
    }

    getTemplate(id: number) {
        openApi('get', `/pushes/templates/${id}`)
            .then(result => {
                console.log(result)
                this.template = result.data
                this.getForm(result.data.type)
            })
    }

    uploadTemplate(image: any) {
        this.template.image = image
        return false
    }

    delete(id: number) {
        openApi('delete', `/pushes/templates/${id}`)
            .then(result => {
                notification.success({
                    message: i.t('def:success.complete'),
                    description: i.t('def:success.edit'),
                })
                this.getTemplates()
            })
            .catch(error => {
                console.log(error)
            })
    }

    deleteImageFromTemplate() {
        let uri = `/pushes/templates/image`
        if(this.template.image && this.template.image.id > 0) uri = `${uri}/${this.template.image.id}`

        openApi('delete', uri)
            .then(result => {
                this.template.image = null
                notification.success({
                    message: i.t('def:success.complete'),
                    description: i.t('def:success.edit'),
                })
            })
            .catch(error => {
                console.log(error)
            })
    }

    save(e: FormEvent) {
        e.preventDefault()

        const data = new FormData()

        Object.keys(this.template).map(key => {
            data.append(key, this.template[key])
        })

        let uri = `/pushes/templates`
        if(this.template.id > 0) uri = `${uri}/${this.template.id}`

        openApi('post', uri, data, { key: 'Content-Type', value: 'multipart/form-data' })
            .then(result => {
                if(this.template.id && this.template.id > 0) {
                    this.getTemplate(this.template.id)
                    notification.success({
                        message: i.t('def:success.complete'),
                        description: i.t('def:success.edit'),
                    })
                } else {
                    window.location.href = `/pushes/templates`
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

}

export default new PushTemplatesController()