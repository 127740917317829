import {observer} from "mobx-react-lite";
import pc from "../controllers/properties.controller"
import TemplateTitle from "../../../template/title/TemplateTitle";
import {Row, Col, Input, Select, Checkbox, Radio, InputNumber, Switch} from "antd";
import {useParams} from "react-router-dom";
import React, {useEffect} from "react";
import ButtonAction from "../../../template/buttons/ButtonsActions";
import i from '../../../../translations/i'

export const ShopPropertyExample = () => {

    const optionsWithDisabled = [
        { label: i.t('props:options.apple'), value: 'Apple' },
        { label: i.t('props:options.pear'), value: 'Pear' },
        { label: i.t('props:options.orange'), value: 'Orange' }
    ];

    return(
        <Row className="template-examples" gutter={[20, 20]}>
            <Col span={24}>
                <p className="title">{i.t('props:example.title')}</p>
            </Col>
            <Col span={12}>
                <p className="title">{i.t('props:example.checkbox')} (Checkbox)</p>
                <p className="description">
                    {i.t('props:example.checkboxDescription')}
                </p>

                <Checkbox.Group
                    options={optionsWithDisabled}
                    defaultValue={['Apple']}
                />
            </Col>
            <Col span={12}>
                <p className="title">{i.t('props:example.radio')} (Radio)</p>
                <p className="description">
                    {i.t('props:example.radioDescription')}
                </p>

                <Radio.Group options={optionsWithDisabled} />
            </Col>
            <br/>
            <Col span={24}>
                <p className="ps">
                    {i.t('props:example.description')}
                </p>
            </Col>
        </Row>
    )
}

const ShopPropertyForm = observer(() => {

    let { propertyID } = useParams();

    useEffect(() => {
        if(propertyID && propertyID > 0) {
            pc.getProperties(propertyID)
        }
    }, [])

    return(
        <Row gutter={20}>
            <Col>
                <label className="all-label">
                    {i.t('props:nameProp')}
                </label>
                <Input
                    placeholder="name"
                    value={pc.property.name}
                    onChange={e => pc.setProperty("name", e.target.value)}
                    required
                />
            </Col>
            <Col>
                <label className="all-label">{i.t('props:type')}</label>
                <Select value={pc.property.type} style={{ width: "100%" }} onChange={e => pc.setProperty("type", e)}>
                    <Select.Option value="checkbox">{i.t('props:example.checkbox')}</Select.Option>
                    <Select.Option value="radio">{i.t('props:example.radio')}</Select.Option>
                  required
                </Select>
            </Col>
            <Col span={24}>
                <label className="all-label">{i.t('props:search')}</label>
                <Switch
                    size="small"
                    checked={pc.property.search > 0}
                    onChange={(e) => pc.setProperty("search", e)}
                />
            </Col>
            <Col span={24}>
                <label className="all-label">{i.t('props:is_search')}</label>
                <Switch
                    size="small"
                    checked={pc.property.is_search > 0}
                    onChange={(e) => pc.setProperty("is_search", e)}
                />
            </Col>
            {propertyID && propertyID > 0 &&
                <Col span={24}>
                    <label className="all-label">{i.t('props:values')}</label>
                    <button className="button-main" onClick={() => pc.addPropertyValue(propertyID)}>{i.t('def:actions.add')}</button>
                    <br/><br/>
                    {pc.property.values && pc.property.values.length > 0 && pc.property.values.map(item =>
                        <Row gutter={[10, 10]}>
                            <Col span={15}>
                                <Input
                                    placeholder={i.t('def:name')}
                                    value={item.value}
                                    onChange={e => pc.setVariant(item.id ? "id" : "ids", item[item.id ? "id" : "ids"], e.target.value)}
                                    required
                                />
                            </Col>
                            <Col span={5}>
                                <InputNumber
                                    placeholder="position"
                                    value={item.position}
                                    onChange={e => pc.setVariant("position", item[item.id ? "id" : "ids"], e, item.id ? "id" : "ids")}
                                    required
                                />
                            </Col>
                            <Col span={4}>
                                {item.id && item.id > 0 &&
                                <ButtonAction
                                    item={{
                                        type: "delete",
                                        typeButton: "button",
                                        placeholder: i.t('def:actions.remove'),
                                        onClick: () => pc.deleteValue(item.id)
                                    }}
                                />
                                }
                            </Col>
                        </Row>
                    )}
                </Col>
            }
        </Row>
    )
})

const ShopProperty = observer(() => {

    const breadcrumb = [
        { key: "shop", link: "/shop", value: i.t('orders:shop') },
        { key: "properties", link: "/shop/properties/", value: i.t('crumb:props') },
        { key: "element", link: null, value: i.t('props:editProp') }
    ]

    const buttons = [
        { title: i.t('def:actions.save'), type: "submit"}
    ]

    return(
        <>
          <form onSubmit={(e) => pc.savePropForm(e)}>
            <TemplateTitle
              title={i.t('props:editProp')}
              breadcrumb={breadcrumb}
              buttons={buttons}
            />
            <Row gutter={[40, 20]}>
              <Col span={12}><ShopPropertyForm /></Col>
              <Col span={12}><ShopPropertyExample /></Col>
            </Row>
          </form>
        </>
    )
})

export default ShopProperty