import { observer } from 'mobx-react-lite'
import { storeLocal } from '../helpers/stores'
import { Popover } from 'antd'
import React from 'react'
import i from '../translations/i'

export const ErrorByPricingPlan = observer(({ content, plan = 0 }: any) => {

    return storeLocal.get('pricing_plan') < plan
        ? <Popover placement="bottom" title={i.t('def:errors.pricing_plan')}>{content}</Popover>
        : content
})