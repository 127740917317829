import { makeAutoObservable } from 'mobx'

class spinController {

    spin = false

    constructor() {
        makeAutoObservable(this)
    }

    onChange() {
        this.spin = !this.spin
    }
}

export default new spinController()