import { observer } from 'mobx-react-lite'
import i from '../../translations/i'
import { Titles } from '../../layouts/Titles'
import __multiple from '../../helpers/multiple_actions/__multiple'
import __news from './__news'
import { Badge, Button, Col, Input, Row, Space, Table } from 'antd'
import React, { useEffect } from 'react'
import { INews } from './types'
import { useParams } from 'react-router-dom'
import { ParamTypes } from '../../helpers/types.interfaces'
import { SketchPicker } from 'react-color'

export const NewsType = observer(() => {

    const { id }: any = useParams<ParamTypes>()

    useEffect(() => {
        return () => __news.getOneType(id)
    }, [])

    return(
        <>
            <form onSubmit={(e) => __news.saveType(e)}>
                <Titles
                    title={i.t('newsType:editType')}
                    buttons={[
                        { title: i.t('def:actions.backList'), href: `/news_types`, type: 'dashed' },
                        { title: i.t('def:actions.save'), htmlType: `submit`, type: 'primary' }
                    ]}
                    breadcrumb={[
                        { title: i.t('newsType:title'), href: `/news_types` },
                        { title: i.t('newsType:editType') },
                    ]}
                />

                <Row gutter={[20, 20]}>
                    <Col span={12}>
                        <label className="label">{i.t('def:name')}</label>
                        <Input
                            value={__news.type.name}
                            onChange={(e) => __news.type.name = e.target.value}
                            required
                        />
                    </Col>
                    <Col span={24}>
                        <label className="label">{i.t('def:color')}</label>
                        <SketchPicker
                            color={`#${__news.type.colorhex}`}
                            onChange={(e) => __news.setColor(e.hex)}
                        />
                    </Col>
                </Row>
            </form>
        </>
    )
})

export const NewsTypes = observer(() => {

    useEffect(() => {
        return () => {
            __multiple.setActions(__news.getActionsTypes())
            __news.getTypesList()
        }
    }, [])

    const columns = [
        {
            title: '',
            dataIndex: 'colorhex',
            key: 'colorhex',
            render: (el: string) => <Badge color={`#${el}`} />,
            width: 30
        },
        {
            title: i.t('def:name'),
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: i.t('orders:actions'),
            key: 'action',
            width: '200px',
            render: (record: INews) => (
                <>
                    <Space>
                        <Button
                            type="dashed"
                            href={`/news_types/${record.id}`}
                        >
                            {i.t('orders:view')}
                        </Button>
                    </Space>
                </>
            )
        }
    ]

    return(
        <>
            <Titles
                title={i.t('newsType:title')}
                buttons={[
                    { title: i.t('def:actions.create'), href: `/news_types/0`, type: 'primary' }
                ]}
                breadcrumb={[
                    { title: i.t('newsType:title') }
                ]}
            />

            <Table
                rowKey="id"
                rowSelection={{
                    onSelect: (record, selected, selectedRows) => {
                        __multiple.set(selectedRows)
                    },
                    onSelectAll: (selected, selectedRows) => {
                        __multiple.set(selectedRows)
                    },
                    type: 'checkbox',
                    selectedRowKeys: __multiple.selected
                }}
                columns={columns}
                dataSource={__news.types_list}
                pagination={false}
            />
        </>
    )
})