import { makeAutoObservable, runInAction } from 'mobx'
import { storeLocal, storeSession } from '../../helpers/stores'
import { parseUser } from '../../helpers/project.controller'
import { FormEvent } from 'react'
import { openApi } from '../../helpers/request'
import store from 'store'
import { notification } from 'antd'
import userController from '../../front/user/controllers/user.controller'

class layoutController {

    wl: any = {}

    config = {
        token: {
            colorPrimary: '#000000',
            fontFamily: "Gilroy",
        }
    }

    mobile = false

    user: any = {
        firstname: '',
        lastname: '',
        super: 0,
        project: {},
    }

    project: any = {}

    search = ''

    markers: any = {}

    bundles: any[] = []

    constructor() {
        makeAutoObservable(this)
    }

    getBundles() {
        // openApi('get', '/manager/projects/')
        //     .then(result => {
        //         console.log(result)
        //         // runInAction(() => this.bundles = result)
        //     })
    }

    getMarkers() {
        openApi('get', 'markers')
            .then(result => {
                runInAction(() => this.markers = result.data)
            })
    }

    getWL(host: string) {
        if(!store.get('wl')) {
            const data = new FormData()
            data.append('Client-Domain', host)

            openApi('post', 'wl', data)
              .then(result => {
                  console.log(result)
                  runInAction(() => {
                      this.wl = result.data
                      store.set('wl', result.data)
                      const favicon = document.getElementById("favicon")
                      // @ts-ignore
                      favicon.href = this.wl.favicon
                  })
              })
        } else {
            this.wl = store.get('wl')
            this.config.token.colorPrimary = this.wl.primary_color
            document.documentElement.style.setProperty('--primary-color', this.wl.primary_color)
            const favicon = document.getElementById("favicon")
            // @ts-ignore
            favicon.href = this.wl.favicon
        }
    }

    setMobile() {
        runInAction(() => this.mobile = !this.mobile)
    }

    logout() {
        storeLocal.clearAll()
        storeSession.clearAll()
        window.location.href = '/'
        return false
    }

    async getProject() {
        const project = await userController.decrypt('project')

        if(project) {
            runInAction(() => this.project = project)
        }
    }

    onChangeSearch(value: string) {
        runInAction(() => this.search = value)
    }

    searchProducts(e: FormEvent) {
        e.preventDefault()
        window.location.href = "/shop/search?name=" + this.search
    }

    openNotification(data: any) {
        console.log('socket', data)
        if(data.type === 'info' || data.type === 'success' || data.type === 'warning' || data.type === 'error') {
            // @ts-ignore
            notification[data.type]({
                message: data.data.title,
                description: data.data.description,
            })
        } else {
            if(data.type === 'markers') {
                runInAction(() => this.markers = data.data)
            }
        }
    }

}

export default new layoutController()