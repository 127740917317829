import { makeObservable, observable, runInAction } from 'mobx'
import { IProduct } from '../../components/list/types'
import { openApi } from '../../helpers/request'
import i from '../../translations/i'
import __multiple from '../../helpers/multiple_actions/__multiple'

class __catalog {

    list_categories = []
    list_products: IProduct[] = []

    page: number = 1
    total: number = 0
    limit: number = 10

    type_list: string = ''

    this_category_id: number = 0

    productWithoutCategory:number = 0

    tree: {title: string, href?: string}[] = [
        { title: i.t('categories:catalog'), href: `/shop/catalog/category` }
    ]

    constructor() {
        makeObservable(this, {
            list_categories: observable,
            list_products: observable,
            page: observable,
            total: observable,
            this_category_id: observable,
            type_list: observable,
            tree: observable,
            productWithoutCategory: observable,
        })
    }

    defaultTree(tree: any[]) {
      this.tree = [
        { title: i.t('categories:catalog'), href: `/shop/catalog/category` }
      ]

      tree.map((el: any) => this.tree.push({
        title: el.name,
        href: el.subcategories ? `/shop/catalog/category/${el.id}` : `/shop/products/${el.id}`
      }))
    }

    get() {
        openApi('get', `/category/list/${this.this_category_id}?limit=${this.limit}&page=${this.page}&with[]=image`)
            .then(result => {
                console.log(result)
                this.total = result.total

                this.list_categories = result.data
                __multiple.setActions(this.getActionsCategories())

                this.tree = [{title: i.t('categories:catalog'), href: `/shop/catalog/category`}]
                if(result.tree && result.tree.length)
                    result.tree.map((el: any) => this.tree.push({title: el.label, href: `/shop/catalog/category/${el.value}`}))

                this.productWithoutCategory = result.product_without_category ?? 0

                this.defaultTree(result.breadcrumbs)
            })
            .catch(error => {

            })
    }

    getProducts() {
        openApi('get', `/products/list?filter[category_id]=${this.this_category_id}&important=true&limit=${this.limit}&page=${this.page}&with[]=image`)
          .then(result => {
              this.total = result.total

              this.list_products = result.data
              __multiple.setActions(this.getActionsProducts())

              this.tree = [{title: i.t('categories:catalog'), href: `/shop/catalog/category`}]
              if(result.tree && result.tree.length)
                  result.tree.map((el: any) => this.tree.push({title: el.label, href: `/shop/catalog/category/${el.value}`}))

              this.productWithoutCategory = result.product_without_category ?? 0

            this.defaultTree(result.breadcrumbs)
          })
          .catch(error => {
            console.log(error.response)
          })
    }

    setPage(page: number) {
        runInAction(() => this.page = page)
        this.get()
    }

    setPageProducts(page: number) {
      runInAction(() => this.page = page)
      this.getProducts()
    }

    deleteCategories() {
        const data = new FormData()
        __multiple.selected.map(el => data.append('categories[]', `${el}`))

        openApi('post', '/category/delete', data)
            .then(() => {
                this.get()
                __multiple.clear()
            })
          .catch(error => console.log(error.response))
    }

    deleteProducts() {
        const data = new FormData()

        __multiple.selected.map(el => data.append('elements[]', `${el}`))

        openApi('post', '/shop/product/delete', data)
            .then(result => {
                this.getProducts()
                __multiple.clear()
            })
    }

    getActionsCategories() {
        return [
            { label: i.t('def:actions.remove'), value: 'delete', action: () => this.deleteCategories() }
        ]
    }

    getActionsProducts() {
        return [
            { label: i.t('def:actions.remove'), value: 'delete', action: () => this.deleteProducts() }
        ]
    }

    setPosition(category_id: number, action: string = 'up') {
        const data = new FormData()
        data.append('action', action)
        data.append('category_id', `${category_id}`)
        data.append('parent_id', `${this.this_category_id}`)

        openApi('post', '/shop/category/position', data)
            .then((result) => {
                this.get()
            })
    }

    setPositionProduct(product_id: number, action: string) {
        openApi('post', `/products/direction/${product_id}/${this.this_category_id}/${action}`)
            .then((result) => {
                this.getProducts()
            })
            .catch(error => console.log(error.response))
    }

    setActiveProduct(productID: number, view: boolean) {
        const data = new FormData()
        data.append('view', `${+ view}`)

        openApi('post', `/shop/product/active/${productID}`, data)
            .then(() => {
                this.getProducts()
            })
    }

    setActiveCategory(categoryID: number, view: boolean) {
        const data = new FormData()
        data.append('view', `${+ view}`)

        openApi('post', `/category/${categoryID}`, data)
            .then((result) => {
                console.log(result)
                this.get()
            })
          .catch(err => console.log(err.response))
    }

}

export default new __catalog()