import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Row, Col, Button, Input, Space, Table, Pagination } from 'antd'
import controller from './operators.controller'
import ButtonAction from '../../../modules/template/buttons/ButtonsActions'
import i from '../../../translations/i'
import { Helmet } from 'react-helmet'

export const Operator = observer(() => {

    let { elementID } = useParams()
    useEffect(() => Number(elementID) > 0 && controller.getElement(elementID), [])

    return(
        <>
            <form onSubmit={(e) => controller.save(e)}>
                <Row justify="space-around" align="middle" className="title_page">
                    <Col span={12}>
                        <h1>{i.t('operators:edit')}</h1>
                    </Col>
                    <Col span={12} className="right">
                        <Button
                            type="primary"
                            htmlType="submit"
                        >
                            {i.t('operators:save')}
                        </Button>
                    </Col>
                    <Col span={24}>
                        <Row gutter={[20, 20]}>
                            <Col span={12}>
                                <label htmlFor="name" className="all-label">{i.t('operators:name')}</label>
                                <Input
                                    value={controller.element.name}
                                    id="name"
                                    onChange={(e) => controller.set('name', e.target.value)}
                                    required
                                />
                            </Col>
                            <Col span={12}>
                                <label htmlFor="email" className="all-label">{i.t('operators:email')}</label>
                                <Input
                                    value={controller.element.email}
                                    id="name"
                                    onChange={(e) => controller.set('email', e.target.value)}
                                    required
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </form>
        </>
    )
})

export const Operators = observer(() => {

    useEffect(() => {
        controller.getList()
    }, [])

    const columns = [
        { title: 'ID', dataIndex: 'id', key: 'id', },
        { title: i.t('operators:name'), dataIndex: 'name', key: 'name' },
        { title: i.t('operators:email'), dataIndex: 'email', key: 'email' },
        { title: i.t('operators:telegram'), dataIndex: 'chatid', key: 'chatid', render: (column) => column > 0 ? i.t('operators:yes') : i.t('operators:no') },
        {
            title: i.t('operators:actions'),
            key: 'action',
            align: 'right',
            render: (text, record) => (
                <>
                    <Space>
                        <ButtonAction
                            item={{
                                type: "edit",
                                typeButton: "link",
                                placeholder: i.t('operators:put'),
                                url: `/settings/operators/${record.id}`
                            }}
                        />
                        <ButtonAction
                            item={{
                                type: "delete",
                                typeButton: "button",
                                placeholder: i.t('operators:remove'),
                                onClick: () => controller.remove(record.id)
                            }}
                        />
                    </Space>
                </>
            ),
        },
    ]

    return(
        <>
            <Helmet>
                <title>{i.t('operators:title')}</title>
            </Helmet>
            <Row justify="space-around" align="middle" className="title_page" gutter={[20, 20]}>
                <Col span={12}><h1>{i.t('operators:title')}</h1></Col>
                <Col span={12} className="right">
                    <Button
                        type="primary"
                        href='/settings/operators/0'
                    >
                        {i.t('operators:add')}
                    </Button>
                </Col>
                <Col span={24}>
                    <Table columns={columns} dataSource={controller.list} pagination={false} />
                    <br />
                    <Pagination
                        defaultCurrent={controller.pagination.page}
                        current={controller.pagination.page}
                        defaultPageSize={controller.pagination.limit}
                        pageSize={controller.pagination.limit}
                        total={controller.pagination.total}
                        showSizeChanger={false}
                        onChange={(e) => controller.setPage(e)}
                    />
                </Col>
            </Row>
        </>
    )
})