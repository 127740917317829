import { observer } from 'mobx-react-lite'
import i from '../../translations/i'
import { Titles } from '../../layouts/Titles'
import { Button, Col, Input, Row, Switch, Table, Tooltip } from 'antd'
import { SettingOutlined } from '@ant-design/icons'
import React, { useEffect } from 'react'
import __pages from './__pages'
import { useParams } from 'react-router-dom'
import { Editor } from 'primereact/editor'
import controller from '../settings/cities/cities.controller'

export const Pages = observer(() => {

    useEffect(() => {
        return () => __pages.getList()
    }, [])

    const columns = [
        {
            title: i.t('pages:name'),
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: i.t('pages:status'),
            key: 'status',
            render: (record: any) => {
                let status = i.t('pages:notActive');
                if(record.active > 0) {
                    status = i.t('pages:active');
                }
                return(
                    <>{status}</>
                )
            }
        },
        {
            title: i.t('pages:actions'),
            key: 'action',
            render: (record: any) => (
                <Tooltip title={i.t('pages:edit')}>
                    <Button type="dashed" shape="circle" href={"/pages/" + record.id} icon={<SettingOutlined />} />
                </Tooltip>
            ),
        },
    ]

    return(
        <>
            <Titles
                title={i.t('pages:list')}
                buttons={[
                    { title: i.t('pages:create'), type: 'primary', href: `/pages/create` }
                ]}
                breadcrumb={[
                    {title: i.t('pages:list')},
                ]}
            />

            <Table
                columns={columns}
                dataSource={__pages.list}
                pagination={false}
            />
        </>
    )
})

export const Page = observer(() => {

    let { pageID }: any = useParams()

    useEffect(() => {
        return () => __pages.getOne(pageID)
    }, [])

    return(
        <>
            <form onSubmit={(e) => __pages.save(e)}>
                <Titles
                    title={i.t('page:editPage')}
                    buttons={[
                        { title: i.t('def:actions.backList'), type: 'dashed', href: `/pages` },
                        { title: i.t('def:actions.save'), type: 'primary', htmlType: `submit` },
                    ]}
                    breadcrumb={[
                        {title: i.t('pages:list'), href: `/pages`},
                        {title: i.t('page:editPage')},
                    ]}
                />

                <Row justify="space-around" gutter={[20, 20]}>
                    <Col xs={24} className="switch-block">
                        <div className="element">
                            <div></div>
                            <Switch
                                checked={__pages.page.active > 0}
                                onChange={e => __pages.page.active = e ? 1 : 0}
                                size="small"
                            />
                            <div>
                                <span>{i.t('page:view')}</span>
                            </div>
                        </div>
                    </Col>
                    <Col span={24}>
                        <label className="label">{i.t('page:name')}</label>
                        <Input
                            name="name"
                            value={__pages.page.name}
                            onChange={(e) => __pages.page.name = e.target.value}
                        />
                    </Col>
                    <Col span={24}>
                        <label className="label">{i.t('page:previewText')}</label>
                        <Input.TextArea
                            rows={4}
                            value={__pages.page.anotation}
                            onChange={(e) => __pages.page.anotation = e.target.value}
                        />
                    </Col>
                    <Col span={24}>
                        <label className="label">{i.t('page:description')}</label>
                        <Editor
                            style={{height:'320px'}}
                            value={__pages.page.text}
                            onTextChange={(e) => __pages.page.text = e.htmlValue}
                        />
                    </Col>
                </Row>
            </form>
        </>
    )
})