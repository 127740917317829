import { observer } from 'mobx-react-lite'
import {
    Row,
    Col,
    Input,
    Divider,
    InputNumber,
    Select,
    TreeSelect,
    Button,
    Upload,
    Modal,
    Tooltip,
    Popconfirm, Switch
} from "antd";
import { SwitchBlock } from '../../../../components/SwitchBlock'
import i from '../../../../translations/i'
import { __product } from '../__product'
import { DeleteOutlined, EditOutlined, InboxOutlined, LeftOutlined, PlayCircleOutlined, RightOutlined } from '@ant-design/icons'
import React from 'react'
import { storeLocal } from "../../../../helpers/stores";
import { ErrorByPricingPlan } from "../../../../components/ErrorByPricingPlan";

export const ProductImages = observer(() => {

    const updateData = (e: any) => {
        console.log(e)
        __product.item.images.push({
            path: URL.createObjectURL(e),
            sorting: __product.item.images.length + 1,
            file: e
        })
    }

    return(
        <>
            <Row gutter={[20, 20]} align="bottom">
                <Col span={24}>
                    <SwitchBlock array={[
                        { label: i.t('product:showPictures'), click: (e: any) => __product.item.show_pictures = e, value: __product.item.show_pictures },
                    ]} />
                </Col>
                <Divider />
                <Col span={24}>
                    <div className="product__images">
                        {__product.item.images.map((el: any, index: number) => (
                            <div className="product__image" key={index}>
                                {index === 0 &&
                                    <span className="product__image-first">{i.t('def:main_image')}</span>
                                }

                                {el.video_uri &&
                                    <div className="product__image-hasVideo">
                                        <PlayCircleOutlined />
                                    </div>
                                }

                                <div className="product__image-buttons">
                                    {index !== 0 &&
                                        <Tooltip title={i.t('def:Change Position')}>
                                            <Button
                                                type="dashed"
                                                shape="circle"
                                                icon={<LeftOutlined />}
                                                onClick={() => __product.sortingPictures(index, 'top')}
                                            />
                                        </Tooltip>
                                    }
                                    {index !== __product.item.images.length - 1 &&
                                        <Tooltip title={i.t('def:Change Position')}>
                                            <Button
                                                type="dashed"
                                                shape="circle"
                                                icon={<RightOutlined />}
                                                onClick={() => __product.sortingPictures(index, 'bottom')}
                                            />
                                        </Tooltip>
                                    }

                                    <Tooltip title={i.t('def:Video Link')}>
                                        <Button
                                            type="dashed"
                                            shape="circle"
                                            icon={<PlayCircleOutlined />}
                                            onClick={() => __product.openImagesModal(index)}
                                        />
                                    </Tooltip>
                                    <Tooltip title={i.t('categories:delete')}>
                                        <Popconfirm
                                            title={i.t('def:confirms.delete')}
                                            onConfirm={() => __product.deleteImage(index)}
                                            okText={i.t('def:yes')}
                                            cancelText={i.t('def:no')}
                                        >
                                            <Button
                                                type="dashed"
                                                shape="circle"
                                                icon={<DeleteOutlined />}
                                                danger
                                            />
                                        </Popconfirm>
                                    </Tooltip>
                                </div>
                                <img src={el.path} alt="" />
                            </div>
                        ))}
                    </div>
                </Col>
                <Col span={24}>
                    <Upload.Dragger
                        multiple={true}
                        fileList={[]}
                        beforeUpload={(info: any) => {
                            updateData(info)
                            return
                        }}
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">{i.t('product:drop')}</p>
                        <p className="ant-upload-hint">
                            {i.t('product:multiple')}
                        </p>
                    </Upload.Dragger>
                </Col>
            </Row>

            <Modal
                title={i.t('def:edit')}
                open={__product.modalOpened}
                footer={null}
                onCancel={() => __product.closeImagesModal(true)}
                width={600}
                centered={true}
                maskClosable={false}
            >
                <Row gutter={[20,20]}>
                    <Col span={24}>
                        <label className="formLabel">{i.t('def:Video Link')}*</label>
                        <ErrorByPricingPlan
                          plan={1}
                          content={
                              <Input
                                value={__product.imageVideoLink}
                                onChange={(e) => __product.imageVideoLink = e.target.value}
                                disabled={storeLocal.get('pricing_plan') < 1}
                              />
                          }
                        />
                    </Col>

                    <Col span={24}>
                        <Button
                            type="primary"
                            shape="round"
                            onClick={() => __product.closeImagesModal()}
                        >{i.t('def:actions.save')}</Button>
                    </Col>
                </Row>
            </Modal>
        </>
    )
})