import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import _promo from "../controllers/promo.controller"
import i from '../../../../translations/i'
import React, { useEffect } from "react"
import { Col, DatePicker, Input, InputNumber, Row, Checkbox, Select, Tag } from 'antd'
import { storeLocal } from '../../../../helpers/stores'
import { ErrorByPricingPlan } from '../../../../components/ErrorByPricingPlan'
import { SelectProduct } from '../../../../helpers/select_link/SelectLink'
import __select from '../../../../helpers/select_link/__select.controller'
import { Titles } from '../../../../layouts/Titles'
import dayjs from 'dayjs'

const ShopPromo = observer(() => {

    let { couponID } = useParams();

    useEffect(() => {
        _promo.getCoupon(couponID)
    }, [])

    return(
        <form onSubmit={(e) => _promo.saveCoupon(e)}>

            <Titles
                title={i.t('promo:edit')}
                breadcrumb={[
                    { title: i.t('promo:coupons'), href: '/shop/promo' },
                    { title: i.t('promo:edit') },
                ]}
                buttons={[
                    { type: "primary", title: i.t('def:actions.save'), htmlType: 'submit' },
                    { type: "dashed", title: i.t('def:actions.backList'), link: "/shop/promo" }
                ]}
            />

            <p><Tag color="#108ee9">{i.t('promo:uses')}: { _promo.coupon.countall > 0 ? _promo.coupon.count : i.t('promo:none') }</Tag></p>

            <Row gutter={[16, 16]}>
                <Col span={8}>
                    <label htmlFor="name" className="all-label">
                        {i.t('promo:name')}
                    </label>
                    <Input
                        value={_promo.coupon.name}
                        id="name"
                        onChange={(e) => _promo.onChange("name", e.target.value)}
                        required
                    />
                </Col>
                <Col span={8}>
                    <label htmlFor="code" className="all-label">
                        {i.t('promo:code')}
                    </label>
                    <Input
                        value={_promo.coupon.code}
                        id="name"
                        onChange={(e) => _promo.onChange("code", e.target.value)}
                        maxLength={20}
                        required
                    />
                </Col>
                <Col span={8}>
                    <label htmlFor="name" className="all-label">
                        {i.t('promo:total')}
                    </label>
                    <InputNumber
                        value={_promo.coupon.countall ? _promo.coupon.countall : 0}
                        id="count"
                        style={{ width: 100 + "%" }}
                        onChange={(e) => _promo.onChange("countall", e)}
                        required
                    />
                </Col>
                <Col span={24}>
                    <label htmlFor="text" className="all-label">
                        {i.t('promo:description')}
                    </label>
                    <Input.TextArea
                        rows={4}
                        value={_promo.coupon.text}
                        onChange={e => _promo.onChange("text", e.target.value)}
                    />
                </Col>
                <Col span={6}>
                    <label className="all-label">
                        {i.t('promo:start')}
                    </label>
                    <DatePicker
                        showTime
                        style={{ width: 100 + "%" }}
                        format="YYYY-MM-DD HH:mm"
                        minuteStep={10}
                        value={_promo.coupon.datestart}
                        onChange={(time) => _promo.onChange("datestart", time)}
                    />
                </Col>
                <Col span={6}>
                    <label className="all-label">
                        {i.t('promo:end')}
                    </label>
                    <DatePicker
                        showTime
                        style={{ width: 100 + "%" }}
                        format="YYYY-MM-DD HH:mm"
                        minuteStep={10}
                        value={_promo.coupon.dateend}
                        onChange={(time) => _promo.onChange("dateend", time)}
                    />
                </Col>
                <Col span={6}>
                    <label className="all-label">
                        {i.t('promo:one')}
                    </label>
                    <Checkbox
                        onChange={e => _promo.onChange("onetouser", e.target.checked)}
                        checked={_promo.coupon.onetouser}
                    >{_promo.coupon.onetouser ? i.t('def:states.included') : i.t('def:states.turned')}</Checkbox>
                </Col>
                <Col span={6}>
                    <label className="all-label">
                        {i.t('promo:first')}
                    </label>
                    <Checkbox
                        onChange={e => _promo.onChange("firstorder", e.target.checked)}
                        checked={_promo.coupon.firstorder}
                    >{_promo.coupon.firstorder ? i.t('def:states.included') : i.t('def:states.turned')}</Checkbox>
                </Col>
                <Col span={6}>
                    <label className="all-label">
                        {i.t('promo:birthday')}
                    </label>
                    <ErrorByPricingPlan content={
                        <Checkbox
                            onChange={e => _promo.onChange("birthday", e.target.checked)}
                            checked={_promo.coupon.birthday}
                            disabled={!storeLocal.get('pricing_plan') || storeLocal.get('pricing_plan') < 2}
                        >{_promo.coupon.birthday ? i.t('def:states.included') : i.t('def:states.turned')}</Checkbox>
                    } plan={2} />
                </Col>
                {_promo.coupon.birthday ?
                    <Col span={6}>
                        <label className="all-label">
                            {i.t('promo:birthday_limit')}
                        </label>

                        <InputNumber
                            value={_promo.coupon.birthday_limit ? _promo.coupon.birthday_limit : 0}
                            id="birthday_limit"
                            style={{ width: 100 + "%" }}
                            onChange={(e) => _promo.onChange("birthday_limit", e)}
                            min={0}
                            required
                        />
                    </Col> : ''
                }
                <Col span={12}>
                    <label className="all-label">
                        {i.t('promo:min')}
                    </label>

                    <InputNumber
                        value={_promo.coupon.summcard ? _promo.coupon.summcard : 0}
                        id="count"
                        style={{ width: 100 + "%" }}
                        onChange={(e) => _promo.onChange("summcard", e)}
                        required
                    />
                </Col>

                <Col span={24}></Col>
                <Col span={6}>
                    <label className="all-label">
                        {i.t('promo:type')}
                    </label>

                    <Select
                        style={{ width: 100 + "%" }}
                        value={_promo.coupon.type ? _promo.coupon.type : 0}
                        onChange={ e => _promo.onChange("type", e) }
                    >
                        <Select.Option value={0}>
                            {i.t('promo:types.one')}
                        </Select.Option>
                        <Select.Option value={1}>
                            {i.t('promo:types.two')}
                        </Select.Option>
                        <Select.Option value={2} disabled={!storeLocal.get('pricing_plan') || storeLocal.get('pricing_plan') < 1}>
                            <ErrorByPricingPlan content={i.t('promo:types.three')} plan={1} />
                        </Select.Option>
                    </Select>
                </Col>
                <Col span={6}>
                    {_promo.coupon.type === 2
                        ? <></>
                        :
                        <>
                            <label className="all-label">
                                {i.t('promo:value')}
                            </label>
                            <InputNumber
                                value={_promo.coupon.value ? _promo.coupon.value : 0}
                                id="count"
                                style={{ width: 100 + "%" }}
                                onChange={(e) => _promo.onChange("value", e)}
                                required
                            />
                        </>
                    }
                </Col>
                {_promo.coupon.type === 2
                    ? <>
                        <Col span={24}>
                            <SelectProduct
                                elementID={__select.select.value}
                                element={_promo.coupon.element ?? null}
                            />
                        </Col>
                    </>
                    : <></>}
            </Row>
        </form>
    )
})

export default ShopPromo