import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import i from '../../translations/i'
import { Titles } from '../../layouts/Titles'
import __news from './__news'
import __multiple from '../../helpers/multiple_actions/__multiple'
import { Badge, Button, Space, Table, Tag } from 'antd'
import { INews } from './types'

export const NewsList = observer(() => {

    useEffect(() => {
        return () => {
            __multiple.setActions(__news.getActions())
            __news.getNewsList()
        }
    }, [])

    const columns = [
        {
            title: '',
            dataIndex: 'active',
            key: 'active',
            render: (el: boolean) => <Badge status={el ? 'success' : 'error'} />,
            width: '20px',
        },
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: '50px',
        },
        {
            title: 'Заголовок',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: i.t('orders:actions'),
            key: 'action',
            width: '200px',
            render: (record: INews) => (
                <>
                    <Space>
                        <Button
                            type="dashed"
                            href={`/news/${record.id}`}
                        >
                            {i.t('orders:view')}
                        </Button>
                    </Space>
                </>
            )
        }
    ]

    return(
        <>
            <Titles
                title={i.t('news:list')}
                buttons={[
                    { title: i.t('def:actions.create'), href: `/news/0`, type: 'primary' }
                ]}
                breadcrumb={[
                    { title: i.t('news:list') }
                ]}
            />

            <Table
                rowKey="id"
                rowSelection={{
                    onSelect: (record, selected, selectedRows) => {
                        __multiple.set(selectedRows)
                    },
                    onSelectAll: (selected, selectedRows) => {
                        __multiple.set(selectedRows)
                    },
                    type: 'checkbox',
                    selectedRowKeys: __multiple.selected
                }}
                columns={columns}
                dataSource={__news.news_list}
                pagination={{
                    position: ['bottomLeft'],
                    hideOnSinglePage: true,
                    current: __news.page,
                    pageSize: __news.limit,
                    total: __news.total,
                    showSizeChanger: false,
                    onChange: (e) => __news.setPage(e)
                }}
            />
        </>
    )
})