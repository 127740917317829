import { Map, Placemark, YMaps } from '@pbe/react-yandex-maps'

export const YandexMapLocation = ({ coordinates }: any) => {

    return(
        <>
            <YMaps>
                <Map
                    defaultState={{ center: coordinates, zoom: 15 }}
                    width="100%"
                    height="400px"
                >
                    <Placemark geometry={coordinates} />
                </Map>
            </YMaps>
        </>
    )
}
