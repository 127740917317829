import { makeAutoObservable, runInAction } from 'mobx'
import hashController from '../../template/controllers/hash.controller';
import i from '../../../translations/i'
import { getUnits } from '../../../helpers/project.controller'
import { openApi, request } from '../../../helpers/request'
import { notification } from "antd";

class unitController {

    list = []
    element = {}
    pagination = {
        total: 0,
        limit: 10,
        page: 1,
        offset: 0
    }

    constructor() {
        makeAutoObservable(this)
    }

    get(elementID = 0, all = false) {
        if(elementID > 0) {
            request('get', `/product/unit/${elementID}`)
                .then(result => {
                    runInAction(() => {
                        this.element = result
                        return false
                    })
                })
        } else {
            openApi('get', all ? `/unit?limit=1000&page=1` : `/unit?limit=${this.pagination.limit}&page=${this.pagination.page}`)
                .then(result => {
                    this.list = result.data ?? []
                    this.pagination.total = result.total
                    getUnits()
                })
        }
    }

    set(name, value) {
        this.element[name] = value
    }

    setPage(page) {
        this.pagination.page = page
        this.get()
    }

    save(e) {
        e.preventDefault()

        let facts = new FormData()
        facts.append('description', this.element.description || "")
        facts.append('name', this.element.name || "")
        facts.append('step', this.element.step || 1)
        facts.append("hash", hashController.setHash(facts))

        let url = this.element.id ? `/product/unit/${this.element.id}` : `/product/unit/0`

        request('post', url, facts)
            .then(result => {
                console.log(result)
                window.location.href = '/shop/units'
                return false
            })
            .catch(err => console.log(err))
    }

    remove(elementID) {
        if (window.confirm(i.t('def:confirms.delete'))) {

            openApi('delete', `/unit/${elementID}`)
              .then(_ => {
                  console.log(_)
                  this.get()
              })
              .catch(error => {
                  console.log(error.response)
                  notification.error({
                      message: i.t('def:error.error'),
                      description: error.response.data.message
                  });
              })
        }
    }
}

export default new unitController()