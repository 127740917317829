import { observer } from "mobx-react-lite";
import pc from "./controllers/properties.controller"
import i from '../../../translations/i'
import TemplateTitle from "../../template/title/TemplateTitle";
import React, {useEffect} from "react";
import ButtonAction from "../../template/buttons/ButtonsActions"
import {Button, Pagination, Space, Table, Tag, Tooltip} from "antd";

const ShopProperties = observer(() => {


    useEffect(() => {
        pc.getAllProperties()
    }, [])

    const breadcrumb = [
        { key: "shop", link: "/shop", value: i.t('crumb:shop') },
        { key: "element", link: null, value: i.t('crumb:props') }
    ]

    const buttons = [
        { title: i.t('def:actions.create'), type: "link", link: "/shop/properties/0" }
    ]

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: i.t('def:name'),
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: i.t('def:type'),
            dataIndex: 'type',
            key: 'type'
        },
        {
            title: i.t('orders:actions'),
            key: 'action',
            align: 'right',
            render: (text, record) => (
                <>
                    <Space>
                        <ButtonAction
                            item={{
                                type: "edit",
                                typeButton: "link",
                                placeholder: i.t('def:actions.edit'),
                                url: "/shop/properties/" + record.id
                            }}
                        />
                        <ButtonAction
                            item={{
                                type: "delete",
                                typeButton: "button",
                                placeholder: i.t('def:actions.remove'),
                                onClick: () => pc.delete(record.id)
                            }}
                        />
                    </Space>
                </>
            ),
        },
    ];


    return(
        <>
            <TemplateTitle
                title={i.t('crumb:props')}
                breadcrumb={breadcrumb}
                buttons={buttons}
            />
            <Table
              columns={columns}
              dataSource={pc.properties}
              pagination={{
                current: pc.pagination.page,
                total: pc.pagination.total,
                pageSize: pc.pagination.limit,
                showSizeChanger: false,
                onChange: (e) => pc.setOffset(e)
              }}
            />
        </>
    )
})

export default ShopProperties