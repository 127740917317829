import cryptoJS from 'crypto-js/md5'

const hashKey = process.env.REACT_APP_HASH_KEY

export const setHash = (params: any) => {
    let string = ''
    for (let item of params.entries()) {
        string = string + '-' + item[1]
    }
    string = string + hashKey
    string = string.slice(1)

    return cryptoJS(string).toString()
}

export const setHashDelete = (items: any) => {
    let string = ''
    for (let item in items) {
        string = string + '-' + items[item]
    }
    string = string + hashKey
    string = string.slice(1)

    return cryptoJS(string).toString()
}
