import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import controller from '../settings/project/project.controller'
import __designer from './__designer'
import i from '../../translations/i'
import { Titles } from '../../layouts/Titles'
import { Button, Col, Row, Select, Image, Alert, Divider, Switch } from "antd";
import { WarehousesController } from '../settings/warehouses/warehouses.controller'
import { SwitchBlock } from '../../components/SwitchBlock'
import { DesignerModal } from './designer_modules/DesignerModal'
import { ActionsConstructor } from './designer_modules/ActionsConstructor'
import { storeLocal } from "../../helpers/stores";
import { ErrorByPricingPlan } from "../../components/ErrorByPricingPlan";

const Storage = new WarehousesController()

export const OnBoarding = observer(({onBoarding = true}: any) => {

    useEffect(() => {
        if(!onBoarding) {
            Storage.pagination.limit = 999
            Storage.get()
        }
        controller.get()
        __designer.isOnBoarding = onBoarding
        __designer.onBoardingType = onBoarding ? 'onboarding' : 'promo_slides'
        __designer.getList(__designer.onBoardingType)
    }, [])

    let breadcrumbsItems = [
        {title: i.t('def:settings'), href: '/settings'},
        { title: i.t('nav:onBoarding') },
    ]

    if(!onBoarding) breadcrumbsItems = []

    const typeKey: string = onBoarding ? 'onboarding_view_type' : 'promo_slide_view_type'
    const enabledKey: string = onBoarding ? 'onboarding_enabled' : 'promo_slide_enabled'

    return <>
        <Titles
            title={onBoarding ? i.t('nav:onBoarding') : i.t('def:Promo Slides')}
            buttons={[
                // { title: i.t('def:actions.backList'), type: 'dashed', href: `/settings` },
            ]}
            breadcrumb={breadcrumbsItems}
        />

        {onBoarding &&
            <>
                <Alert message={i.t('def:onBoardingAlert')} type="info" />
                <Divider />
            </>
        }

        <Row gutter={[20, 20]} className="designer">
            {(!onBoarding) &&
                <Col span={24}>
                    <Row gutter={[10,10]}>
                        <Col>
                            <Select
                                value={__designer.selectedWarehouse > 0 ? __designer.selectedWarehouse : null}
                                style={{ width: "250px" }}
                                onChange={(e) => __designer.setWarehouse(e, 'promo_slides')}
                            >
                                <Select.Option value={null}>{i.t("constructor:storages")}</Select.Option>
                                {Storage.list.map((item: any) => (
                                    <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
                                ))}
                            </Select>
                        </Col>
                    </Row>
                </Col>
            }


            <Col span={24} className="designer__blocks">
                <Row gutter={[10, 10]}>
                    <div className="designer__block designer__block-opened">
                        <Row gutter={[20,20]} style={{ width: `100%` }}>
                            <Col span={12}>
                                <label className="formLabel">{i.t('onboarding:type')}</label>
                                <Select
                                    style={{ width: `100%` }}
                                    options={[
                                        { label: i.t('onboarding:items.one'), value: 1 },
                                        { label: i.t('onboarding:items.two'), value: 2 },
                                        { label: i.t('onboarding:items.three'), value: 3 },
                                        { label: i.t('onboarding:items.four'), value: 4 },
                                    ]}
                                    onChange={(e) => controller.set(typeKey, e)}
                                    value={onBoarding ? controller.project.onboarding_view_type : controller.project.promo_slide_view_type}
                                />
                            </Col>

                            <Col>
                                <label className="formLabel">&nbsp;</label>
                                <SwitchBlock array={[{
                                    label: i.t('def:active'),
                                    click: (e: any) => controller.set(enabledKey, e),
                                    value: onBoarding ? controller.project.onboarding_enabled : controller.project.promo_slide_enabled
                                }]} />
                            </Col>

                            <Col>
                                <label className="formLabel">&nbsp;</label>
                                <ErrorByPricingPlan
                                  plan={!onBoarding ? 2 : 1}
                                  content={
                                      <Button
                                          type="primary"
                                          shape="round"
                                          onClick={() => controller.saving()}
                                          disabled={!onBoarding ? storeLocal.get('pricing_plan') < 2 : storeLocal.get('pricing_plan') < 1}
                                      >
                                        {i.t('def:actions.save')}
                                      </Button>
                                  }
                                />
                            </Col>
                        </Row>
                        <div className="block__elements">
                            <Row gutter={[10, 10]}>
                                <Col span={24}>
                                    <ErrorByPricingPlan
                                      plan={!onBoarding ? 2 : 1}
                                      content={
                                          <Button
                                            type="primary"
                                            shape="round"
                                            onClick={() => __designer.openModal('onboarding')}
                                            disabled={!onBoarding ? storeLocal.get('pricing_plan') < 2 : storeLocal.get('pricing_plan') < 1}
                                          >
                                              {i.t('def:Add Slide')}
                                          </Button>
                                      }
                                    />
                                </Col>


                                {__designer.list.map((el: any, key: number) => {
                                    let actions: any = [
                                        {type: 'edit', onClick: () => __designer.openModal('onboarding', el.id, el.id)},
                                        {type: 'moveUp', onClick: () => __designer.sortBlock('up', el.id, 'onboarding')},
                                        {type: 'moveDown', onClick: () => __designer.sortBlock('down', el.id, 'onboarding')},
                                        {type: 'delete', onClick: () => __designer.deleteOne('onboarding', el.id)}
                                    ]

                                    // @ts-ignore
                                    actions = [{type: 'switch', onClick: () => __designer.toggleActive('onboarding', el.id, el.id), value: el.enabled}].concat(actions)

                                    let image = el?.file?.uri
                                    if(image) {
                                        image = `${el.file.base}${el.file.uri}`
                                    }

                                    return <Col span={24} key={key}>
                                        <div className="designer__block designer__block-withImage">
                                            <div className="block__image">
                                                {image &&
                                                    <Image
                                                        src={image}
                                                        alt={el?.name ?? ''}
                                                        preview={{
                                                            toolbarRender: () => <></>
                                                        }}
                                                    />
                                                }
                                            </div>

                                            <div className="block__title">
                                                <div className="block__name">
                                                    #{el.id}{el?.label ? ` | ${el.label}` : ''}
                                                </div>

                                                {el.refer?.type &&
                                                    <div className="block__refer">
                                                        {__designer.getReferNameByType(el.refer.type)}
                                                    </div>
                                                }
                                            </div>

                                            <ActionsConstructor actions={actions} />
                                        </div>
                                    </Col>
                                })}
                            </Row>
                        </div>
                    </div>
                </Row>
            </Col>
        </Row>

        <DesignerModal />
    </>
})