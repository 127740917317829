import { observer } from 'mobx-react-lite'
import i from '../../../translations/i'
import { Titles } from '../../../layouts/Titles'
import { Button, Space, Table, Tooltip, Popconfirm } from 'antd'
import pushesController, { PushesImageInterface } from './pushes.controller'
import { SettingOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { useEffect } from 'react'
import moment from 'moment'
import { moment_custom } from '../../../helpers/timestamp/datetime.helper'
import { PushesTemplates } from './PushesTemplates'
import dayjs from 'dayjs'

export const Pushes = observer(() => {

    useEffect(() => {
        pushesController.get()
    }, [])

    const buttons = [
        { title: i.t('pushes:templates.title'), href: `/pushes/templates`, type: 'dashed' },
        { title: i.t('def:actions.create'), href: `/pushes/0`, type: 'primary' }
    ]

    const columns = [
        Table.EXPAND_COLUMN,
        {
            title: i.t('pushes:pushes.table.title'),
            dataIndex: 'title',
            key: 'title'
        },
        Table.SELECTION_COLUMN,
        {
            title: i.t('pushes:pushes.table.sent'),
            dataIndex: 'sent',
            key: 'sent',
            render: (sent: boolean) => sent ? i.t('def:yes') : i.t('def:no')
        },
        {
            title: i.t('pushes:pushes.table.postpone_to'),
            dataIndex: 'postpone_to',
            key: 'postpone_to',
            render: (value: number) => moment_custom(value*1000, "LLL")
        },
        {
            title: i.t('pushes:pushes.table.devices'),
            dataIndex: 'devices',
            key: 'devices'
        },
        {
            title: i.t('pushes:pushes.table.count_sent'),
            dataIndex: 'count_sent_ios',
            render: (text: any, item: any) => `${item.count_sent_ios} / ${item.count_sent_android}`
        },
        {
            title: i.t('points:actions'),
            key: 'action',
            render: (text: any, record: any) => (
                <>
                    <Space>
                        {record.postpone_to > dayjs().unix() &&
                            <Tooltip title={i.t('def:actions.edit')}>
                                <Button
                                    type="dashed"
                                    shape="circle"
                                    icon={<EditOutlined />}
                                    href={`/pushes/${record.id}`}
                                />
                            </Tooltip>
                        }
                        <Tooltip title={i.t('def:actions.remove')}>
                            <Popconfirm
                                placement="left"
                                title={i.t('def:confirms.delete')}
                                onConfirm={() => pushesController.delete(record.id)}
                                // onCancel={cancel}
                                okText={i.t('def:yes')}
                                cancelText={i.t('def:no')}
                            >
                                <Button
                                    danger
                                    type="dashed"
                                    shape="circle"
                                    icon={<DeleteOutlined />}
                                />
                            </Popconfirm>
                        </Tooltip>
                    </Space>
                </>
            ),
        },
    ]

    return(
        <>
            <Titles
                title={i.t('pushes:pushes.title')}
                buttons={buttons}
            />

            <Table
                columns={columns}
                dataSource={pushesController.list.list}
                rowKey="id"
                expandable={{
                    expandedRowRender: (record: any) => <p style={{ margin: 0 }}>{record.description}</p>,
                }}
                pagination={{
                    total: pushesController.list.count,
                    pageSize: pushesController.list.limit,
                    onChange: (page, pageSize) => {
                        pushesController.list.offset = page > 1 ? (page * pushesController.list.limit) - pushesController.list.limit : 0
                        pushesController.get()
                    },
                    onShowSizeChange: (current, size) => {
                        pushesController.list.limit = size
                        pushesController.list.offset = 0
                        pushesController.get()
                    }
                }}
            />
        </>
    )
})