import { makeAutoObservable } from 'mobx'
import { openApi } from '../../../helpers/request'
import { FormEvent } from 'react'
import i from '../../../translations/i'
import { notification } from 'antd'
import __multiple from '../../../helpers/multiple_actions/__multiple'

interface FormInterface {
    id: number
    enabled: boolean
    title: string
    description: string
    webhook: boolean,
    elements: any
    picture: any
    type_view: string
    show_in_list: boolean
    success_title: string
    success_description: string
}

interface ElementInterface {
    id: number
    enabled: boolean
    is_filled: boolean
    form_id: number
    type: string
    required: boolean
    multiple: boolean
    label: string
    description: string
    placeholder: string
    is_hidden: boolean
    hidden_value: string
    min: number
    max: number
    options: any
    comparisons: any
    picture: any
}

class formsController {

    constructor() {
        makeAutoObservable(this)
    }

    list: FormInterface[] = []
    form: FormInterface = {
        id: 0,
        enabled: true,
        title: '',
        description: '',
        webhook: false,
        elements: [],
        picture: null,
        type_view: 'default',
        show_in_list: true,
        success_title: '',
        success_description: ''
    }

    element: ElementInterface = {
        id: 0,
        enabled: true,
        is_filled: true,
        form_id: 0,
        type: '',
        required: false,
        multiple: false,
        label: '',
        description: '',
        placeholder: '',
        is_hidden: false,
        hidden_value: '',
        min: 0,
        max: 0,
        options: [],
        comparisons: [],
        picture: null
    }

    selectedType = {
        min: false,
        max: false,
        options: false,
        multiple: false,
        type: '',
        comparisons: false,
        comparisons_type: 'string'
    }

    types = []

    pagination = {
        total: 0,
        limit: 10,
        page: 1,
        offset: 0
    }

    get(formId: string | number = 0, sorter: any = false) {
        let path = ''
        if(Number(formId) > 0) path = `/forms/${formId}`
        else {
            path = `/forms?limit=${this.pagination.limit}&page=${this.pagination.page}&enabled=false`
            if(sorter !== false){
                let direction = sorter.order
                if(direction !== undefined){
                    direction = (direction === 'ascend') ? 'asc' : 'desc'
                    path += `&sorting_type=${sorter.columnKey}&sorting_action=${direction}`
                }
            }
        }

        openApi('get', path)
            .then(result => {
                if(Number(formId) > 0) this.form = result.data
                else {
                    this.pagination.total = result.total
                    this.pagination.page = result.page
                    this.pagination.limit = result.limit
                    this.list = result.data
                }
            })
    }

    save(e: FormEvent) {
        e.preventDefault()

        let data = new FormData()
        data.append('enabled', `${+ this.form.enabled}`)
        data.append('title', this.form.title)
        if(this.form.description) data.append('description', this.form.description)
        data.append('webhook', `${+ this.form.webhook}`)
        data.append('picture', this.form.picture)
        data.append('type_view', this.form.type_view)
        data.append('show_in_list', `${+ this.form.show_in_list}`)
        data.append('success_title', this.form.success_title)
        data.append('success_description', this.form.success_description)

        let path = '/forms'
        if(this.form.id > 0) path += `/${this.form.id}`

        openApi('post', path, data)
            .then(result => {
                if(this.form.id === 0) window.location.href = `/shop/forms`
                else {
                    this.get(this.form.id)
                    notification.success({
                        message: i.t('def:success.complete'),
                        description: i.t('def:success.edit')
                    });
                }
            })
    }

    getElement(elementID: string | number = 0, formID: string | number) {
        if(elementID > 0){
            openApi('get', `/forms/element/${elementID}`, {})
                .then(result => {
                    this.element = result.data
                    this.selectedType = this.types.filter((el: any) => el.type === result.data.type)[0]
                })
        }
        this.element.form_id = Number(formID)
    }

    saveElement(e: FormEvent) {
        e.preventDefault()

        let data = new FormData()

        data.append('enabled', `${+ this.element.enabled}`)
        data.append('is_filled', `${+ this.element.is_filled}`)
        data.append('form_id', `${this.element.form_id}`)
        data.append('type', this.element.type)
        data.append('label', this.element.label)
        data.append('multiple', `${+ this.element.multiple}`)
        if(this.element.description) data.append('description', this.element.description)
        if(this.element.placeholder) data.append('placeholder', this.element.placeholder)
        data.append('min', `${this.element.min}`)
        data.append('max', `${this.element.max}`)
        data.append('picture', this.element.picture)
        if(this.element.options.length > 0){
            this.element.options.map((option: any, s: number) => {
                let optionForPost = {
                    value: option.value,
                    default: option.default,
                }
                if(option.id > 0) { // @ts-ignore
                    optionForPost['id'] = option.id
                }

                Object.keys(optionForPost).map((key: any) => {
                    data.append(`options[${s}][${key}]`, option[key])
                })
            })
        }

        if(this.selectedType?.comparisons === true && this.element.comparisons.length > 0){
            this.element.comparisons.map((comparison: any, s: number) => {
                let comparisonForPost = {label: comparison.label}
                if(comparison.id > 0) { // @ts-ignore
                    comparisonForPost['id'] = comparison.id
                }

                Object.keys(comparisonForPost).map((key: any) => {
                    data.append(`comparisons[${s}][${key}]`, comparison[key])
                })
            })
        }

        if(this.selectedType?.comparisons === true && (this.element.options.length > this.element.comparisons.length)){
            return notification.error({
                message: '',
                description: i.t('def:errors.comparisons')
            })
        }

        let path = '/forms/element'
        if(this.element.id > 0) path += `/${this.element.id}`

        openApi('post', path, data)
            .then(result => {
                if(this.element.id === 0) window.location.href = `/shop/forms/${this.element.form_id}`
                else {
                    this.getElement(this.element.id, this.element.form_id)
                    notification.success({
                        message: i.t('def:success.complete'),
                        description: i.t('def:success.edit')
                    });
                }
            })
    }

    getTypes() {
        openApi('get', '/forms/types')
            .then(result => {
                this.types = result.data
            })
    }

    remove(formID: string) {
        if(window.confirm(i.t('def:confirms.delete'))) {
            openApi('delete', `/forms/${formID}`)
                .then(result => {
                    this.get()
                })
        }
    }

    removeElement(elementID: string) {
        if(window.confirm(i.t('def:confirms.delete'))) {
            openApi('delete', `/forms/element/${elementID}`)
                .then(result => {
                    window.location.reload()
                })
        }
    }

    set(name: string, value: any) {
        //@ts-ignore
        this.form[name] = value
    }

    setElement(name: string, value: any) {
        //@ts-ignore
        this.element[name] = value
        if(name === 'type'){
            this.element.options = []
            // @ts-ignore
            this.element.is_filled = this.types.filter(el => el.type === value)?.[0]?.is_filled ?? false
        }
    }

    moveElement(feedbackID: number | string, elementID: number | string, action: string) {
        openApi('put', `/forms/sorting/elements/${feedbackID}/${elementID}/${action}`)
            .then(result => {
                this.form.elements = result.data
            })
    }

    removeOption(index: number, type: string = 'options') {
        // @ts-ignore
        let item = this.element[type][index]
        if(item.id > 0){
            if(window.confirm(i.t('def:confirms.delete'))) {
                openApi('delete', `/forms/option/${item.id}`)
                    .then(result => {})
            }
        }
        // @ts-ignore
        this.element[type].splice(index, 1)
    }

    moveOption(index: number, action: string, type: string = 'options') {
        // @ts-ignore
        let item = this.element[type][index]
        if(item.id > 0){
            openApi('put', `/forms/sorting/options/${this.element.id}/${item.id}/${action}`)
                .then(result => {
                    console.log(result)
                })
        }

        if(action === 'up'){
            if(index === 0) return
            // @ts-ignore
            [this.element[type][index], this.element[type][index - 1]] = [this.element[type][index - 1], this.element[type][index]]
        } else {
            // @ts-ignore
            if(index === this.element[type].length - 1) return
            // @ts-ignore
            [this.element[type][index], this.element[type][index + 1]] = [this.element[type][index + 1], this.element[type][index]]
        }
    }

    setPage(page: number) {
        this.pagination.page = page
        if (page > 0) {
            this.pagination.offset =
                this.pagination.limit * page - this.pagination.limit
        } else {
            this.pagination.offset = 0
        }
        this.get()
    }

    upload(image: any, type: string = 'form') {
        // @ts-ignore
        this[type].picture = image
        return false
    }

    removeImage(id: string | number, formID: string | number = 0, type: string = 'form') {
        console.log(id, formID, type)
        if(window.confirm(i.t('def:confirms.delete'))) {
            console.log('delete picture yes')
            let path
            if(type === 'form') path = `/forms/picture/${id}`
            else path = `/forms/element/picture/${id}`
            openApi('delete', path)
                .then(result => {
                    if(type === 'form') {
                        console.log(result.data)
                        this.form = result.data
                    } else {
                        this.getElement(id, formID)
                    }
                })
        }
    }

    readAll() {
        openApi('post', `/forms/read/forms`)
            .then(result => {
                this.get()
                __multiple.clear()
            })
    }

    multipleRead() {
        const data = new FormData()

        __multiple.selected.map((el: number) => data.append('elements[]', `${el}`))

        openApi('post', `/forms/read/forms`, data)
            .then(result => {
                this.get()
                __multiple.clear()
            })
    }

    multipleDeleteForms() {
        const data = new FormData()

        __multiple.selected.map((el: number) => data.append('elements[]', `${el}`))

        openApi('post', `/forms/delete/multiple`, data)
            .then(result => {
                this.get()
                __multiple.clear()
            })
    }
}

export default new formsController()