import {observer} from "mobx-react-lite";
import controller from './controller'
import React, {useEffect} from "react";
import i from "../../../translations/i";
import {Titles} from "../../../layouts/Titles";
import {Row, Col, Button, Input} from 'antd'
import {SettingOutlined} from "@ant-design/icons";
import {SketchPicker} from "react-color";
import {runInAction} from "mobx";
import { IconWaiting, IconWaitingOrder, IconReadyToShip2, IconReadyToShip1, IconReadyToShip, IconBack, IconCheck, IconCloses, IconCatfishPickup, IconDelivery, IconDone, IconNew, IconPacket } from './OrderStatusIcons'
import {SwitchBlock} from "../../../components/SwitchBlock";

export const OrderStatuses = observer(() => {

    useEffect(() => {
        controller.getList()
    }, [])

    const buttons = [
        { title: i.t('def:actions.create'), htmlType: `submit`, type: 'primary', click: () => controller.create() }
    ]

    return(
        <>
            <Titles
                title={i.t('orders:statuses')}
                buttons={buttons}
            />
            
            <Row gutter={[20, 20]}>
                {controller.list.map((el: any, index: number) => <Status status={el} key={`status_${index}`} />)}
            </Row>
        </>
    )
})

const Status = observer(({ status }: any) => {
    
    return(
        <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
            xl={{ span: 8 }}
            xxl={{ span: 8 }}
        >

            <form onSubmit={e => controller.setStatus(e)}>
                <Row className="order-status">
                    {status.id === controller.status.id
                        ? <></>
                        : <>
                            <div className="for"><Button onClick={() => controller.selectStatus(status.id)} shape="circle" type="dashed" icon={<SettingOutlined />} /></div>
                            <div className="icon">
                                {status.icon && status.icon !== "" &&
                                    <div className="tops">
                                        {status.icon === "ic_order_status_waiting" && <IconWaiting color={status.color_hex} />}
                                        {status.icon === "ic_order_status_waiting_order" && <IconWaitingOrder color={status.color_hex} />}
                                        {status.icon === "ic_order_status_ready_to_ship2" && <IconReadyToShip2 color={status.color_hex} />}
                                        {status.icon === "ic_order_status_ready_to_ship1" && <IconReadyToShip1 color={status.color_hex} />}
                                        {status.icon === "ic_order_status_ready_to_ship" && <IconReadyToShip color={status.color_hex} />}
                                        {status.icon === "ic_order_status_back" && <IconBack color={status.color_hex} />}
                                        {status.icon === "ic_order_status_check" && <IconCheck color={status.color_hex} />}
                                        {status.icon === "ic_order_status_closes" && <IconCloses color={status.color_hex} />}
                                        {status.icon === "ic_order_status_pickup" && <IconCatfishPickup color={status.color_hex} />}
                                        {status.icon === "ic_order_status_delivery" && <IconDelivery color={status.color_hex} />}
                                        {status.icon === "ic_order_status_done" && <IconDone color={status.color_hex} />}
                                        {status.icon === "ic_order_status_new" && <IconNew color={status.color_hex} />}
                                        {status.icon === "ic_order_status_packet" && <IconPacket color={status.color_hex} />}
                                    </div>
                                }
                            </div>
                        </>
                    }

                    <Col span={24}>
                        <p className="order_id">#{ status.id }</p>
                    </Col>
                    {status.id === controller.status.id ?
                        <Row gutter={[20, 20]}>
                            <Col span={24}>
                                <label className="formLabel">{i.t('orders:statusName')}</label>
                                <input
                                    type="text"
                                    className="status_input"
                                    value={controller.status.name}
                                    onChange={e => controller.onChangeStatus("name", e.target.value)}
                                    placeholder={i.t('orders:statusName')}
                                    required
                                />
                            </Col>
                            <Col span={24}>
                                <label className="formLabel">{i.t('orders:selectColor')}</label>
                                <SketchPicker
                                    color={`#${controller.status.color_hex}`}
                                    onChange={(e) => runInAction(() => controller.status.color_hex = (e.hex).replace('#', ''))}
                                />
                            </Col>
                            <Col span={24}>
                                <SwitchBlock array={[
                                    { label: i.t('def:status_order.is_initial'), click: (e: any) => controller.onChangeStatus('is_initial', e), value: controller.status.is_initial },
                                    { label: i.t('def:status_order.is_completed'), click: (e: any) => controller.onChangeStatus('is_completed', e), value: controller.status.is_completed },
                                    { label: i.t('def:status_order.is_canceled'), click: (e: any) => controller.onChangeStatus('is_canceled', e), value: controller.status.is_canceled },
                                ]} />
                            </Col>
                            <Col span={24}>
                                <label className="formLabel">{i.t('def:id_crm')}</label>
                                <Input
                                    value={controller.status.id_crm}
                                    onChange={(e) => controller.status.id_crm = e.target.value}
                                />
                            </Col>
                            <Col span={24}>
                                <label className="formLabel">{i.t('def:status_order.name_button_enter')}</label>
                                <Input
                                    value={controller.status.name_button_enter}
                                    onChange={(e) => controller.status.name_button_enter = e.target.value}
                                />
                            </Col>
                            <Col span={24}>
                                <label className="formLabel">{i.t('def:status_order.name_button_complete')}</label>
                                <Input
                                    value={controller.status.name_button_complete}
                                    onChange={(e) => controller.status.name_button_complete = e.target.value}
                                />
                            </Col>
                            <Col span={24}>
                                <label className="formLabel">{i.t('orders:selectIcon')}</label>

                                <Row>
                                    {[
                                        {key: 'ic_order_status_waiting', icon: <IconWaiting color={status.color_hex} />},
                                        {key: 'ic_order_status_waiting_order', icon: <IconWaitingOrder color={status.color_hex} />},
                                        {key: 'ic_order_status_ready_to_ship2', icon: <IconReadyToShip2 color={status.color_hex} />},
                                        {key: 'ic_order_status_ready_to_ship1', icon: <IconReadyToShip1 color={status.color_hex} />},
                                        {key: 'ic_order_status_ready_to_ship', icon: <IconReadyToShip color={status.color_hex} />},
                                        {key: 'ic_order_status_back', icon: <IconBack color={status.color_hex} />},
                                        {key: 'ic_order_status_check', icon: <IconCheck color={status.color_hex} />},
                                        {key: 'ic_order_status_closes', icon: <IconCloses color={status.color_hex} />},
                                        {key: 'ic_order_status_pickup', icon: <IconCatfishPickup color={status.color_hex} />},
                                        {key: 'ic_order_status_delivery', icon: <IconDelivery color={status.color_hex} />},
                                        {key: 'ic_order_status_done', icon: <IconDone color={status.color_hex} />},
                                        {key: 'ic_order_status_new', icon: <IconNew color={status.color_hex} />},
                                        {key: 'ic_order_status_packet', icon: <IconPacket color={status.color_hex} />},
                                    ].map((element: any) => (
                                        <Col span={6}>
                                            <div
                                                className={status.icon === element.key ? "icon_bg selected" : "icon_bg"}
                                                onClick={() => controller.onChangeStatus("icon", element.key)}
                                            >
                                                {element.icon}
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </Col>
                            <Col span={24}>
                                <Row gutter={[15, 15]}>
                                    <Col span={24}><Button htmlType="submit" block type="primary">
                                        {i.t('def:actions.save')}
                                    </Button></Col>
                                    <Col span={24}>
                                        <Button block type="primary" danger onClick={() => controller.deleteStatus(status.id)}>
                                            {i.t('def:actions.remove')}
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        :
                        <>
                            <Col span={24}>

                                <p className="order_name" style={{ color: "#" + status.color_hex }}>{ status.name }</p>
                            </Col>
                        </>
                    }
                </Row>
            </form>
        </Col>
    )
})