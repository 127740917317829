import { makeAutoObservable } from 'mobx'
import { openApi, request } from '../request'
import i from '../../translations/i'

interface SelectSelect {
    type: string
    value: any
    value_array: any[]
    element: any
}

class __select {

    limit = 10
    offset = 0
    page = 1
    total = 0

    filter_name: any = null

    categoryID = 0

    types = [
        { label: i.t('def:selectTypes.none'), value: '' },
        { label: i.t('def:selectTypes.productCategory'), value: 'category' },
        { label: i.t('def:Product Group'), value: 'product_group' },
        { label: i.t('def:selectTypes.cart'), value: 'cart' },
        { label: i.t('def:selectTypes.favorite'), value: 'favorite' },
        { label: i.t('def:selectTypes.cabinet'), value: 'cabinet' },
        { label: i.t('def:selectTypes.home'), value: 'home' },
        { label: i.t('def:selectTypes.news'), value: 'news' },
        { label: i.t('def:selectTypes.product'), value: 'product' },
        { label: i.t('def:Products List'), value: 'custom_product_list' },
        { label: i.t('def:selectTypes.contacts'), value: 'contacts' },
        { label: i.t('def:selectTypes.depart'), value: 'place' },
        { label: i.t('def:Places Group'), value: 'places_group' },
        { label: i.t('def:selectTypes.feedback'), value: 'feedback' },
        { label: i.t('def:selectTypes.questioning'), value: 'forms' },
        { label: i.t('def:selectTypes.url'), value: 'url' },
        { label: i.t('def:selectTypes.url_blank'), value: 'url_blank' },
        { label: i.t('def:selectTypes.jivo'), value: 'jivo' },
        { label: i.t('def:selectTypes.orders'), value: 'orders' },
        { label: i.t('def:selectTypes.notifications'), value: 'notifications' },
        { label: i.t('def:selectTypes.faq'), value: 'faq' },
        { label: i.t('def:selectTypes.promo'), value: 'promo' },
        { label: i.t('def:selectTypes.purchased_product'), value: 'purchased_product' },
    ]

    select: SelectSelect = {
        type: '',
        value: '',
        value_array: [],
        element: null
    }

    save_type: boolean = true

    list: any[] = []
    statuses: any = []

    elements: any = []
    element: any = null

    constructor() {
        makeAutoObservable(this)
    }

    clear() {

    }

    setTypesElements() {
        this.types = this.types.filter(el => el.value !== '')
    }

    getTypeByCode(code: string) {
        const index = this.types.findIndex(el => el.value === code)
        if(index > -1) return this.types[index].label
        return ''
    }

    getDefaultValueByType(type: string) {
        if(type === 'products') return []
        else if (type === 'custom_product_list') return
        else return ''
    }

    setValue(value: any) {
        console.log(value)
        this.select.value = value
    }

    setValueMultiple(value: any) {
        if(this.select.value_array.findIndex((el: any) => `${el}` === `${value}`) > -1) {
            this.select.value_array = this.select.value_array.filter((el: any) => `${el}` !== `${value}`)
        } else {
            let array = this.select.value_array
            array.push(value)
            this.select.value_array = array
        }
    }

    setElements(elements: any) {
        this.elements = elements
    }

    setElement(element: any) {
        this.element = element
    }

    setType(type: string, element: any = false) {

        this.limit = 10
        this.offset = 0
        this.page = 1
        this.total = 0

        this.select = {
            type: type,
            value: this.getDefaultValueByType(type),
            value_array: [],
            element: null
        }

        if(element) {
            if(Array.isArray(element)) this.select.value_array = element
            else this.select.value = element
        }
    }

    setPage(page: number) {
        this.offset = page > 1 ? (this.limit * page) - this.limit : 0
        this.page = page
    }

    getNews() {
        openApi('get', `/news?active=1&limit=${this.limit}&page=${this.page}`)
            .then(success => {
                console.log(success)
                success = success.data
                this.list = success.list
                this.total = success.count
            })
    }

    getProducts() {

        let uri = `/products/list?limit=${this.limit}&page=${this.page}&filter[view]=1&with[]=image`
        if(this.filter_name) uri += `&filter[name]=${this.filter_name}`

        openApi('get', uri)
            .then((result) => {
                this.list = result.data.map((el: any) => ({...el, key: el.id}))
                this.total = result.total
            })
          .catch(err => console.log(err.response))
    }

    getContacts() {
        request('get', '/info/contacts', {}, true)
            .then(result => {
                console.log(result)
                this.list = result.data
                // this.total = result.data[1]
            })
    }

    getPoints() {
        openApi('get', `/places?limit=${this.limit}&page=${this.page}`)
            .then((result) => {
                this.list = result.data
                this.total = result.total
            })
    }

    getPointsGroups() {
        openApi('get', `/places/groups?limit=${this.limit}&page=${this.page}`)
            .then((result) => {
                this.list = result.data
                this.total = result.total
            })
    }

    getPromo() {
        request('get', `/product/promocode?limit=${this.limit}&offset=${this.offset}`)
            .then((response) => {
                this.total = response[1]
                this.list = response[0]
            })
            .catch((response) => {})
    }

    getFeedback() {
        openApi('get', `/feedback?limit=${this.limit}&page=${this.page}`)
            .then(result => {
                console.log(result)
                this.total = result.size
                this.page = result.page
                this.limit = result.limit
                this.list = result.data
            })
    }

    getForm() {
        let path = `/forms?limit=${this.limit}&page=${this.page}`

        openApi('get', path)
            .then(result => {
                console.log(result)
                this.total = result.size
                this.page = result.page
                this.limit = result.limit
                this.list = result.data
            })
    }

    getCategories(categoryID = 0, refresh = false) {
        console.log('getCategories')
        this.categoryID = categoryID
        if(refresh) {
            this.offset = 0
            this.page = 1
        }
        request('get', `/product/category/${categoryID}?limit=${this.limit}&offset=${this.offset}`)
            .then(result => {
                this.list = result.category.map((el: any) => ({...el, key: el.id}))
                this.total = result.count
            })
    }

    getStatuses() {
        request('get', '/shop/statuses/', {}, true).then((success) => {
            this.list = success.data[0]
            this.statuses = success.data[0]
        })
    }

}

export default new __select()