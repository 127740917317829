import { observer } from 'mobx-react-lite'
import { Button, Col, Row, Space } from 'antd'
import { __cpl } from './__cpl'
import i from '../../../translations/i'
import React, { FormEvent } from "react";
import { CustomProductListFields } from './CustomProductListFields'


export const CustomProductList = observer(() => {

    return <>
        <Col span={24}>
            <Row gutter={[20, 20]}>
                <Col span={24}>
                    <Row align="middle" justify="space-between" className="title_page">
                        <h1>{i.t('def:List Settings')}</h1>
                        <Space>
                            <Button
                              type="dashed"
                              onClick={() => {
                                  __cpl.selectedList = null
                                  __cpl.getList()
                              }}
                            >
                                {i.t('def:actions.back')}
                            </Button>
                            <Button
                              type="primary"
                              onClick={() => __cpl.save("group")}
                            >
                                {i.t('def:actions.save')}
                            </Button>
                        </Space>
                    </Row>
                </Col>
            </Row>
            <CustomProductListFields />
        </Col>
    </>
})