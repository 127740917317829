import { makeAutoObservable } from 'mobx'
import { request } from '../../../../helpers/request'

class importStatisticController {

    list = []

    constructor() {
        makeAutoObservable(this)
    }

    get() {
        request('get', `/product/synchronization`)
            .then((response) => {
                console.log(response)
                if (response.length > 0) {
                    this.list = response
                }
            })
            .catch((response) => {
                console.log(response)
            })
    }
}

export default new importStatisticController()