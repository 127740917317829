import { observer } from 'mobx-react-lite'
import __points from './controllers/__points'
import React, { FormEvent, memo, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import i from '../../../translations/i'
import { Titles } from '../../../layouts/Titles'
import {
    Col,
    Divider,
    Input,
    InputNumber,
    Row,
    TimePicker,
    Space,
    Radio,
    Button,
    Switch,
    Table,
    Upload,
    Segmented
} from 'antd'
import { IPlacePhone } from './types'
import { InboxOutlined } from '@ant-design/icons'
import { runInAction } from 'mobx'

export const Place = observer(() => {

    let { id }: any = useParams()

    useEffect(() => {
        __points.getOne(id)
        __points.getPropertiesList(99999)
    }, [])

    const tabs = [
        { label: i.t('product:tabMain'), value: 'main' },
        { label: i.t('def:Images'), value: 'images' },
        { label: i.t('def:Groups'), value: 'groups' },
        { label: i.t('def:Characteristics'), value: 'characteristics' },
    ]

    return(
        <>
            <form onSubmit={(e: FormEvent) => __points.save(e)}>
                <Titles
                    title={i.t('points:editPoint')}
                    buttons={[
                        { title: i.t('def:actions.backList'), type: 'dashed', href: `/settings/places` },
                        { title: i.t('def:actions.save'), type: 'primary', htmlType: 'submit' }
                    ]}
                    breadcrumb={[
                        {title: i.t('def:settings'), href: '/settings'},
                        { title: i.t('points:list'), href: '/settings/places' },
                        { title: i.t('points:editPoint') },
                    ]}
                />

                <Segmented
                    value={__points.selectedTab}
                    options={tabs.map((el: any) => ({ label: el.label, value: el.value }))}
                    onChange={(e: any) => __points.setTab(e)}
                />

                <Divider />

                <Row gutter={[20, 20]}>
                    {__points.selectedTab === 'main' &&
                        <>
                            <Col xs={24} className="switch-block">
                                <div className="element" style={{margin: '0 0 10px'}}>
                                    <div></div>
                                    <Switch
                                        checked={__points.place.enabled}
                                        onChange={e => __points.place.enabled = e}
                                        size="small"
                                    />
                                    <div>
                                        <span>{i.t('settings:cities.form.enabled')}</span>
                                    </div>
                                </div>
                            </Col>
                            <Col span={12}>
                                <label className="formLabel">{i.t('def:name')}</label>
                                <Input
                                    name="title"
                                    placeholder={i.t('def:name')}
                                    value={__points.place.name}
                                    onChange={(e) => __points.place.name = e.target.value}
                                />
                            </Col>
                            <Col span={12}>
                                <label className="formLabel">{i.t('def:id_crm')}</label>
                                <Input
                                  placeholder={i.t('def:id_crm')}
                                  value={__points.place.id_crm ?? ''}
                                  onChange={(e) => __points.place.id_crm = e.target.value ? e.target.value : null}
                                />
                            </Col>

                            <Divider />

                            <Col span={24}>
                                <label className="title">{i.t('def:coordinates')}</label>
                            </Col>
                            <Col span={12}>
                                <label className="formLabel">{i.t('def:address')}</label>
                                <Input
                                    name="title"
                                    placeholder={i.t('def:address')}
                                    value={__points.place.address}
                                    onChange={(e) => __points.place.address = e.target.value}
                                    required
                                />
                            </Col>
                            <Col span={6}>
                                <label className="formLabel">{i.t('def:latitude')}</label>
                                <InputNumber
                                    style={{ width: `100%` }}
                                    placeholder="Широта"
                                    value={__points.place.latitude}
                                    onChange={(e) => e ? __points.place.latitude = e : __points.place.latitude = 0}
                                    required
                                />
                            </Col>
                            <Col span={6}>
                                <label className="formLabel">{i.t('def:longitude')}</label>
                                <InputNumber
                                    style={{ width: `100%` }}
                                    placeholder="Долгота"
                                    value={__points.place.longitude}
                                    onChange={(e) => e ? __points.place.longitude = e : __points.place.longitude = 0}
                                    required
                                />
                            </Col>

                            <Divider />

                            <Col span={24}>
                                <label className="title">{i.t('def:Contacts')}</label>
                            </Col>
                            <Col span={24}>
                                <Row gutter={[20, 20]}>
                                    {__points.place.phones.map((el: IPlacePhone, index: number) =>
                                        (
                                            <Col span={24} key={`phones_${index}`}>
                                                <Space>
                                                    <Input
                                                        placeholder={i.t('def:Change Contact')}
                                                        value={el.value}
                                                        onChange={(e) => __points.setPhone(index, e.target.value)}
                                                    />
                                                    <Input
                                                        placeholder={i.t('def:comment')}
                                                        value={el.description}
                                                        onChange={(e) => __points.setPhone(index, e.target.value, 'description')}
                                                    />
                                                    <Button onClick={() => __points.deletePhone(index)}>{i.t('def:actions.remove')}</Button>
                                                </Space>
                                            </Col>
                                        )
                                    )}
                                    <Col span={24}>
                                        <Button type="dashed" onClick={() => __points.addPhone()}>{i.t('def:Add Contact')}</Button>
                                    </Col>
                                </Row>
                            </Col>

                            <Divider />

                            <Col span={24}>
                                <Row gutter={[20,20]} align="middle">
                                    <Col>
                                        <label className="title">{i.t('def:schedule')}</label>
                                    </Col>
                                    <Col>
                                        <div className="switch-block">
                                            <div className="element" style={{margin: '0 0 10px'}}>
                                                <div></div>
                                                <Switch
                                                    checked={__points.place.schedule}
                                                    onChange={e => __points.place.schedule = e}
                                                    size="small"
                                                />
                                                <div>
                                                    <span>{i.t('settings:cities.form.enabled')}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            {(__points.place.schedule) &&
                                <Col span={24}>
                                    <Row gutter={[20, 20]}>
                                        {__points.days.map((day: string) => (
                                            <PlaceDaySchedule
                                                key={`day_${day}`}
                                                day={day}
                                                value={__points.getStatusDay(day)}
                                                time={__points.getTimeDay(day)}
                                            />
                                        ))}
                                    </Row>
                                </Col>
                            }

                            <Divider />
                        </>
                    }

                    {__points.selectedTab === 'images' &&
                        <>
                            <Col span={24}>
                                <label className="title">{i.t('def:Images')}</label>
                            </Col>
                            <Col span={24}>
                                <Upload
                                    multiple={true}
                                    beforeUpload={(e: any) => {
                                        let reader = new FileReader()
                                        reader.readAsDataURL(e)
                                        reader.onloadend = function () {
                                            e.url = reader.result
                                            runInAction(() => __points.toggleImage(e))
                                        }

                                        return false
                                    }}
                                    onRemove={(e) => runInAction(() => __points.toggleImage(e))}
                                    fileList={__points.images ?? []}
                                    showUploadList={true}
                                    listType="picture-card"
                                >
                                    <Button icon={<InboxOutlined />}>{i.t('def:actions.upload')}</Button>
                                </Upload>
                            </Col>
                        </>
                    }

                    {__points.selectedTab === 'groups' &&
                        <>
                            <Col span={24}>
                                <label className="title">{i.t('def:Groups')}</label>
                            </Col>
                            <Col span={24}>
                                <SelectGroups elementID={__points.selectedGroups} />
                            </Col>
                        </>
                    }

                    {__points.selectedTab === 'characteristics' &&
                        <>
                            <Col span={24}>
                                <label className="title">{i.t('def:Characteristics')}</label>
                            </Col>
                            <Col span={24}>
                                {__points.propertiesList.map((property: any, index: number) => {
                                    return (<React.Fragment key={index}>
                                        <p className="product-filters-title">{property.name}</p>
                                        <div className="product-filters-elements">
                                            {property.values.map((v: any, index: number) =>
                                                <span
                                                    key={index}
                                                    onClick={() => __points.selectProperty(v.id)}
                                                    className={__points.selectedProperties.find((elem: any) => elem === v.id) ? 'active' : ''}
                                                >{v.value}</span>
                                            )}
                                        </div>
                                    </React.Fragment>)
                                })}

                            </Col>
                        </>
                    }
                </Row>
            </form>
        </>
    )
})

const PlaceDaySchedule = memo(({ day, value, time = [] }: { day: string, value: number, time: any }) => {

    return (
        <Col span={24}>
            <label className="formLabel">{i.t(`def:days.${day}`)}</label>
            <Space>
                <Radio.Group
                    options={[
                        { label: i.t('def:workDay'), value: 1 },
                        { label: i.t('def:workDayFull'), value: 2 },
                        { label: i.t('def:workDayOff'), value: 0 }
                    ]}
                    onChange={(e: any) => __points.setStatusDay(day, e.target.value)}
                    value={value}
                    optionType="button"
                />
                <TimePicker.RangePicker
                    format="HH:mm"
                    onChange={(time, timeString) => __points.setScheduleDay(day, timeString)}
                    value={time}
                    disabled={value !== 1}
                    placeholder={[i.t('def:open'), i.t('def:close')]}
                />
            </Space>
        </Col>
    )
})

const SelectGroups = observer(({ elementID }: any) => {

    useEffect(() => {
        __points.getGroupsList(99999)
    }, [])

    const columns = [
        {
            title: "ID",
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: i.t('def:name'),
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: i.t('def:actions.action'),
            render: (record: any) =>
                <Button
                    type={elementID.findIndex((el: any) => `${el}` === `${record.id}`) > -1 ? 'primary' : 'dashed'}
                    onClick={() => __points.selectGroup(record.id)}
                >
                    {elementID.findIndex((el: any) => `${el}` === `${record.id}`) > -1 ? i.t('def:selected') : i.t('def:select')}
                </Button>
        },
    ]

    return(
        <>
            <Row gutter={[20,20]}>
                <Col span={24}>
                    <Space>
                        <Button>{i.t('def:Selected Groups')}: {elementID.length}</Button>
                        <Button danger onClick={() => __points.selectedGroups = []}>{i.t('def:reset')}</Button>
                    </Space>
                </Col>
                <Col span={24}>
                    <Table
                        columns={columns}
                        dataSource={__points.groupsList}
                        pagination={{
                            position: ['bottomLeft'],
                            hideOnSinglePage: true,
                            pageSize: 99999,
                            showSizeChanger: false,
                        }}
                    />
                </Col>
            </Row>
        </>
    )
})