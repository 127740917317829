import { observer } from 'mobx-react-lite'
import { Button, Col, Divider, Input, Modal, Row, Segmented, Select, Space, Tooltip, Alert } from 'antd'
import { __cpl } from './__cpl'
import i from '../../../translations/i'
import { runInAction } from 'mobx'
import { DeleteOutlined } from '@ant-design/icons'
import propertiesController from '../../../modules/shop/properties/controllers/properties.controller'
import { SelectCategories, SelectProducts, SelectStatus } from '../../../helpers/select_entity/SelectEntity'
import __select from '../../../helpers/select_entity/__select.controller'
import { storeLocal } from "../../../helpers/stores";


export const CustomProductListFields = observer(() => {

    return <>
        <Row gutter={[20, 20]}>
            <Col span={12}>
                <label className="formLabel">{i.t('def:name')}</label>
                <Input
                    value={__cpl.item.name}
                    onChange={ (e) => runInAction(() =>__cpl.item.name = e.target.value) }
                />
            </Col>
            <Col span={12}>
                <label className="formLabel">{i.t('def:id_crm')}</label>
                <Input
                    value={__cpl.item.id_crm}
                    onChange={ (e) => runInAction(() =>__cpl.item.id_crm = e.target.value) }
                />
            </Col>
            <Col span={24}>
                <Divider />
            </Col>
            <Col span={24}>
                <Row gutter={[20,20]}>
                    <Col span={24}>
                        <Button
                            type="primary"
                            onClick={() => __cpl.addCondition()}
                        >{i.t('def:Add Condition')}</Button>
                    </Col>
                    {__cpl.item.conditions.map((el: any, index: number) => {

                        return (
                            <div className="list_condition" key={index}>
                                <Row justify="space-between">
                                    <Col><p className="sub">{i.t('def:Condition #')}{index + 1}</p></Col>
                                    <Col>
                                        <Tooltip title={i.t('categories:delete')}>
                                            <Button
                                                type="dashed"
                                                shape="circle"
                                                icon={<DeleteOutlined />}
                                                danger
                                                onClick={() => __cpl.removeCondition(index)}
                                            />
                                        </Tooltip>
                                    </Col>
                                </Row>

                                <Row gutter={[20, 20]}>
                                    {storeLocal.get('pricing_plan') < 2 ? <Col span={24}>
                                        <Alert message="Некоторые условия недоступны для Вашего тарифного плана" type="warning" />
                                    </Col> : <></>}
                                    <Col span={24}>
                                        <Segmented
                                            options={[
                                                { label: i.t('def:selectTypes.products'), value: 'products' },
                                                { label: i.t('def:selectTypes.productCategory'), value: 'category' },
                                                { label: i.t('def:Characteristic'), value: 'prop', disabled: storeLocal.get('pricing_plan') < 2 },
                                                { label: i.t('def:selectTypes.status'), value: 'type' },
                                                // { label: i.t('def:Product Settings'), value: 'option' },
                                                // { label: i.t('product:storage'), value: 'warehouse' },
                                            ]}
                                            onChange={(e) => __cpl.changeConditionType(String(e), index)}
                                            value={__cpl.item.conditions[index].type}
                                        />
                                    </Col>

                                    <Col span={24}>
                                        {__cpl.item.conditions[index].type === 'products' &&
                                            <>
                                                <Row gutter={[20, 20]}>
                                                    <Col span={24}>
                                                        <Space size="large">
                                                            <Button
                                                                type="primary"
                                                                onClick={() => __cpl.toggleModal(index)}
                                                            >{i.t('def:Select Products')}</Button>

                                                            <span>{i.t('def:selected_products')}: {__cpl.item.conditions[index]?.productIDs?.length ?? 0}</span>
                                                        </Space>
                                                    </Col>
                                                </Row>
                                            </>
                                        }

                                        {__cpl.item.conditions[index].type === 'category' &&
                                            <>
                                                <Row gutter={[20, 20]}>
                                                    <Col span={24}>
                                                        <Space size="large">
                                                            <Button
                                                                type="primary"
                                                                onClick={() => __cpl.toggleModal(index)}
                                                            >
                                                                {__cpl.item.conditions[index].category?.id ? (<>{i.t('def:Change Category')}</>) : (<>{i.t('def:select_category')}</>)}
                                                            </Button>
                                                            {__cpl.item.conditions[index].category?.id &&
                                                                <span>{i.t('def:Selected Category')}: {__cpl.item.conditions[index].category?.name ?? ''}</span>
                                                            }
                                                        </Space>
                                                    </Col>
                                                </Row>
                                            </>
                                        }

                                        {__cpl.item.conditions[index].type === 'prop' &&
                                            <Row gutter={[20,20]}>
                                                <Col span={12}>
                                                    <label>{i.t('def:Characteristic')}</label>
                                                    <Select
                                                        value={__cpl.item.conditions[index].label === '' ? '' : Number(__cpl.item.conditions[index].label)}
                                                        style={{ width: 100 + '%' }}
                                                        onChange={e => __cpl.changeCharacteristic(Number(e), index)}
                                                        showSearch={true}
                                                        filterOption={(input: string, option: any) => (option?.children ?? '').toLowerCase().includes(input.toLowerCase())}
                                                    >
                                                        {propertiesController.properties.map((el: any, s: number) => <Select.Option value={el.id}
                                                                                                             key={s}>{el.name}</Select.Option>)}
                                                    </Select>
                                                </Col>
                                                {__cpl?.properties[__cpl.item.conditions[index].label] &&
                                                    <Col span={12}>
                                                        <label>{i.t('def:Characteristic Value')}</label>
                                                        <Select
                                                            value={__cpl.item.conditions[index].value === '' ? '' : Number(__cpl.item.conditions[index].value)}
                                                            style={{ width: 100 + '%' }}
                                                            onChange={e => __cpl.item.conditions[index].value = e}
                                                            showSearch={true}
                                                            filterOption={(input: string, option: any) => (option?.children ?? '').toLowerCase().includes(input.toLowerCase())}
                                                        >
                                                            {__cpl?.properties[__cpl.item.conditions[index].label].values.map((el: any, s: number) => <Select.Option value={el.id}
                                                                                                                                key={s}>{el.value}</Select.Option>)}
                                                        </Select>
                                                    </Col>
                                                }
                                            </Row>
                                        }

                                        {__cpl.item.conditions[index].type === 'type' &&
                                            <>
                                                <Row gutter={[20, 20]}>
                                                    <Col span={24}>
                                                        <Space size="large">
                                                            <Button
                                                                type="primary"
                                                                onClick={() => __cpl.toggleModal(index)}
                                                            >{i.t('def:Select Status')}</Button>

                                                            {__cpl.item.conditions[index].value > 0 &&
                                                                <span>{i.t('def:Selected Status')}: {__select.statuses.find((el: any) => el.id == __cpl.item.conditions[index].value)?.name ?? ''}</span>
                                                            }
                                                        </Space>
                                                    </Col>
                                                </Row>
                                            </>
                                        }

                                        {__cpl.item.conditions[index].type === 'option' &&
                                            <>option</>
                                        }

                                        {__cpl.item.conditions[index].type === 'warehouse' &&
                                            <>warehouse</>
                                        }
                                    </Col>
                                </Row>
                            </div>
                        )
                    })}
                </Row>
            </Col>
        </Row>

        <Modal
            open={__cpl.modalOpened}
            footer={null}
            onCancel={() => __cpl.toggleModal()}
            width={1000}
            centered={true}
            maskClosable={false}
            className="cpl__modal"
        >
            <Button
                type="primary"
                onClick={() => __cpl.toggleModal()}
                className="cpl__modal-close"
            >{i.t('def:actions.save')}</Button>

            {__cpl.item.conditions?.[__cpl.productsModalSelectedIndex]?.type === 'products' &&
                <SelectProducts elementID={__select.select.value_array} elements={__cpl.selectedConditionProducts} />
            }

            {__cpl.item.conditions?.[__cpl.productsModalSelectedIndex]?.type === 'category' &&
                <SelectCategories elementID={__select.select.value} element={__cpl.item.conditions[__cpl.productsModalSelectedIndex]?.category ?? []} type="custom_list" />
            }

            {__cpl.item.conditions?.[__cpl.productsModalSelectedIndex]?.type === 'type' &&
                <SelectStatus elementID={__select.select.value} />
            }
        </Modal>
    </>
})