import { observer } from 'mobx-react-lite'
import {
    Button,
    Select,
    Switch,
    Input,
    InputNumber,
    Form,
    notification,
    Upload,
    Image,
    Space,
    Table, Tooltip, Popconfirm, Tag, Row, Col, Alert
} from 'antd'
import i from '../../../translations/i'
import { useEffect } from 'react'
import { Titles } from '../../../layouts/Titles'
import 'react-textarea-emoji/dist/index.css'
import { DeleteOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons'
import controller from './push_templates.controller'
import { useParams } from 'react-router-dom'
import { ErrorByPricingPlan } from '../../../components/ErrorByPricingPlan'
import { storeLocal } from '../../../helpers/stores'

export const PushesTemplates = observer(() => {

    useEffect(() => {
        controller.getTemplates()
    }, [])

    const buttons = [
        { title: i.t('def:actions.back'), href: `/pushes`, type: 'dashed' },
        { title: i.t('def:actions.create'), href: `/pushes/templates/0`, type: 'primary' },
    ]

    const columns = [
        {
            title: i.t('def:status'),
            dataIndex: 'enabled',
            key: 'enabled',
            render: (el: boolean) => el ? <Tag color="success">{i.t('def:states.included')}</Tag> : <Tag color="error">{i.t('def:states.turned')}</Tag>
        },
        {
            title: i.t('def:name'),
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: "Тип",
            dataIndex: 'type',
            key: 'type'
        },
        {
            title: i.t('points:actions'),
            key: 'action',
            render: (text: any, record: any) => (
                <>
                    <Space>
                        <Tooltip title={i.t('def:actions.edit')}>
                            <Button
                                type="dashed"
                                shape="circle"
                                icon={<EditOutlined />}
                                href={`/pushes/templates/${record.id}`}
                            />
                        </Tooltip>
                        <Tooltip title={i.t('def:actions.remove')}>
                            <Popconfirm
                                placement="left"
                                title={i.t('def:confirms.delete')}
                                onConfirm={() => controller.delete(record.id)}
                                okText={i.t('def:yes')}
                                cancelText={i.t('def:no')}
                            >
                                <Button
                                    danger
                                    type="dashed"
                                    shape="circle"
                                    icon={<DeleteOutlined />}
                                />
                            </Popconfirm>
                        </Tooltip>
                    </Space>
                </>
            ),
        },
    ]

    return(
        <>
            <Titles
                title={i.t('pushes:templates.title')}
                buttons={buttons}
            />

            <Table
                columns={columns}
                dataSource={controller.templates}
                rowKey="id"
                pagination={{
                    total: controller.pagination.count,
                    pageSize: controller.pagination.limit,
                    onChange: (page, pageSize) => {
                        controller.pagination.offset = page > 1 ? (page * controller.pagination.limit) - controller.pagination.limit : 0
                        controller.getTemplates()
                    },
                    onShowSizeChange: (current, size) => {
                        controller.pagination.limit = size
                        controller.pagination.offset = 0
                        controller.getTemplates()
                    }
                }}
            />
        </>
    )
})

const GetRenderImage = observer(({ el, key_element }: any) => {

    return(
        <Form.Item
            label={el.label}
        >
            <Space>
                {controller.template[key_element] && controller.template[key_element].base &&
                    <>
                        <Image
                            width={110}
                            src={`${controller.template[key_element].base}${controller.template[key_element].uri}`}
                        />
                        <Button onClick={() => controller.deleteImageFromTemplate()}>{i.t('def:actions.remove')}</Button>
                    </>
                }
                <Upload
                    beforeUpload={(info) => {
                        if(info.size > 500000) {
                            notification.error({
                                message: i.t('def:errors.fileSizeTitle'),
                                description: i.t('def:errors.fileSizeDescription')
                            });
                            return false
                        }
                        const isPNG = info.type === 'image/png';
                        if (!isPNG) {
                            notification.error({
                                message: i.t('def:errors.fileTypeTitle'),
                                description: i.t('def:errors.fileTypeDescription')
                            });
                            return false
                        }
                        controller.uploadTemplate(info)
                        return false
                    }}
                    listType="picture-card"
                    className="photo-uploader"
                    maxCount={1}
                >
                    <div>
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>{i.t('def:image.upload')}</div>
                    </div>
                </Upload>
            </Space>
        </Form.Item>
    )
})

const GetRenderText = observer(({ el, key_element }: any) => {

    return(
        <>
            <Form.Item
                label={el.label}
            >
                <Input.TextArea
                    value={controller.template[key_element]}
                    onChange={(e) => controller.template[key_element] = e.target.value}
                    required={el.required}
                />
            </Form.Item>
        </>
    )
})

const GetRenderNumber = observer(({ el, key_element }: any) => {

    return(
        <>
            <Form.Item
                label={el.label}
                help={el.props.comment}
            >
                <InputNumber
                    max={el.props.max}
                    min={el.props.min}
                    value={controller.template[key_element]}
                    onChange={(e) => e >= 0 ? controller.template[key_element] = e : 12}
                    required={el.required}
                />
            </Form.Item>
        </>
    )
})

const GetRenderString = observer(({ el, key_element }: any) => {

    return(
        <>
            <Form.Item
                label={el.label}
            >
                <Input
                    value={controller.template[key_element]}
                    onChange={(e) => controller.template[key_element] = e.target.value}
                    required={el.required}
                />
            </Form.Item>
        </>
    )
})

const GetRenderSelect = observer(({ el, key_element }: any) => {

    return(
        <>
            <Form.Item
                label={el.label}
            >
                <Select
                    options={el.enums}
                    value={controller.template[key_element]}
                    defaultValue={el.enums[0] && el.enums[0].value ? el.enums[0].value : null}
                    onChange={(e) => controller.template[key_element] = e}
                    defaultActiveFirstOption={true}
                />
            </Form.Item>
        </>
    )
})

const GetRenderByType = observer(({ key_element, element }: any) => {

    return(
        <>
            {element.type === 'image' && <GetRenderImage el={element} key_element={key_element} />}
            {element.type === 'text' && <GetRenderText el={element} key_element={key_element} />}
            {element.type === 'number' && <GetRenderNumber el={element} key_element={key_element} />}
            {element.type === 'string' && <GetRenderString el={element} key_element={key_element} />}
            {element.type === 'select' && <GetRenderSelect el={element} key_element={key_element} />}
        </>
    )
})

export const PushesTemplate = observer(() => {

    const { id } = useParams<any>()

    useEffect(() => {
        if(id > 0) controller.getTemplate(id)
    }, [])

    const buttons = [
        { title: i.t('def:actions.back'), href: `/pushes/templates`, type: 'dashed', plan: 2 }
    ]

    return(
        <>
            <Titles
                title={i.t('pushes:pushes.push')}
                buttons={buttons}
            />

            {(!controller.template.id || controller.template.id === 0) &&
                <Select
                    style={{ width: '300px' }}
                    options={[
                        {value: 'birthday', label: i.t('pushes:templates_types.birthday')},
                        {value: 'custom', label: i.t('pushes:templates_types.custom')},
                        {value: 'expire_bonus_points', label: i.t('pushes:templates_types.expire_bonus_points')},
                    ]}
                    onChange={(e) => controller.getForm(e)}
                />
            }

            {controller.template.type &&
                <form onSubmit={(e) => controller.save(e)}>
                    <Form.Item
                        label={controller.template.enabled ? i.t(`def:states.included`) : i.t(`def:states.turned`)}
                    >
                        <Switch
                            size="small"
                            checked={controller.template.enabled}
                            onChange={(e) => controller.template.enabled = e}
                        />
                    </Form.Item>

                    {controller.vars.length
                        ? <>
                            <Alert
                                type="success"
                                message={<Row>
                                    <Col span={24}>
                                        <p>{i.t('def:push_templates_vars')}</p>
                                    </Col>
                                    <Col span={24}>
                                        {controller.vars
                                            ? <>
                                                {controller.vars.map((var_el: any) => (
                                                    <p><Tag>{`{{${var_el.key}}}`}</Tag> - {var_el.value}</p>
                                                ))}
                                            </>
                                            : <></>
                                        }
                                    </Col>
                                </Row>}
                            />
                            <br/>
                        </>
                        : <></>
                    }

                    {Object.keys(controller.form).map((el: any) => {
                        return <GetRenderByType key_element={el} element={controller.form[el]} />
                    })}
                    <ErrorByPricingPlan
                        plan={2}
                        content={
                            <Button
                                type="primary"
                                htmlType="submit"
                                disabled={!storeLocal.get('pricing_plan') || storeLocal.get('pricing_plan') < 2}
                            >
                                {i.t('def:actions.save')}
                            </Button>
                        }
                    />

                </form>
            }
        </>
    )
})