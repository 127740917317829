import i18n from 'i18next'
import { storeLocal } from './stores'
import i from '../translations/i'

export const getNameLanguageFromCode = (code: string): string => {
    const allLanguages = [
        { name: 'Русский', 'code': 'ru' },
        { name: 'English', 'code': 'en' },
        { name: 'Українська', 'code': 'ua' },
        { name: 'عرب', 'code': 'ar' },
        { name: 'Polski', 'code': 'pl' },
    ]

    let index = allLanguages.findIndex(el => el.code === code)
    if(index > -1) return allLanguages[index].name
    return ''
}

export const getRateName = (rate: number): string => {

    let name = ''

    if(rate === 0) name = i.t('def:ratesName.first')
    if(rate === 1) name = i.t('def:ratesName.second')
    if(rate === 2) name = i.t('def:ratesName.third')
    if(rate === 3) name = i.t('def:ratesName.fourth')

    return name
}

export const setLanguage = (code: string) => {
    i18n.changeLanguage(code);
    storeLocal.set("lang", code)
    window.location.href = '/'
}