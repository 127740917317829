import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import i from '../../../translations/i'
import {
    Select,
    InputNumber,
    Table,
    Switch,
    Row,
    Col,
    Input,
    Space,
    Button,
    Checkbox,
    Upload,
    notification, Badge, Divider
} from 'antd'
import controller from './feedback.controller'
import { Titles } from '../../../layouts/Titles'
import { useParams } from 'react-router-dom'
import { DeleteOutlined, EditOutlined, UpCircleOutlined, DownCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { MultipleActions } from '../../../helpers/multiple_actions/MultipleActions'
import __multiple from '../../../helpers/multiple_actions/__multiple'

export const FeedbackElement = observer(() => {
    // @ts-ignore
    const { formID, elementID } = useParams()

    useEffect(() => {
        controller.getTypes()
        controller.getElement(elementID, formID)
    }, [])

    const buttons = [
        { title: i.t('def:actions.backList'), href: `/shop/feedback/${formID}`, type: 'dashed' },
        { title: i.t('def:actions.save'), htmlType: `submit`, type: 'primary' }
    ]

    return(
        <>
            <form onSubmit={e => controller.saveElement(e)}>
                <Titles
                    title={i.t('settings:feedback.elementDetail')}
                    buttons={buttons}
                />

                <Row gutter={[20, 20]}>
                    <Col xs={24} className="switch-block">
                        <Space>
                            <div className="element">
                                <div></div>
                                <Switch
                                    checked={controller.element.enabled}
                                    onChange={e => controller.setElement('enabled', e)}
                                    size="small"
                                />
                                <div>
                                    <span>{i.t('settings:feedback.form.enabled')}</span>
                                </div>
                            </div>
                            <div className="element">
                                <div></div>
                                <Switch
                                    checked={controller.element.required}
                                    onChange={e => controller.setElement('required', e)}
                                    size="small"
                                />
                                <div>
                                    <span>{i.t('settings:feedback.element.required')}</span>
                                </div>
                            </div>
                            <div className="element">
                                <div></div>
                                <Switch
                                    checked={controller.element.is_hidden}
                                    onChange={e => controller.setElement('is_hidden', e)}
                                    size="small"
                                />
                                <div>
                                    <span>{i.t('settings:feedback.element.is_hidden')}</span>
                                </div>
                            </div>
                            <div className="element">
                                <div></div>
                                <Switch
                                    checked={controller.element.multiple}
                                    onChange={e => controller.setElement('multiple', e)}
                                    size="small"
                                />
                                <div>
                                    <span>{i.t('def:multiple')}</span>
                                </div>
                            </div>
                        </Space>
                    </Col>
                    <Col xs={24} md={12}>
                        <label>{i.t('settings:feedback.element.type')}</label>
                        <Select
                            value={controller.element.type}
                            style={{ width: 100 + '%' }}
                            disabled={controller.element.id > 0}
                            onChange={e => {
                                // @ts-ignore
                                controller.selectedType = controller.types.filter(el => el.type === e)[0]
                                controller.setElement('type', e)
                            }}
                        >
                            {controller.types.map((el: any, s) => <Select.Option value={el.type} key={s}>{el.label}</Select.Option>)}
                        </Select>
                    </Col>
                    <Col xs={24} md={12}>
                        <label>{i.t('settings:feedback.element.label')}</label>
                        <Input
                            placeholder={i.t('settings:feedback.element.label')}
                            value={controller.element.label}
                            onChange={(e) => controller.setElement('label', e.target.value)}
                            required
                        />
                    </Col>
                    <Col xs={24} md={12}>
                        <label>{i.t('settings:feedback.element.placeholder')}</label>
                        <Input
                            placeholder={i.t('settings:feedback.element.placeholder')}
                            value={controller.element.placeholder}
                            onChange={(e) => controller.setElement('placeholder', e.target.value)}
                        />
                    </Col>

                    <Col xs={24} md={12}>
                    {controller.element.type == 'rating'
                        ? <>
                            <label>{i.t('def:countStars')}</label>
                            <Select
                                value={controller.element.max}
                                style={{ width: 100 + '%' }}
                                onChange={e => {
                                    controller.setRating(e)
                                }}
                                options={[
                                    { label: '5', value: 5 },
                                    { label: '10', value: 10 }
                                ]}
                            />
                        </>
                        : <>
                            {controller.selectedType?.min === true &&
                                <Col xs={24} md={12}>
                                    <label>{i.t('settings:feedback.element.min')}</label>
                                    <InputNumber
                                        style={{ width: 100 + '%' }}
                                        controls={false}
                                        value={controller.element.min}
                                        onChange={(e) => controller.setElement('min', e)}
                                    />
                                </Col>
                            }

                            {controller.selectedType?.max === true &&
                                <Col xs={24} md={12}>
                                    <label>{i.t('settings:feedback.element.max')}</label>
                                    <InputNumber
                                        style={{ width: 100 + '%' }}
                                        controls={false}
                                        value={controller.element.max}
                                        onChange={(e) => controller.setElement('max', e)}
                                    />
                                </Col>
                            }
                        </>
                    }
                    </Col>

                    {controller.selectedType?.options === true &&
                        <Col xs={24}>
                            <Row gutter={[20, 20]}>
                                {controller.element.options.map((item: any, k: number) => {
                                      return(
                                          <Col xs={24} key={k}>
                                              <Row gutter={[10, 10]} wrap={false} style={{alignItems: 'center'}}>
                                                  <Col>
                                                      <Row gutter={[10, 10]}>
                                                          <Col>
                                                              <Button type="text" onClick={e => controller.moveOption(k, 'up')}>
                                                                  <UpCircleOutlined />
                                                              </Button>
                                                          </Col>
                                                          <Col>
                                                              <Button type="text" onClick={e => controller.moveOption(k, 'down')}>
                                                                  <DownCircleOutlined />
                                                              </Button>
                                                          </Col>
                                                      </Row>
                                                  </Col>
                                                  <Col flex="auto">
                                                      <Input
                                                          style={{width: '100%'}}
                                                          value={controller.element.options[k].value}
                                                          onChange={e => controller.element.options[k].value = e.target.value}
                                                      />
                                                  </Col>
                                                  <Col>
                                                      <Checkbox
                                                          checked={controller.element.options[k].default}
                                                          onChange={e => {
                                                              controller.element.options.map((op: any, s: number) => controller.element.options[s].default = false)
                                                              controller.element.options[k].default = e.target.checked
                                                          }}
                                                      >
                                                          {i.t('settings:feedback.element.defaultOption')}
                                                      </Checkbox>
                                                  </Col>
                                                  <Col>
                                                      <Button
                                                          type="dashed"
                                                          shape="circle"
                                                          danger
                                                          icon={<DeleteOutlined />}
                                                          onClick={() => controller.removeOption(k)}
                                                      />
                                                  </Col>
                                              </Row>
                                          </Col>
                                      )
                                })}
                                <Col xs={24}>
                                    <Button
                                        type="primary"
                                        onClick={_ => controller.element.options.push({
                                            value: '',
                                            default: false,
                                        })}
                                    >
                                        {i.t('settings:feedback.element.addOption')}
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    }

                </Row>
            </form>
        </>
    )
})

export const FeedbackPage = observer(() => {
    // @ts-ignore
    const { formID } = useParams()

    useEffect(() => {
        controller.get(formID)
        controller.getTypes()
    }, [])

    const buttons = [
        { title: i.t('def:actions.backList'), href: `/shop/feedback`, type: 'dashed' },
        { title: i.t('def:actions.save'), htmlType: `submit`, type: 'primary' }
    ]

    const columns = [
        { title: 'ID', dataIndex: 'id', key: 'id' },
        {
            title: '',
            width: 140,
            render: (text: any, record: any) => {
                return(
                    <Row gutter={[10, 10]}>
                        <Col>
                            <Button type="text" onClick={e => controller.moveElement(record.feedback_id, record.id, 'up')}>
                                <UpCircleOutlined />
                            </Button>
                        </Col>
                        <Col>
                            <Button type="text" onClick={e => controller.moveElement(record.feedback_id, record.id, 'down')}>
                                <DownCircleOutlined />
                            </Button>
                        </Col>
                    </Row>
                )
            }
        },
        {
            title: i.t('settings:feedback.element.type'),
            dataIndex: 'type',
            key: 'type',
            render: (text: any, record: any) => {
                // @ts-ignore
                let typeName = controller.types.filter(el => el.type === text)?.[0]?.label ?? ''
                return (
                    <>{typeName}</>
                )
            }
        },
        {
            title: i.t('settings:feedback.form.enabled'),
            dataIndex: 'enabled',
            key: 'enabled',
            render: (text: any, record: any) => (
                <>{text === 0 ? i.t('def:no') : i.t('def:yes')}</>
            )
        },
        {
            key: 'action',
            render: (text: any, record: any) => (
                <Space size="middle">
                    <Button
                        type="dashed"
                        shape="circle"
                        icon={<EditOutlined />}
                        href={`/shop/feedback/${formID}/elements/${record.id}`}
                    />
                    <Button
                        type="dashed"
                        shape="circle"
                        danger
                        icon={<DeleteOutlined />}
                        onClick={() => controller.removeElement(record.id)}
                    />
                </Space>
            )
        }
    ]

    return(
        <>
            <form onSubmit={e => controller.save(e)}>
                <Titles
                    title={i.t('settings:feedback.titleDetail')}
                    breadcrumb={[
                        { title: i.t('settings:feedback.title'), href: `/shop/feedback` },
                        { title: i.t('settings:feedback.titleDetail') }
                    ]}
                    buttons={buttons}
                />

                <Row gutter={[20, 20]}>
                    <Col xs={24} className="switch-block">
                        <Space>
                            <div className="element">
                                <div></div>
                                <Switch
                                    checked={controller.form.enabled}
                                    onChange={e => controller.set('enabled', e)}
                                    size="small"
                                />
                                <div>
                                    <span>{i.t('settings:feedback.form.enabled')}</span>
                                </div>
                            </div>
                            <div className="element">
                                <div></div>
                                <Switch
                                    checked={controller.form.show_in_list}
                                    onChange={e => controller.set('show_in_list', e)}
                                    size="small"
                                />
                                <div>
                                    <span>{i.t('settings:feedback.form.showInList')}</span>
                                </div>
                            </div>
                            <div className="element">
                                <div></div>
                                <Switch
                                    checked={controller.form.webhook}
                                    onChange={e => controller.set('webhook', e)}
                                    size="small"
                                />
                                <div>
                                    <span>{i.t('settings:feedback.form.webhook')}</span>
                                </div>
                            </div>
                        </Space>
                    </Col>
                    <Col xs={24}>
                        <Row gutter={[20, 20]}>
                            <Col xs={24} md={12}>
                                <label>{i.t('def:name')}</label>
                                <Input
                                    name="title"
                                    placeholder={i.t('def:name')}
                                    value={controller.form.title}
                                    onChange={(e) => controller.set('title', e.target.value)}
                                />
                            </Col>
                            <Col xs={24} md={12}>
                                <label>{i.t('def:viewTypes.title')}</label>
                                <Select
                                    value={controller.form.type_view}
                                    style={{ width: 100 + '%' }}
                                    onChange={e => controller.set('type_view', e)}
                                    options={[
                                        {label: i.t('newsItem:items.one'), value: 'default'},
                                        {label: i.t('newsItem:items.two'), value: 'full'}
                                    ]}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24}>
                        <label>{i.t('settings:feedback.form.description')}</label>
                        <Input
                            name="title"
                            placeholder={i.t('settings:feedback.form.description')}
                            value={controller.form.description}
                            onChange={(e) => controller.set('description', e.target.value)}
                        />
                    </Col>
                    <Divider />
                    <Col xs={24}>
                        <label>{i.t('def:formSuccess')}</label>
                        <Input
                            placeholder={i.t('def:formSuccess')}
                            value={controller.form.success_title}
                            onChange={(e) => controller.set('success_title', e.target.value)}
                        />
                    </Col>
                    <Col xs={24}>
                        <label>{i.t('def:formSuccessDescription')}</label>
                        <Input
                            placeholder={i.t('def:formSuccessDescription')}
                            value={controller.form.success_description}
                            onChange={(e) => controller.set('success_description', e.target.value)}
                        />
                    </Col>
                    <Divider />
                    <Col xs={24} md={12}>
                        <label>{i.t('settings:cities.form.image')}</label>
                        {controller.form.picture?.id > 0 ? (
                            <Row gutter={[10, 10]}>
                                <Col xs={24}>
                                    <img src={controller.form.picture.base + controller.form.picture.uri} alt='' style={{maxWidth: '250px'}}/>
                                </Col>
                                <Col xs={24}>
                                    <Button
                                        onClick={e => controller.removeImage(formID)}
                                    >
                                        {i.t('def:deletePicture')}
                                    </Button>
                                </Col>
                            </Row>
                        ) : (
                            <Upload
                                beforeUpload={(info) => {
                                    if(info.size > 500000) {
                                        notification.error({
                                            message: i.t('def:errors.fileSizeTitle'),
                                            description: i.t('def:errors.fileSizeDescription')
                                        });
                                        return false
                                    }
                                    controller.upload(info)
                                    return false
                                }}
                                listType="picture-card"
                                className="photo-uploader"
                                maxCount={1}
                            >
                                <div>
                                    <PlusOutlined />
                                    <div style={{ marginTop: 8 }}>{i.t('def:image.upload')}</div>
                                </div>
                            </Upload>
                        )}
                    </Col>
                    {controller.form.id > 0 &&
                        <>
                            <Col xs={24}>
                                <Row gutter={[20, 20]} style={{justifyContent: 'space-between', alignItems: 'center'}}>
                                    <Col>
                                        <label style={{margin: 0}}>{i.t('settings:feedback.elementsList')}</label>
                                    </Col>
                                    <Col>
                                        <Button
                                            href={`/shop/feedback/${formID}/elements/0`}
                                            type="primary"
                                            shape="round"
                                        >
                                            {i.t('def:actions.create')}
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={24}>
                                <Table
                                    columns={columns}
                                    dataSource={controller.form.elements}
                                    pagination={{
                                        position: ['bottomLeft'],
                                        hideOnSinglePage: true,
                                        showSizeChanger: false,
                                    }}
                                />
                            </Col>
                        </>
                    }
                </Row>
            </form>
        </>
    )
})

export const SettingsFeedback = observer(() => {
    useEffect(() => {
        controller.get()
        __multiple.setActions([
            { label: i.t('def:delete_elements'), value: 'delete', action: () => controller.multipleDeleteFeedbacks() },
            { label: i.t('def:flag_read'), value: 'read', action: () => controller.multipleRead() },
        ])
    }, [])

    const buttons = [
        { title: i.t('def:flag_read_all'), click: () => controller.readAll(), type: 'dashed' },
        { title: i.t('def:actions.create'), href: `/shop/feedback/0`, type: 'primary', plan: 1 }
    ]

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: i.t('def:name'),
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: i.t('settings:feedback.answersCount'),
            dataIndex: 'answers_count',
            key: 'answers_count',
            sorter: true
        },
        {
            title: i.t('settings:feedback.form.enabled'),
            dataIndex: 'enabled',
            key: 'enabled',
            render: (text: any, record: any) => (
                <>{text === 0 ? i.t('def:no') : i.t('def:yes')}</>
            )
        },
        {
            key: 'action',
            render: (text: any, record: any) => (
                <Space size="middle">
                    <Badge count={record.not_viewed_count} style={{ backgroundColor: '#52c41a' }}>
                        <Button
                            type="dashed"
                            href={`/shop/feedback/${record.id}/answers`}
                        >
                            {i.t('def:answers')}
                        </Button>
                    </Badge>
                    <Button
                        type="dashed"
                        shape="circle"
                        icon={<EditOutlined />}
                        href={`/shop/feedback/${record.id}`}
                    />
                </Space>
            )
        }
    ]

    return (
        <>
            <Titles
                title={i.t('settings:feedback.title')}
                breadcrumb={[{ title: i.t('settings:feedback.title') }]}
                buttons={buttons}
            />

            <Table
                rowKey="id"
                rowSelection={{
                    onSelect: (record: any, selected: any, selectedRows: any) => {
                        __multiple.set(selectedRows)
                    },
                    onSelectAll: (selected: any, selectedRows: any) => {
                        __multiple.set(selectedRows)
                    },
                    type: 'checkbox',
                    selectedRowKeys: __multiple.selected
                }}
                columns={columns}
                dataSource={controller.list}
                pagination={{
                    position: ['bottomLeft'],
                    hideOnSinglePage: true,
                    current: Number(controller.pagination.page),
                    pageSize: controller.pagination.limit,
                    total: controller.pagination.total,
                    showSizeChanger: false,
                    onChange: (e) => controller.setPage(e)
                }}
                onChange={(pagination, filters, sorter, extra) => {
                    if(extra.action === 'sort') controller.get(0, sorter)
                }}
            />
        </>
    )
})

