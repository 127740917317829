class dateTimeHelper {

    dateUTC(timestamp) {
        let date = new Date(timestamp * 1000)
        return date.getUTCDate() + "." + ('0' + (date.getUTCMonth()+1)).slice(-2) +  "." + date.getUTCFullYear()
    }

    date(timestamp) {
        let date = new Date(timestamp * 1000)
        return date.getDate() + "." + ('0' + (date.getMonth()+1)).slice(-2) +  "." + date.getFullYear()
    }

    getFullDate(timestamp) {
        let date = new Date(timestamp * 1000)
        return `${('0' + (date.getDate())).slice(-2)}.${('0' + (date.getMonth()+1)).slice(-2)}.${date.getFullYear()} ${('0' + (date.getHours())).slice(-2)}:${('0' + (date.getMinutes())).slice(-2)}`
    }

    getFullTime(time) {
        let date = new Date(time * 1000)
        return `${('0' + (date.getHours())).slice(-2)}:${('0' + (date.getMinutes())).slice(-2)}`
    }

    getTime(time) {
        return ('0' + time).slice(-2)
    }

    getTimeByString(string) {
        string = string.split(":")
        let ret = ""
        string.map(item => ret = `${ret}${this.getTime(item)}:`)
        return ret.slice(0, -1)
    }

    formatTime(time) {
        // Разбиваем строку времени по символу ":"
        const parts = time.split(':');

        // Извлекаем часы и минуты
        let hours = parseInt(parts[0]);
        let minutes = parseInt(parts[1]);

        // Добавляем нули к часам и минутам, если они состоят из одной цифры
        hours = hours < 10 ? '0' + hours : hours;
        minutes = minutes < 10 ? '0' + minutes : minutes;

        // Возвращаем отформатированное время
        return hours + ':' + minutes;
    }


    getHoursAndMinutesFromSeconds(seconds) {
        const hours = Math.floor(seconds / 3600); // получаем количество часов
        const minutes = Math.floor((seconds % 3600) / 60); // получаем количество минут

        return this.formatTime(`${hours}:${minutes}`)

    }

}

export default new dateTimeHelper()