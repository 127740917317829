import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import i from '../../../translations/i'
import controller from '../project/project.controller'
import { Titles } from '../../../layouts/Titles'
import { Row, Col, Switch, Select, Divider, Input, Tooltip, Button, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'

export const WebSettings = observer(() => {

    const buttons = [
        { title: i.t('def:actions.save'), htmlType: `submit`, type: 'primary' }
    ]

    useEffect(() => {
        controller.get()
    }, [])

    const props = {
        accept: '.ico',
        onRemove: (file: any) => {
            controller.set('web_favicon', null)
        },
        beforeUpload: (file: any) => {
            console.log(file)

            if(file.name !== 'favicon.ico') console.log('Name error')

            controller.set('web_favicon', file)

            return false
        }
    }

    return(
        <>
            <form onSubmit={e => controller.save(e)}>
                <Titles
                    title={i.t('settings:web.title')}
                    buttons={buttons}
                />

                <Row gutter={[20, 20]}>
                    <Col span={24}>
                        <h3>{i.t('settings:web.template')}</h3>
                    </Col>
                    <Col span={8}>
                        <label>{i.t('settings:web.web_logotype_horizontal')}</label>
                        <Row gutter={[15, 15]}>
                            {controller.project.web_logotype_horizontal
                                ? typeof controller.project.web_logotype_horizontal === 'string'
                                    ? <Col span={24}><img src={controller.project.web_logotype_horizontal} alt='' style={{ width: '200px' }} /></Col>
                                    : <></>
                                : <></>
                            }
                            <Col span={24}>
                                <Upload
                                    accept=".png"
                                    onRemove={() => controller.set('web_logotype_horizontal', null)}
                                    beforeUpload={(file: any) => {
                                        controller.set('web_logotype_horizontal', file)
                                        return false
                                    }}
                                >
                                    <Button icon={<UploadOutlined />}>{controller.project.web_logotype_horizontal ? i.t('def:actions.replace') : i.t('def:actions.upload')}</Button>
                                </Upload>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={8}>
                        <label>{i.t('settings:web.favicon')}</label>
                        <Row gutter={[15, 15]}>
                            {controller.project.web_favicon
                                ? typeof controller.project.web_favicon === 'string'
                                    ? <Col span={24}><img src={controller.project.web_favicon} alt='' style={{ width: '200px' }} /></Col>
                                    : <></>
                                : <></>
                            }
                            <Col span={24}>
                                <Upload {...props}>
                                    <Button icon={<UploadOutlined />}>{controller.project.web_favicon ? i.t('def:actions.replace') : i.t('def:actions.upload')}</Button>
                                </Upload>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={8}>
                        <label>{i.t('settings:web.web_footer_app_image')}</label>
                        <Row gutter={[15, 15]}>
                            {controller.project.web_footer_app_image
                                ? typeof controller.project.web_footer_app_image === 'string'
                                    ? <Col span={24}><img src={controller.project.web_footer_app_image} alt='' style={{ width: '200px' }} /></Col>
                                    : <></>
                                : <></>
                            }
                            <Col span={24}>
                                <Upload
                                    accept=".png"
                                    onRemove={() => controller.set('web_footer_app_image', null)}
                                    beforeUpload={(file: any) => {
                                        controller.set('web_footer_app_image', file)
                                        return false
                                    }}
                                >
                                    <Button icon={<UploadOutlined />}>{controller.project.web_footer_app_image ? i.t('def:actions.replace') : i.t('def:actions.upload')}</Button>
                                </Upload>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <label>{i.t('settings:web.prefix')}</label>
                        <Input
                            value={controller.project.web_prefix ? controller.project.web_prefix : ''}
                            onChange={(e) => controller.set('web_prefix', e.target.value)}
                        />
                    </Col>
                    <Col span={24}>
                        <label>{i.t('settings:web.postfix')}</label>
                        <Input
                            value={controller.project.web_postfix ? controller.project.web_postfix : ''}
                            onChange={(e) => controller.set('web_postfix', e.target.value)}
                        />
                    </Col>
                    <Col span={24}>
                        <label>{i.t('settings:web.header')}</label>
                        <Input.TextArea
                            rows={10}
                            value={controller.project.web_header ? controller.project.web_header : ''}
                            onChange={(e) => controller.set('web_header', e.target.value)}
                        />
                    </Col>
                    <Col span={24}>
                        <label>{i.t('settings:web.footer')}</label>
                        <Input.TextArea
                            rows={10}
                            value={controller.project.web_footer ? controller.project.web_footer : ''}
                            onChange={(e) => controller.set('web_footer', e.target.value)}
                        />
                    </Col>
                    <Col span={24}>
                        <h3>{i.t('settings:web.main_page')}</h3>
                    </Col>
                    <Col span={24}>
                        <label>{i.t('settings:web.seo_title')}</label>
                        <Input
                            maxLength={255}
                            value={controller.project.web_main_title ? controller.project.web_main_title : ''}
                            onChange={(e) => controller.set('web_main_title', e.target.value)}
                        />
                    </Col>
                    <Col span={24}>
                        <label>{i.t('settings:web.seo_description')}</label>
                        <Input
                            maxLength={255}
                            value={controller.project.web_main_description ? controller.project.web_main_description : ''}
                            onChange={(e) => controller.set('web_main_description', e.target.value)}
                        />
                    </Col>
                    <Col span={24}>
                        <label>{i.t('settings:web.seo_keywords')}</label>
                        <Input
                            maxLength={255}
                            value={controller.project.web_main_keywords ? controller.project.web_main_keywords : ''}
                            onChange={(e) => controller.set('web_main_keywords', e.target.value)}
                        />
                    </Col>
                </Row>

            </form>
        </>
    )
})