import { observer } from 'mobx-react-lite'
import i from '../../translations/i'
import { Titles } from '../../layouts/Titles'
import controller from './contacts.controller'
import { Button, Pagination, Space, Table, Tag, Row, Col, Input, Select } from 'antd'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { ParamTypes } from '../../helpers/types.interfaces'

export const Contact = observer(() => {

    const { elementID } = useParams<ParamTypes>()

    useEffect(() => {
        controller.get(elementID)
    }, [])

    const buttons = [
        { title: i.t('def:actions.backList'), href: `/contacts`, type: 'dashed' },
        { title: i.t('def:actions.save'), htmlType: `submit`, type: 'primary' }
    ]

    return(
        <>
            <form onSubmit={(e) => controller.save(e)}>
                <Titles
                    title={i.t('contacts:titleDetail')}
                    buttons={buttons}
                />

                <Row gutter={[20, 20]}>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <label>{i.t('contacts:form.title')}</label>
                        <Input
                            name="title"
                            placeholder={i.t('contacts:form.title')}
                            value={controller.element.title}
                            onChange={(e) => controller.set('title', e.target.value)}
                        />
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <label>{i.t('contacts:form.type')}</label>
                        <Select
                            style={{ width: '100%' }}
                            value={controller.element.type}
                            placeholder={i.t('contacts:form.type')}
                            onChange={(e) => controller.set('type', e)}
                        >
                            {controller.types.map(el => (
                                <Select.Option
                                    key={`contact_select_${el.name}`}
                                    value={el.name}
                                >
                                    { el.description }
                                </Select.Option>
                            ))}
                        </Select>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 24 }}>
                        <label>{i.t('contacts:form.value')}</label>
                        <Input.TextArea
                            placeholder={i.t('contacts:form.value')}
                            value={controller.element.value}
                            onChange={(e) => controller.set('value', e.target.value)}
                        />
                    </Col>
                </Row>
            </form>
        </>
    )
})

export const Contacts = observer(() => {

    useEffect(() => {
        controller.get()
    }, [])

    const buttons = [
        { title: i.t('def:actions.create'), href: `/contacts/0`, type: 'primary' }
    ]

    const columns = [
        { title: 'ID', dataIndex: 'id', key: 'id' },
        { title: i.t('def:name'), dataIndex: 'title', key: 'title' },
        { title: i.t('def:actions.type'), dataIndex: 'type', key: 'type', render: (el: any) => <Tag>{controller.getTypeByName(el)}</Tag> },
        { title: i.t('def:actions.value'), dataIndex: 'value', key: 'value' },
        {
            key: 'action',
            render: (text: any, record: any) => (
                <Space size="middle">
                    <Button
                        type="dashed"
                        shape="circle"
                        icon={<EditOutlined />}
                        href={`/contacts/${record.id}`}
                    />
                    <Button
                        type="dashed"
                        shape="circle"
                        danger
                        icon={<DeleteOutlined />}
                        onClick={() => controller.remove(record.id)}
                    />
                </Space>
            )
        }
    ]

    return(
        <>
            <Titles
                title={i.t('contacts:title')}
                buttons={buttons}
            />

            <Table
                columns={columns}
                dataSource={controller.list}
                rowKey="id"
                pagination={false}
            />
            <br />
            <Pagination
                defaultCurrent={controller.pagination.page}
                current={controller.pagination.page}
                defaultPageSize={controller.pagination.limit}
                pageSize={controller.pagination.limit}
                total={controller.pagination.total}
                showSizeChanger={false}
                onChange={(e) => controller.setPage(e)}
            />
        </>
    )
})