import { returnParseProjectElement } from '../../helpers/project.controller'
import { YandexMapLocation } from './yandex/YandexMapLocation'
import { GoogleMapLocation } from './google/GoogleMapLocation'

export const MapLocation = ({ coordinates }: any) => {

    return returnParseProjectElement('typemap') === 'yandex'
        ? <YandexMapLocation coordinates={coordinates} />
        : <GoogleMapLocation
            googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyDGxSDFMf8zdwo38AMY-oX9EiFwXXLBfmQ"
            coordinates={coordinates}
            // loadingElement={<div style={{ height: `100%` }} />}
            // containerElement={<div style={{ height: `400px` }} />}
            // mapElement={<div style={{ height: `100%` }} />}
        />
}
