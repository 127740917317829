import { makeAutoObservable } from 'mobx'
import moment from 'moment/moment'
import { openApi } from '../../../helpers/request'
import { FormEvent } from 'react'
import { message, notification } from 'antd'
import i from '../../../translations/i'
import __select from '../../../helpers/select_link/__select.controller'
import dayjs from "dayjs";

export interface PushesImageInterface {
    base: string
    uri: string
}

export interface PushesTemplatesInterface {
    id: number
    type: string
    title: string
    description: string
    enabled: boolean
    hour_utc: number
    before_days: number
    image?: any
}

class pushesController {

    templates: PushesTemplatesInterface[] = []

    template: PushesTemplatesInterface = {
        id: 0,
        enabled: true,
        type: 'birthday',
        title: '',
        description: '',
        hour_utc: 0,
        before_days: 0,
        image: null
    }

    push_type = 'instant'

    pushes_types = ['instant', 'delayed']

    oldImage: any = null

    push_create = {
        id: 0,
        title: '',
        description: '',
        devices: 'all',
        postpone_to: dayjs().unix(),
        element_type: '',
        element_id: 0,
        image: null,
        element: null
    }

    list = {
        limit: 10,
        offset: 0,
        count: 0,
        list: [],
    }

    constructor() {
        makeAutoObservable(this)
    }

    upload(image: any) {
        this.push_create.image = image
        return false
    }

    uploadTemplate(image: any) {
        this.template.image = image
        return false
    }

    getTemplates(type: string = '') {
        openApi('get', `/pushes/templates/${type}`)
            .then(result => {
                console.log(result)
                if(type !== '') this.template = result
                else this.templates = result
            })
            .catch(error => {

            })
    }

    get() {
        openApi('get', `/pushes?offset=${this.list.offset}&limit=${this.list.limit}`)
            .then(result => {
                console.log(result.items)
                result = result.data
                this.list.list = result.items
                this.list.count = result.count
            })
            .catch(error => console.log(error))
    }

    getOne(id: any) {
        openApi('get', `/pushes/${id}`)
            .then(result => {
                console.log(result)
                this.oldImage = result.data.image
                this.push_create = result.data
                this.push_type = result.data.type
                this.push_create.image = null

                __select.select.type = result.data.element_type
                __select.select.value = result.data.element_id
            })
            .catch(error => console.log(error))
    }

    onChangeType(value: string) {
        this.push_create.postpone_to = dayjs().unix()
        this.push_type = value
    }

    disabledDate(current: any) {
        return current && current < moment().subtract(1, 'day')
    }

    disabledDateTime = () => ({
        disabledMinutes: () => this.range(0, 60),
        disabledSeconds: () => this.range(0, 60),
    })

    range(start: number, end: number) {
        const result = []
        for (let i = start; i < end; i++) {
            result.push(i)
        }
        return result
    }

    delete(id: number) {
        openApi('delete', `/pushes/${id}`)
            .then(result => {
                console.log(result)
                this.get()
            })
    }

    deletePicture(id: any) {
        openApi('delete', `/pushes/picture/${id}`)
            .then(result => {
                this.oldImage = null
                this.push_create = result
            })
    }

    create(e: FormEvent) {
        e.preventDefault()

        let uri = `/pushes`
        if(this.push_create.id && this.push_create.id > 0) uri += `/${this.push_create.id}`

        const data = new FormData()
        data.append('title', this.push_create.title)
        if(this.push_create.description) data.append('description', this.push_create.description)
        data.append('devices', this.push_create.devices)
        data.append('type', this.push_type)
        data.append('postpone_to', `${this.push_create.postpone_to}`)

        data.append('element_type', __select.select.type)
        data.append('element_id', __select.select.value)

        if(this.push_create.image) {
            // @ts-ignore
            data.append('image', this.push_create.image)
        }

        openApi('post', uri, data, { key: 'Content-Type', value: 'multipart/form-data' })
            .then(result => {
                window.location.href = '/pushes'
            })
            .catch(error => {
                notification.error({
                    message: i.t('def:errors.error'),
                    description: error.response.data.data.message ?? ''
                })
            })
    }

    saveTemplate(e: FormEvent) {
        e.preventDefault()

        let uri = `/pushes/templates`
        if(this.template.id > 0) uri = `${uri}/${this.template.id}`

        console.log(`${this.template.enabled}`)

        const data = new FormData()
        data.append('title', this.template.title)
        data.append('description', this.template.description)
        data.append('enabled', `${this.template.enabled}`)
        data.append('type', this.template.type)
        data.append('before_days', `${this.template.before_days}`)
        data.append('hour_utc', `${this.template.hour_utc}`)

        // @ts-ignore
        data.append('image', this.template.image)

        openApi('post', uri, data, { key: 'Content-Type', value: 'multipart/form-data' })
            .then(result => {
                console.log(result)
                notification.success({
                    message: i.t('def:success.complete'),
                    description: i.t('def:success.edit'),
                })
            })
            .catch(error => {
                console.log(error)
            })
    }

    deleteImageFromTemplate() {
        let uri = `/pushes/templates/image`
        if(this.template.image && this.template.image.id > 0) uri = `${uri}/${this.template.image.id}`

        openApi('delete', uri)
            .then(result => {
                this.template.image = null
                notification.success({
                    message: i.t('def:success.complete'),
                    description: i.t('def:success.edit'),
                })
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export default new pushesController()