import { makeAutoObservable } from 'mobx'

interface ActionsInterface {
    label: string
    value: string | number
    action: any
    danger?: boolean
}

interface MultipleActionsInterface {
    size: number
    actions: ActionsInterface[]
}

class __multiple {

    selected: number[] = []

    actions: ActionsInterface[] = []
    size: number = 0

    constructor() {
        makeAutoObservable(this)
    }

    setActions(actions: any) {
        this.actions = actions
    }

    set(records: any) {
        // @ts-ignore
        let array = []
        records.map((el: any) => array.push(el.id))
        // @ts-ignore
        this.selected = array

        console.log(this.selected)
    }

    clear() {
        this.selected = []
    }
}

export default new __multiple()