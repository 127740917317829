import { observer } from 'mobx-react-lite'
import { VerifyComponent } from '../../../layouts/components/user/VerifyComponent'
import controller from '../controllers/user.controller'
import { Row, Col, Button } from 'antd'
import i from '../../../translations/i'
import { UserIcon } from '../controllers/user.icons'
import { VerifySuccessComponent } from '../../../layouts/components/user/VerifySuccessComponent'
import { Helmet } from 'react-helmet'

export const Verify = observer(() => {

    return !controller.verifyStatus ? (
        <>
            <Helmet>
                <title>{i.t('auth:title.verify')}</title>
            </Helmet>
            <form onSubmit={(e) => controller.verify(e)}>
                <Row gutter={[15, 15]}>
                    <Col span={24}>
                        <h1 className="verification">{i.t('auth:title.verify')}</h1>
                    </Col>
                    <Col span={24}>
                        <Row gutter={[15, 15]} className='fixed' justify="space-around">
                            {controller.error !== '' &&
                                <Col span={24} className="errors short">
                                    <p>{controller.error}</p>
                                </Col>
                            }
                            <Col span={24} className="verify-block">
                                <VerifyComponent onChange={(e: any) => controller.onChangeVerify(e)} error={controller.error !== ''} />
                            </Col>
                            <Col span={24} className="verify-description">
                                <p>{i.t('auth:verify.text')}</p>
                            </Col>
                            <Col span={24} className="verify-button">
                                <Button
                                    type='link'
                                    icon={UserIcon('fresh')}
                                    onClick={() => controller.newCode()}
                                >
                                    {i.t('auth:verify.code')}
                                </Button>
                            </Col>
                            <Col span={24} className="buttons">
                                <Row justify="space-around" align="middle" gutter={[10, 10]}>
                                    <Col span={24}>
                                        <Button
                                            key={'login_form_button_submit'}
                                            type="primary"
                                            htmlType="submit"
                                            className="button"
                                        >
                                            {i.t('def:actions.continue')}
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </form>
        </>
    ) : <VerifySuccessComponent />
})