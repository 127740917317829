import { observer } from 'mobx-react-lite'
import i from '../../../../translations/i'
import { Titles } from '../../../../layouts/Titles'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Row, Col, Divider, Input, Button, Upload } from 'antd'
import __points from '../controllers/__points'
import { runInAction } from 'mobx'
import { InboxOutlined } from '@ant-design/icons'

export const PlacesGroup = observer(() => {

    const { groupID }: any = useParams()

    useEffect(() => {
        if(groupID > 0) {
            __points.getOneGroup(groupID)
        }
    }, [])

    return <>
        <form onSubmit={(e) => __points.saveGroup(e)}>
            <Titles
                title={i.t('def:Edit Group')}
                breadcrumb={[
                    { title: i.t('def:settings'), href: '/settings' },
                    { title: i.t('points:list'), href: '/settings/places' },
                    { title: i.t('def:Groups'), href: '/settings/places_groups' }
                ]}
                buttons={[
                    { title: i.t('def:actions.back'), href: '/settings/places_groups', type: 'dashed' },
                    { title: i.t('def:actions.save'), htmlType: 'submit', type: 'primary' }
                ]}
            />
            <Row gutter={[20, 20]}>
                <Col span={12}>
                    <label className="formLabel">{i.t('def:name')}</label>
                    <Input
                        value={__points.item.name}
                        onChange={ (e) => __points.item.name = e.target.value }
                    />
                </Col>

                <Divider />
                <Col span={24}>
                    <label className="title">{i.t('def:Images')}</label>
                </Col>

                <Col span={24}>
                    <Upload
                        multiple={false}
                        beforeUpload={(e: any) => {
                            let reader = new FileReader()
                            reader.readAsDataURL(e)
                            reader.onloadend = function () {
                                e.url = reader.result
                                runInAction(() => __points.toggleImage(e))
                            }

                            return false
                        }}
                        onRemove={(e) => runInAction(() => __points.toggleImage(e))}
                        fileList={__points.images ?? []}
                        showUploadList={true}
                        listType="picture-card"
                    >
                        <Button icon={<InboxOutlined />}>{i.t('def:actions.upload')}</Button>
                    </Upload>
                </Col>
            </Row>
        </form>
    </>
})