import { observer } from "mobx-react-lite";
import i from "../../../translations/i";
import { Titles } from "../../../layouts/Titles";
import controller from './discount.controller'
import { Col, Input, InputNumber, Row, Select, Switch } from "antd";
import { useEffect } from "react";
import { returnParseProjectElement } from "../../../helpers/project.controller";
import { useParams } from "react-router-dom";
import { ParamTypes } from "../../../helpers/types.interfaces";

export const Discount = observer(() => {

  const { id }: any = useParams<ParamTypes>()

  useEffect(() => {
    controller.getOne(id)
  }, [])

  const buttons = [
    { title: i.t('def:actions.backList'), href: `/settings/discount`, type: 'dashed' },
    { title: i.t('def:actions.save'), htmlType: `submit`, type: 'primary' }
  ]

  return(
    <>
      <form onSubmit={(e) => controller.save(e)}>
        <Titles
          title={i.t('def:Discount')}
          buttons={buttons}
        />

        <Row gutter={[20, 20]}>
          <Col xs={24} className="switch-block">
            <div className="element">
              <div></div>
              <Switch
                checked={controller.item.enabled}
                onChange={(e) => controller.item.enabled = e}
                size="small"
              />
              <div>
                <span>{i.t('settings:cities.form.enabled')}</span>
              </div>
            </div>
          </Col>
          <Col xs={24} md={12}>
            <label>{i.t('settings:cities.form.name')}</label>
            <Input
              name="name"
              placeholder={i.t('settings:cities.form.name')}
              value={controller.item.name}
              onChange={(e) => controller.item.name = e.target.value}
            />
          </Col>
          <Col xs={24} md={12}>
            <label>{i.t('def:id_crm')}</label>
            <Input
              name="id_crm"
              placeholder={i.t('def:id_crm')}
              value={controller.item.id_crm}
              onChange={(e) => controller.item.id_crm = e.target.value}
            />
          </Col>
          <Col xs={24} md={12}>
            <label>{i.t('def:Discount purpose')}</label>
            <Select
              style={{ width: `100%` }}
              placeholder={i.t('def:Discount purpose')}
              value={controller.item.purpose}
              onChange={(e) => controller.item.purpose = e}
              options={[
                { label: 'На корзину при доставке', value: 'cart_delivery' },
                { label: 'На корзину при самовывозе', value: 'cart_pickup' },
              ]}
            />
          </Col>
          <Col xs={24} md={12}>
            <label>{i.t('def:Measurement')}</label>
            <Select
              style={{ width: `100%` }}
              placeholder={i.t('def:Measurement')}
              value={controller.item.measurement}
              onChange={(e) => controller.item.measurement = e}
              options={[
                { label: '%', value: 0 },
                { label: returnParseProjectElement('CURRENCY_CODE'), value: 1 },
              ]}
            />
          </Col>
          <Col xs={24} md={12}>
            <label>{i.t('def:Discount amount')}</label>
            <InputNumber
              name="amount"
              style={{ width: `100%` }}
              placeholder={i.t('def:Discount amount')}
              value={controller.item.amount}
              onChange={(e) => controller.item.amount = e}
            />
          </Col>
        </Row>
      </form>
    </>
  )
})