import { observer } from "mobx-react-lite";
import { Breadcrumb, Button, Col, Row, Space } from 'antd'
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet'

const TemplateTitleBreadcrumb = observer(({ breadcrumb=[] }) => {

    const { t } = useTranslation();

    return(
        <Breadcrumb>
            <Breadcrumb.Item key="breadcrumb-home" href={"/"}>{t('order:main')}</Breadcrumb.Item>
            {breadcrumb.length > 0 && breadcrumb.map(item => (
                <Breadcrumb.Item key={"breadcrumb-" + item.key} href={item.link ? item.link : null}>{ item.value }</Breadcrumb.Item>
            ))}
        </Breadcrumb>
    )
})

const EventButton = observer(({ item }) => {

    return item.type === "link"
        ?   <a href={item.link} key={`template_title_link_${item.title}`} className={item.active ? "button-main" : "button-main_no-active"}>{ item.title }</a>
        : item.type === "button"
            ? <Button onClick={item.ref} key={`template_title_button_${item.title}`} type="dashed">{ item.title }</Button>
            : item.type === "submit"
                ?  <button type="submit" key={`template_title_submit_${item.title}`} className="button-main">{ item.title }</button>
                : <Button onClick={item.ref} key={`template_title_button_${item.title}`} icon={item.icon} type="dashed"/>
})

const TemplateTitle = observer(({ title, breadcrumb, buttons = [] }) => {

    return(
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <Row justify="space-around" align="middle" className="title_page">
                <Col span={24}>
                    <TemplateTitleBreadcrumb breadcrumb={breadcrumb} />
                </Col>
                <Col span={buttons.length > 0 ? 12 : 24}>
                    <h1>{ title }</h1>
                </Col>
                {buttons.length > 0 &&
                    <Col span={12} className="right">
                        <Space>
                            {buttons.map(item => <EventButton item={item} key={`template_title_link_${item.title}`} /> )}
                        </Space>
                    </Col>
                }
            </Row>
        </>
    )
})

export default TemplateTitle