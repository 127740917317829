import { observer } from "mobx-react-lite";
import React, { useEffect } from "react"
import _promo from "../controllers/promo.controller"
import TemplateTitle from "../../../template/title/TemplateTitle";
import { Pagination, Space, Table } from 'antd'
import ButtonAction from "../../../template/buttons/ButtonsActions";
import { newDateTimeHelper } from "../../../template/helpers/templateHelpers"
import i from '../../../../translations/i'

const ShopPromoList = observer(() => {

    useEffect(() => {
        return () => {
            _promo.setPageNull()
            _promo.getCoupons()
        }
    }, [])

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: i.t('promo:code'),
            dataIndex: 'code',
            key: 'code'
        },
        {
            title: i.t('promo:limit'),
            dataIndex: 'countall',
            key: 'countall',
            render: item => item === 0 ? i.t('promo:none') : item
        },
        {
            title: i.t('promo:uses'),
            dataIndex: 'countall',
            key: 'countall',
            render: (item, record) => item === 0 ? i.t('promo:none') : record.count
        },
        {
            title: i.t('promo:start'),
            dataIndex: 'datestart',
            key: 'datestart',
            render: (item) => Number(item) !== 0 ? newDateTimeHelper(Number(item)) : ""
        },
        {
            title: i.t('promo:end'),
            dataIndex: 'dateend',
            key: 'dateend',
            render: (item) => Number(item) !== 0 ? newDateTimeHelper(Number(item)) : ""
        },
        {
            title: i.t('def:actions.actions'),
            key: 'action',
            align: 'right',
            render: (text, record) => (
                <>
                    <Space>
                        <ButtonAction
                            item={{
                                type: "statistics",
                                typeButton: "link",
                                placeholder: i.t('promo:statistic'),
                                url: `/shop/promo/${record.id}/statistics`
                            }}
                        />
                        <ButtonAction
                            item={{
                                type: "edit",
                                typeButton: "link",
                                placeholder: i.t('def:actions.edit'),
                                url: "/shop/promo/" + record.id
                            }}
                        />
                        <ButtonAction
                            item={{
                                type: "delete",
                                typeButton: "button",
                                placeholder: i.t('def:actions.remove'),
                                onClick: () => _promo.deleteCoupon(record.id)
                            }}
                        />
                    </Space>
                </>
            ),
        },
    ];

    return(
        <>
            <TemplateTitle
                title={i.t('promo:coupons')}
                breadcrumb={_promo.breadcrumb}
                buttons={[
                    { title: i.t('def:actions.create'), type: "link", link: "/shop/promo/0" }
                ]}
            />

            <Table columns={columns} dataSource={_promo.coupons} rowKey="id" pagination={false} />

            <br />
            <Pagination
                defaultCurrent={_promo.pagination.page}
                current={_promo.pagination.page}
                defaultPageSize={_promo.pagination.limit}
                pageSize={_promo.pagination.limit}
                total={_promo.pagination.total}
                showSizeChanger={false}
                onChange={(e) => _promo.setPage(e)}
            />
        </>
    )
})

export default ShopPromoList