import { observer } from 'mobx-react-lite'
import { Titles } from '../../../layouts/Titles'
import { Row, Col, Segmented, Button, Input, Switch, Space, Table, Badge, Select } from "antd";
import controller from './__navigation.controller'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { ParamTypes } from '../../../helpers/types.interfaces'
import i from '../../../translations/i'
import { SelectLink } from '../../../helpers/select_link/SelectLink'
import { ArrowDownOutlined, ArrowUpOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons'
import __select from '../../../helpers/select_link/__select.controller'
import { SelectIcon } from '../../../helpers/select_icons/SelectIcon'
import projectController from '../project/project.controller'
import { WarehousesController } from "../warehouses/warehouses.controller";
import i18n from "../../../translations/i";

const Storage = new WarehousesController()

export const Navigation = observer(() => {

    useEffect(() => {
        projectController.get()
    }, [])

    const { type = 'tab_bar' }: any = useParams<ParamTypes>()

    const buttons = [
        { title: i.t('def:createElement'), type: 'primary', href: `/settings/navigation/${controller.type}/0` }
    ]

    useEffect(() => {
        Storage.pagination.limit = 999
        Storage.get()
        controller.setType(type)
        controller.getList()
    }, [])

    const columns = [
        {
            title: "ID",
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: i.t('def:active'),
            dataIndex: 'enabled',
            key: 'enabled',
            render: (el: boolean) => el ? <Badge status="success" /> : <Badge status="error" />
        },
        {
            title: i.t('def:name'),
            dataIndex: 'label',
            key: 'label'
        },
        {
            title: i.t('def:destinationType'),
            dataIndex: 'link_type',
            key: 'label',
            render: (el: string) => __select.getTypeByCode(el)
        },
        {
            title: i.t('news:actions'),
            render: (record: any) =>
                <Space>
                    <Button
                        type="dashed"
                        size="small"
                        icon={<ArrowUpOutlined />}
                        onClick={() => controller.sorting(record.id, 'up', type)}
                    />
                    <Button
                        type="dashed"
                        size="small"
                        icon={<ArrowDownOutlined />}
                        onClick={() => controller.sorting(record.id, 'down', type)}
                    />
                    <Button
                        type="dashed"
                        size="small"
                        href={`/settings/navigation/${controller.type}/${record.id}`}
                        icon={<EditOutlined />}
                    />
                    <Button
                        type="dashed"
                        size="small"
                        icon={<DeleteOutlined />}
                        onClick={() => controller.delete(record.id)}
                        danger
                    />
                </Space>
        },
    ]

    return (
        <>
            <Titles
                title={i.t('nav:nav')}
                buttons={buttons}
                breadcrumb={[ {title: i.t('def:settings'), href: '/settings'}, { title: i.t('nav:nav') }, ]}
            />

            <Row gutter={[20, 20]}>
                <Col span={24}>
                    <Segmented
                        value={controller.type}
                        onChange={(e) => controller.setType(e)}
                        options={[
                            { label: i.t('def:tab_bar'), value: 'tab_bar' },
                            { label: i.t('def:user_cabinet'), value: 'cabinet' },
                            { label: i.t('def:Quick menu'), value: 'quick_menu' },
                            { label: i.t('def:Site menu'), value: 'site_menu' },
                        ]}
                    />
                </Col>
                <Col span={24} className="">
                    <Space>
                        <div className="switch-block">
                            <div className="element">
                                <div></div>
                                <Switch
                                  checked={projectController.project.update_navigation_warehouse}
                                  onChange={e => {
                                      projectController.project.update_navigation_warehouse = e;
                                      projectController.saving()
                                  }}
                                  size="small"
                                />
                                <div>
                                    <span>{i.t('def:Update navigation when changing warehouse')}</span>
                                </div>
                            </div>
                        </div>

                        <Select
                          value={controller.warehouse}
                          style={{ width: "250px" }}
                          onChange={(e) => {
                              controller.warehouse = e
                              controller.getList()
                          }}
                        >
                            <Select.Option value={0}>{i18n.t("constructor:storages")}</Select.Option>
                            {Storage.list.map((item: any) => (
                              <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
                            ))}
                        </Select>
                    </Space>
                </Col>
                {controller.type === 'tab_bar' &&
                    <div className="switch-block">
                        <div className="element">
                            <div></div>
                            <Switch
                                checked={projectController.project.tab_bar_enabled}
                                onChange={e => projectController.set('tab_bar_enabled', e, true)}
                                size="small"
                            />
                            <div>
                                <span>{projectController.project.tab_bar_enabled ? i.t('def:tab_bar_enable') : i.t('def:tab_bar_disable')}</span>
                            </div>
                        </div>
                    </div>
                }
                <Col span={24}>
                    <Table
                        columns={columns}
                        dataSource={controller.list}
                        pagination={false}
                    />
                </Col>
            </Row>
        </>
    )
})

export const NavigationElement = observer(() => {

    const edit = (e: any) => {
        console.log(e)
    }

    const { type, id }: any = useParams<ParamTypes>()

    const buttons = [
        { title: i.t('def:actions.backList'), type: 'dashed', href: `/settings/navigation/${type}` },
    ]

    useEffect(() => {
        Storage.pagination.limit = 999
        Storage.get()
        controller.getOne(id, type)
    }, [])

    return (
        <form onSubmit={(e) => controller.save(e)}>
            <Titles
                title={i.t('def:nav_edit')}
                buttons={buttons}
                breadcrumb={[
                    { title: i.t('def:settings'), href: '/settings' },
                    { title: i.t('nav:nav'), href: '/settings/navigation' },
                    { title: i.t('def:nav_edit') },
                ]}
            />

            <Row gutter={[20, 20]}>
                <Col xs={24} className="switch-block">
                    <div className="element">
                        <div></div>
                        <Switch
                            checked={controller.element.enabled}
                            onChange={e => controller.element.enabled = e}
                            size="small"
                        />
                        <div>
                            <span>{i.t('settings:cities.form.enabled')}</span>
                        </div>
                    </div>
                </Col>
                <Col span={12}>
                    <label>{i.t('settings:cities.form.name')}</label>
                    <Input
                        name="title"
                        placeholder={i.t('settings:cities.form.name')}
                        value={controller.element.label}
                        onChange={(e) => controller.element.label = e.target.value}
                        required
                    />
                </Col>
                <Col span={12}>
                    <label>{i18n.t("constructor:storages")}</label>
                    <Select
                      value={controller.element.warehouse_id}
                      style={{ width: "100%" }}
                      onChange={(e) => {
                          controller.element.warehouse_id = e
                      }}
                    >
                        <Select.Option value={0}>{i18n.t("constructor:storages")}</Select.Option>
                        {Storage.list.map((item: any) => (
                          <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
                        ))}
                    </Select>
                </Col>
                <Col span={24}>
                    <label>{i.t('def:icon')}</label>

                    <SelectIcon
                        referal_link={(e: any) => controller.setIcon(e)}
                        active={controller.element.icon}
                    />
                </Col>
                <Col span={24}>
                    <label>{i.t('def:link.link')}</label>

                    <SelectLink
                        type={controller.element.link_type}
                        element={controller.element.link_assignment}
                        elements={controller.element.products ?? []}
                        item_element={controller.element.selected ?? null}
                        none={false}
                    />
                </Col>
                <Col span={24}>
                    <Button
                        type="primary"
                        htmlType="submit"
                    >
                        {i.t('def:actions.save')}
                    </Button>
                </Col>
            </Row>
        </form>
    )
})