const languages = process.env.REACT_APP_LANGUAGES || ''

export const config = {
    serviceName: process.env.REACT_APP_SERVICE_NAME,
    shortServiceName: process.env.REACT_APP_SHORT_SERVICE_NAME,
    languages: languages.split(','),
    defaultLanguage: process.env.REACT_APP_DEFAULT_LANGUAGE,
    defaultCountry: process.env.REACT_APP_DEFAULT_COUNTRY_PHONE,
    apiUrl: process.env.REACT_APP_API_URL,
    apiAuth: process.env.REACT_APP_API_AUTH_URL,
    siteUrl: process.env.REACT_APP_SITE_URL,
    formUrl: process.env.REACT_APP_FORM_URL,
    mainColor: process.env.REACT_APP_COLOR_MAIN,
    docs: process.env.REACT_APP_DOCS_URI,
    socket: process.env.REACT_APP_SOCKET_URI ?? '',
    open_api_uri: process.env.REACT_APP_API_OPEN_API_URL ?? ''
}