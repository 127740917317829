import { request } from '../helpers/request'

export default async function deleteFile(type, url, id=0) {
    let facts = new FormData();
    facts.append("url", url);
    facts.append("id", id);

    if(id > 0) {
        facts.append("type", type);
    } else {
        facts.append("type", "file");
    }

    request('post', '/upload/delete/', facts)
        .then(success => {
            return new Promise((resolve, reject) => {
                setTimeout(function(){
                    resolve("Ok")
                }, 250);
            })
        })
        .catch(error => {

        });
}