import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { storeLocal } from '../helpers/stores'
import { getLanguageConfig } from './config'

const resources = {
    ru: getLanguageConfig('ru'),
    en: getLanguageConfig('en'),
    ua: getLanguageConfig('ua'),
    pl: getLanguageConfig('pl'),
    // ar: getLanguageConfig('ar'),
}

// @ts-ignore
const languages = process.env.REACT_APP_LANGUAGES.split(',')
const defaultLanguage = process.env.REACT_APP_DEFAULT_LANGUAGE

const thisLang =
    storeLocal.get('lang') &&
    storeLocal.get('lang') !== '' &&
    languages.findIndex(el => el === storeLocal.get('lang')) > -1
    ? storeLocal.get('lang')
    : defaultLanguage

i18n.use(initReactI18next).init({
    resources,
    lng: thisLang,
    interpolation: {
        escapeValue: false
    }
})

export default i18n
