import { observable } from 'mobx'
import { request } from '../request'

export class Controller {

    path = ''
    uri = ''
    version = false

    list: any[] = []
    element: any = {}

    pagination = { total: 0, limit: 10, page: 1, offset: 0 }

    constructor(path: string, uri: string, version: boolean) {
        this.path = path
        this.uri = uri
        this.version = version

        observable(this, {
            path: observable,
            uri: observable,
            list: observable,
            element: observable,
            pagination: observable,
        })
    }

    get() {
        request('get', `${this.path}?limit=${this.pagination.limit}&offset=${this.pagination.offset}`, {}, this.version)
            .then(result => {
                console.log(result.data[0])
                this.list = result.data[0]
            })
            .catch(error => {

            })
    }

    getElement(elementId: number | string) {

    }

    onChange(name: string, value: any) {
        this.element[name] = value
    }

    setPage(page: number) {
        this.pagination.page = page
        if (page > 0) {
            this.pagination.offset =
                this.pagination.limit * page - this.pagination.limit
        } else {
            this.pagination.offset = 0
        }
        this.get()
    }

}