import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import controller from './answers.controller'
import i from '../../../../translations/i'
import { Titles } from '../../../../layouts/Titles'
import { Row, Col, Button, Space, Table, Select, DatePicker, Badge, List } from 'antd'
import { MultipleActions } from '../../../../helpers/multiple_actions/MultipleActions'
import __multiple from '../../../../helpers/multiple_actions/__multiple'

export const AnswersPage = observer(() => {
    // @ts-ignore
    const { formID, elementID } = useParams()

    useEffect(() => {
        controller.get(formID, elementID)
    }, [])

    const buttons = [
        { title: i.t('def:actions.remove'), click: () => controller.deleteAnswer(elementID, formID, true), type: 'dashed', danger: true },
        { title: i.t('def:actions.backList'), href: `/shop/feedback/${formID}/answers`, type: 'dashed' },
    ]

    return(
        <>
            <Titles
                title={i.t('settings:feedback.answers.titleDetail')}
                buttons={buttons}
            />

            <>
                <List
                    itemLayout="horizontal"
                    dataSource={controller.answer.elements.map((el: any) => ({
                        element_label: el.element_label,
                        value: el.value,
                        files: el.files
                    }))}
                    renderItem={(item) => (
                        <List.Item
                            extra={
                                <Space>
                                    {item.files.length > 0 && item.files.map((file: any, index: number) => (
                                        <Button type="dashed" href={`${file.base}${file.uri}`} target="_blank">{i.t('def:file')} {index+1}</Button>
                                    ))}
                                </Space>
                            }
                        >
                            <List.Item.Meta
                                title={item.element_label}
                                description={item.value}
                            />
                        </List.Item>
                    )}
                />
                <List
                    itemLayout="horizontal"
                    dataSource={[
                        { element_label: i.t('def:source_info'), value: controller.answer.source }
                    ]}
                    renderItem={(item) => (
                        <List.Item>
                            <List.Item.Meta
                                title={item.element_label}
                                description={item.value}
                            />
                        </List.Item>
                    )}
                />
                <List
                    itemLayout="horizontal"
                    dataSource={[
                        { element_label: i.t('def:device_info'), value: controller.answer.device_info }
                    ]}
                    renderItem={(item) => (
                        <List.Item>
                            <List.Item.Meta
                                title={item.element_label}
                                description={item.value}
                            />
                        </List.Item>
                    )}
                />
            </>
        </>
    )
})

export const AnswersList = observer(() => {

    // @ts-ignore
    const { formID } = useParams()

    useEffect(() => {
        controller.get(formID)
        controller.getFilters(formID)
        controller.feedback_id = formID

        __multiple.setActions([
            { label: i.t('def:delete_elements'), value: 'delete', action: () => controller.multipleDelete() },
            { label: i.t('def:flag_read'), value: 'read', action: () => controller.multipleRead() },
        ])
    }, [])

    const buttons = [
        { title: i.t('def:flag_read_all'), click: () => controller.readAll(), type: 'dashed' },
        { title: i.t('users:excelExport'), click: () => controller.export(formID, 'xlsx'), type: 'dashed' },
        { title: i.t('users:csvExport'), click: () => controller.export(formID, 'csv'), type: 'dashed' },
        { title: i.t('def:actions.backList'), href: `/shop/feedback`, type: 'dashed' },
    ]

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            sorter: true
        },
        {
            title: 'User ID',
            dataIndex: 'user_id',
            key: 'user_id',
            sorter: true,
            render: (user_id: number) => user_id > 0 ? <a href={`/users/${user_id}`} target="_blank">{user_id}</a> : user_id
        },
        {
            title: i.t('def:source'),
            dataIndex: 'source',
            key: 'source',
            sorter: true
        },
        {
            title: i.t('settings:feedback.answers.answerDate'),
            dataIndex: 'created_at',
            key: 'created_at',
            sorter: true,
            render: (text: any, record: any) => (
                <>{new Intl.DateTimeFormat('ru-RU', {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'}).format(new Date(text))}</>
            )
        },
        {
            key: 'action',
            render: (text: any, record: any) => (
                <Space size="middle">
                    <Badge count={record.viewed ? 0 : 1} style={{ backgroundColor: '#52c41a' }} dot={true}>
                        <Button
                            type="dashed"
                            href={`/shop/feedback/${formID}/answers/${record.id}`}
                        >
                            {i.t('def:jump.jump')}
                        </Button>
                    </Badge>
                </Space>
            )
        }
    ]

    return(
        <>
            <Titles
                title={i.t('settings:feedback.answers.title')}
                buttons={buttons}
            />

            <Row gutter={[20,20]} style={{marginBottom: '20px'}}>
                {controller.filters.map((el: any, k) => {
                    if(el.type === 'select') {
                        let options: any[] = []
                        options.push({
                            label: i.t('def:noneSelected'),
                            value: null
                        })
                        console.log(typeof el.options)
                        el.options && Object.keys(el.options).map((o: any) => {
                            options.push({
                                label: el.options[o],
                                value: el.options[o]
                            })
                        })
                        return (
                            <Col xs={24} md={12} lg={6} key={k}>
                                <label>{el.label}</label>
                                <Select
                                    value={controller.filtersValues[el.key] ?? null}
                                    style={{ width: 100 + '%' }}
                                    onChange={e => controller.setFilter(el.key, e, formID)}
                                    options={options}
                                />
                            </Col>
                        )
                    } else if(el.type === 'datetime') {
                        return (
                            <Col xs={24} md={12} lg={6} key={k}>
                                <label>{el.label}</label>
                                <DatePicker
                                    placeholder={el.label}
                                    format={"DD.MM.YYYY"}
                                    style={{ width: 100 + '%' }}
                                    onChange={(date, dateString) => {
                                        let dateToGet
                                        if(dateString !== '') {
                                            // @ts-ignore
                                            let ds = dateString.split(".")
                                            console.log(ds)
                                            dateToGet = new Date(`${ds[2]}-${ds[1]}-${ds[0]}`).getTime() / 1000
                                        }
                                        else dateToGet = null
                                        controller.setFilter(el.key, dateToGet, formID)
                                    }}
                                />
                            </Col>
                        )
                    }
                })}
            </Row>

            <Table
                rowKey="id"
                rowSelection={{
                    onSelect: (record: any, selected: any, selectedRows: any) => {
                        __multiple.set(selectedRows)
                    },
                    onSelectAll: (selected: any, selectedRows: any) => {
                        __multiple.set(selectedRows)
                    },
                    type: 'checkbox',
                    selectedRowKeys: __multiple.selected
                }}
                columns={columns}
                dataSource={controller.list}
                pagination={{
                    position: ['bottomLeft'],
                    hideOnSinglePage: true,
                    current: Number(controller.pagination.page),
                    pageSize: controller.pagination.limit,
                    total: controller.pagination.total,
                    showSizeChanger: false,
                    onChange: (e) => controller.setPage(formID, e)
                }}
                onChange={(pagination, filters, sorter, extra) => {
                    if(extra.action === 'sort') controller.get(formID, 0, sorter)
                }}
            />
        </>
    )
})