import { observer } from 'mobx-react-lite'
import TemplateTitle from '../../template/title/TemplateTitle'
import controller from './unit.controller'
import { useEffect } from 'react'
import { Space, Button, Pagination, Table, Row, Col, Input, InputNumber } from 'antd'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { useParams } from 'react-router-dom'
import i from '../../../translations/i'

export const Unit = observer(() => {
    const { elementID } = useParams()

    useEffect(() => {
        controller.get(Number(elementID))
    }, [])

    const buttons = [
        { title: i.t('def:actions.backList'), link: '/shop/units', type: 'link' },
        { title: i.t('def:actions.save'), type: 'submit' }
    ]

    const breadcrumb = [
        { key: 'shop', link: '/shop', value: i.t('crumb:shop') },
        { key: 'units', link: '/shop/units', value: i.t('crumb:unit') },
        { key: 'element', link: null, value: i.t('def:edit') }
    ]

    return (
        <form onSubmit={(e) => controller.save(e)}>
            <TemplateTitle
                title={ i.t('unit:edit') }
                breadcrumb={ breadcrumb }
                buttons={ buttons }
            />

            <Row gutter={[15, 15]}>
                <Col span={12} className="product-input_block">
                    <label>{i.t('unit:fullName')}</label>
                    <Input
                        value={controller.element.description}
                        onChange={(e) =>
                            controller.set('description', e.target.value)
                        }
                        required
                    />
                </Col>
                <Col span={12} className="product-input_block">
                    <label>{i.t('unit:name')}</label>
                    <Input
                        value={controller.element.name}
                        onChange={(e) => controller.set('name', e.target.value)}
                        required
                    />
                </Col>
                <Col span={12} className="product-input_block">
                    <label>{i.t('unit:step')}</label>
                    <InputNumber
                        style={{ width: '100%' }}
                        value={controller.element.step}
                        onChange={(e) => controller.set('step', e)}
                        required
                    />
                </Col>
            </Row>
        </form>
    )
})

export const Units = observer(() => {
    useEffect(() => {
        controller.get()
    }, [])

    const breadcrumb = [
        { key: 'shop', link: '/shop', value: i.t('crumb:shop') },
        { key: 'element', link: null, value: i.t('crumb:unit') }
    ]

    const buttons = [
        {
            title: i.t('def:actions.create'),
            type: 'link',
            link: '/shop/units/0'
        }
    ]

    const columns = [
        { title: 'ID', dataIndex: 'id', key: 'id' },
        {
            title: i.t('def:name'),
            dataIndex: 'description',
            key: 'description'
        },
        { title: i.t('unit:abbreviated'), dataIndex: 'name', key: 'name' },
        {
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Button
                        type="dashed"
                        shape="circle"
                        icon={<EditOutlined />}
                        href={`/shop/units/${record.id}`}
                    />
                    <Button
                        type="dashed"
                        shape="circle"
                        danger
                        icon={<DeleteOutlined />}
                        onClick={() => controller.remove(record.id)}
                    />
                </Space>
            )
        }
    ]

    return (
        <>
            <TemplateTitle
                title={i.t('crumb:unit')}
                breadcrumb={breadcrumb}
                buttons={buttons}
            />
            <Table
                columns={columns}
                dataSource={controller.list}
                rowKey="id"
                pagination={false}
            />
            <br />
            <Pagination
                defaultCurrent={controller.pagination.page}
                current={controller.pagination.page}
                defaultPageSize={controller.pagination.limit}
                pageSize={controller.pagination.limit}
                total={controller.pagination.total}
                showSizeChanger={false}
                onChange={(e) => controller.setPage(e)}
            />
        </>
    )
})
