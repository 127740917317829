import { makeAutoObservable } from 'mobx'
import hashController from '../../../modules/template/controllers/hash.controller'
import { notification } from 'antd'
import i from '../../../translations/i'
import { request } from '../../../helpers/request'

class operatorsController {

    list = []
    pagination = {
        page: 1,
        limit: 10,
        offset: 0,
        total: 0
    }

    element = {}

    constructor() {
        makeAutoObservable(this)
    }

    setPage(page) {
        this.pagination = {
            ...this.pagination,
            page: page,
            offset: (page * this.pagination.limit) - this.pagination.limit
        }
        this.getList()
    }

    getList() {
        request('get', `/admin/operator?limit=${this.pagination.limit}&offset=${this.pagination.offset}`)
            .then(result => {
                console.log(result)
                this.list = result[0]
                this.pagination.total = result[1]
            })
            .catch(err => console.log(err.response))
    }

    getElement(elementID) {
        request('get', `/admin/operator/${elementID}`)
            .then(result => {
                this.element = result
            })
            .catch(err => console.log(err.response))
    }

    set(name, value) {
        this.element[name] = value
    }

    save(e) {
        e.preventDefault()

        console.log(this.element)

        let facts = new FormData()
        facts.append('name', this.element.name)
        facts.append('email', this.element.email)
        facts.append('type', '1') // 1 - оператор

        const hash = hashController.setHash(facts)

        facts.append('hash', hash)

        request('post', this.element.id && this.element.id > 0 ? `/admin/operator/${this.element.id}` : `/admin/operator/0`, facts)
            .then(result => {
                console.log(result)
                window.location.href = `/settings/operators`
            })
            .catch(err => {
                if(err.response && err.response.data && err.response.data.message) {
                    notification.error(err.response.data.message)
                    notification.error({
                        message: err.response.data.message
                    })
                } else {
                    notification.error({
                        message: i.t('operators:error')
                    })
                }
            })
    }

    remove(elementID) {
        if(window.confirm(i.t('operators:confirm'))) {
            let facts = {
                typestructure: "ksOperator"
            }

            request('delete', `/product/delete/${elementID}`, { data: "typestructure=ksOperator&hash=" + hashController.setHashDelete(facts) })
                .then(response => {
                    console.log(response)
                    this.getList()
                })
                .catch(response => {
                    console.log(response)
                })
        }
    }

}

export default new operatorsController()