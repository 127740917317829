import { observer } from 'mobx-react-lite'
import i from '../../../../translations/i'
import { Titles } from '../../../../layouts/Titles'
import { useEffect } from 'react'
import { webhookController } from './webhook.controller'
import { Button, Modal, Row, Col, Tag, Input, Table, Pagination, Space } from 'antd'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'

const controller = new webhookController()

export const SettingsWebhook = observer(() => {

    const buttons = [
        // { title: i.t('def:actions.docs'), type: 'dashed', target: true, href: `${config.docs}/webhook`},
        { title: i.t('def:actions.create'), click: () => controller.new(), type: 'primary' }
    ]

    const columns = [
        { title: 'ID', dataIndex: 'id', key: 'id' },
        { title: i.t('settings:webhook.uri'), dataIndex: 'uri', key: 'uri' },
        { title: i.t('settings:webhook.types'), dataIndex: 'types', key: 'types', render: (text: any) => (
            <>
                {text.map((el: string) => {
                    let index = controller.types.findIndex(item => item.key === el)
                    if(index > -1) {
                        return (<Tag>{controller.types[index].value}</Tag>)
                    }
                })}
            </>
            ) },
        {
            key: 'action',
            render: (text: any, record: any) => (
                <Space size="middle">
                    <Button
                        type="dashed"
                        shape="circle"
                        icon={<EditOutlined />}
                        onClick={() => controller.select(record.id)}
                    />
                    <Button
                        type="dashed"
                        shape="circle"
                        icon={<DeleteOutlined />}
                        onClick={() => controller.delete(record.id)}
                        danger
                    />
                </Space>
            )
        }
    ]

    useEffect(() => {
        return () => {
            controller.get()
            controller.getTypes()
        }
    }, [])

    return(
        <>
            <Titles
                title={i.t('settings:webhook.title')}
                buttons={buttons}
            />

            {controller.types.length > 0 &&
                <>
                    <Table
                        columns={columns}
                        dataSource={controller.list}
                        rowKey="id"
                        pagination={false}
                    />
                    <br />
                    <Pagination
                        defaultCurrent={controller.pagination.page}
                        current={controller.pagination.page}
                        defaultPageSize={controller.pagination.limit}
                        pageSize={controller.pagination.limit}
                        total={controller.pagination.total}
                        showSizeChanger={false}
                        onChange={(e) => controller.setPage(e)}
                    />
                </>
            }

            <Modal open={controller.visible} onOk={() => controller.save()} onCancel={() => controller.close()} maskClosable={false}>
                <Row gutter={[20, 20]}>
                    <Col span={24}>
                        <h3>{i.t('settings:webhook.modal.title')}</h3>
                    </Col>
                    <Col span={24}>
                        <h4>{i.t('settings:webhook.modal.uri')}</h4>
                        <Input
                            value={controller.element.uri}
                            onChange={(e) => controller.onChangeUri(e.target.value)}
                            required
                        />
                    </Col>
                    <Col span={24}>
                        <h4>{i.t('settings:webhook.modal.types')}</h4>
                        {controller.types.map(el => (
                            <Tag.CheckableTag
                                key={el.key}
                                checked={controller.element.types.findIndex(item => item === el.key) > -1}
                                onChange={() => controller.onChangeType(el.key)}
                            >{el.value}</Tag.CheckableTag>
                        ))}
                    </Col>
                </Row>
            </Modal>
        </>
    )
})