import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { Row, Col, Button, Statistic, Switch, Input, InputNumber, Divider, Table, Space, Tooltip, Popconfirm } from 'antd'
import i from '../../../translations/i'
import { Titles } from '../../../layouts/Titles'
import controller from './__carts'
import dayjs from 'dayjs'
import { useParams } from 'react-router-dom'

export const Carts = observer(() => {

    useEffect(() => {
        controller.getList()
    }, [])

    const columns = [
        {
            title: i.t('def:carts.table.created_at'),
            dataIndex: 'created_at',
            key: 'created_at',
            render: (e: any) => e ? dayjs(e).format("DD.MM.YYYY") : ''
        },
        {
            title: i.t('def:carts.table.user'),
            dataIndex: 'userid',
            key: 'userid',
            render: (e: any) => e ? <a href={`/users/${e}`}>{i.t('def:jump.jump')}</a> : ""
        },
        {
            title: i.t('def:carts.table.product_count'),
            dataIndex: 'get_products_count',
            key: 'get_products_count',
        },
        {
            title: i.t('def:carts.table.sum'),
            dataIndex: 'sum',
            key: 'sum',
        },
        {
            title: i.t('points:actions'),
            key: 'action',
            render: (text: any, record: any) => (
                <>
                    <Space>
                        <Button type="dashed" href={`/carts/${record.id}`}>{i.t('def:jump.jump')}</Button>
                    </Space>
                </>
            ),
        },
    ]

    return(
        <>
            <Titles
                title={i.t('def:carts.title')}
            />

            <Row gutter={[20, 20]}>
                <Col span={24}>
                    <h2>{i.t('def:carts.top')}</h2>
                </Col>
                {controller.top.map((el: any) => (
                    <Col span={6}>
                        <a href={`/shop/catalog/product/${el.id}`}>
                            <Statistic title={el.product.name} value={el.count} />
                        </a>
                    </Col>
                ))}
            </Row>

            <br/><br/>

            <Table
                columns={columns}
                dataSource={controller.list}
                rowKey="id"
                pagination={{
                    total: controller.pagination.total,
                    pageSize: 50,
                    onChange: (page, pageSize) => {
                        controller.pagination.page = page
                        controller.getList()
                    },
                }}
            />
        </>
    )
})

export const CartDetail = observer(() => {

    let { id }: any = useParams()

    useEffect(() => {
        if(id) controller.getCart(id)
    }, [])

    return(
        <>
            <Titles
                title={i.t('def:carts.detail_title')}
                buttons={[
                    { title: i.t('def:actions.back'), type: 'dashed', href: `/carts` }
                ]}
            />

            <Row gutter={[20, 20]}>
                <Col span={24}>
                    <h3>{i.t('def:carts.get_products')}</h3>
                </Col>
                <Col span={24}>
                    <ListProducts list={controller.cart.get_products} />
                </Col>
                {controller.cart.free_products && controller.cart.free_products.length
                    ?
                    <>
                        <Col span={24}>
                            <h3>{i.t('def:carts.free_products')}</h3>
                        </Col>
                        <Col span={24}>
                            <ListProducts list={controller.cart.free_products} />
                        </Col>
                    </>
                    : <></>
                }

            </Row>
        </>
    )
})

const ListProducts = ({ list, free = false }: any) => {

    const columns = [
        {
            title: "ID",
            dataIndex: 'product_id',
            key: 'product_id'
        },
        {
            title: i.t('product:count'),
            dataIndex: 'count',
            key: 'count'
        },
        {
            title: i.t('def:name'),
            dataIndex: 'product',
            key: 'name',
            render: (e: any) => e.name
        },
        {
            title: i.t('def:price'),
            dataIndex: 'product',
            key: 'price',
            render: (e: any) => free ? 0 : e.price
        },
    ]

    return(
        <>
            <Table
                columns={columns}
                dataSource={list}
                rowKey="id"
                pagination={false}
            />
        </>
    )
}