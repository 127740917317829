import { observer } from 'mobx-react-lite'
import React, { FormEvent, useEffect } from 'react'
import __schedule from './__schedule'
import i from '../../../translations/i'
import { Titles } from '../../../layouts/Titles'
import { Button, Col, Input, Row, Segmented, Switch, Modal, Upload, Divider } from 'antd'
import { UploadOutlined } from '@ant-design/icons'



const intervals = () => {
    let arr = []
    for(let i = 0; i < 24; i++) {
        arr.push({ number: i, string: `${('0' + (`${i}`)).slice(-2)}:00`, seconds: i > 0 ? i * 60 * 60 : 0 })
    }
    return arr
}



export const ImportSchedule = observer(() => {

    useEffect(() => {
        return () => {
            __schedule.get()
        }
    }, [])



    const days = [
        { title: i.t('def:day.monday'), key: 'schedule_monday', value: __schedule.schedule.schedule_monday },
        { title: i.t('def:day.tuesday'), key: 'schedule_tuesday', value: __schedule.schedule.schedule_tuesday },
        { title: i.t('def:day.wednesday'), key: 'schedule_wednesday', value: __schedule.schedule.schedule_wednesday },
        { title: i.t('def:day.thursday'), key: 'schedule_thursday', value: __schedule.schedule.schedule_thursday },
        { title: i.t('def:day.friday'), key: 'schedule_friday', value: __schedule.schedule.schedule_friday },
        { title: i.t('def:day.saturday'), key: 'schedule_saturday', value: __schedule.schedule.schedule_saturday },
        { title: i.t('def:day.sunday'), key: 'schedule_sunday', value: __schedule.schedule.schedule_sunday },
    ]

    return(
        <>
            <form onSubmit={(e: FormEvent) => __schedule.save(e)}>
                <Titles
                    title={i.t('settings:import.settings')}
                    buttons={[
                        { title: i.t('def:actions.back'), href: '/settings', type: 'dashed' },
                        { title: i.t('def:actions.save'), htmlType: `submit`, type: 'primary' }
                    ]}
                    breadcrumb={[
                        { title: i.t('def:settings'), href: '/settings' },
                        { title: i.t('settings:import.settings') },
                    ]}
                />

                <Row gutter={[20, 20]}>
                    <Col span={24}>
                        <label className="title">{i.t('def:importDays')}</label>
                        <div className="default-flex">
                            {days.map((el, index) => (
                                <div
                                    key={`schedule_${index}`}
                                    className={el.value ? "schedule active" : "schedule"}
                                    onClick={() => __schedule.setDay(el.key, el.value)}
                                >
                                    <p>{ el.title }</p>
                                </div>
                            ))}
                        </div>
                    </Col>
                    <Divider />
                    <Col span={24}>
                        <label className="title">{i.t('def:importTimes')}</label>
                        <p>{i.t('settings:import.serverTime')}</p>

                        <div className="default-flex-min">
                            {intervals().map((el, index) => (
                                <div
                                    key={`div_interval_${index}`}
                                    className={
                                        __schedule.scheduleDisabledTime.findIndex((o: any) => Number(o) === el.number) > -1
                                            ? "schedule disabled"
                                            : __schedule.schedule.schedule_time.findIndex((o: any) => Number(o) === el.seconds) > -1 ? "schedule active" : "schedule"}
                                    onClick={() => __schedule.setScheduleTime(el)}
                                >
                                    <p>{el.string}</p>
                                </div>
                            ))}
                        </div>
                    </Col>
                </Row>
            </form>
        </>
    )
})