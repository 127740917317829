import { makeObservable, observable, runInAction } from 'mobx'
import { openApi } from '../../helpers/request'
import __multiple from '../../helpers/multiple_actions/__multiple'
import { INews, INewsType } from './types'
import { FormEvent } from 'react'
import { notification } from 'antd'
import i from '../../translations/i'

class __news {

    news_list: INews[] = []
    news_element: INews = {
        id: 0,
        name: '',
        position: 0,
        anotation: '',
        text: [],
        active: true,
        type: '',
        publicdate: 0,
        sendpush: false,
        image: null,
        datefrom: 0,
        dateto: 0,
        typeview: 'default',
        warehouse_id: 0
    }

    news_element_new_image: any = null

    types_list: INewsType[] = []
    type = {
        id: 0,
        name: '',
        colorhex: 'FFFFFF'
    }

    limit: number = 10
    total: number = 0
    page: number = 1

    constructor() {
        makeObservable(this, {
            news_list: observable,
            news_element: observable,
            total: observable,
            page: observable,
            types_list: observable,
            news_element_new_image: observable,
            type: observable,
        })
    }

    saveType(e: FormEvent) {
        e.preventDefault()
        const data = new FormData()
        data.append('name', this.type.name)
        data.append('colorhex', this.type.colorhex)

        openApi('post', this.type.id > 0 ? `/news/types/${this.type.id}` : `/news/types`, data)
            .then(result => {
                console.log(result)
                if(this.type.id > 0) {
                    this.getOneType(this.type.id)
                    notification.success({
                        message: i.t('def:success.complete'),
                        description: i.t('def:success.edit')
                    });
                }
                else window.location.href = `/news_types`
            })
    }

    save(e: FormEvent) {
        e.preventDefault()

        const data = new FormData()
        data.append('name', this.news_element.name)
        data.append('anotation', this.news_element.anotation)
        data.append('active', `${+ this.news_element.active}`)
        data.append('type', this.news_element.type)
        data.append('sendpush', `${+ this.news_element.sendpush}`)
        data.append('typeview', this.news_element.typeview)
        data.append('warehouse_id', `${this.news_element.warehouse_id ?? 0}`)
        if(this.news_element.datefrom) data.append('datefrom', `${this.news_element.datefrom}`)
        if(this.news_element.dateto) data.append('dateto', `${this.news_element.dateto}`)

        // @ts-ignore
        if(this.news_element.image && !this.news_element.image.path) data.append('image', this.news_element.image)

        if(!this.news_element.image) data.append('image_delete', 'true')

        let text: any[] = []

        this.news_element.text.map(el => {
            if(el.type === 'image' && el.link) {
                data.append(el.link, el.file)
                text.push({id: el.id, link: el.link, value: '', type: 'image'})
            } else text.push(el)
        })

        data.append('text', JSON.stringify(text))

        openApi('post', this.news_element.id > 0 ? `/news/${this.news_element.id}` : `/news`, data, { key: 'Content-Type', value: 'multipart/form-data' })
            .then(result => {
                console.log(result)
                if(this.news_element.id > 0) {
                    this.getOne(this.news_element.id)
                    notification.success({
                        message: i.t('def:success.complete'),
                        description: i.t('def:success.edit')
                    });
                }
                else window.location.href = `/news`
            })
    }

    getNewsList() {
        openApi('get', `/news?limit=${this.limit}&page=${this.page}`)
            .then(result => {
                console.log(result)
                result = result.data
                runInAction(() => {
                    this.total = result.count
                    this.news_list = result.list
                })
            })
    }

    getOne(id: any) {
        if(id > 0)
            openApi('get', `/news/${id}`)
                .then(result => {
                    console.log(result)
                    runInAction(() => this.news_element = result.data)
                })
    }

    setPage(page: number) {
        runInAction(() => {
            this.page = page
            this.getNewsList()
        })
    }

    getActions() {
        return [
            { label: i.t('def:actions.remove'), value: 'delete', action: () => this.delete() }
        ]
    }

    getActionsTypes() {
        return [
            { label: i.t('def:actions.remove'), value: 'delete', action: () => this.deleteTypes() }
        ]
    }

    delete() {
        const data = new FormData()
        __multiple.selected.map(el => data.append('elements[]', `${el}`))

        openApi('post', `/news/delete`, data)
            .then(result => {
                this.getNewsList()
            })
    }

    onChangeTab(tab: string) {
        console.log(tab)
    }

    getTypesList(add: boolean = false) {
        openApi('get', `/news/types?limit=1000&page=1`)
          .then(result => {
              console.log(result)
              runInAction(() => {
                  this.types_list = result.data
                  if (add) this.types_list.unshift({ name: ' ', id: 0, colorhex: '', colorrgb: '' })
              })
          })
          .catch(err => console.log(err.response))
    }

    addBlockText() {
        this.news_element.text.push({
            id: 0,
            type: 'text',
            value: ''
        })
    }

    addBlockLink() {
        this.news_element.text.push({
            id: 0,
            type: 'link',
            name: '',
            value: ''
        })
    }

    addBlockImage() {
        this.news_element.text.push({
            id: 0,
            type: 'image',
            value: null,
            file: null
        })
    }

    deleteBlock(index: number) {
        this.news_element.text.splice(index, 1)
    }

    pullUp(index: number) {
        if(index > 0) {
            let tmp = this.news_element.text[index]
            this.news_element.text[index] = this.news_element.text[index - 1]
            this.news_element.text[index - 1] = tmp
        }
    }

    pullDown(index: number) {
        if(index < this.news_element.text.length - 1) {
            let tmp = this.news_element.text[index]
            this.news_element.text[index] = this.news_element.text[index + 1]
            this.news_element.text[index + 1] = tmp
        }
    }

    deleteTypes() {
        const data = new FormData()
        __multiple.selected.map(el => data.append('elements[]', `${el}`))

        openApi('post', `/news/types/delete`, data)
            .then(result => {
                this.getTypesList()
            })
    }

    getOneType(id: any) {
        if(id > 0)
            openApi('get', `/news/types/${id}`)
                .then(result => {
                    runInAction(() => this.type = result.data)
                })
    }

    setColor(color: string) {
        this.type.colorhex = color.replace('#', '')
    }

}

export default new __news()