import { observer } from 'mobx-react-lite'
import {
    Row,
    Col, Alert, Switch, InputNumber, Tooltip, Divider
} from 'antd'
import i from '../../../../translations/i'
import { __product } from '../__product'
import React, { useEffect } from 'react'
import { runInAction } from 'mobx'
import { WarehousesController } from '../../../settings/warehouses/warehouses.controller'
import { returnParseProjectElement } from '../../../../helpers/project.controller'

const Storage = new WarehousesController()

export const ProductWarehouses = observer(() => {

    useEffect(() => {
        Storage.get(1000)
    }, [])

    return(
        <>
            {returnParseProjectElement('warehouses_enabled', true) < 1 &&
                <Row>
                    <Col span={24}>
                        <Alert message={i.t('product:storageAlert')} type="info" showIcon />
                        <br/>
                    </Col>
                </Row>
            }

            {Storage.list && Storage.list.length > 0 &&
                <>
                    <Row gutter={[20, 20]}>
                        {Storage.list.map((el: any) => (
                            <>
                                <Col span={24} className="product_storage">
                                    <Row gutter={[20, 20]} justify="space-around">
                                        <Col span={15}>
                                            <p className="name">{el.name}</p>
                                        </Col>
                                        <Col span={9} className="right">
                                            <Tooltip
                                                title={i.t('def:view')}
                                            >
                                                <Switch
                                                    size="small"
                                                    onChange={(e) => __product.setProductStorageID(el.id, e)}
                                                    checked={__product.item.storages.find((item: any) => item.warehouseid === el.id) && __product.item.storages.find((item: any) => item.warehouseid === el.id).view > 0}
                                                />
                                            </Tooltip>
                                        </Col>
                                        {__product.item.storages.find((item: any) => item.warehouseid === el.id) &&
                                            <>
                                                <Col span={8}>
                                                    <label className="formLabel">{i.t('product:oldPrice')}</label>
                                                    <InputNumber
                                                        style={{ width: '100%' }}
                                                        value={__product.item.storages.find((item: any) => item.warehouseid === el.id).priceold}
                                                        onChange={(e) => __product.setProductDataToStorage(el.id, "priceold", e > 0 ? e : 0)}
                                                    />
                                                </Col>
                                                <Col span={8}>
                                                    <label className="formLabel">{i.t('product:price')}</label>
                                                    <InputNumber
                                                        style={{ width: '100%' }}
                                                        value={ __product.item.storages.find((item: any) => item.warehouseid === el.id).price }
                                                        onChange={ (e) => __product.setProductDataToStorage(el.id, "price", e > 0 ? e : 0) }
                                                    />
                                                </Col>
                                                <Col span={8}>
                                                    <label className="formLabel">{i.t('product:count')}</label>
                                                    <InputNumber
                                                        style={{ width: '100%' }}
                                                        value={ __product.item.storages.find((item: any) => item.warehouseid === el.id).count }
                                                        onChange={ (e) => __product.setProductDataToStorage(el.id, "count", e > 0 ? e : 0) }
                                                    />
                                                </Col>
                                            </>
                                        }
                                    </Row>
                                </Col>
                            </>
                        ))}
                    </Row>
                </>
            }
        </>
    )
})