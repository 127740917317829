import { observer } from 'mobx-react-lite'
import i from '../../../translations/i'
import { Titles } from '../../../layouts/Titles'
import controller from './cities.controller'
import { Button, Pagination, Space, Table, Switch, Row, Col, Input, notification, Upload } from 'antd'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { ParamTypes } from '../../../helpers/types.interfaces'

export const City = observer(() => {
    const { elementID } = useParams<ParamTypes>()

    useEffect(() => {
        controller.get(elementID)
    }, [])

    const buttons = [
        { title: i.t('def:actions.backList'), href: `/settings/cities`, type: 'dashed' },
        { title: i.t('def:actions.save'), htmlType: `submit`, type: 'primary' }
    ]

    return (
        <>
            <form onSubmit={(e) => controller.save(e)}>
                <Titles
                    title={i.t('settings:cities.titleDetail')}
                    buttons={buttons}
                />

                <Row gutter={[20, 20]}>
                    <Col xs={24} className="switch-block">
                        <div className="element">
                            <div></div>
                            <Switch
                                checked={controller.city.enabled}
                                onChange={e => controller.set('enabled', e)}
                            />
                            <div>
                                <span>{i.t('settings:cities.form.enabled')}</span>
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} md={12}>
                        <label>{i.t('settings:cities.form.name')}</label>
                        <Input
                            name="title"
                            placeholder={i.t('settings:cities.form.name')}
                            value={controller.city.name}
                            onChange={(e) => controller.set('name', e.target.value)}
                        />
                    </Col>
                    <Col xs={24} md={12}>
                        <label>{i.t('def:id_crm')}</label>
                        <Input
                            name="id_crm"
                            placeholder={i.t('def:id_crm')}
                            value={controller.city.id_crm}
                            onChange={(e) => controller.set('id_crm', e.target.value)}
                        />
                    </Col>
                    <Col xs={24} md={12}>
                        <label>{i.t('settings:cities.form.latitude')}</label>
                        <Input
                            name="title"
                            placeholder={i.t('settings:cities.form.latitude')}
                            value={controller.city.latitude}
                            onChange={(e) => controller.set('latitude', e.target.value)}
                        />
                    </Col>
                    <Col xs={24} md={12}>
                        <label>{i.t('settings:cities.form.longitude')}</label>
                        <Input
                            name="title"
                            placeholder={i.t('settings:cities.form.longitude')}
                            value={controller.city.longitude}
                            onChange={(e) => controller.set('longitude', e.target.value)}
                        />
                    </Col>
                    <Col xs={24} md={12}>
                        <label>{i.t('settings:cities.form.image')}</label>
                        {controller.city.picture?.id > 0 ? (
                            <Row gutter={[10, 10]}>
                                <Col xs={24}>
                                    <img src={controller.city.picture.base + controller.city.picture.uri} alt='' style={{maxWidth: '250px'}}/>
                                </Col>
                                <Col xs={24}>
                                    <Button
                                        onClick={e => controller.removeImage(elementID)}
                                    >
                                        {i.t('def:deletePicture')}
                                    </Button>
                                </Col>
                            </Row>
                        ) : (
                            <Upload
                                beforeUpload={(info) => {
                                    controller.upload(info)
                                    return false
                                }}
                                listType="picture-card"
                                className="photo-uploader"
                                maxCount={1}
                            >
                                <div>
                                    <PlusOutlined />
                                    <div style={{ marginTop: 8 }}>{i.t('def:image.upload')}</div>
                                </div>
                            </Upload>
                        )}
                    </Col>
                </Row>
            </form>
        </>
    )
})

export const Cities = observer(() => {
    useEffect(() => {
        controller.get()
    }, [])

    const buttons = [
        { title: i.t('def:actions.create'), href: `/settings/cities/0`, type: 'primary' }
    ]

    const columns = [
        { title: 'ID', dataIndex: 'id', key: 'id' },
        { title: i.t('def:name'), dataIndex: 'name', key: 'name' },
        {
            title: i.t('settings:cities.form.enabled'),
            dataIndex: 'enabled',
            key: 'enabled',
            render: (text: any, record: any) => (
                <>{text === 0 ? i.t('def:no') : i.t('def:yes')}</>
            )
        },
        {
            key: 'action',
            render: (text: any, record: any) => (
                <Space size="middle">
                    <Button
                        type="dashed"
                        shape="circle"
                        icon={<EditOutlined />}
                        href={`/settings/cities/${record.id}`}
                    />
                    <Button
                        type="dashed"
                        shape="circle"
                        danger
                        icon={<DeleteOutlined />}
                        onClick={() => controller.remove(record.id)}
                    />
                </Space>
            )
        }
    ]

    return (
        <>
            <Titles
                title={i.t('settings:cities.title')}
                buttons={buttons}
            />

            <Table
                columns={columns}
                dataSource={controller.list}
                pagination={{
                    position: ['bottomLeft'],
                    hideOnSinglePage: true,
                    current: Number(controller.pagination.page),
                    pageSize: controller.pagination.limit,
                    total: controller.pagination.total,
                    showSizeChanger: false,
                    onChange: (e) => controller.setPage(e)
                }}
            />
        </>
    )
})