import { observer } from 'mobx-react-lite'
import React, { FormEvent, useEffect } from 'react'
import __schedule from './__schedule'
import { Titles } from '../../../layouts/Titles'
import i from '../../../translations/i'
import {
    Button,
    Col,
    Divider,
    Input,
    InputNumber,
    Modal,
    Row,
    Segmented,
    Space,
    Switch,
    Upload,
    UploadProps
} from 'antd'
import { DeleteOutlined, ExclamationCircleFilled, UploadOutlined } from '@ant-design/icons'
import controller from './controllers/importExcel.controller'
import projectController from '../project/project.controller'
import { config } from '../../../config/app'

const { confirm } = Modal

export const Import = observer(() => {

    useEffect(() => {
        return () => {
            __schedule.get()
            projectController.get()
        }
    }, [])

    const showConfirmNow = () => {
        confirm({
            title: i.t('def:importCatalog'),
            icon: <ExclamationCircleFilled />,
            content: i.t('def:importNow'),
            centered: true,
            onOk() {
                __schedule.import_now()
            }
        })
    }

    const showConfirm = () => {
        confirm({
            title: i.t('def:importFromFile'),
            icon: <ExclamationCircleFilled />,
            content: i.t('def:importNowFile'),
            centered: true,
            onOk() {
                if(__schedule.file_type === 'excel') controller.saveExelFile(__schedule.file)
                else __schedule.upload()
            }
        })
    }

    const props: UploadProps = {
        fileList: __schedule.file ? [__schedule.file] : [],
        accept: __schedule.file_type === 'xml' ? '.xml,.yml' : '.xls,.xlsx',
        maxCount: 1,
        beforeUpload(info) {
            __schedule.file = info
            //showConfirm()
            return false
        }
    }

    const types = [
        { label: 'YClients', value: 'yclients', b2c: true },
        { label: 'Sbis', value: 'sbis', b2c: true },
        { label: 'XML / YML', value: 'file' },
    ]

    return(
        <>
            <form onSubmit={(e: FormEvent) => __schedule.save(e)}>
                <Titles
                    title={i.t('def:importSettings')}
                    buttons={[
                        { title: i.t('def:actions.back'), href: '/settings', type: 'dashed' },
                        { title: i.t('def:actions.save'), htmlType: `submit`, type: 'primary' }
                    ]}
                    breadcrumb={[
                        { title: i.t('def:settings'), href: '/settings' },
                        { title: i.t('def:importSettings') },
                    ]}
                />

                <Row gutter={[20, 20]}>
                    <Col span={24}>
                        <label className="title">{i.t('def:importActionsItems')}:</label>
                    </Col>
                    <Col span={24}>
                        <label className="label">{i.t('def:importActionsItemsDescriptions')}</label>
                        <Segmented
                            value={projectController.project.import_action_elements}
                            options={[
                                { label: i.t('def:importActionsItemsDescriptionsOne'), value: 0 },
                                { label: i.t('def:importActionsItemsDescriptionsTwo'), value: 1 },
                                { label: i.t('def:actions.remove'), value: 2 },
                            ]}
                            onChange={(e) => projectController.set('import_action_elements', e, true)}
                        />
                    </Col>
                    <Divider />
                    <Col span={24}>
                        <label className="title">{i.t('def:importingFromFile')}:</label>
                    </Col>
                    <Col span={24}>
                        <Row gutter={[20, 20]}>
                            <Col span={24}>
                                <label className="label">{i.t('def:selectFileType')}</label>
                                <Segmented
                                    value={__schedule.file_type}
                                    options={[
                                        { label: 'XML / YML', value: 'xml' },
                                        // { label: 'Excel', value: 'excel' },
                                    ]}
                                    onChange={(e) => __schedule.file_type = String(e)}
                                />
                            </Col>
                            <Col span={24}>
                                <Upload {...props}>
                                    <Button icon={<UploadOutlined />}>{i.t('def:selectFile')}</Button>
                                </Upload>
                            </Col>
                            {__schedule.file_type === 'excel' && <Col span={24}><ImportExcel /></Col>}
                            <Col span={24}>
                                <Space>
                                    <Button onClick={showConfirm} type="primary" disabled={!__schedule.file}>{i.t('def:start')}</Button>
                                    {__schedule.file_type === 'excel' && <Button onClick={() => controller.saveExel()} type="dashed">{i.t('def:saveSettingExcel')}</Button>}
                                </Space>
                            </Col>
                        </Row>
                    </Col>
                    <Divider />
                    <Col span={24}>
                        <label className="title">{i.t('def:importSchedule')}</label>
                    </Col>
                    <Col span={24} className="switch-block">
                        <div className="element">
                            <div></div>
                            <Switch
                                checked={__schedule.schedule.schedule_enabled}
                                onChange={e => __schedule.schedule.schedule_enabled = e}
                                size="small"
                            />
                            <div>
                                <span>{__schedule.schedule.schedule_enabled ? i.t('def:included') : i.t('def:switchedOff')}</span>
                            </div>
                        </div>
                    </Col>
                    {__schedule.schedule.schedule_enabled ?
                        <>
                            <ImportFile />

                            <Divider />
                            <Col span={24}>
                                <Space>
                                    <Button
                                        type='dashed'
                                        href="/settings/import/schedule"
                                    >
                                        {i.t('def:scheduleSettings')}
                                    </Button>
                                    <Button
                                        onClick={showConfirmNow}
                                        type='dashed'
                                        danger
                                    >
                                        {i.t('def:startImportNow')}
                                    </Button>
                                </Space>
                            </Col>
                        </> : <></>
                    }
                </Row>
            </form>
        </>
    )
})

const ImportExcel = observer(() => {

    useEffect(() => {
        controller.getExel()
    }, [])

    return(
        <>
            <Row gutter={[20, 20]} align="bottom" justify="space-between">
                <Col span={12}>
                    {i.t('import:excel.lines')}
                </Col>
                <Col span={12}>
                    <InputNumber
                        style={{ width: `100%` }}
                        onChange={(e) => controller.setExel('lineexclusion', e)}
                        value={controller.exel.lineexclusion}
                    />
                </Col>
                <Col span={12}>
                    <b>{i.t('import:excel.var')}</b>
                </Col>
                <Col span={12}>
                    <b>{i.t('import:excel.row')}</b>
                </Col>
                {/*<Col span={12}>Поле наименования блока категорий</Col>*/}
                {/*<Col span={12}>*/}
                {/*    <InputNumber*/}
                {/*        style={{ width: `100%` }}*/}
                {/*        onChange={(e) => controller.setExel('name_categorys', e)}*/}
                {/*        value={controller.exel.name_categorys}*/}
                {/*    />*/}
                {/*</Col>*/}
                {/*<Col span={12}>Поле с наименованием категории</Col>*/}
                {/*<Col span={12}>*/}
                {/*    <InputNumber*/}
                {/*        style={{ width: `100%` }}*/}
                {/*        onChange={(e) => controller.setExel('name_category', e)}*/}
                {/*        value={controller.exel.name_category}*/}
                {/*    />*/}
                {/*</Col>*/}
                {/*<Col span={12}>Поле родительской категории</Col>*/}
                {/*<Col span={12}>*/}
                {/*    <InputNumber*/}
                {/*        style={{ width: `100%` }}*/}
                {/*        onChange={(e) => controller.setExel('parentid_category', e)}*/}
                {/*        value={controller.exel.parentid_category}*/}
                {/*    />*/}
                {/*</Col>*/}
                {/*<Col span={12}>Поле ID по которому синхронизируем категории</Col>*/}
                {/*<Col span={12}>*/}
                {/*    <InputNumber*/}
                {/*        style={{ width: `100%` }}*/}
                {/*        onChange={(e) => controller.setExel('id_crm_category', e)}*/}
                {/*        value={controller.exel.id_crm_category}*/}
                {/*    />*/}
                {/*</Col>*/}
                {/*<Col span={12}>Поле с изображением категории</Col>*/}
                {/*<Col span={12}>*/}
                {/*    <InputNumber*/}
                {/*        style={{ width: `100%` }}*/}
                {/*        onChange={(e) => controller.setExel('picture_category', e)}*/}
                {/*        value={controller.exel.picture_category}*/}
                {/*    />*/}
                {/*</Col>*/}
                <Col span={12}>
                    {i.t('import:excel.sync')}
                </Col>
                <Col span={12}>
                    <InputNumber
                        style={{ width: `100%` }}
                        onChange={(e) => controller.setExel('id_crm_offer', e)}
                        value={controller.exel.id_crm_offer}
                        defaultValue={0}
                    />
                </Col>
                <Col span={12}>
                    {i.t('import:excel.vendor')}
                </Col>
                <Col span={12}>
                    <InputNumber
                        style={{ width: `100%` }}
                        onChange={(e) => controller.setExel('vendorcode_offer', e)}
                        value={controller.exel.vendorcode_offer}
                    />
                </Col>
                <Col span={12}>{i.t('import:excel.name')}</Col>
                <Col span={12}>
                    <InputNumber
                        style={{ width: `100%` }}
                        onChange={(e) => controller.setExel('name_offer', e)}
                        value={controller.exel.name_offer}
                    />
                </Col>
                <Col span={12}>{i.t('import:excel.image')}</Col>
                <Col span={12}>
                    <InputNumber
                        style={{ width: `100%` }}
                        onChange={(e) => controller.setExel('picture_offer', e)}
                        value={controller.exel.picture_offer}
                    />
                </Col>
                <Col span={12}>{i.t('import:excel.price')}</Col>
                <Col span={12}>
                    <InputNumber
                        style={{ width: `100%` }}
                        onChange={(e) => controller.setExel('price_offer', e)}
                        value={controller.exel.price_offer}
                    />
                </Col>
                <Col span={12}>{i.t('import:excel.oldPrice')}</Col>
                <Col span={12}>
                    <InputNumber
                        style={{ width: `100%` }}
                        onChange={(e) => controller.setExel('oldprice_offer', e)}
                        value={controller.exel.oldprice_offer}
                    />
                </Col>
                {/*<Col span={12}>Поле с наименованием блока товаров</Col>*/}
                {/*<Col span={12}>*/}
                {/*    <InputNumber*/}
                {/*        style={{ width: `100%` }}*/}
                {/*        onChange={(e) => controller.setExel('name_offers', e)}*/}
                {/*        value={controller.exel.name_offers}*/}
                {/*    />*/}
                {/*</Col>*/}
                {/*<Col span={12}>Поле с идентификатором родительской категории</Col>*/}
                {/*<Col span={12}>*/}
                {/*    <InputNumber*/}
                {/*        style={{ width: `100%` }}*/}
                {/*        onChange={(e) => controller.setExel('parentid_offer', e)}*/}
                {/*        value={controller.exel.parentid_offer}*/}
                {/*    />*/}
                {/*</Col>*/}
                <Col span={12}>
                    {i.t('import:excel.description')}
                </Col>
                <Col span={12}>
                    <InputNumber
                        style={{ width: `100%` }}
                        onChange={(e) => controller.setExel('description_offer', e)}
                        value={controller.exel.description_offer}
                    />
                </Col>
                <Col span={12}>
                    {i.t('import:excel.count')}
                </Col>
                <Col span={12}>
                    <InputNumber
                        style={{ width: `100%` }}
                        onChange={(e) => controller.setExel('count_offer', e)}
                        value={controller.exel.count_offer}
                    />
                </Col>
                {/*<Col span={12}>Поле главного товара для подтовара</Col>*/}
                {/*<Col span={12}>*/}
                {/*    <InputNumber*/}
                {/*        style={{ width: `100%` }}*/}
                {/*        onChange={(e) => controller.setExel('oferid_offer', e)}*/}
                {/*        value={controller.exel.oferid_offer}*/}
                {/*    />*/}
                {/*</Col>*/}
                <Col span={24}>
                    {i.t('settings:import.props')}
                </Col>
                {controller.exel.param.map((el: any) => (
                    <Col span={24} key={el.key}>
                        <Row gutter={[20, 20]} justify="space-between" align="bottom">
                            <Col span={10}>
                                <label>{i.t('settings:import.propName')}</label>
                                <Input
                                    style={{ width: `100%` }}
                                    onChange={(e) => controller.setParam(el.key, 'name', e.target.value)}
                                    value={el.name}
                                />
                            </Col>
                            <Col span={10}>
                                <label>{i.t('settings:import.fieldPropValue')}</label>
                                <InputNumber
                                    style={{ width: `100%` }}
                                    onChange={(e) => controller.setParam(el.key, 'value', e)}
                                    value={el.value}
                                />
                            </Col>
                            <Col span={4}>
                                <Button onClick={() => controller.removeParam(el.key)} icon={<DeleteOutlined />} />
                            </Col>
                        </Row>
                    </Col>
                ))}
                <Col span={24}>
                    <Button onClick={() => controller.addNewParam()}>{i.t('settings:import.addProp')}</Button>
                </Col>
            </Row>
        </>
    )
})

const ImportFile = observer(() => {

    return(
        <>
            <Divider />
            <Col span={24}>
                <label className="label">{i.t('settings:label.link')}</label>
                <Input
                    value={__schedule.schedule.type_file_uri}
                    onChange={(e) => __schedule.schedule.type_file_uri = e.target.value}
                    placeholder={i.t('settings:label.link')}
                />
            </Col>
        </>
    )
})