import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import i from '../../../../translations/i'
import {Titles} from '../../../../layouts/Titles'
import { Button, Space, Table, Tooltip, Switch, Popconfirm } from 'antd'
import {SettingOutlined, DeleteOutlined} from '@ant-design/icons'
import __points from '../controllers/__points'

export const PlacesGroupsList = observer(() => {
    useEffect(() => {
        return () => {
            __points.getGroupsList()
        }
    }, [])

    const columns = [
        {
            title: "ID",
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: i.t('def:name'),
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: i.t('def:actions.actions'),
            key: 'action',
            render: (group: any) => (
                <>
                    <Space>
                        <Tooltip title={i.t('def:actions.edit')}>
                            <Button type="dashed" shape="circle" href={`/settings/places_groups/${group.id}`} icon={<SettingOutlined />} />
                        </Tooltip>
                        <Tooltip title={i.t('def:actions.remove')} zIndex={5}>
                            <Popconfirm
                                title={i.t('def:confirms.delete')}
                                onConfirm={() => __points.deleteOneGroup(group.id)}
                                okText={i.t('def:yes')}
                                cancelText={i.t('def:no')}
                            >
                                <Button
                                    type="dashed"
                                    shape="circle"
                                    icon={<DeleteOutlined />}
                                    danger
                                />
                            </Popconfirm>
                        </Tooltip>
                    </Space>
                </>
            ),
        },
    ]

    return <>
        <Titles
            title={i.t('def:Groups')}
            breadcrumb={[
                { title: i.t('def:settings'), href: '/settings' },
                { title: i.t('points:list'), href: '/settings/places' },
                { title: i.t('def:Groups') },
            ]}
            buttons={[
                { title: i.t('def:actions.back'), href: `/settings/places`, type: 'dashed' },
                { title: i.t('def:Create Group'), href: `/settings/places_groups/0`, type: 'primary' }
            ]}
        />

        <Table
            rowKey="id"
            columns={columns}
            dataSource={__points.groupsList}
            pagination={{
                position: ['bottomLeft'],
                hideOnSinglePage: true,
                current: __points.page,
                pageSize: __points.limit,
                total: __points.total,
                showSizeChanger: false,
                onChange: (e: number) => {
                    __points.setPageNew(e)
                    __points.getGroupsList()
                }
            }}
        />
    </>
})