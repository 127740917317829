import { makeAutoObservable } from 'mobx'
import { request } from '../../../../helpers/request'
import moment from 'moment'

class intervalController {
    constructor() {
        makeAutoObservable(this)
    }

    interval = {
        viewintervaldelivery: false,
        timestartdelivery: 0,
        timeenddelivery: 0,
        timeinterval: 0,
        viewintervalpickup: false,
        timestartpickup: 0,
        timeendpickup: 0,
        timeintervalpickup: 0,
        timeassemble: 0,
        timeassemblepickup: 0
    }

    intervalOrder = {
        viewintervaldelivery: false,
        timestartdelivery: 0,
        timeenddelivery: 0,
        timeinterval: 0,
        viewintervalpickup: false,
        timestartpickup: 0,
        timeendpickup: 0,
        timeintervalpickup: 0,
        timeassemble: 0,
        timeassemblepickup: 0
    }

    getIntervalForOrder() {
        return new Promise((resolve, reject) => {
            request('get', '/shop/intervaldelivery', {}, true)
                .then((success) => {
                    this.intervalOrder = {
                        viewintervaldelivery: success.data.viewintervaldelivery,
                        timestartdelivery: success.data.timestartdelivery,
                        timeenddelivery: success.data.timeenddelivery,
                        timeinterval: success.data.timeinterval,
                        viewintervalpickup: success.data.viewintervalpickup,
                        timestartpickup: success.data.timestartpickup,
                        timeendpickup: success.data.timeendpickup,
                        timeintervalpickup: success.data.timeintervalpickup,
                        timeassemble: success.data.timeassemble,
                        timeassemblepickup: success.data.timeassemblepickup
                    }
                    setTimeout(() => {
                        resolve('foo')
                    }, 300)
                })
                .catch((error) => console.log(error))
        })
    }

    getInterval() {
        request('get', '/shop/intervaldelivery', {}, true)
            .then((success) => {
                console.log(success)
                this.interval = {
                    viewintervaldelivery:
                        success.data.viewintervaldelivery === 1 ? true : false,
                    timestartdelivery: this.convertFromSecond(
                        success.data.timestartdelivery
                    ),
                    timeenddelivery: this.convertFromSecond(
                        success.data.timeenddelivery
                    ),
                    timeinterval:
                        success.data.timeinterval > 0
                            ? success.data.timeinterval / 60
                            : 0,
                    viewintervalpickup:
                        success.data.viewintervalpickup === 1 ? true : false,
                    timestartpickup: this.convertFromSecond(
                        success.data.timestartpickup
                    ),
                    timeendpickup: this.convertFromSecond(
                        success.data.timeendpickup
                    ),
                    timeintervalpickup:
                        success.data.timeintervalpickup > 0
                            ? success.data.timeintervalpickup / 60
                            : 0,
                    timeassemble:
                        success.data.timeassemble > 0
                            ? success.data.timeassemble / 60
                            : 0,
                    timeassemblepickup:
                        success.data.timeassemblepickup > 0
                            ? success.data.timeassemblepickup / 60
                            : 0
                }
            })
            .catch((error) => console.log(error))
    }

    onChangeSwitch(name, value) {
        this.interval[name] = value
    }

    onChange(name, value) {
        this.interval[name] = value
    }

    onChangeTimeT(key, time) {
        console.log(time)
        this.onChangeTime(key, time, moment(time).lang('ru').format('HH:mm'))
    }

    onChangeTimeValue(key, time) {
        let a = time.split(':')
        let seconds = +a[0] * 60 * 60 + +a[1] * 60

        if((key === 'timeendpickup' || key === 'timeenddelivery') && seconds === 0) {
            seconds = 86400
        }

        console.log(seconds)
        this.interval[key] = time
    }

    onChangeTime(key, time, timeString) {
        console.log(timeString)

        let a = timeString.split(':')
        let seconds = +a[0] * 60 * 60 + +a[1] * 60

        if((key === 'timeendpickup' || key === 'timeenddelivery') && seconds === 0) {
            seconds = 86400
        }

        console.log(seconds)
        this.interval[key] = timeString
    }

    convertToSeconds(time) {
        let startTime = time.split(':')
        return +startTime[0] * 60 * 60 + +startTime[1] * 60
    }

    convertFromSecond(time) {
        let hour = Math.floor(time / 60 / 60)

        let minutesSeconds = time - hour * 60 * 60

        return hour + ':' + minutesSeconds / 60
    }

    onSubmit() {
        let facts = new FormData()

        facts.append(
            'viewintervaldelivery',
            this.interval.viewintervaldelivery ? 1 : 0
        )
        facts.append(
            'timestartdelivery',
            this.convertToSeconds(this.interval.timestartdelivery)
        )

        if(this.interval.timeenddelivery === '00:00') {
            facts.append('timeenddelivery', 86400)
        } else {
            facts.append('timeenddelivery', this.convertToSeconds(this.interval.timeenddelivery))
        }

        facts.append('timeinterval', this.interval.timeinterval * 60)

        facts.append(
            'viewintervalpickup',
            this.interval.viewintervalpickup ? 1 : 0
        )
        facts.append(
            'timestartpickup',
            this.convertToSeconds(this.interval.timestartpickup)
        )

        if(this.interval.timeendpickup === '00:00') {
            facts.append('timeendpickup', 86400)
        } else {
            facts.append('timeendpickup', this.convertToSeconds(this.interval.timeendpickup))
        }

        facts.append(
            'timeintervalpickup',
            this.interval.timeintervalpickup * 60
        )
        facts.append(
            'timeassemble',
            this.interval.timeassemble * 60
        )
        facts.append(
            'timeassemblepickup',
            this.interval.timeassemblepickup * 60
        )

        request('post', '/shop/intervaldelivery', facts, true).then(
            (success) => {
                console.log(success)
            }
        )
    }
}

export default new intervalController()
