import axios from 'axios'
import userController from '../front/user/controllers/user.controller'
import spinController from './spin/spin.controller'
import { config } from '../config/app'
import layoutController from '../layouts/controllers/layout.controller'
import { storeLocal } from './stores'
import { notification } from 'antd'
import dayjs from 'dayjs'

interface HeaderInterface {
    key?: string,
    value?: string
}

export const openApi = async (method: string, path: string, data: any = {}, header: HeaderInterface = { key: '', value: '' }) => {
    spinController.onChange()

    let access_token = ''
    let refresh_token = ''

    let tokens = await userController.decrypt()

    if(tokens.expires <= dayjs().unix())
        layoutController.logout()

    if(tokens) {
        if(tokens.access_token) access_token = tokens.access_token
        if(tokens.refresh_token) refresh_token = tokens.refresh_token
    }

    let headers = {
        'Authorization': `Bearer ${access_token}`,
        'Refresh-Token': refresh_token,
        'Language': storeLocal.get('lang') ? storeLocal.get('lang') : config.defaultLanguage,
        'Client-Domain': window && window.location && window.location.host && window.location.host !== '' ? window.location.host : '',
        'Cloud-System': 'cloud'
    }

    if(header.key && header.key !== '' && header.value && header.value !== '') { // @ts-ignore
        headers[header.key] = header.value
    }

    const req = axios.create({
        baseURL: `${config.open_api_uri}`,
        headers: headers
    })

    if(method === 'get') {
        return req.get(path)
            .then(result => {
                spinController.onChange()
                return result.data
            })
            .catch(error => {
                spinController.onChange()
                console.log(error)

                throw error
            })
    } else if(method === 'post') {
        return req.post(path, data)
            .then(result => {
                console.log(result)
                spinController.onChange()
                return result.data
            })
            .catch(error => {
                spinController.onChange()
                console.log(error)

                throw error
            })
    } else if(method === 'put') {
        return req.put(path, data)
          .then(result => {
              console.log(result)
              spinController.onChange()
              return result.data
          })
          .catch(error => {
              spinController.onChange()
              console.log(error)

              throw error
          })
    } else if(method === 'delete') {
        return req.delete(path, data)
            .then(result => {
                spinController.onChange()
                return result.data
            })
            .catch(error => {
                spinController.onChange()
                console.log(error)

                throw error
            })
    }

}

export const request = async (method: string, path: string, data: any = {}, connect: boolean = false) => {
    spinController.onChange()

    let access = ''
    let refresh = ''
    let expires = 0

    const thisTime = Math.floor(Date.now() / 1000)

    const tokens = await userController.decrypt()

    if(tokens) {
        if(tokens.access_token) access = tokens.access_token
        if(tokens.refresh_token) refresh = tokens.refresh_token
        if(tokens.expires) expires = Number(tokens.expires)
    }

    if(thisTime >= expires) {
        openApi('post', '/access_token')
            .then(accessed => {
                userController.encrypt('auth', {
                    access_token: accessed.access_token,
                    refresh_token: accessed.refresh_token,
                    expires: accessed.expires
                })
                access = accessed.access_token
                refresh = accessed.refresh_token
            })
            .catch(err => {
                layoutController.logout()
                return false
            })
    }

    const uri = !connect ? `${config.apiUrl}/v1` : `${config.apiUrl}/panel`

    const sender = axios.create({
        baseURL: uri,
        headers: {
            "Type-Device": "react",
            'Access-Token': access,
            'Refresh-Token': refresh,
            'Language': storeLocal.get('lang') ? storeLocal.get('lang') : config.defaultLanguage,
        }
    })

    switch (method) {
        case 'get':
            return await sender.get(path)
                .then(result => {
                    spinController.onChange()
                    return result.data
                })
                .catch(async error => {
                    if (error.response.status === 401) {
                        await userController.access()
                    }
                    spinController.onChange()
                    return false
                })
        case 'post':
            return sender.post(path, data)
                .then(result => {
                    spinController.onChange()
                    return result.data
                })
                .catch(async error => {
                    if (error.response.status === 401) {
                        await userController.access()
                    }
                    spinController.onChange()
                    return false
                })
        case 'delete':
            return sender.delete(path, data)
                .then(result => {
                    spinController.onChange()
                    return result.data
                })
                .catch(async error => {
                    if (error.response.status === 401) {
                        await userController.access()
                    }
                    spinController.onChange()
                    return false
                })
    }
}