import { observer } from 'mobx-react-lite'
import { BaseButtonProps } from 'antd/es/button/button'
import i from '../../../translations/i'
import {
    ArrowDownOutlined,
    ArrowUpOutlined,
    DeleteOutlined,
    EditOutlined,
    UnorderedListOutlined
} from '@ant-design/icons'
import { Button, Popconfirm, Switch, Tooltip } from 'antd'
import React from 'react'

export const ActionsConstructor = observer(({actions = []}: any) => {
    if(actions.length === 0) return <></>

    return <>
        <div className="block__actions">
            {actions.map((el: any, index: number) => {
                let label = ''

                let buttonProps: BaseButtonProps = {
                    size: 'small',
                    shape: 'circle',
                    type: 'dashed',
                }

                if(el.type !== 'delete' && el.onClick){
                    // @ts-ignore
                    buttonProps['onClick'] = el.onClick
                }

                if(el.type === 'edit') {
                    label = el?.label ?? i.t('def:actions.edit')
                    buttonProps['icon'] = el?.icon ?? <EditOutlined />
                } else if(el.type === 'moveUp') {
                    label = el?.label ?? i.t('def:Change Position')
                    buttonProps['icon'] = el?.icon ?? <ArrowUpOutlined />
                } else if(el.type === 'moveDown') {
                    label = el?.label ?? i.t('def:Change Position')
                    buttonProps['icon'] = el?.icon ?? <ArrowDownOutlined />
                } else if(el.type === 'delete') {
                    label = el?.label ?? i.t('def:actions.remove')
                    buttonProps['icon'] = el?.icon ?? <DeleteOutlined />
                } else if(el.type === 'editElements') {
                    label = el?.label ?? i.t('def:Edit Elements')
                    buttonProps['icon'] = el?.icon ?? <UnorderedListOutlined />
                } else if(el.type === 'switch') {
                    label = el?.label ?? i.t('def:active')
                }

                return <React.Fragment key={index}>
                    {el.type !== 'switch' ?
                        <Tooltip title={label} key={index}>
                            {el.type === 'delete' ?
                                <Popconfirm
                                    title={i.t('def:confirms.delete')}
                                    onConfirm={() => el.onClick()}
                                    okText={i.t('def:yes')}
                                    cancelText={i.t('def:no')}
                                >
                                    <Button {...buttonProps} danger/>
                                </Popconfirm>
                                : <Button {...buttonProps}/>
                            }
                        </Tooltip>
                        :
                        <Tooltip title={label} key={index}>
                            <Switch
                                size="small"
                                checked={el.value}
                                onChange={() => el.onClick()}
                            />
                        </Tooltip>
                    }
                </React.Fragment>
            })}
        </div>
    </>
})