import { makeAutoObservable } from "mobx";
import { openApi } from "../../../helpers/request";

class roadmapController {

  list: any[] = []
  item: any = {
    id: 0,
  }

  statuses: any[] = []

  managerTypes = []

  pagination = {
    total: 0,
    limit: 1000,
    page: 1,
    offset: 0
  }

  constructor() {
    makeAutoObservable(this)
  }

  getRoadmap() {
    openApi('get', '/roadmap')
      .then((result: any) => {
        console.log(result)
        this.list = result.data
      })
  }

  saveRoadmap() {
    const data = new FormData()

    {this.list.map((el: any, index: number) => {
      if(el.id && el.id > 0) data.append(`items[${index}][id]`, `${el.id}`)
      data.append(`items[${index}][status_id]`, `${el.status_id}`)
      if(el.available_for && el.available_for !== '') data.append(`items[${index}][available_for]`, `${el.available_for}`)
      data.append(`items[${index}][waiting_for_payment]`, `${+ el.waiting_for_payment}`)
      data.append(`items[${index}][editable]`, `${+ el.editable}`)
      data.append(`items[${index}][editable_user]`, `${+ el.editable_user}`)
      data.append(`items[${index}][sorting]`, `${index+1}`)
    })}

    openApi('post', `/roadmap`, data)
      .then((result: any) => {
        this.getRoadmap()
      })
  }

  setPage(page: number) {

  }

  setPosition(index: number, action = 'up') {
    let newIndex = -1
    if(action === 'up') {
      newIndex = index - 1
    } else {
      if(index + 1 <= this.list.length)
        newIndex = index + 1
    }

    if(newIndex > -1) {
      const element = this.list.splice(index, 1)
      console.log(element)
      this.list.splice(newIndex, 0, element[0])
    }
  }

  remove(index: number) {
    if(this.list[index].id && this.list[index].id > 0)
      openApi('delete', `/roadmap/${this.list[index].id}`)
        .then((result: any) => {
          this.getRoadmap()
        })
    else this.list.splice(index, 1)

  }

  add() {
    this.list.push({
      sorting: 500,
      status_id: null,
      waiting_for_payment: false,
      available_for: null,
      editable: false,
      editable_user: false
    })
  }

}

export default new roadmapController()