import { makeAutoObservable, runInAction } from "mobx";

class loaderController {

    load = false

    constructor() {
        makeAutoObservable(this)
    }

    onChange(value) {
        runInAction(() => {
            this.load = value
        })
    }
}

export default new loaderController()