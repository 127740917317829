import { openApi } from '../../../../helpers/request'
import { IPlace, IPlacePhone } from '../types'
import { FormEvent } from 'react'
import { makeAutoObservable, toJS } from 'mobx'
import i from '../../../../translations/i'
import dayjs from 'dayjs'
import __multiple from '../../../../helpers/multiple_actions/__multiple'
import { notification } from 'antd'

class __points {

    list: IPlace[] = []
    place: IPlace = {
        id: 0,
        enabled: true,
        id_crm: null,
        name: '',
        latitude: 0,
        longitude: 0,
        address: '',
        phones: [],
        position: 0,
        schedule: true,

        schedule_monday_status: 0,
        schedule_monday_from: '',
        schedule_monday_to: '',

        schedule_tuesday_status: 0,
        schedule_tuesday_from: '',
        schedule_tuesday_to: '',

        schedule_wednesday_status: 0,
        schedule_wednesday_from: '',
        schedule_wednesday_to: '',

        schedule_thursday_status: 0,
        schedule_thursday_from: '',
        schedule_thursday_to: '',

        schedule_friday_status: 0,
        schedule_friday_from: '',
        schedule_friday_to: '',

        schedule_saturday_status: 0,
        schedule_saturday_from: '',
        schedule_saturday_to: '',

        schedule_sunday_status: 0,
        schedule_sunday_from: '',
        schedule_sunday_to: '',

        groups: [],
        images: [],
        characteristics: []
    }

    images: any = []
    deletedImages: any = []

    days: string[] = [
        'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'
    ]

    total: number = 0
    page: number = 1
    limit: number = 10

    selectedGroup: number = 0
    selectedGroups: any = []
    selectedProperties: any = []
    groupsList: any = []
    propertiesList: any = []
    item: any = {
        id: 0,
        name: '',
        values: []
    }

    selectedTab: string = 'main'

    constructor() {
        makeAutoObservable(this)
    }

    setTab(tab: string) {
        this.selectedTab = tab
    }

    addPhone() {
        this.place.phones.push({ id: 0, sorting: 0, value: '', description: '' })
    }

    deletePhone(index: number) {
        this.place.phones.splice(index, 1)
    }

    setPhone(index: number, value: string, type: string = 'value') {
        console.log(index, value)
        // @ts-ignore
        this.place.phones[index][type] = value
    }

    setScheduleDay(day: string, value: string[]) {
        // @ts-ignore
        this.place[`schedule_${day}_from`] = value[0]
        // @ts-ignore
        this.place[`schedule_${day}_to`] = value[1]
    }

    getStatusDay(day: string) {
        // @ts-ignore
        return this.place[`schedule_${day}_status`]
    }

    getTimeDay(day: string) {
        // @ts-ignore
        if(!this.place[`schedule_${day}_from`] || !this.place[`schedule_${day}_to`]) return null

        // @ts-ignore
        if(this.place[`schedule_${day}_from`].indexOf(':') >= 0 && this.place[`schedule_${day}_to`].indexOf(':') >= 0) {
            // @ts-ignore
            return [dayjs(this.place[`schedule_${day}_from`], "HH:mm"), dayjs(this.place[`schedule_${day}_to`], "HH:mm")]
        } else return null

    }

    setStatusDay(day: string, value: number) {
        console.log(day, value)
        // @ts-ignore
        this.place[`schedule_${day}_status`] = value
    }

    setPage(page: number) {
        this.page = page
        this.getList()
    }

    getList() {
        let uri = `/places?limit=${this.limit}&page=${this.page}`

        if(this.selectedGroup > 0){
            uri += `&group_id=${this.selectedGroup}`
        }

        openApi('get', uri)
            .then((result) => {
                this.list = result.data
                this.total = result.total
            })
    }

    getOne(id: string | number) {
        if(id > 0)
            openApi('get', `/places/${id}`)
                .then((result) => {
                    this.place = result.data
                    this.place.schedule = Boolean(result.data.schedule)

                    let selectedGroups: any = []
                    result.data.groups.map((group: any) => {
                        selectedGroups.push(group.group_id)
                    })
                    this.selectedGroups = selectedGroups

                    let images: any = []
                    result.data.images.map((image: any) => {
                        images.push({
                            id: image.id,
                            uid: image.id,
                            status: 'done',
                            name: image.entity_type,
                            url: `${image.base}${image.uri}`,
                            uri: image.uri
                        })
                    })

                    this.images = images

                    let selectedProperties: any = []
                    result.data.characteristics.map((property: any) => {
                        selectedProperties.push(property.value_id)
                    })

                    this.selectedProperties = selectedProperties
                })
    }

    delete() {
        const data = new FormData()
        __multiple.selected.map(el => data.append('elements[]', `${el}`))

        openApi('post', `/places/delete`, data)
            .then((result: boolean) => {
                __multiple.clear()
                this.getList()
            })
    }

    save(e: FormEvent) {
        e.preventDefault()

        let uri = `/places`
        if(this.place && this.place.id && this.place.id > 0) uri += `/${this.place.id}`

        const data = new FormData()

        if(this.place.phones.length)
            this.place.phones.map((el, i) => {
                data.append(`phones[${i}][id]`, `${el.id}`)
                data.append(`phones[${i}][value]`, el.value)
                data.append(`phones[${i}][description]`, el.description)
            })


        if(this.place.id_crm) data.append(`id_crm`, this.place.id_crm)
        data.append(`enabled`, `${+ this.place.enabled}`)
        data.append(`name`, this.place.name)

        data.append(`latitude`, `${this.place.latitude}`)
        data.append(`longitude`, `${this.place.longitude}`)
        data.append(`address`, this.place.address)
        data.append(`schedule`, `${+ this.place.schedule}`)

        data.append(`schedule_monday_status`, `${this.place.schedule_monday_status}`)
        data.append(`schedule_monday_from`, this.place.schedule_monday_from)
        data.append(`schedule_monday_to`, this.place.schedule_monday_to)

        data.append(`schedule_tuesday_status`, `${this.place.schedule_tuesday_status}`)
        data.append(`schedule_tuesday_from`, this.place.schedule_tuesday_from)
        data.append(`schedule_tuesday_to`, this.place.schedule_tuesday_to)

        data.append(`schedule_wednesday_status`, `${this.place.schedule_wednesday_status}`)
        data.append(`schedule_wednesday_from`, this.place.schedule_wednesday_from)
        data.append(`schedule_wednesday_to`, this.place.schedule_wednesday_to)

        data.append(`schedule_thursday_status`, `${this.place.schedule_thursday_status}`)
        data.append(`schedule_thursday_from`, this.place.schedule_thursday_from)
        data.append(`schedule_thursday_to`, this.place.schedule_thursday_to)

        data.append(`schedule_friday_status`, `${this.place.schedule_friday_status}`)
        data.append(`schedule_friday_from`, this.place.schedule_friday_from)
        data.append(`schedule_friday_to`, this.place.schedule_friday_to)

        data.append(`schedule_saturday_status`, `${this.place.schedule_saturday_status}`)
        data.append(`schedule_saturday_from`, this.place.schedule_saturday_from)
        data.append(`schedule_saturday_to`, this.place.schedule_saturday_to)

        data.append(`schedule_sunday_status`, `${this.place.schedule_sunday_status}`)
        data.append(`schedule_sunday_from`, this.place.schedule_sunday_from)
        data.append(`schedule_sunday_to`, this.place.schedule_sunday_to)

        this.selectedGroups.map((groupID: number, index: number) => {
            data.append(`groups[${index}]`, `${groupID}`)
        })

        this.selectedProperties.map((propertyID: number, index: number) => {
            data.append(`characteristics[${index}]`, `${propertyID}`)
        })

        this.images.map((image: any, index: number) => {
            if(image.uri){
                data.append(`images[${index}]`, image.uri)
            } else {
                data.append(`images[${index}]`, image)
            }
        })

        this.deletedImages.map((imageID: number) => {
            openApi('delete', `/file/${imageID}`)
                .then((result: any) => {

                })
        })

        openApi('post', uri, data)
            .then((result: any) => {
                if(this.place.id > 0) {
                    this.getOne(this.place.id)
                    notification.success({
                        message: i.t('def:success.complete'),
                        description: i.t('def:success.edit')
                    });
                }
                else window.location.href = `/settings/places`
            })
    }

    actions() {
        return [
            { label: i.t('def:actions.remove'), value: 'delete', action: () => this.delete() },
        ]
    }

    setPageNew(page: number) {
        this.page = page
    }

    getGroupsList(limit: number = 0) {
        openApi('get', `/places/groups?limit=${limit > 0 ? limit : this.limit}&page=${this.page}`)
            .then((result) => {
                this.groupsList = result.data
                this.total = result.total
            })
    }

    getOneGroup(id: number) {
        openApi('get', `/places/groups/${id}`)
            .then((result) => {
                this.item = result.data

                let images: any = []
                result.data.images.map((image: any) => {
                    images.push({
                        id: image.id,
                        uid: image.id,
                        status: 'done',
                        name: image.entity_type,
                        url: `${image.base}${image.uri}`,
                        uri: image.uri
                    })
                })

                this.images = images
            })
    }

    deleteOneGroup(id: number) {
        openApi('delete', `/places/groups/${id}`)
            .then((result) => {
                this.getGroupsList()
            })
    }

    saveGroup(e: FormEvent) {
        e.preventDefault()

        let uri = '/places/groups'
        if(this.item.id > 0) {
            uri += `/${this.item.id}`
        }

        if(this.item.name === '') {
            return notification.error({
                message: i.t('def:errors.error'),
                description: i.t('def:errors.data')
            })
        }

        const data = new FormData()
        data.append('name', this.item.name)

        this.images.map((image: any, index: number) => {
            if(image.uri){
                data.append(`images[${index}]`, image.uri)
            } else {
                data.append(`images[${index}]`, image)
            }
        })

        this.deletedImages.map((imageID: number) => {
            openApi('delete', `/file/${imageID}`)
                .then((result: any) => {

                })
        })

        openApi('post', uri, data)
            .then(result => {
                if(this.item.id > 0) {
                    notification.success({
                        message: i.t('def:success.success'),
                        description: i.t('def:success.edit')
                    })
                } else {
                    window.location.href = `/settings/places_groups`
                }
            })
            .catch(error => {
                notification.error({
                    message: i.t('def:errors.error'),
                    description: error.response.data.data.message ?? 'error'
                })
            })
    }

    getPropertiesList(limit: number = 0) {
        openApi('get', `/places/characteristics?limit=${limit > 0 ? limit : this.limit}&page=${this.page}`)
            .then((result) => {
                this.propertiesList = result.data
                this.total = result.total
            })
    }

    getOneProperty(id: number) {
        openApi('get', `/places/characteristics/${id}`)
            .then((result) => {
                this.item = result.data
            })
    }

    deleteOneProperty(id: number) {
        openApi('delete', `/places/characteristics/${id}`)
            .then((result) => {
                this.getGroupsList()
            })
    }

    addPropertyValue() {
        this.item.values.push({
            id: 0,
            value: ''
        })
    }

    removePropertyValue(index: number) {
        let array = toJS(this.item.values)
        array.splice(index, 1)
        this.item.values = array
    }

    saveProperty(e: FormEvent) {
        e.preventDefault()

        let uri = '/places/characteristics'
        if(this.item.id > 0) {
            uri += `/${this.item.id}`
        }

        if(this.item.name === '') {
            return notification.error({
                message: i.t('def:errors.error'),
                description: i.t('def:errors.data')
            })
        }

        const data = new FormData()
        data.append('name', this.item.name)

        if(this.item.type){
            data.append('type', this.item.type)
        }

        this.item.values.map((value: any, index: number) => {
            if(value.id > 0) data.append(`values[${index}][id]`, value.id)
            data.append(`values[${index}][value]`, value.value)
        })

        openApi('post', uri, data)
            .then(result => {
                if(this.item.id > 0) {
                    notification.success({
                        message: i.t('def:success.success'),
                        description: i.t('def:success.edit')
                    })
                } else {
                    window.location.href = `/settings/places_properties`
                }
            })
            .catch(error => {
                notification.error({
                    message: i.t('def:errors.error'),
                    description: error.response.data.data.message ?? 'error'
                })
            })
    }

    selectGroup(id: number) {
        let index = this.selectedGroups.findIndex((el: any) => el === id)

        if(index > -1) {
            this.selectedGroups.splice(index, 1)
        } else {
            this.selectedGroups.push(id)
        }
    }

    selectProperty(id: number) {
        let index = this.selectedProperties.findIndex((el: any) => el === id)

        if(index > -1) {
            this.selectedProperties.splice(index, 1)
        } else {
            this.selectedProperties.push(id)
        }
    }

    toggleImage(image: any, multiple: boolean = true) {
        let index = this.images.findIndex((el: any) => el.uid === image.uid)
        let array = toJS(this.images)
        if(index > -1) {
            if(image?.id > 0) {
                this.deletedImages.push(image.id)
            }
            array.splice(index, 1)
        } else {
            if(multiple) {
                array.push(image)
            } else {
                array = [image]
            }
        }
        this.images = array
    }
}

export default new __points()