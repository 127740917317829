import { observer } from 'mobx-react-lite'
import { Row, Col, Button, Divider, Select, Space, Tooltip, Table, Tag } from 'antd'
import i from '../../../../translations/i'
import { __product } from '../__product'
import React, { useEffect } from 'react'
import pc from '../../../../modules/shop/properties/controllers/properties.controller'
import { DeleteOutlined, SettingOutlined } from '@ant-design/icons'
import { runInAction } from 'mobx'

export const ProductProps = observer(() => {

    useEffect(() => {
        pc.getPropertiesByCategories(__product.item.categories)
    }, [])

    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return(
        <>
            <Row gutter={[20, 20]} align="bottom">
                {__product.item.id && __product.item.id > 0 && __product.item.is_variant ? <></>
                    : <>
                        <Col span={24}>
                            <Button onClick={() => __product.createVar()} type="dashed">{i.t('product:createSub')}</Button>
                        </Col>
                        {__product.item.view_variations && __product.item.variations && Array.isArray(__product.item.variations) ?
                            <Col span={24}>
                                <ProductPropsVariations items={__product.item.variations} />
                            </Col>
                            : <></>
                        }
                        <Divider />
                    </>
                }

                {!__product.item.view_variations &&
                    <>
                        <Col span={24}>
                            <p>{i.t('product:selectFilters')}</p>
                        </Col>
                        {pc.product.length > 0 && pc.product.map((el: any) => (
                            <>
                                <Col span={24}>
                                    <Row>
                                        <Col span={24}>
                                            <p className="product-filters-title">{el.filter_category.name}</p>
                                        </Col>
                                        <Col span={24}>
                                            <Select
                                                mode="multiple"
                                                style={{ width: '100%' }}
                                                showSearch
                                                options={el.filter_category.values.map((option: any) => ({
                                                    label: option.value,
                                                    value: option.id,
                                                }))}
                                                filterOption={filterOption}
                                                onChange={(selected: any) => {
                                                    const newValues = [...selected, ...__product.item.filter_values.filter((v: any) => !el.filter_category.values.map((c: any) => c.id).includes(v)).filter((v: any) => !selected.includes(v))];
                                                    runInAction(() => __product.item.filter_values = newValues);
                                                }}
                                                value={__product.item.filter_values && __product.item.filter_values.filter((v: any) => el.filter_category.values.map((b: any) => b.id).includes(v))}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Divider />
                            </>
                        ))}
                    </>
                }
            </Row>
        </>
    )
})

const ProductPropsVariations = observer(({ items }: {items: any[]}) => {

    const columnsProducts = [
        {title: 'ID', dataIndex: 'id', key: 'id'},
        {title: i.t('categories:idCrm'), dataIndex: 'vendor_code', key: 'vendor_code'},
        {title: i.t('categories:name'), dataIndex: 'name', key: 'name',
            render: (text: any, record: any) => (
                <>
                    <p>{ text }</p>
                    {record.props.map((el: any) => <Tag>{el.category.name}: {el.value}</Tag>)}
                </>
            )
        },
        { title: i.t('categories:price'), dataIndex: 'price', key: 'price' },
        { title: i.t('categories:count'), dataIndex: 'count', key: 'count' },
        { title: i.t('categories:actions'), key: 'action',
            render: (text: any, record: any) => (
                <>
                    <Space>
                        {/*<Tooltip title={i.t('categories:top')}>*/}
                        {/*    <Button type="dashed" shape="circle" icon={<UpOutlined />} onClick={() => shopProductController.setPosition(record.id)} />*/}
                        {/*</Tooltip>*/}
                        {/*<Tooltip title={i.t('categories:bottom')}>*/}
                        {/*    <Button type="dashed" shape="circle" icon={<DownOutlined />} onClick={() => shopProductController.setPosition(record.id, 'down')} />*/}
                        {/*</Tooltip>*/}
                        <Tooltip title={i.t('categories:edit')}>
                            <a href={`/shop/catalog/product/${record.id}`}>
                                <Button type="dashed" shape="circle" icon={<SettingOutlined />} />
                            </a>
                        </Tooltip>
                        <Tooltip title={i.t('categories:delete')}>
                            <Button
                                type="dashed"
                                shape="circle"
                                icon={<DeleteOutlined />}
                                danger
                                onClick={() => __product.deleteOne(record.id)}
                            />
                        </Tooltip>
                    </Space>
                </>
            ),
        },
    ]

    return(
        <>
            <Row>
                <Col span={24}>
                    <Table columns={columnsProducts} dataSource={ items } pagination={false} />
                </Col>
            </Row>
        </>
    )
})