import { observer } from 'mobx-react-lite'
import { LoadScript, GoogleMap, Polygon as Poly } from '@react-google-maps/api'
import { useCallback, useEffect, useRef, useState } from 'react'

export const GoogleMapZone = observer(({ controller, coordinates, centerPosition }: any) => {

    const [path, setPath] = useState([])
    const [center, setCenter] = useState({ lat: 0, lng: 0 })

    const mapRef = useRef(null)
    const polygonRef = useRef(null)
    const listenersRef = useRef([])

    useEffect(() => {
        setPath(coordinates)
    }, [coordinates])

    useEffect(() => {
        setCenter({ lat: centerPosition[0], lng: centerPosition[1] })
    }, [centerPosition])


    const onCenter = () => {
        if (!mapRef.current) return;
        // @ts-ignore
        const newPos = mapRef.current.getCenter().toJSON()
        controller.setCenter([
            newPos.lat,
            newPos.lng
        ])
        setCenter(newPos)
    }

    const onLoadMap = (map: any) => {
        mapRef.current = map
    }

    const onEdit = useCallback(() => {
        if (polygonRef.current) {
            const nextPath = polygonRef.current
                // @ts-ignore
                .getPath()
                .getArray()
                .map((latLng: any) => {
                    return { lat: latLng.lat(), lng: latLng.lng() };
                });
            setPath(nextPath)
            // @ts-ignore
            let arr = []
            nextPath.map((el: any) => arr.push([el.lat, el.lng]))
            // @ts-ignore
            controller.onChangeGoogle(arr)
        }
    }, [setPath])

    const select = (id: number) => {
        setPath(controller.list.find((el: any) => el.id === id).coordinatesGoogle)
        controller.select(id)
    }

    const onLoad = useCallback(
        (polygon: any) => {
            polygonRef.current = polygon;
            const path = polygon.getPath();
            // listenersRef.current.push(
            //     // @ts-ignore
            //     // path.addListener("set_at", onEdit),
            //     // path.addListener("insert_at", onEdit),
            //     // path.addListener("remove_at", onEdit)
            // );
        },
        [onEdit]
    )

    const onUnmount = useCallback(() => {
        // @ts-ignore
        // listenersRef.current.forEach(lis => lis.remove());
        // polygonRef.current = null;
    }, [])

    return(
        <>
            <LoadScript
                id="script-loader"
                googleMapsApiKey="AIzaSyAsrW_1meOXr6ozX-__m-Iz1XQ4kUfH0mg"
                language="en"
                region="ru"
            >
                <GoogleMap
                    mapContainerClassName="App-map"
                    center={center}
                    zoom={12}
                    version="weekly"
                    onLoad={onLoadMap}
                    onDragEnd={onCenter}
                    // on
                >
                    {controller.active.id > -1 &&
                        <Poly
                            editable
                            path={path}
                            onMouseUp={onEdit}
                            onLoad={onLoad}
                            onUnmount={onUnmount}
                            draggable
                        />
                    }
                    {controller.list.map((item: any) =>
                        <>
                            {controller.active.id !== item.id &&
                                <Poly
                                    path={item.coordinatesGoogle}
                                    onClick={() => select(item.id)}
                                />
                            }
                        </>
                    )}
                </GoogleMap>
            </LoadScript>
        </>
    )
})
