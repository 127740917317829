import { makeAutoObservable } from 'mobx'
import { parseProject } from '../../../helpers/project.controller'
import { FormEvent } from 'react'
import { openApi } from '../../../helpers/request'
import userController from '../../user/controllers/user.controller'
import webhookController from './webhook.controller'
import i from '../../../translations/i'
import { notification } from 'antd'

class settingsController {

    project = {
        ordercomment: '',
        deliveryattributes: 0,
        ordering: 0,
        ordering_description: '',
        cutlery: 0
    }

    webhookDescriptionNewOrderParams = [
        { name: 'id',                      description: i.t('settings:webhook.order.id'), type: 'int', vars: [] },
        { new: true, name: 'typepay',      description: i.t('settings:webhook.order.typepay'), type: 'string', vars: [ 'Apple Pay', 'Google Pay', 'Samsung Pay', 'Выставить счет', 'Картой онлайн', 'Картой при получении', 'Наличными', 'Перевод на карту' ] },
        { name: 'paystatus',               description: i.t('settings:webhook.order.paystatus'), type: 'string', vars: [ 'Оплачен', 'Не оплачен' ] },
        { new: true, name: 'comment',      description: i.t('settings:webhook.order.comment'), type: 'string', vars: [] },
        { new: true, name: 'userid',       description: i.t('settings:webhook.order.userid'), type: 'int', vars: [] },
        { new: true, name: 'firstname',    description: i.t('settings:webhook.order.firstname'), type: 'string', vars: [] },
        { new: true, name: 'lastname',     description: i.t('settings:webhook.order.lastname'), type: 'string', vars: [] },
        { new: true, name: 'phone',        description: i.t('settings:webhook.order.phone'), type: 'string', vars: [] },
        { new: true, name: 'email',        description: i.t('settings:webhook.order.email'), type: 'string', vars: [] },
        { new: true, name: 'created',      description: i.t('settings:webhook.order.created'), type: 'string', format: 'Y-m-d H:i', vars: [] },
        { new: true, name: 'summ',         description: i.t('settings:webhook.order.summ'), type: 'float', vars: [] },
        { new: true, name: 'paydelivery',  description: i.t('settings:webhook.order.paydelivery'), type: 'float', vars: [] },
        { new: true, name: 'promocode',    description: i.t('settings:webhook.order.promocode'), type: 'string', vars: [] },
        { new: true, name: 'discount',     description: i.t('settings:webhook.order.discount'), type: 'float', vars: [] },
        { new: true, name: 'typedelivery', description: i.t('settings:webhook.order.typedelivery'), type: 'string', vars: [] },
        { new: true, name: 'delivery',     description: i.t('settings:webhook.order.delivery'), type: 'string', vars: [ 'default', 'sdek', 'boxbery', 'pochta' ] },
        { new: true, name: 'datedelivery', description: i.t('settings:webhook.order.datedelivery'), type: 'string', format: 'Y-m-d H:i', vars: [] },
        { new: true, name: 'timedelivery', description: i.t('settings:webhook.order.timedelivery'), type: 'string', format: 'H:i-H:i', vars: [] },
        { new: true, name: 'address',      description: i.t('settings:webhook.order.address'), type: 'string', vars: [] },
        { new: true, name: 'latitude',     description: i.t('settings:webhook.order.latitude'), type: 'float', vars: [] },
        { new: true, name: 'longitude',    description: i.t('settings:webhook.order.longitude'), type: 'float', vars: [] },
        { new: true, name: 'fulladdress',  description: i.t('settings:webhook.order.fulladdress'), type: 'string', vars: [] },
        { new: true, name: 'zip',          description: i.t('settings:webhook.order.zip'), type: 'string', vars: [] },
        { new: true, name: 'entrance',     description: i.t('settings:webhook.order.entrance'), type: 'string', vars: [] },
        { new: true, name: 'floor',        description: i.t('settings:webhook.order.floor'), type: 'string', vars: [] },
        { new: true, name: 'apartment',    description: i.t('settings:webhook.order.apartment'), type: 'string', vars: [] },
        { new: true, name: 'changefrom',   description: i.t('settings:webhook.order.changefrom'), type: 'float', vars: [] },
        { new: true, name: 'systeminfo',   description: i.t('settings:webhook.order.systeminfo'), type: 'string', vars: [] },
        { new: true, name: 'recipient',   description: i.t('settings:webhook.order.recipient'), type: 'bool', vars: [] },
        { new: true, name: 'recipient_name',   description: i.t('settings:webhook.order.recipient_name'), type: 'string', vars: [] },
        { new: true, name: 'recipient_phone',   description: i.t('settings:webhook.order.recipient_phone'), type: 'string', vars: [] },
        { new: true, name: 'products',   description: i.t('settings:webhook.order.products'), type: 'array', vars: [], parent: [
                { name: 'id',   description: i.t('settings:webhook.order.products_array.id'), type: 'int' },
                { name: 'name',   description: i.t('settings:webhook.order.products_array.name'), type: 'string' },
                { name: 'filtername',   description: i.t('settings:webhook.order.products_array.filtername'), type: 'string' },
                { name: 'price',   description: i.t('settings:webhook.order.products_array.price'), type: 'float' },
                { name: 'summ',   description: i.t('settings:webhook.order.products_array.summ'), type: 'float' },
                { name: 'vendorcode',   description: i.t('settings:webhook.order.products_array.vendorcode'), type: 'string' },
                { name: 'countorder',   description: i.t('settings:webhook.order.products_array.countorder'), type: 'string' },
        ] },
    ]

    constructor() {
        makeAutoObservable(this)
    }

    async getProject() {
        const project = await parseProject()
        if(project) {
            this.project.ordercomment = project.ordercomment
            this.project.deliveryattributes = project.deliveryattributes
            this.project.ordering = project.ordering
            this.project.ordering_description = project.ordering_description
            this.project.cutlery = project.cutlery
        }
    }

    onChangeProject(name: string, value: any) {
        // @ts-ignore
        this.project[name] = value
    }

    async save(e: FormEvent) {
        e.preventDefault()

        let data = new FormData()
        for await (const [key, value] of Object.entries(this.project)) {
            console.log(key, `${value}`)
            await data.append(key, `${value}`)
        }

        // data.append('hash', hashController.setHash(data))

        openApi('post', '/setting/edit', data)
            .then(result => {
                userController.encrypt('project', result.data)
                notification.success({
                    message: i.t('def:success.complete')
                })
            })
            .catch(result => {
                console.log(result.response.data)
            })

        webhookController.save()
    }
}

export default new settingsController()