import { makeAutoObservable, runInAction } from 'mobx'
import { message } from 'antd'
import i from '../../translations/i'
import { parseProject } from '../../helpers/project.controller'
import { openApi } from '../../helpers/request'

class dashboardController {

    statisticOrder: any = []
    statisticOrderDates?: string[] = []

    project = {
        urlios: '',
        urlandroid: '',
        name: '',
        bandl: '',
        datepayend: 0,
    }

    stat = {
        products: 0,
        categories: 0,
        orders: 0,
        users: 0
    }

    constructor() {
        makeAutoObservable(this)
    }

    setStatisticOrderDates(dates: string[]) {
        this.statisticOrderDates = dates
        this.ordersStatistic()
    }

    getStat() {
        openApi('get', '/stat')
            .then(result => {
                runInAction(() => this.stat = result.data)
            })
    }

    copyLink(link: string) {
        navigator.clipboard.writeText(link)
            .then(result => message.success(i.t('dashboard:links.copy')))
            .catch(err => message.success(i.t('dashboard:links.copyError')))
    }

    async getProject() {
        const project = await parseProject()
        if(project) {
            console.log(project)
            runInAction(() => this.project = project)
        }
    }

    ordersStatistic() {
        let uri = '/statistic'
        if(this.statisticOrderDates && this.statisticOrderDates.length > 0)
            uri = `${uri}?date_from=${this.statisticOrderDates[0]}&date_to=${this.statisticOrderDates[1]}`

        openApi('get', uri)
            .then(result => {
                console.log(result)
                let array: any = []
                Object.keys(result).map((el: string) => array.push({
                    name: el,
                    full_count: result[el].full_count,
                    sum: result[el].sum,
                    count_not_payed: result[el].count_not_payed,
                    count_payed: result[el].count_payed,
                }))

                this.statisticOrder = array
            })
            .catch(error => console.log(error.response.data.message))
    }

    getBestSellers() {}

}

export default new dashboardController()