import { makeAutoObservable } from 'mobx'
import { openApi } from '../../../helpers/request'

class __carts {

    list: any[] = []
    cart: any = {}
    top: any[] = []

    pagination = {
        total: 0,
        limit: 50,
        page: 1
    }

    constructor() {
        makeAutoObservable(this)
    }

    getList() {
        openApi('get', `/shop/cart_list?limit=${this.pagination.limit}&page=${this.pagination.page}`)
            .then(result => {
                console.log(result)
                this.list = result.list
                this.top = result.top
                this.pagination.total = result.total
            })
    }

    getCart(id: any) {
        openApi('get', `/shop/cart_list/${id}`)
            .then(result => {
                console.log(result)
                this.cart = result
            })
    }

}

export default new __carts()