import { makeAutoObservable } from 'mobx'
import { openApi } from '../../../../helpers/request'
import { message } from 'antd'
import i from '../../../../translations/i'

interface WebHookInterface {
    id: number
    uri: string
    types: string[]
}

interface WebHookTypesInterface {
    key: string
    value: string
}

export class webhookController {

    types: WebHookTypesInterface[] = []
    list: WebHookInterface[] = []
    element: WebHookInterface = {
        id: 0,
        uri: '',
        types: []
    }

    visible = false

    pagination = {
        total: 0,
        limit: 10,
        page: 1,
        offset: 0
    }

    constructor() {
        makeAutoObservable(this)
    }

    setPage(page: number) {
        this.pagination.page = page
        if (page > 0) {
            this.pagination.offset =
                this.pagination.limit * page - this.pagination.limit
        } else {
            this.pagination.offset = 0
        }
        this.get()
    }

    getTypes() {
        openApi('get', '/setting/webhook/types')
            .then(result => {
                this.types = result.data
            })
            .catch(error => console.log(error))
    }

    get() {
        openApi('get', '/setting/webhook')
            .then(result => {
                console.log(result)
                this.list = result.data
            })
            .catch(error => console.log(error))
    }

    save() {

        if(this.element.uri.length <= 0) {
            message.error(i.t('def:errors.webhookURLEmpty'))
            return false
        }

        if(this.element.types.length <= 0) {
            message.error(i.t('def:errors.webhookTypes'))
            return false
        }

        const data = new FormData()
        data.append('uri', this.element.uri)
        data.append('types', JSON.stringify(this.element.types))

        const facts = {
            uri: this.element.uri,
            types: this.element.types
        }

        openApi(this.element.id > 0 ? 'put' : 'post', this.element.id > 0 ? `/setting/webhook/${this.element.id}` : `/setting/webhook`, this.element.id > 0 ? facts : data)
            .then(result => {
                console.log(result)
                this.close()
                this.get()
            })
            .catch(error => {
                if(error.response.status === 404) {
                    message.error(i.t('def:errors.webhookURL'))
                }
            })
    }

    delete(entityId: number) {
        openApi('delete', `/setting/webhook/${entityId}`)
            .then(result => {
                console.log(result)
                this.get()
            })
            .catch(error => console.log(error))
    }

    select(entityId: number) {
        // @ts-ignore
        this.element = this.list.find(el => el.id === entityId)
        this.visible = true
    }

    new() {
        this.element = {
            id: 0,
            uri: '',
            types: []
        }
        this.visible = true
    }

    close() {
        this.visible = false
        this.element = {
            id: 0,
            uri: '',
            types: []
        }
        this.get()
    }

    onChangeType(type: string) {
        if(this.element.types.findIndex(el => el === type) > -1) {
            this.element.types = this.element.types.filter(el => el !== type)
        } else {
            this.element.types.push(type)
        }
    }

    onChangeUri(uri: string) {
        this.element.uri = uri
    }

}