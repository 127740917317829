import { observer } from 'mobx-react-lite'
import i from '../../../../translations/i'
import { Titles } from '../../../../layouts/Titles'
import { Button, Input, InputNumber, Modal, Space, Table, Popconfirm, Switch, Badge } from 'antd'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import React, { useEffect } from 'react'
import { FullscreenControl, Map, Polygon, YMaps, ZoomControl } from '@pbe/react-yandex-maps'
import { zonesController } from './zones.controller'
import { returnParseProjectElement } from '../../../../helpers/project.controller'
import { GoogleMapZone } from '../../../../components/map/google/GoogleMapZone'

const controller = new zonesController()

export const SettingDeliveryZones = observer(() => {

    const draw = (ref: any) => {
        ref.editor.startEditing()
    }

    const columns = [
        {
            title: '',
            dataIndex: 'enabled',
            key: 'enabled',
            render: (active: boolean) => (
                <Badge
                    status={active ? 'success' : 'error'}
                />
            )
        },
        { title: i.t('delivery:zones.name'), dataIndex: 'name', key: 'name' },
        { title: i.t('delivery:zones.price'), dataIndex: 'price', key: 'price', },
        { title: i.t('delivery:zones.minPrice'), dataIndex: 'minprice', key: 'minprice', },
        { title: i.t('delivery:zones.maxPrice'), dataIndex: 'maxprice', key: 'maxprice', },
        {
            title: i.t('delivery:zones.actions'),
            key: 'action',
            render: (text: any, record: any) => (
                <Space size="middle">
                    <Button
                        type="primary"
                        shape="circle"
                        icon={<EditOutlined />}
                        onClick={() => controller.edit(record.id)}
                    />
                    <Popconfirm
                        title={i.t('def:confirms.delete')}
                        onConfirm={() => controller.deleteByID(record.id)}
                        // onCancel={cancel}
                        okText={i.t('def:yes')}
                        cancelText={i.t('def:no')}
                    >
                        <Button
                            type="dashed"
                            shape="circle"
                            icon={<DeleteOutlined />}
                            danger
                        />
                    </Popconfirm>
                </Space>
            ),
        },
    ]

    const optionsActive = {
        // fillColor: '#00FF00',
        // strokeColor: '#0000FF',
        // opacity: 0.5,
        // strokeWidth: 5,
        // strokeStyle: 'shortdash',
        draggable: true
    }

    const optionsAll = {
        fillColor: '#000000',
        strokeColor: '#000000',
        opacity: 0.5,
        strokeWidth: 5,
        strokeStyle: 'shortdash',
        draggable: true
    }

    useEffect(() => {
        controller.getAllZone()
    }, [])

    return(
        <>
            <Titles
                title={i.t('settings:delivery.zones.title')}
            />

            <div className="ymap_block">
                {returnParseProjectElement('typemap') === 'yandex'
                    ? <>
                        <YMaps
                            version={'2.1'}
                            query={{
                                apikey: "50d6500a-1178-4721-9006-de55e27a6dc2",
                            }}
                        >
                            <div className="zone">
                                <div className="buttons">
                                    <Space>
                                        <Button
                                            type="primary"
                                            size="small"
                                            onClick={() => controller.openModal()}
                                            disabled={controller.active.id > -1}
                                        >
                                            {i.t('delivery:zones.create')}
                                        </Button>
                                        <Button
                                            type="primary"
                                            size="small"
                                            danger
                                            onClick={() => controller.delete()}
                                            disabled={controller.active.id < 0}
                                        >
                                            {i.t('delivery:zones.delete')}
                                        </Button>
                                        <Button
                                            type="primary"
                                            size="small"
                                            onClick={() => controller.saveActive()}
                                            disabled={controller.active.id < 0}
                                        >
                                            {i.t('delivery:zones.save')}
                                        </Button>
                                    </Space>
                                </div>
                                <Map
                                    state={{ center: controller.center, zoom: controller.zoom }}
                                    width={100 + "%"}
                                    height={100 + "%"}
                                    modules={["geoObject.addon.editor", "geolocation", "geocode"]}
                                    onLoad={ymaps => controller.locationUser(ymaps)}
                                    onBoundsChange={(event: any) => controller.center = event.get('target').getCenter()}
                                    //onBoundsChange={(e: any) => controller.onChangeMap(e)}
                                >
                                    <FullscreenControl />
                                    <ZoomControl />
                                    {/*options={{ float: 'right' }}*/}
                                    {/*options={optionsActive}*/}
                                    {controller.active.id > -1 &&
                                        <Polygon
                                            geometry={[controller.active.coordinates]}
                                            onGeometryChange={(e: any) => controller.onChange(e)}
                                            instanceRef={(ref: any) => ref && draw(ref)}
                                            options={{ draggable: true }}
                                        ></Polygon>
                                    }
                                    {controller.list.map(item =>
                                        <>
                                            {controller.active.id !== item.id &&
                                                <Polygon
                                                    geometry={[item.coordinates]}
                                                    onClick={() => controller.select(item.id)}
                                                />
                                            }
                                        </>
                                    )}
                                </Map>
                            </div>
                        </YMaps>
                        </>
                    : <>
                        <div
                            style={{ width: '100%', height: '400px', position: 'relative' }}
                        >
                            <div className="buttons">
                                <Space>
                                    <Button
                                        type="primary"
                                        size="small"
                                        onClick={() => controller.openModal()}
                                        disabled={controller.active.id > -1}
                                    >
                                        {i.t('delivery:zones.create')}
                                    </Button>
                                    <Button
                                        type="primary"
                                        size="small"
                                        danger
                                        onClick={() => controller.delete()}
                                        disabled={controller.active.id < 0}
                                    >
                                        {i.t('delivery:zones.delete')}
                                    </Button>
                                    <Button
                                        type="primary"
                                        size="small"
                                        onClick={() => controller.saveActive()}
                                        disabled={controller.active.id < 0}
                                    >
                                        {i.t('delivery:zones.save')}
                                    </Button>
                                </Space>
                            </div>
                            <GoogleMapZone
                                controller={controller}
                                coordinates={controller.active.coordinatesGoogle}
                                centerPosition={controller.center}
                            />
                        </div>
                    </>
                }
            </div>


            <Table columns={columns} dataSource={controller.list} rowKey={'id'} />

            <Modal
                visible={controller.modal}
                title={i.t('delivery:zones.edit')}
                onCancel={() => controller.closeModal()}
                footer={null}
                centered={true}
                maskClosable={false}
            >
                <form onSubmit={e => controller.saveDataZone(e)}>
                    <div className="switch-block">
                        <div className="element">
                            <div></div>
                            <Switch
                                checked={controller.active.enabled}
                                onChange={e => controller.onChangeInput('enabled', e)}
                                size="small"
                            />
                            <div>
                                <span>{i.t('def:active')}</span>
                            </div>
                        </div>
                    </div>

                    <h3>{i.t('delivery:zones.nameZone')}</h3>
                    <Input
                        value={controller.active.name}
                        onChange={e => controller.onChangeInput("name", e.target.value)}
                        placeholder={i.t('delivery:zones.nameZone')}
                        required
                    />
                    <br/><br/>
                    <h3>{i.t('def:id_crm')}</h3>
                    <Input
                      value={controller.active.id_crm ?? ''}
                      onChange={e => controller.onChangeInput("id_crm", e.target.value)}
                      placeholder={i.t('def:id_crm')}
                    />
                    <br/><br/>
                    <h3>{i.t('delivery:zones.minPrice')}</h3>
                    <InputNumber
                        style={{
                            width: 100 + "%"
                        }}
                        value={controller.active.minprice ?? 0}
                        onChange={e => controller.onChangeInput("minprice", e)}
                        placeholder={i.t('delivery:zones.minPrice')}
                        min={0}
                    />
                    <br/><br/>
                    <h3>{i.t('delivery:zones.maxPrice')}</h3>
                    <InputNumber
                        style={{
                            width: 100 + "%"
                        }}
                        value={controller.active.maxprice ?? 0}
                        onChange={e => controller.onChangeInput("maxprice", e)}
                        placeholder={i.t('delivery:zones.maxPrice')}
                        min={0}
                    />
                    <br/><br/>
                    <h3>{i.t('delivery:zones.price')}</h3>
                    <InputNumber
                        style={{
                            width: 100 + "%"
                        }}
                        value={controller.active.price ?? 0}
                        onChange={e => controller.onChangeInput("price", e)}
                        placeholder={i.t('delivery:zones.price')}
                        min={0}
                    />
                    <br/><br/>
                    <Space>
                        <Button
                            htmlType="submit"
                        >
                            {i.t('delivery:zones.map')}
                        </Button>
                        {controller.active.id > 0 &&
                            <Button type="primary" onClick={() => controller.saveActive()}>{i.t('delivery:save')}</Button>
                        }
                    </Space>
                </form>
            </Modal>
        </>
    )
})