import { makeAutoObservable } from 'mobx'
import { FormEvent } from 'react'
import { storeSession } from '../../../helpers/stores'
import { openApi } from "../../../helpers/request";
import { notification } from 'antd'
import i from '../../../translations/i'
import userController from './user.controller'
import CryptoJS from 'crypto-js'

class forgotController {

    data = {
        login: '',
        code: '',
        password: '',
        passwordConfirm: ''
    }

    error = ''

    /*
        Описание step
        0 - Не передана почта,
        1 - передана почта, ожидается код подтверждения,
        2 - код верный, нужен пароль
     */

    step = 0

    constructor() {
        makeAutoObservable(this)
    }

    onChangeData(name: string, value: any) {
        // @ts-ignore
        this.data[name] = value
    }

    getStepFromSession() {
        console.log('getStepFromSession', storeSession.get('forgot_step'))
        this.step = storeSession.get('forgot_step') || 0
    }

    setStep(step: number) {
        this.step = step
        storeSession.set('forgot_step', step)
    }

    newCode() {
        const email = storeSession.get('forgot_login') || undefined

        if(email) {
            const data = new FormData()
            data.append('email', email)
            data.append('type', 'forgot')

            openApi('post', '/manager/new_code', data)
                .then(result => {
                    notification.success({
                        message: i.t('def:success.complete'),
                        description: i.t('def:success.code')
                    })
                })
                .catch(err => {
                    console.log(err)
                    notification.error({
                        message: i.t('def:errors.error'),
                        description: i.t('def:errors.code')
                    })
                })
        } else {
            this.setStep(0)
        }
    }

    forgot(e: FormEvent) {
        e.preventDefault()

        const data = new FormData()
        let path = '/manager/forgot'

        if(this.step === 0) {
            storeSession.set('forgot_login', this.data.login)
            data.append('email', this.data.login)
        } else if(this.step === 1) {
            data.append('email', storeSession.get('forgot_login') || '')
            data.append('code', this.data.code)
        } else if(this.step === 2) {

            if(this.data.password !== this.data.passwordConfirm) {
                this.error = i.t('def:errors.passwords')
                return false
            }

            path = '/manager/password'
            data.append('password', CryptoJS.SHA1(this.data.password).toString() || '')
        }

        openApi('post', path, data)
            .then(result => {
                console.log(result)

                if(this.step === 1) {
                    userController.pack(result)
                        .then(res => {
                            storeSession.remove('forgot_login')
                            storeSession.remove('forgot_step')
                            this.setStep(this.step + 1)
                        })
                        .catch(err => {
                            this.setStep(-1)
                        })
                }
                else if(this.step === 2) {
                    storeSession.remove('forgot_step')
                    window.location.href = '/select'
                } else
                    this.setStep(this.step + 1)


            })
            .catch(err => {
                console.log(err)
            })
    }

}

export default new forgotController()