import { observer } from 'mobx-react-lite'
import controller from '../controllers/user.controller'
import i from '../../../translations/i'
import PhoneInput from 'react-phone-input-2'
import '../../../layouts/style/phone.css'
import { Row, Col, Button, Input } from 'antd'
import { config } from '../../../config/app'
import { UserIcon } from '../controllers/user.icons'
import { Helmet } from 'react-helmet'
import layoutController from "../../../layouts/controllers/layout.controller";

export const Register = observer(() => {

    return(
        <>
            <Helmet>
                <title>{i.t('auth:title.register')}</title>
            </Helmet>
            <form onSubmit={(e) => controller.register(e)}>
                <Row gutter={[15, 15]}>
                    <Col span={24}>
                        <h1>{i.t('auth:title.register')}</h1>
                    </Col>
                    <Col span={24}>
                        <Row gutter={[15, 15]} className='fixed' justify="space-around">
                            {controller.error !== '' &&
                                <Col span={24} className="errors">
                                    <Row align="top">
                                        <Col>{UserIcon('error')}</Col>
                                        <Col><p>{controller.error}</p></Col>
                                    </Row>
                                </Col>
                            }
                            <Col span={24}>
                                <Input
                                    className={controller.error !== '' ? 'error' : ''}
                                    key={'login_form_name'}
                                    type='text'
                                    placeholder={i.t('auth:placeholder.name')}
                                    value={controller.dataRegister.name}
                                    prefix={UserIcon('profile')}
                                    onChange={(e) => controller.onChangeRegister('name', e.target.value)}
                                    required
                                />
                            </Col>
                            <Col span={24}>
                                <PhoneInput
                                    country={config.defaultCountry}
                                    inputClass={controller.error !== '' ? 'error' : ''}
                                    placeholder={i.t('auth:placeholder.phone')}
                                    value={controller.dataRegister.phone}
                                    onChange={(e) => controller.onChangeRegister('phone', e)}
                                />
                            </Col>
                            <Col span={24}>
                                <Input
                                    className={controller.error !== '' ? 'error' : ''}
                                    key={'login_form_email'}
                                    type='email'
                                    placeholder={i.t('auth:placeholder.email')}
                                    value={controller.dataRegister.email}
                                    prefix={UserIcon('email')}
                                    onChange={(e) => controller.onChangeRegister('email', e.target.value)}
                                    required
                                />
                            </Col>
                            <Col span={24}>
                                <label>{i.t('auth:placeholder.password')}</label>
                            </Col>
                            <Col span={24}>
                                <Input.Password
                                    className={controller.error !== '' ? 'error' : ''}
                                    key={'login_form_password'}
                                    type='password'
                                    placeholder={i.t('auth:placeholder.password')}
                                    value={controller.dataRegister.password}
                                    prefix={UserIcon('password-lock')}
                                    onChange={(e) => controller.onChangeRegister('password', e.target.value)}
                                    required
                                />
                            </Col>
                            <Col span={24}>
                                <Input.Password
                                    className={controller.error !== '' ? 'error' : ''}
                                    key={'login_form_confirmPassword'}
                                    type='confirmPassword'
                                    placeholder={i.t('auth:placeholder.confirmPassword')}
                                    value={controller.dataRegister.confirmPassword}
                                    prefix={UserIcon('password')}
                                    onChange={(e) => controller.onChangeRegister('confirmPassword', e.target.value)}
                                    required
                                />
                            </Col>
                            <Col span={24} className="buttons">
                                <Row justify="space-around" align="middle" gutter={[10, 10]}>
                                    {/*<Col span={12} className="right">*/}
                                    {/*    <Button*/}
                                    {/*        key={'login_form_button_submit'}*/}
                                    {/*        type="primary"*/}
                                    {/*        className="button enter"*/}
                                    {/*        href='/login'*/}
                                    {/*    >*/}
                                    {/*        {i.t('def:actions.enter')}*/}
                                    {/*    </Button>*/}
                                    {/*</Col>*/}
                                    <Col span={24} className="left">
                                        <Button
                                            key={'login_form_button_register'}
                                            type="primary"
                                            htmlType="submit"
                                            className="button"
                                            disabled={controller.error !== ''}
                                            style={{ width: '100%' }}
                                            block
                                        >
                                            {i.t('def:actions.continue')}
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={24} className="verify-block">
                                <a className='doc' href={layoutController.wl.contract_uri} target="_blank" rel="noreferrer">{i.t('auth:register.doc')}</a>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </form>
        </>
    )
})