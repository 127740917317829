import { observer } from 'mobx-react-lite'
import { Row, Col } from 'antd'
import i from '../../../../translations/i'
import { __product } from '../__product'
import { runInAction } from 'mobx'
import { Editor } from 'primereact/editor'

export const ProductText = observer(() => {

  return(
    <>
      <Row gutter={[20, 20]} align="bottom">
        <Col span={24}>
          <label className="formLabel">{i.t('product:description')}</label>
          <Editor
            headerTemplate={ (
              <span className="ql-formats">
                  <button className="ql-bold" aria-label="Bold"></button>
                  <button className="ql-italic" aria-label="Italic"></button>
                  <button className="ql-underline" aria-label="Underline"></button>
              </span>
            )}
            style={{height:'320px'}}
            value={__product.item.text}
            onTextChange={(e) => runInAction(() => __product.item.text = e.htmlValue)}
          />
        </Col>
      </Row>
    </>
  )
})