import { observer } from 'mobx-react-lite'
import { Row, Col, Input, Divider, InputNumber, Select, TreeSelect, Button } from 'antd'
import { SwitchBlock } from '../../../../components/SwitchBlock'
import i from '../../../../translations/i'
import { __product } from '../__product'
import { runInAction } from 'mobx'
import { useEffect } from 'react'
import unitController from '../../../../modules/shop/units/unit.controller'
import shopProductStatusesController from '../../../../modules/shop/product/controllers/shopProductStatuses.controller'
import { DeleteOutlined } from '@ant-design/icons'
import __selectController from "../../../../helpers/select_link/__select.controller";
import __select from "../../../../helpers/select_link/__select.controller";

export const ProductMain = observer(() => {

    useEffect(() => {
        __product.getCategoryTree()
        unitController.get(0, true)
        shopProductStatusesController.getAllStatuses()


    }, [])

    return(
        <>
            <Row gutter={[20, 20]} align="bottom">
                <Col span={24}>
                    <SwitchBlock array={[
                        { label: i.t('product:active'), click: (e: any) => __product.item.view = e, value: __product.item.view },
                        { label: i.t('product:weightGoods'), click: (e: any) => __product.item.weight_goods = e, value: __product.item.weight_goods },
                        { label: i.t('product:float'), click: (e: any) => __product.item.view_per_unit = e, value: __product.item.view_per_unit },
                        { label: i.t('product:allowAddingToCart'), click: (e: any) => __product.item.allow_adding_to_cart = e, value: __product.item.allow_adding_to_cart },
                        { label: i.t('product:allowTransitionToProductCard'), click: (e: any) => __product.item.allow_transition_to_product_card = e, value: __product.item.allow_transition_to_product_card },
                    ]} />
                </Col>
                <Divider />
                <Col span={24}>
                    <label className="formLabel">{i.t('product:parent')}</label>
                    {__product.tree.length
                      ? <TreeSelect
                        style={{ width: '100%' }}
                        treeData={__product.tree}
                        value={__product.item.categories}
                        treeCheckable={true}
                        onChange={(e: any) => runInAction(() => __product.item.categories = e)}
                        showSearch={false}
                        multiple
                      />
                      : <></>
                    }

                </Col>
                <Divider />
                <Col span={12}>
                    <label className="formLabel">{i.t('product:nameProduct')}</label>
                    <Input
                        value={__product.item.name}
                        onChange={ (e) => runInAction(() => __product.item.name = e.target.value) }
                        required
                    />
                </Col>
                <Col span={6}>
                    <label className="formLabel">{i.t('product:idCrm')}</label>
                    <Input
                        value={__product.item.vendor_code}
                        onChange={ (e) => runInAction(() => __product.item.vendor_code = e.target.value) }
                    />
                </Col>
                <Col span={6}>
                    <label className="formLabel">{i.t('product:id_crm')}</label>
                    <Input
                        value={__product.item.id_crm}
                        onChange={ (e) => runInAction(() => __product.item.id_crm = e.target.value) }
                    />
                </Col>
                <Col span={6}>
                    <label className="formLabel">{i.t('product:price')}</label>
                    <InputNumber
                        style={{ width: '100%' }}
                        value={__product.item.price}
                        onChange={ (e) => runInAction(() => __product.item.price = e) }
                    />
                </Col>
                <Col span={6}>
                    <label className="formLabel">{i.t('product:oldPrice')}</label>
                    <InputNumber
                        style={{ width: '100%' }}
                        value={__product.item.old_price}
                        onChange={ (e) => runInAction(() => __product.item.old_price = e) }
                    />
                </Col>
                <Col span={6}>
                    <label className="formLabel">{i.t('product:count')}</label>
                    <InputNumber
                        style={{ width: '100%' }}
                        value={__product.item.count}
                        onChange={ (e) => runInAction(() => __product.item.count = e) }
                    />
                </Col>
                <Col span={6}>
                    <label className="formLabel">{i.t('product:unit')}</label>
                    <Select
                        style={{ width: '100%' }}
                        options={unitController.list.map((el: any) => ({
                            label: `${el.name} (${el.description})`,
                            value: el.id
                        }))}
                        value={__product.item.unit_id}
                        onChange={ (e) => runInAction(() => __product.item.unit_id = e) }
                    />
                </Col>
                <Col span={6}>
                    <label className="formLabel">{i.t('product:type')}</label>
                    <Select
                        style={{ width: '100%' }}
                        options={shopProductStatusesController.statuses.map((el: any) => ({
                            label: el.name,
                            value: el.id
                        }))}
                        value={__product.item.types}
                        onChange={(e) => runInAction(() => __product.item.types = e) }
                        mode="multiple"
                        showSearch={false}
                    />
                </Col>
                <Col span={6}>
                    <label className="formLabel">{i.t('product:step')}</label>
                    <InputNumber
                      style={{ width: '100%' }}
                      value={__product.item.step}
                      onChange={ (e) => runInAction(() => __product.item.step = e) }
                    />
                </Col>
                <Col span={6}>
                    <label className="formLabel">{i.t('product:cart_limit')}</label>
                    <InputNumber
                      style={{ width: '100%' }}
                      value={__product.item.cart_limit}
                      onChange={ (e) => runInAction(() => __product.item.cart_limit = e) }
                    />
                </Col>
                <Col span={6}>
                    <label className="formLabel">{i.t('product:free_text')}</label>
                    <Input
                      value={__product.item.free_text}
                      onChange={ (e) => runInAction(() => __product.item.free_text = e.target.value) }
                    />
                </Col>
                <Col span={6}>
                    <label className="formLabel">{i.t('product:buy_button')}</label>
                    <Input
                      value={__product.item.buy_button}
                      onChange={ (e) => runInAction(() => __product.item.buy_button = e.target.value) }
                    />
                </Col>
                <Col span={6}>
                    <label className="formLabel">{i.t('def:Sorting in an Order')}</label>
                    <Input
                        value={__product.item.sorting_order}
                        onChange={ (e) => runInAction(() => __product.item.sorting_order = e.target.value) }
                    />
                </Col>
                <Col span={6}>
                    <label className="formLabel">{i.t('def:Weight')}</label>
                    <InputNumber
                      style={{ width: '100%' }}
                      value={__product.item.weight}
                      onChange={ (e) => runInAction(() => __product.item.weight = e) }
                    />
                </Col>
                <Col span={6}>
                    <label className="formLabel">{i.t('def:Volume')}</label>
                    <InputNumber
                      style={{ width: '100%' }}
                      value={__product.item.volume}
                      onChange={ (e) => runInAction(() => __product.item.volume = e) }
                    />
                </Col>

                <Divider />

                <Col span={6}>
                    <label className="formLabel">{i.t('def:seo.name_encode')}</label>
                    <Input
                      value={__product.item.name_encode}
                      onChange={ (e) => runInAction(() => __product.item.name_encode = e.target.value.replace(/[^a-zA-Z0-9_]/g, '')) }
                    />
                </Col>
                <Col span={6}>
                    <label className="formLabel">{i.t('def:seo.title')}</label>
                    <Input
                      value={__product.item.seo_title}
                      onChange={ (e) => runInAction(() => __product.item.seo_title = e.target.value) }
                    />
                </Col>
                <Col span={6}>
                    <label className="formLabel">{i.t('def:seo.description')}</label>
                    <Input
                      value={__product.item.seo_description}
                      onChange={ (e) => runInAction(() => __product.item.seo_description = e.target.value) }
                    />
                </Col>
                <Col span={6}>
                    <label className="formLabel">{i.t('def:seo.keywords')}</label>
                    <Input
                      value={__product.item.seo_keywords}
                      onChange={ (e) => runInAction(() => __product.item.seo_keywords = e.target.value) }
                    />
                </Col>

                <Divider />

                <Col span={24}>
                    <Row gutter={[10, 10]}>
                        <Col span={24}>
                            <label className="formLabel">{i.t('product:barcode')}</label>
                        </Col>
                        {__product.item.barcodes.map((el: any, i: number) => (
                            <Col span={8}>
                                <Row gutter={[5, 5]}>
                                    <Col span={20}>
                                        <Input
                                            value={el.value}
                                            onChange={(e: any) => __product.item.barcodes[i].value = e.target.value}
                                        />
                                    </Col>
                                    <Col span={4}>
                                        <Button
                                            icon={<DeleteOutlined />}
                                            onClick={() => __product.item.barcodes.splice(i, 1)}
                                            type="dashed"
                                            danger
                                        />
                                    </Col>
                                </Row>

                            </Col>
                        ))}
                        <Col span={24}>
                            <Button type="dashed" onClick={() => __product.addBarcodeToItem()}>{i.t('def:actions.add')}</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
})