import { observer } from 'mobx-react-lite'
import TemplateTitle from '../../../template/title/TemplateTitle'
import productsWithoutCategoryController from './controllers/productsWithoutCategory.controller'
import React, { useEffect } from 'react'
import {
    Image,
    Pagination,
    Space,
    Button,
    Tooltip,
    Table,
    notification
} from 'antd'
import { SettingOutlined, DeleteOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons'
import i from '../../../../translations/i'
import { request } from '../../../../helpers/request'
import __catalog from '../../../../front/catalog/__catalog'
import { IProduct } from '../../../../components/list/types'
import { ProductsList } from '../../../../components/list/ProductsList'
import { __product } from '../../../../front/catalog/product/__product'
import __multiple from "../../../../helpers/multiple_actions/__multiple";

const ProductsWithoutCategory = observer(() => {
    useEffect(() => {
        __product.filter_list.category_id = "0"
        __product.getList()
      __multiple.setActions(__product.getActions())
    }, [])

    const breadcrumb = [
        { link: '/shop', value: i.t('crumb:shop') },
        { link: null, value: i.t('crumb:without') }
    ]

    function deleteProduct(id) {
        if (window.confirm(i.t('categories:confirmDelete'))) {
            request('delete', '/shop/product/' + id + '/', {}, true).then(
                (response) => {
                    if (response.code === 200) {
                        notification.success({
                            placement: 'bottomLeft',
                            message: i.t('categories:success'),
                            description: i.t('categories:deleteSuccess')
                        })
                        productsWithoutCategoryController.getProducts()
                    }
                }
            )
        }
    }

    return (
        <>
            <TemplateTitle
                title={i.t('crumb:without')}
                breadcrumb={breadcrumb}
            />

            <ProductsList
                products={__product.list}
                pagination={{
                    position: ['bottomLeft'],
                    hideOnSinglePage: true,
                    current: __product.pagination.page,
                    pageSize: __product.pagination.limit,
                    total: __product.pagination.total,
                    showSizeChanger: false,
                    onChange: (e) => __product.setPage(e)
                }}
                actions={{
                    title: i.t('orders:actions'),
                    key: 'action',
                    align: 'right',
                    render: (record) => (
                        <Space>
                            <Button
                                href={`/shop/catalog/product/${record.id}`}
                                target="_blank"
                                type="dashed"
                                icon={<SettingOutlined />}
                            />
                        </Space>
                    )
                }}
                setActive={(productID, view) => __product.setActive(productID, view)}
            />
        </>
    )
})

export default ProductsWithoutCategory
