import { makeAutoObservable } from 'mobx'
import { FormEvent } from 'react'

class exportController {



    constructor() {
        makeAutoObservable(this)
    }

    save(e: FormEvent) {
        e.preventDefault()
    }

}

export default new exportController()