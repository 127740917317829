import { makeAutoObservable, reaction, runInAction, toJS } from 'mobx'
import { openApi } from '../../../helpers/request'
import i from '../../../translations/i'
import __multiple from '../../../helpers/multiple_actions/__multiple'
import { FormEvent } from 'react'
import __selectController from '../../../helpers/select_link/__select.controller'
import { notification } from 'antd'
import { __cpl } from '../custom_product_lists/__cpl'



interface FilterListInterface {
    name: null | string
    id: null | string
    id_crm: null | string
    vendor_code: null | string
    category_id: null | string
}

class ProductController {
    filter_list: FilterListInterface = {
        name: null,
        id: null,
        id_crm: null,
        vendor_code: null,
        category_id: null
    }

    pagination = {
        limit: 10,
        page: 1,
        total: 0
    }

    list: any[] = []

    item: any = {
        id: 0,
        name: '',
        price: null,
        old_price: null,
        text: null,
        id_crm: null,
        view: true,
        count: null,
        view_variations: false,
        is_variant: false,
        product_id: null,
        vendor_code: null,
        unit_id: null,
        step: 1,
        view_per_unit: false,
        weight_goods: false,
        cart_limit: null,
        ban_on_discount: false,
        show_pictures: true,
        allow_adding_to_cart: true,
        allow_transition_to_product_card: true,
        custom_button_enabled: false,
        custom_button_text: null,
        custom_button_entity_type: null,
        custom_button_entity_id: null,
        buy_button: null,
        free_text: null,
        bonus_ban: false,
        increased_cashback: false,
        increased_cashback_type: 0,
        increased_cashback_value: 1,
        categories: [],
        barcodes: [],
        images: [],
        storages: [],
        type: [],
        filter_values: [],
        name_encode: null,
        seo_title: null,
        seo_description: null,
        seo_keywords: null,
        rel: [],
        docs: [],
        weight: 0,
        volume: 0
    }

    item_tab = 'main'
    modalOpened = false
    selectedBlock: any = {}
    selectedBlockIndex: number = 0
    deletedFiles: any = []

    selectedImageIndex = 0
    imageVideoLink = ''
    filesToEdit: any = []

    tree: any[] = []

    constructor() {
        makeAutoObservable(this)

        reaction(() => this.filter_list.name, () => this.getList())
        reaction(() => this.filter_list.id, () => this.getList())
        reaction(() => this.filter_list.id_crm, () => this.getList())
        reaction(() => this.filter_list.vendor_code, () => this.getList())
    }

    changeTab(tab: string) {
        this.item_tab = tab
    }

    getActions() {
        return [
            { label: i.t('def:actions.remove'), value: 'delete', action: () => this.delete() }
        ]
    }

    getList() {
        let uri = `/products?limit=${this.pagination.limit}&page=${this.pagination.page}&with[]=image`

        if(this.filter_list.id) uri += `&filter[id]=${this.filter_list.id}`
        if(this.filter_list.name) uri += `&filter[name]=${this.filter_list.name}`
        if(this.filter_list.id_crm) uri += `&filter[id_crm]=${this.filter_list.id_crm}`
        if(this.filter_list.vendor_code) uri += `&filter[vendor_code]=${this.filter_list.vendor_code}`
        if(this.filter_list.category_id) uri += `&filter[category_id]=${this.filter_list.category_id}`

        console.log(uri)

        openApi('get', uri)
            .then(result => {
                this.list = result.data
                this.pagination.total = result.total
            })
            .catch(error => console.log(error.response))
    }

    setActive(product_id: number, view: any) {
        const data = new FormData()
        data.append('view', `${+ view}`)

        openApi('post', `/shop/product/active/${product_id}`, data)
            .then(() => {
                this.getList()
            })
    }

    setPage(page: number) {
        runInAction(() => this.pagination.page = page)
        this.getList()
    }

    deleteOne(id: number) {
        const data = new FormData()
        data.append('elements[]', `${id}`)

        openApi('post', '/shop/product/delete', data)
            .then(result => {
                this.getOne(this.item.id)
            })

    }

    delete() {
        const data = new FormData()

        __multiple.selected.map(el => data.append('elements[]', `${el}`))

        openApi('post', '/shop/product/delete', data)
            .then(result => {
                this.getList()


                __multiple.clear()
            })
    }

    getOne(product_id: number | string) {
        openApi('get', `/products/${product_id}?with[]=images&with[]=barcodes&with[]=storages&with[]=types&with[]=categories&with[]=variations&with[]=rel&with[]=docs`)
            .then(result => {
                console.log(result)
                this.item = result.data

                __selectController.select.type =
                  this.item.custom_button_entity_type

                let parse = ""

                try {
                    if(this.item.custom_button_entity_id !== "") {
                        parse = JSON.parse(result.data.custom_button_entity_id)
                    }
                } catch (e) {
                    parse = result.data.custom_button_entity_id
                }


                if(Array.isArray(parse)) {
                    __selectController.select.value_array = parse
                } else {
                    __selectController.select.value = parse
                }

                console.log(__selectController.select.value)

                let rel: any = []
                result.data.rel.map((item: any) => {
                    let lists: any = []
                    item.group.lists.map((list: any) => {
                        if(list?.custom_list){
                            lists.push(list.custom_list)
                        }
                    })

                    rel.push({
                        id: item.group.id,
                        name: item.group.label,
                        type: item.group?.lists?.length > 0 ? 'lists' : '',
                        lists: lists
                    })
                })
                this.item.rel = rel

                let docs: any = []
                result.data.docs.map((group: any) => {
                    let files: any = []
                    group.files.map((file: any) => {
                        files.push({
                            id: file.id,
                            uid: file.id,
                            status: 'done',
                            name: file?.filename ?? file.uri.split('/')[1],
                            url: `${file.base}${file.uri}`,
                            uri: file.uri
                        })
                    })

                    docs.push({
                        id: group.group.id,
                        label: group.group.label,
                        files: files
                    })
                })
                this.item.docs = docs


            })
            .catch(error => {
                console.log(error)
                console.log(error.response)
            })
    }

    sendForm(e: FormEvent) {
        e.preventDefault()
        this.save()
    }

    save() {
        const data = new FormData()

        data.append('name', this.item.name)
        data.append('price', this.item.price)
        data.append('old_price', this.item.old_price)
        data.append('text', this.item.text)
        data.append('id_crm', this.item.id_crm)
        data.append('view', `${+ this.item.view}`)
        data.append('count', `${this.item.count}`)
        data.append('view_variations', `${+ this.item.view_variations}`)
        data.append('is_variant', `${+ this.item.is_variant}`)
        data.append('product_id', `${this.item.product_id}`)
        data.append('vendor_code', this.item.vendor_code)
        data.append('unit_id', this.item.unit_id)
        data.append('step', this.item.step)
        data.append('view_per_unit', `${+ this.item.view_per_unit}`)
        data.append('weight_goods', `${+ this.item.weight_goods}`)
        data.append('cart_limit', this.item.cart_limit)
        data.append('ban_on_discount', `${+ this.item.ban_on_discount}`)
        data.append('show_pictures', `${+ this.item.show_pictures}`)
        data.append('allow_adding_to_cart', `${+ this.item.allow_adding_to_cart}`)
        data.append('allow_transition_to_product_card', `${+ this.item.allow_transition_to_product_card}`)
        data.append('weight', `${this.item.weight}`)
        data.append('volume', `${this.item.volume}`)
        data.append('custom_button_enabled', `${+ this.item.custom_button_enabled}`)
        data.append('custom_button_text', this.item.custom_button_text)
        data.append('custom_button_entity_type', __selectController.select.type)

        if(this.item.name_encode) data.append('name_encode', this.item.name_encode)
        if(this.item.seo_title) data.append('seo_title', this.item.seo_title)
        if(this.item.seo_description) data.append('seo_description', this.item.seo_description)
        if(this.item.seo_keywords) data.append('seo_keywords', this.item.seo_keywords)

        if(__selectController.select.value_array.length > 0) {
            console.log(1);
            data.append(
              "custom_button_entity_id",
              JSON.stringify(__selectController.select.value_array)
            );
        }else {
            console.log(2)
            data.append(
                'custom_button_entity_id',
                __selectController.select.value
            )
        }

        data.append('buy_button', this.item.buy_button)
        data.append('free_text', this.item.free_text)
        data.append('bonus_ban', `${+ this.item.bonus_ban}`)
        data.append('increased_cashback', `${+ this.item.increased_cashback}`)
        data.append('increased_cashback_type', this.item.increased_cashback_type)
        data.append('increased_cashback_value', this.item.increased_cashback_value)

        if(this.item.types && Array.isArray(this.item.types))
            this.item.types.map((type_id: number) => data.append('types[]', `${type_id}`))

        if(this.item.categories && Array.isArray(this.item.categories) && this.item.categories.length > 0)
            this.item.categories.map((category_id: number) => data.append('categories[]', `${category_id}`))
        else {
            notification.error({
                message: i.t('def:errors.error'),
                description: i.t('def:errors.emptyCategory'),
            })
            return
        }

        if(this.item.barcodes && Array.isArray(this.item.barcodes))
            this.item.barcodes.map((item: any, i: number) => {
                if(item.id) data.append(`barcodes[${i}][id]`, item.id ? `${item.id}` : `0`)
                data.append(`barcodes[${i}][value]`, `${item.value}`)
            })

        if(this.item.images && Array.isArray(this.item.images))
            this.item.images.map((item: any, i: number) => {
                data.append(`images[${i}][sorting]`, `${item.sorting}`)
                if(item.id) data.append(`images[${i}][id]`, `${item.id}`)
                else data.append(`images[${i}][id]`, `0`)
                if(item.file) data.append(`file_${i}`, item.file)
                if(item.video_uri){
                    data.append(`images[${i}][video_uri]`, `${item.video_uri}`)
                }
            })

        if(this.item.storages && Array.isArray(this.item.storages))
            this.item.storages.map((item: any, i: number) => {
                data.append(`warehouses[${i}][warehouseid]`, `${item.warehouseid}`)
                data.append(`warehouses[${i}][view]`, `${+ item.view}`)
                data.append(`warehouses[${i}][priceold]`, `${item.priceold}`)
                data.append(`warehouses[${i}][price]`, `${item.price}`)
                data.append(`warehouses[${i}][count]`, `${item.count}`)
            })

        if(this.item.filter_values && Array.isArray(this.item.filter_values))
            this.item.filter_values.map((el: number, i: number) => data.append(`props[${i}]`, `${el}`))

        this.item.rel.map((block: any, index: number) => {
            if(block.name !== null && block.name !== ''){
                if(block.id > 0) {
                    data.append(`related[${index}][id]`, block.id)
                }
                data.append(`related[${index}][label]`, block.name)
                if(block.type === 'lists') {
                    block.lists.map((list: any, k: number) => {
                        data.append(`related[${index}][list][${k}]`, `${list.id}`)
                    })
                }
            }
        })

        this.item.docs.map((group: any, index: number) => {
            if(group.label === null || group.label === '' || group.files.length === 0) {
                return notification.error({
                    message: i.t('def:errors.error'),
                    description: i.t('def:errors.data')
                })
            }
            if(group.id > 0) {
                data.append(`docs[${index}][id]`, group.id)
            }
            data.append(`docs[${index}][label]`, `${group.label}`)
            data.append(`docs[${index}][key]`, `docs_${index}`)

            group.files.map((file: any, k: number) => {
                if(file.uri){

                } else {
                    data.append(`docs_${index}[${k}]`, file)
                }
            })
        })

        this.deletedFiles.map((fileID: number) => {
            openApi('delete', `/file/${fileID}`)
                .then((result: any) => {

                })
        })

        openApi('post', `/products/${this.item.id}`, data)
            .then(result => {
                if(this.item.id > 0) {
                    this.getOne(result.data.id)
                    notification.success({
                        message: i.t('def:success.success'),
                        description: i.t('def:success.edit')
                    })
                } else {
                    window.location.href = `/shop/catalog/product/${result.data.id}`
                }
            })
            .catch(error => {
                console.log(error.response)
                notification.error({
                    message: i.t('def:errors.error'),
                    description: error.response.data.data.message ?? 'error'
                })
            })
    }

    getCategoryTree() {
        openApi('get', `/category/tree`)
            .then(result => {
                this.tree = result.data
            })
            .catch(error => console.log(error.response))
    }

    addBarcodeToItem() {
        this.item.barcodes.push({

        })
    }

    handleOnDragEnd(result: any) {
        const items = Array.from(this.item.images)
        const [reorderedItem] = items.splice(result.source.index, 1)
        items.splice(result.destination.index, 0, reorderedItem)

        this.item.images = items
    }

    sortingPictures(index: number, action: string) {

        let array: any[] = []

        this.item.images.map((el: any, i: number) => {
            el.sorting = i
            array.push(el)
        })

        if(action === 'top') {
            if(array[index-1]) {
                let this_element = array[index]
                let prev_element = array[index-1]

                array[index-1].sorting = this_element.sorting
                array[index].sorting = prev_element.sorting
            }
        } else {
            if(array[index+1]) {
                let this_element = array[index]
                let prev_element = array[index+1]

                array[index+1].sorting = this_element.sorting
                array[index].sorting = prev_element.sorting
            }
        }

        array.sort((el1, el2) => el1['sorting'] > el2['sorting'] ? 1 : -1)

        let array2: any[] = []

        array.map((el: any, i: number) => {
            el.sorting = i
            array2.push(el)
        })

        this.item.images = array2

        return
    }

    deleteImage(index: number) {
        this.item.images.splice(index, 1)
    }

    setProductStorageID(warehouse_id: number, value: boolean) {
        const storage = this.item.storages.find((el: any) => el.warehouseid === warehouse_id);

        if (value) {
            if (storage) {
                storage.view = 1;
            } else {
                this.item.storages.push({
                    warehouseid: warehouse_id,
                    view: 1,
                    price: 0,
                    priceold: 0,
                    count: 0
                });
            }
        } else if (storage) {
            storage.view = 0;
        } else {
            this.item.storages = this.item.storages.filter((el: any) => el.warehouseid !== warehouse_id);
        }
    }

    setProductDataToStorage(warehouse_id: number, field_name: string, value: number) {
        let element = this.item.storages.find((el: any) => el.warehouseid === warehouse_id)
        let list = this.item.storages.filter((el: any) => el.warehouseid !== warehouse_id)
        element[field_name] = value
        list.push(element)
        this.item.storages = list
    }

    createVar() {
        openApi('post', `/products/var/${this.item.id}`)
            .then(result => {
                console.log(result.data)
                this.getOne(this.item.id)
            })
            .catch((error) => console.log(error.response))
    }

    addBlock() {
        this.item.rel.push({
            id: 0,
            name: null,
            type: '',
            lists: []
        })
    }

    removeBlock(index: number) {
        this.item.rel.splice(index, 1)
    }

    openModal(index: number) {
        this.selectedBlockIndex = index
        let selectedBlock = toJS(this.item.rel[index])
        let lists: any = []
        selectedBlock.lists.map((list: any) => {
            lists.push(list.id)
        })

        __cpl.selectedLists = lists
        this.selectedBlock = selectedBlock
        this.modalOpened = true
    }

    closeModal(force: boolean = false) {
        if(!force) {
            if(this.selectedBlock.name === '' || this.selectedBlock.name === null) {
                return notification.error({
                    message: i.t('def:errors.error'),
                    description: i.t('def:errors.data')
                })
            }

            let lists: any = []
            __cpl.selectedLists.map((listID: number) => {
                lists.push(__cpl.list.find(el => el.id === listID))
            })
            this.selectedBlock.lists = lists
            this.item.rel[this.selectedBlockIndex] = toJS(this.selectedBlock)
        }

        this.modalOpened = false

        setTimeout(() => {
            this.selectedBlock = {}
            this.selectedBlockIndex = 0
            __cpl.selectedLists = []
        }, 300)
    }

    addFilesGroup() {
        this.item.docs.push({
            id: 0,
            label: null,
            files: []
        })
    }

    removeFilesGroup(index: number) {
        this.item.docs.splice(index, 1)
    }

    toggleFile(i: number, file: any, multiple: boolean = true) {
        let index = this.item.docs[i].files.findIndex((el: any) => el.uid === file.uid)
        let array = toJS(this.item.docs[i].files)
        if(index > -1) {
            if(file?.id > 0) {
                this.deletedFiles.push(file.id)
            }
            array.splice(index, 1)
        } else {
            if(multiple) {
                array.push(file)
            } else {
                array = [file]
            }
        }
        this.item.docs[i].files = array
    }

    openImagesModal(index: number) {
        this.selectedImageIndex = index
        this.imageVideoLink = toJS(this.item.images[this.selectedImageIndex]?.video_uri ?? '')
        this.modalOpened = true
    }

    closeImagesModal(force: boolean = false) {
        if(!force) {
            if(this.imageVideoLink === '') {
                return notification.error({
                    message: i.t('def:errors.error'),
                    description: i.t('def:errors.data')
                })
            }

            this.item.images[this.selectedImageIndex].video_uri = toJS(this.imageVideoLink)
        }

        console.log(this.item.images)

        this.modalOpened = false

        setTimeout(() => {
            this.imageVideoLink = ''
            this.selectedImageIndex = 0
        }, 300)
    }

    toggleEditFile(file: any, action: string = 'edit', index: number = -1, editingFileIndex: number = -1) {
        if(action === 'edit') {
            this.filesToEdit.push(toJS(file))
        } else if(action === 'save') {
            if(editingFileIndex > -1 && index > -1) {
                let fileIndex = this.item.docs[index].files.findIndex((el: any) => el.id === file.id)

                if(fileIndex > -1) {
                    let newFile = toJS(this.filesToEdit[editingFileIndex])
                    let fileName = newFile?.name ?? ''

                    if(fileName === '') {
                        return notification.error({
                            message: i.t('def:errors.error'),
                            description: i.t('def:Fill File Name')
                        })
                    }

                    this.item.docs[index].files[fileIndex] = newFile
                    this.filesToEdit.splice(editingFileIndex, 1)

                    const data = new FormData()

                    data.append('filename', fileName)

                    console.log(`/file/${newFile.id}`)

                    openApi('post', `/file/${newFile.id}`, data)
                        .then(result => {
                            notification.success({
                                message: i.t('def:success.success'),
                                description: i.t('def:File Saved')
                            })
                        })
                        .catch(error => {
                            notification.error({
                                message: i.t('def:errors.error'),
                                description: error.response.data.data.message ?? 'error'
                            })
                        })
                }
            }
        }
    }
}

export const __product = new ProductController()