import { observer } from "mobx-react-lite"
import { Button, Image, InputNumber, Space, Tag, Row, Col } from "antd";
import i from "../../../../translations/i"
import { storeSession } from "../../../../helpers/stores"
import { getPrice } from "../../../../helpers/price"

export const OrderProducts = observer(({ items, editable, setQuantity, deleteProduct }: any) => {

  return(
    <>
        <div className="order__products">
            {items.map((item: any, index: number) => {
                let className = 'item'
                if(editable) {
                    className += ' item-editable'
                }

                return (
                    <div className={className} key={index}>
                        <div className="item__image">
                            {item.product && item.product.image &&
                                <Image src={item.product.image.path} />
                            }
                        </div>

                        <div className="item__meta">
                            {item.product && item.product.vendor_code &&
                                <div className="item__meta-vendor">{i.t('def:vendor')}: {item.product.vendor_code}</div>
                            }
                            {item.product && item.product.name &&
                                <div className="item__meta-name">{item.product.name}</div>
                            }
                            {Array.isArray(item.props) &&
                                <div className="item__meta-props">
                                    {item.props.map((el: any) => (<Tag>{el.category.name}: {el.value.value}</Tag>))}
                                </div>
                            }
                        </div>

                        <div className="item__quantity">
                            {editable
                                ? <>
                                    <InputNumber
                                        style={{ width: '100%' }}
                                        value={item.product && (item.product.weight_goods || item.product.step !== 1)
                                            ? (item.count * item.product.step).toFixed(2)
                                            : item.count}
                                        onChange={(e: any) => {
                                            setQuantity(item.id, e, item.product.weight_goods, item.product.step)
                                        }}
                                    />
                                </>
                                : <>
                                    {item.product && item.product.unit_id && item.product.step ? (item.count * item.product.step).toFixed(2) : item.count}
                                </>
                            }
                            &nbsp;
                            {item.product && item.product.unit_id && storeSession.get("project_units").findIndex((el: any) => el.id === item.product.unit_id) > -1
                                ? <>{storeSession.get("project_units").find((el: any) => el.id === item.product.unit_id).name}</>
                                : <></>}
                        </div>

                        <div className="item__price">{getPrice((item.price * item.count).toFixed(2))}</div>

                        {editable &&
                            <div className="item__remove">
                                <Button
                                    danger
                                    type="dashed"
                                    onClick={() => deleteProduct(item.id)}
                                >{i.t('def:actions.remove')}</Button>
                            </div>
                        }
                    </div>
                )
            })}
        </div>
    </>
  )
})