import { makeAutoObservable } from 'mobx'
import { openApi } from '../../../../helpers/request'
import i from '../../../../translations/i'
import { message, notification } from 'antd'
import { FormEvent } from 'react'

export class paymentTypesController {

    list: any[] = []
    element: any = {}

    constructor() {
        makeAutoObservable(this)
    }

    save(e: FormEvent) {
        e.preventDefault()

        let data

        if(this.element.id && this.element.id > 0) {
            data = {
                name: this.element.name
            }
        } else {
            data = new FormData()
            data.append('name', this.element.name)
        }

        openApi('post', this.element.id && this.element.id > 0 ? `/shop/payments/types/${this.element.id}` : '/shop/payments/types', data)
            .then(result => {
                console.log(result)
                if(this.element.id && this.element.id > 0) {
                    this.element = result.data
                    notification.success({
                        message: i.t('def:success.edit')
                    })
                } else {
                   window.location.href = '/shop/payment/types'
                }
            })
    }

    onChange(name: string, value: any) {
        this.element[name] = value
    }

    getList() {
        openApi('get', '/shop/payments/types')
            .then(result => {
                console.log(result)
                this.list = result.data
            })
    }

    getOne(entityId: any) {
        openApi('get', `/shop/payments/types/${entityId}`)
            .then(result => {
                this.element = result.data
            })
    }

    delete(entityId: number) {
        if (window.confirm(i.t('def:confirms.delete'))) {
            openApi('delete', `/shop/payments/types/${entityId}`)
                .then(result => {
                    this.getList()
                })
                .catch(result => {
                    message.error(i.t('def:errors.remove'))
                })
        }
    }

}