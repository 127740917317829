import { observer } from 'mobx-react-lite'
import controller from '../controllers/forgot.controller'
import i from '../../../translations/i'

import { Row, Col, Button, Input } from 'antd'
import { UserIcon } from '../controllers/user.icons'
import { useEffect } from 'react'
import { VerifyComponent } from '../../../layouts/components/user/VerifyComponent'
import { Helmet } from 'react-helmet'

const ForgotStepThree = observer(() => {

    return(
        <>
            <form onSubmit={(e) => controller.forgot(e)}>
                <Row gutter={[15, 15]}>
                    <Col span={24}>
                        <h1 className="forgot">{i.t('auth:title.changePassword')}</h1>
                    </Col>
                    <Col span={24} className="forgot-description">
                        <p>{i.t('auth:forgot.password')}</p>
                    </Col>
                    <Col span={24}>
                        <Row gutter={[15, 15]} className='fixed' justify="space-around">
                            {controller.error !== '' &&
                                <Col span={24} className="errors short">
                                    <p>{controller.error}</p>
                                </Col>
                            }
                            <Col span={24}>
                                <Input.Password
                                    className={controller.error !== '' ? 'error' : ''}
                                    key={'login_form_password'}
                                    placeholder={i.t('auth:placeholder.password')}
                                    value={controller.data.password}
                                    prefix={UserIcon('password')}
                                    onChange={(e) => controller.onChangeData('password', e.target.value)}
                                    required
                                />
                            </Col>
                            <Col span={24}>
                                <Input.Password
                                    className={controller.error !== '' ? 'error' : ''}
                                    key={'login_form_passwordConfirm'}
                                    placeholder={i.t('auth:placeholder.confirmPassword')}
                                    value={controller.data.passwordConfirm}
                                    prefix={UserIcon('password-lock')}
                                    onChange={(e) => controller.onChangeData('passwordConfirm', e.target.value)}
                                    required
                                />
                            </Col>
                            <Col span={24} className="buttons">
                                <Row justify="space-around" align="middle" gutter={[10, 10]}>
                                    <Col span={24}>
                                        <Button
                                            key={'login_form_button_submit'}
                                            type="primary"
                                            htmlType="submit"
                                            className="button"
                                        >
                                            {i.t('def:actions.continue')}
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </form>
        </>
    )
})

const ForgotStepTwo = observer(() => {

    return(
        <>
            <form onSubmit={(e) => controller.forgot(e)}>
                <Row gutter={[15, 15]}>
                    <Col span={24}>
                        <h1 className="forgot">{i.t('auth:title.verify')}</h1>
                    </Col>
                    <Col span={24}>
                        <Row gutter={[15, 15]} className='fixed' justify="space-around">
                            {controller.error !== '' &&
                                <Col span={24} className="errors short">
                                    <p>{controller.error}</p>
                                </Col>
                            }
                            <Col span={24} className="verify-block">
                                <VerifyComponent onChange={(e: any) => controller.onChangeData('code', e)} error={controller.error !== ''} />
                            </Col>
                            <Col span={24} className="verify-description">
                                <p>{i.t('auth:verify.text')}</p>
                            </Col>
                            <Col span={24} className="verify-button">
                                <Button
                                    type='link'
                                    icon={UserIcon('fresh')}
                                    onClick={() => controller.newCode()}
                                >
                                    {i.t('auth:verify.code')}
                                </Button>
                            </Col>
                            <Col span={24} className="buttons">
                                <Row justify="space-around" align="middle" gutter={[10, 10]}>
                                    <Col span={24}>
                                        <Button
                                            key={'login_form_button_submit'}
                                            type="primary"
                                            htmlType="submit"
                                            className="button"
                                        >
                                            {i.t('def:actions.continue')}
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </form>
        </>
    )
})

const ForgotStepOne = observer(() => {

    return(
        <>
            <form onSubmit={(e) => controller.forgot(e)}>
                <Row gutter={[15, 15]}>
                    <Col span={24}>
                        <h1 className="forgot">{i.t('auth:title.forgot')}</h1>
                    </Col>
                    <Col span={24} className="forgot-description">
                        <p>{i.t('auth:forgot.text')}</p>
                    </Col>
                    <Col span={24}>
                        <Row gutter={[15, 15]} className='fixed' justify="space-around">
                            {controller.error !== '' &&
                                <Col span={24} className="errors short">
                                    <p>{controller.error}</p>
                                </Col>
                            }
                            <Col span={24}>
                                <Input
                                    className={controller.error !== '' ? 'error' : ''}
                                    key={'login_form_email'}
                                    type='email'
                                    placeholder={i.t('auth:placeholder.email')}
                                    value={controller.data.login}
                                    prefix={UserIcon('email')}
                                    onChange={(e) => controller.onChangeData('login', e.target.value)}
                                    required
                                />
                            </Col>
                            <Col span={24} className="buttons">
                                <Row justify="space-around" align="middle" gutter={[10, 10]}>
                                    <Col span={12} className="right">
                                        <Button
                                            key={'login_form_button_submit'}
                                            type="primary"
                                            className="button enter"
                                            href='/login'
                                        >
                                            {i.t('def:actions.back')}
                                        </Button>
                                    </Col>
                                    <Col span={12} className="left">
                                        <Button
                                            key={'login_form_button_submit'}
                                            type="primary"
                                            htmlType="submit"
                                            className="button"
                                            disabled={controller.error !== ''}
                                        >
                                            {i.t('def:actions.send')}
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </form>
        </>
    )
})

export const Forgot = observer(() => {

    useEffect(() => {
        controller.getStepFromSession()
    }, [])

    return controller.step === 0
        ?   <>
                <Helmet>
                    <title>{i.t('auth:title.forgot')}</title>
                </Helmet>
                <ForgotStepOne />
            </>
        :   controller.step === 1
            ?   <>
                    <Helmet>
                        <title>{i.t('auth:title.forgot')}</title>
                    </Helmet>
                    <ForgotStepTwo />
                </>
            :   <>
                    <Helmet>
                        <title>{i.t('auth:title.forgot')}</title>
                    </Helmet>
                    <ForgotStepThree />
                </>
})