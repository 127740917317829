import { makeAutoObservable } from 'mobx'
import { openApi } from '../../../../helpers/request'
import __multiple from '../../../../helpers/multiple_actions/__multiple'

class answersController {
    constructor() {
        makeAutoObservable(this)
    }

    form_id = 0

    list = []

    pagination = {
        total: 0,
        limit: 10,
        page: 1,
        offset: 0
    }

    filters = []
    filtersValues: any = {}

    answer = {
        source: '',
        device_info: '',
        answers: []
    }

    export(form_id: number, type: string = 'xlsx') {

        let path = `/forms/export/${form_id}/${type}?page=1`

        if(Object.keys(this.filtersValues).length > 0){
            Object.keys(this.filtersValues).map((key: any) => {
                if(this.filtersValues[key] !== null) path += `&filter[${key}]=${this.filtersValues[key]}`
            })
        }

        openApi('get', path)
            .then(result => {
                console.log(result)
                window.open(result.data)
            })
            .catch(error => {})
    }

    get(formID: number | string, elementID: number | string = 0, sorter: any = false){
        let path = ''
        if(elementID > 0) path = `/forms/answer/${elementID}`
        else {
            path = `/forms/answers/${formID}?limit=${this.pagination.limit}&page=${this.pagination.page}`
            if(sorter !== false){
                let direction = sorter.order
                if(direction !== undefined){
                    direction = (direction === 'ascend') ? 'asc' : 'desc'
                    path += `&sorting_type=${sorter.columnKey}&sorting_action=${direction}`
                }
            }
            if(Object.keys(this.filtersValues).length > 0){
                Object.keys(this.filtersValues).map((key: any) => {
                    if(this.filtersValues[key] !== null) path += `&filter[${key}]=${this.filtersValues[key]}`
                })
            }
        }

        openApi('get', path)
            .then(result => {
                console.log(result)
                if(elementID > 0) this.answer = result.data
                else {
                    this.pagination.total = result.total
                    this.pagination.page = result.page
                    this.pagination.limit = result.limit
                    this.list = result.data
                }
            })
    }

    remove(formID: number | string, answerID: number | string) {
        openApi('delete', `/forms/answers/${answerID}`)
            .then(result => {
                window.location.href = '/shop/forms'
            })
    }

    setPage(formID: number | string, page: number) {
        this.pagination.page = page
        this.get(formID)
    }

    getFilters(formID: number | string) {
        openApi('get', `/forms/filters/${formID}`)
            .then(result => {
                console.log(result)
                this.filters = result.data
            })
    }

    setFilter(name: string, value: any, formID: number | string) {
        this.filtersValues[name] = value
        this.get(formID)
    }

    readAll() {
        openApi('post', `/forms/read/answers/${this.form_id}`)
            .then(result => {
                this.get(this.form_id)
                __multiple.clear()
            })
    }

    multipleDelete() {
        const data = new FormData()

        __multiple.selected.map((el: number) => data.append('elements[]', `${el}`))

        openApi('post', `/forms/delete/multiple/${this.form_id}`, data)
            .then(result => {
                this.get(this.form_id)
                __multiple.clear()
            })
    }

    multipleRead() {
        const data = new FormData()

        __multiple.selected.map((el: number) => data.append('elements[]', `${el}`))

        openApi('post', `/forms/read/answers/${this.form_id}`, data)
            .then(result => {
                this.get(this.form_id)
                __multiple.clear()
            })
    }

}

export default new answersController()