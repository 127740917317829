import { observer } from 'mobx-react-lite'
import i from "../translations/i"
import { pathHelper } from '../helpers/path'

import {
    DashboardOutlined,
    ShopOutlined,
    ShoppingOutlined,
    TagsOutlined,
    PercentageOutlined,
    FilterOutlined,
    CreditCardOutlined,
    SnippetsOutlined,
    FundProjectionScreenOutlined,
    AppstoreOutlined,
    UserOutlined,
    LayoutOutlined,
    PicRightOutlined,
    HighlightOutlined,
    ContactsOutlined,
    DeploymentUnitOutlined,
    SettingOutlined,
    EuroOutlined,
    NotificationOutlined,
    ExceptionOutlined,
    SolutionOutlined,
    GroupOutlined, UnorderedListOutlined, FireOutlined
} from '@ant-design/icons'

import './style/navigation.less'
import layoutController from './controllers/layout.controller'
import { Badge } from 'antd'
import { useEffect } from "react";
import { storeLocal } from "../helpers/stores";

export const Navigation = observer(() => {

    useEffect(() => {
        console.log(storeLocal.get('role'))
    }, [])

    const this_path = pathHelper(window.location.pathname)

    const nav: any[] = [
        {
            key: "navigation_dashboard",
            role: true,
            items: [
                { title: i.t('nav:dashboard'), url: "/", icon: <DashboardOutlined />, key: "navigation_shop_dashboard", badge: 0, active: this_path === '/', role: 1 },
                { title: i.t('nav:apps'), url: "/apps", icon: <AppstoreOutlined />, key: "navigation_apps", badge: 0, active: this_path === '/apps' },
            ]
        },
        {
            key: "navigation_shop",
            title: i.t('nav:shop'),
            items: [
                { title: i.t('nav:catalog'), url: "/shop/catalog", icon: <ShopOutlined />, key: "navigation_shop_catalog", badge: 0, active: this_path === '/shop/catalog' },
                { title: i.t('nav:products'), url: "/catalog/products", icon: <ShoppingOutlined />, key: "navigation_shop_products", badge: 0, active: this_path === '/catalog/products' },
                { title: i.t('nav:lists'), url: "/catalog/lists", icon: <UnorderedListOutlined />, key: "navigation_shop_groups", badge: 0, active: this_path === '/catalog/lists' },
                { title: i.t('nav:groups'), url: "/catalog/groups", icon: <GroupOutlined />, key: "navigation_shop_lists", badge: 0, active: this_path === '/catalog/groups' },
                { title: i.t('nav:productsStatuses'), url: "/shop/status", icon: <TagsOutlined /> , key: "navigation_shop_products_statuses", badge: 0, active: this_path === '/shop/status'},
                { title: i.t('nav:filters'), url: "/shop/properties", icon: <FilterOutlined />, key: "navigation_shop_filters", badge: 0, active: this_path === '/shop/properties' },
                { title: i.t('nav:units'), url: "/shop/units", icon: <DeploymentUnitOutlined />, key: "navigation_shop_units", badge: 0, active: this_path === '/shop/units' },
            ]
        },
        {
            key: "navigation_sale",
            title: i.t('nav:sale'),
            role: true,
            items: [
                { title: i.t('nav:orders'), url: "/shop/orders", icon: <CreditCardOutlined />, key: "navigation_shop_orders", badge: layoutController.markers.orders ? layoutController.markers.orders : 0, active: this_path === '/shop/orders', role: 1 },
                { title: i.t('nav:ordersStatuses'), url: "/shop/statuses", icon: <SnippetsOutlined />, key: "navigation_shop_orders_statuses", badge: 0, active: this_path === '/shop/statuses' },
                { title: i.t('nav:users'), url: "/users", icon: <UserOutlined />, key: "navigation_other_users", badge: 0, active: this_path === '/users', role: 1 },
                { title: i.t('payment:types.title'), url: "/shop/payment/types", icon: <EuroOutlined />, key: "navigation_shop_payment_types", badge: 0, active: this_path === '/shop/payment/types' },
            ]
        },
        {
            key: "navigation_marketing",
            title: i.t('nav:marketing'),
            items: [
                { title: i.t('nav:screens'), url: "/screens", icon: <FundProjectionScreenOutlined />, key: "navigation_other_screens", badge: 0, active: this_path === '/screens' },
                { title: i.t('def:Promo Slides'), url: "/promo_slides", icon: <FireOutlined />, key: "navigation_other_promo_slides", badge: 0, active: this_path === '/promo_slides' },
                { title: i.t('nav:promocodes'), url: "/shop/promo", icon: <PercentageOutlined />, key: "navigation_shop_promocodes", badge: 0, active: this_path === '/shop/promo' },
                { title: i.t('nav:pushes'), url: "/pushes", icon: <NotificationOutlined />, key: "navigation_pushes", badge: 0, active: this_path === '/pushes' },
            ]
        },
        {
            key: "navigation_feedback",
            title: i.t('nav:feedbackSection'),
            items: [
                { title: i.t('nav:contacts'), url: "/contacts", icon: <ContactsOutlined />, key: "navigation_contacts_contacts", badge: 0, active: this_path === '/contacts' },
                { title: i.t('nav:feedback'), url: '/shop/feedback', icon: <SolutionOutlined />, key: 'navigation_other_feedback', badge: layoutController.markers.feedback ? layoutController.markers.feedback : 0, active: this_path === '/shop/feedback' },
                { title: i.t('nav:forms'), url: '/shop/forms', icon: <ExceptionOutlined />, key: 'navigation_other_forms', badge: layoutController.markers.forms ? layoutController.markers.forms : 0, active: this_path === '/shop/forms' },
            ]
        },
        {
            key: "navigation_other",
            title: i.t('nav:settings_project'),
            items: [
                { title: i.t('nav:settings'), url: "/settings", icon: <SettingOutlined />, key: "navigation_shop_settings", badge: 0, active: this_path === '/settings' },
                { title: i.t('nav:web_settings'), url: "/web_settings", icon: <SettingOutlined />, key: "navigation_shop_web_settings", badge: 0, active: this_path === '/web_settings' },
                { title: i.t('nav:articles'), url: "/pages", icon: <LayoutOutlined />, key: "navigation_other_articles", badge: 0, active: this_path === '/pages' },
            ]
        },
        {
            key: "navigation_news",
            title: "",
            items: [
                { title: i.t('nav:newTypes'), url: "/news_types", icon: <PicRightOutlined />, key: "navigation_news_types", badge: 0, active: this_path === '/news_types' },
                { title: i.t('nav:news'), url: "/news", icon: <HighlightOutlined />, key: "navigation_news_news", badge: 0, active: this_path === '/news' }
            ]
        },
    ]

    return(
        <>
            <div className="navigation">
                <ul>
                    {nav.map((item, i: number) => item.role || !storeLocal.get('role') || storeLocal.get('role') === 0 ?  (
                        <div key={`navigation_elements_${i}`} className="navigation-rel">
                            {(item.title || item.title === "") &&
                                <div>
                                    <hr/>
                                    {item.title !== "" && <span className="titles">{ item.title }</span>}
                                </div>
                            }
                            {item.items.map((el: any, index: number) =>
                              storeLocal.get('role') && storeLocal.get('role') !== 0 && (!el.role || el.role !== storeLocal.get('role')) ? <></> : (
                                <li key={el.key}>
                                    <a href={el.url} className={el.active ? 'active' : ''}>
                                        {el.new
                                            ? <Badge.Ribbon text="New">
                                                <div key={`navigation_elements_parent_${i}_element_${index}`} className={el.active ? 'navigation_element navigation_active' : 'navigation_element'}>

                                                    <div className="inline">
                                                        <div className="icon">{ el.icon }</div>
                                                        <div className="point">{ el.title }</div>
                                                    </div>
                                                </div>
                                            </Badge.Ribbon>
                                            : <>
                                                <Badge count={el.badge ? el.badge : 0} size="small" style={{ backgroundColor: '#52c41a' }} offset={[10, 10]}>
                                                    <div key={`navigation_elements_parent_${i}_element_${index}`} className={el.active ? 'navigation_element navigation_active' : 'navigation_element'}>

                                                        <div className="inline">
                                                            <div className="icon">{ el.icon }</div>
                                                            <div className="point">{ el.title }</div>
                                                        </div>
                                                    </div>
                                                </Badge>
                                            </>}
                                    </a>
                                </li>
                            ))}
                        </div>
                    ) : <></>)}
                </ul>
            </div>
        </>
    )
})