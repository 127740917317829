import { request } from './request'
import { setHashDelete } from './hash'

export const remove = async (type: string, elementID: string | number) => {
    let facts = { typestructure: type }
    const result = request('delete', `/product/delete/${elementID}`, {
        data: `typestructure=${type}&hash=${setHashDelete(facts)}`
    })
    return !!result
}
