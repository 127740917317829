import { makeAutoObservable } from 'mobx'
import { notification } from 'antd'
import loaderController from '../../template/loader/loader.controller'
import hashController from '../../template/controllers/hash.controller'
import i from '../../../translations/i'
import { openApi, request } from '../../../helpers/request'

class massController {
    action = null
    value = 1

    constructor() {
        makeAutoObservable(this)
    }

    setAction(action) {
        this.action = action
    }

    setValue(value) {
        this.value = value
    }

    onSubmit(event) {
        event.preventDefault()

        if (this.action && this.value) {
            if (window.confirm(i.t('product:mass.confirm'))) {
                const data = new FormData()
                data.append('action', this.action)
                data.append('value', this.value)

                openApi('post', `/products/change_price`, data)
                    .then((result) => {
                        notification.success({
                            message: i.t('product:mass.success')
                        })
                    })
                    .catch((err) => {
                        notification.error({
                            message: i.t('def:errors.server')
                        })
                    })
            }
        } else {
            notification.error({
                message: i.t('def:errors.data')
            })
        }
    }
}

export default new massController()
