const newDateTimeHelper = (timestamp, type="") => {

    let date = new Date(timestamp * 1000)
    let returned

    let returnedDate = ("0" + date.getDate()).slice(-2) + "." + ("0" + (date.getMonth() + 1)).slice(-2) + "." + date.getFullYear()
    let returnedTime = ("0" + date.getHours()).slice(-2) + ":" + ("0" + (date.getMinutes() + 1)).slice(-2)

    if(type === "time") {
        returned = returnedTime
    } else if(type === "date") {
        returned = returnedDate
    } else {
        returned = `${returnedDate} ${returnedTime}`
    }

    return returned
}

module.exports = {
    newDateTimeHelper
}