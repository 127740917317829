import { observer } from 'mobx-react-lite'
import { Button, Col, Row } from 'antd'
import i from '../../../translations/i'
import { __cpl } from './__cpl'
import { CustomProductList } from './CustomProductList'
import { CustomProductListsList } from './CustomProductListsList'
import { useEffect } from 'react'
import propertiesController from '../../../modules/shop/properties/controllers/properties.controller'

export const CustomProductListsLayout = observer(({type = 'group', selectedElements = [], multiple = true}: any) => {
    useEffect(() => {
        __cpl.getList()
        propertiesController.pagination.limit = 99999
        propertiesController.getProperties()
    }, [])

    return <Row gutter={[20, 20]}>
        {__cpl.selectedList === null ? (<>
            <Col span={24}>
                <Row align="middle" justify="space-between">
                    <p className="sub">{i.t('def:Products Lists')}</p>
                    <Button
                        type="dashed"
                        onClick={() => __cpl.setEditedList(0)}
                    >
                        {i.t('def:Add List')}
                    </Button>
                </Row>
            </Col>
            <Col span={24}>
                <CustomProductListsList type={type} selectedElements={selectedElements} multiple={multiple} />
            </Col>
        </>) : (
            <CustomProductList />
        )}
    </Row>
})