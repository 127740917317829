import { makeAutoObservable } from "mobx";
import hashController from "../../../template/controllers/hash.controller"
import translite from "../../../template/controllers/translite.controller"
import loaderController from "../../../template/loader/loader.controller";
import i from '../../../../translations/i'
import { openApi, request } from '../../../../helpers/request'
import { notification } from 'antd'

class propertiesController {

    constructor() {
        makeAutoObservable(this)
    }

    property = {
        type: 'checkbox'
    }
    properties = []

    propStore = []
    propDelete = []

    pagination = {
        total: 0,
        page: 1,
        limit: 10
    }

    product = []

    setOffset(e) {
        this.pagination.page = e
        this.getAllProperties()
    }

    getProperties(id = 0) {
        if(id > 0) {
            openApi('get', `/filters/${id}`)
              .then(result => {
                  console.log(result)
                  this.property = result.data
              })
              .catch(error => {

              })
        } else {
            openApi('get', `/filters?page=${this.pagination.page}&limit=${this.pagination.limit}`)
              .then(result => {
                  console.log(result)
                  this.properties = result.data
                  this.pagination.page = result.page
                  this.pagination.total = result.total
                  this.pagination.limit = result.limit
              })
              .catch(error => {

              })
        }
    }

    getAllProperties() {
        openApi('get', `/filters?page=${this.pagination.page}&limit=${this.pagination.limit}`)
          .then(result => {
              console.log(result)
              this.properties = result.data
              this.pagination.page = result.page
              this.pagination.total = result.total
              this.pagination.limit = result.limit
          })
          .catch(error => {

          })
    }

    setProperty(name, value) {
        this.property[name] = value
        if(name === "name") {
            this.property.namevalue = translite.translite(value)
        }
    }

    savePropForm(e) {
        e.preventDefault()
        this.saveProperty()
    }

    saveProperty() {
        let data = new FormData()
        data.append("name", this.property.name)
        data.append("namevalue", this.property.namevalue)
        data.append("type", this.property.type)
        data.append("search", this.property.search ? 1 : 0)
        data.append("is_search", this.property.is_search ? 1 : 0)

        this.property.values && Array.isArray(this.property.values) && this.property.values.map((el, index) => {
            if(el.id && el.id > 0) data.append(`values[${index}][id]`, el.id)
            if(el.label) data.append(`values[${index}][label]`, el.label)
            data.append(`values[${index}][value]`, el.value)
            data.append(`values[${index}][position]`, el.position)
            if(el.valuemin) data.append(`values[${index}][valuemin]`, el.valuemin)
            if(el.valuemax) data.append(`values[${index}][valuemax]`, el.valuemax)
        })

        openApi('post', `/filters/${this.property.id && this.property.id > 0 ? this.property.id : 0}`, data)
          .then(result => {
              if(this.property.id && this.property.id > 0) {
                  this.property = result

                  notification.success({
                      message: i.t('def:success.edit')
                  })

                  this.property = result.data

              } else {
                  document.location.href="/shop/properties"
              }
          })
          .catch(error => console.log(error.response))
    }

    delete(propertyID) {
        if(window.confirm(i.t('def:confirms.delete'))) {

            openApi('delete', `/filters/${propertyID}`)
              .then(result => this.getAllProperties())
              .catch(error => {})
        }

    }

    addPropertyValue(propertyID) {
        let array = this.property.values
        console.log(array)
        array.push({
            id: null,
            filtercategoryid: propertyID,
            label: "",
            value: "",
            ids: Date.now()
        })
        this.property.values = array

    }

    setVariant(type, id, value, pos="") {
        let elem
        if(type === "id") {
            this.property.values[this.property.values.findIndex(el => el.id === id)].value = value
            elem = this.property.values[this.property.values.findIndex(el => el.id === id)]
        } else if (type === "ids") {
            this.property.values[this.property.values.findIndex(el => el.ids === id)].value = value
            elem = this.property.values[this.property.values.findIndex(el => el.ids === id)]
        } else {
            this.property.values[this.property.values.findIndex(el => el[pos] === id)].position = value
            elem = this.property.values[this.property.values.findIndex(el => el[pos] === id)]
        }

        if(pos !== "") {
            if(this.propStore.findIndex(el => el[pos] === id) >= 0) {
                this.propStore[this.propStore.findIndex(el => el[pos] === id)] = elem
            } else {
                this.propStore.push(elem)
            }
        } else {
            if(this.propStore.findIndex(el => el[type] === id) >= 0) {
                this.propStore[this.propStore.findIndex(el => el[type] === id)] = elem
            } else {
                this.propStore.push(elem)
            }
        }
    }

    getPropertiesByCategory(categoryID) {
        request('get', `/product/filterbycategory/${categoryID}?`)
            .then(success => {
                console.log(success)
                this.product = success
            })
            .catch(error => console.log(error))
    }

    getPropertiesByCategories(categories) {
        let uri = `props/filter?`

        categories && Array.isArray(categories) && categories.map(el => {
            console.log(el)
            uri += `ids[]=${el}&`
        })

        console.log(uri)

        openApi('get', uri)
            .then(success => {
                console.log(success)
                this.product = success.data
            })
            .catch(error => console.log(error.response))
    }

    deleteValue(id) {
        this.property.values = this.property.values.filter(el => el.id !== id)
        this.propDelete.push(id)
    }

}

export default new propertiesController()