import { observer } from 'mobx-react-lite'
import i from '../../../translations/i'
import { Titles } from '../../../layouts/Titles'
import React, { useEffect } from 'react'
import controller from './controllers/importStatistic.controller'
import { Table, Tag } from 'antd'
import dateTimeHelper from '../../../helpers/dateTime.helper'

export const ImportStatistic = observer(() => {

    useEffect(() => {
        controller.get()
    }, [])

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: i.t('def:status'),
            key: 'status',
            render: (record: any) => <Tag color={record.error ? 'error' : 'success'}>{record.error ? i.t('def:errors.error') : i.t('def:success.success')}</Tag>
        },
        {
            title: i.t('settings:sbis.category'),
            dataIndex: 'countcategory',
            key: 'countcategory'
        },
        {
            title: i.t('settings:sbis.product'),
            dataIndex: 'countproduct',
            key: 'countproduct'
        },
        {
            title: i.t('settings:sbis.date'),
            dataIndex: 'date',
            key: 'date',
            render: (item: any) => dateTimeHelper.getFullDate(item)
        }
    ]

    return(
        <>
            <Titles
                title={i.t('settings:sbis.last')}
                breadcrumb={[
                    { title: i.t('def:settings'), href: '/settings' },
                    { title: i.t('settings:sbis.last') },
                ]}
            />

            <Table
                columns={columns}
                dataSource={controller.list}
                pagination={false}
            />
        </>
    )
})