const emailIcon = <svg width="21" height="15" viewBox="0 0 21 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.4" d="M19.0346 1.54771L10.9026 8.80878C10.5508 9.12151 10.0206 9.12151 9.6688 8.80878L1.54046 1.54046M7.83552 7.16448L1.61844 13.5339M19.0292 13.4577L12.7341 7.16267M2.85714 14H17.7143C18.74 14 19.5714 13.1685 19.5714 12.1429V2.85714C19.5714 1.83147 18.74 1 17.7143 1H2.85714C1.83147 1 1 1.83147 1 2.85714V12.1429C1 13.1685 1.83147 14 2.85714 14Z" stroke="#9D9EA9" strokeWidth="1.4"/></svg>
const profileIcon = <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.4" d="M12.2513 11.2857C12.513 11.4279 16.4286 12.428 16.4286 14.8161C16.4286 16.3862 15.6766 17.8135 14.1722 18.2626C12.8724 18.6507 11.0378 19 8.71428 19C6.39071 19 4.55613 18.6508 3.2564 18.2627C1.75205 17.8135 1 16.3861 1 14.8161C1 12.4232 4.91822 11.4262 5.17725 11.2857M8.71429 13.8571C11.6084 13.8571 13.8571 9.42771 13.8571 4.89222C13.8571 2.77667 11.5928 1 8.71429 1C5.83573 1 3.57143 2.77667 3.57143 4.89222C3.57143 9.42772 5.82021 13.8571 8.71429 13.8571Z" stroke="#9D9EA9" strokeWidth="1.4"/></svg>
const passwordLockIcon = <svg width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.4" d="M10.8421 10.0909V4.63636C10.8421 2.62806 12.4445 1 14.4211 1C16.3977 1 18 2.62806 18 4.63636V7.61157M4.57895 15.4755L6.36842 17.3636L9.94737 13.7273M4.57895 21H9.94737C11.924 21 13.5263 19.3719 13.5263 17.3636V11.9091C13.5263 10.9049 12.7251 10.0909 11.7368 10.0909H2.78947C1.80117 10.0909 1 10.9049 1 11.9091V17.3636C1 19.3719 2.60235 21 4.57895 21Z" stroke="#9D9EA9" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/></svg>
const passwordIcon = <svg width="16" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.4" d="M3.85714 9.57143V4.80952C3.85714 2.70558 5.56272 1 7.66667 1C9.77061 1 11.4762 2.70558 11.4762 4.80952V9.57143M3.85714 9.57143H2.90476C1.85279 9.57143 1 10.4242 1 11.4762V17.1905C1 19.2944 2.70558 21 4.80952 21H10.5238C12.6278 21 14.3333 19.2944 14.3333 17.1905V11.4762C14.3333 10.4242 13.4805 9.57143 12.4286 9.57143H11.4762M3.85714 9.57143H11.4762M8.61905 15.2857C8.61905 15.8117 8.19265 16.2381 7.66667 16.2381C7.14068 16.2381 6.71429 15.8117 6.71429 15.2857C6.71429 14.7597 7.14068 14.3333 7.66667 14.3333C8.19265 14.3333 8.61905 14.7597 8.61905 15.2857Z" stroke="#9D9EA9" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/></svg>
const errorIcon = <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.83267 4.88896C9.38745 3.98112 9.77104 3.35577 10.1067 2.93097C10.441 2.50777 10.6381 2.40356 10.7767 2.37482C10.924 2.34428 11.076 2.34428 11.2233 2.37482C11.3619 2.40356 11.559 2.50777 11.8933 2.93097C12.229 3.35577 12.6125 3.98112 13.1673 4.88896L18.3902 13.4355C18.9874 14.4128 19.4006 15.0912 19.6427 15.6212C19.8858 16.1536 19.8837 16.3879 19.8416 16.5242C19.7957 16.6733 19.7185 16.8109 19.6153 16.9278C19.5208 17.0348 19.322 17.1588 18.741 17.2289C18.1625 17.2988 17.3682 17.3 16.2229 17.3H5.7771C4.63181 17.3 3.83749 17.2988 3.25898 17.2289C2.678 17.1588 2.47915 17.0348 2.38474 16.9278C2.28151 16.8109 2.20435 16.6733 2.15837 16.5242C2.11631 16.3879 2.11424 16.1536 2.35734 15.6212C2.59941 15.0912 3.01255 14.4128 3.60977 13.4355L8.83267 4.88896Z" stroke="#FC8B8A" strokeWidth="1.4"/><path d="M11.7 8C11.7 7.6134 11.3866 7.3 11 7.3C10.6134 7.3 10.3 7.6134 10.3 8H11.7ZM10.3 12C10.3 12.3866 10.6134 12.7 11 12.7C11.3866 12.7 11.7 12.3866 11.7 12H10.3ZM10.3 8V12H11.7V8H10.3Z" fill="#FC8B8A"/><path d="M11.7008 14.3016C11.7008 14.6882 11.3874 15.0016 11.0008 15.0016C10.6142 15.0016 10.3008 14.6882 10.3008 14.3016C10.3008 13.915 10.6142 13.6016 11.0008 13.6016C11.3874 13.6016 11.7008 13.915 11.7008 14.3016Z" fill="#FC8B8A"/></svg>
const iconRefresh = <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.1801 9.23122V12.6598M1.1801 9.23122C2.06548 11.44 4.22634 13 6.75153 13C9.46955 13 11.7655 11.1927 12.5031 8.71429M1.1801 9.23122H4.60867M12.4311 5.06039L12.4311 1.63181M12.4311 5.06039H9.00252M12.4311 5.06039C11.6247 2.69851 9.38648 1 6.75153 1C4.03352 1 1.73761 2.80729 1 5.28571" stroke="#7098E4" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/></svg>

export const UserIcon = (name: string) => {
    switch (name) {
        case 'email':
            return emailIcon
        case 'profile':
            return profileIcon
        case 'password-lock':
            return passwordLockIcon
        case 'password':
            return passwordIcon
        case 'refresh':
            return iconRefresh
        case 'error':
            return errorIcon
    }
}