import { makeAutoObservable } from "mobx"
import {openApi} from "../../../helpers/request";
import {FormEvent} from "react";
import {notification} from "antd";
import i from "../../../translations/i";

class OrderStatusController {

    list: any[] = []
    status: any = {
        id: 0,
        name: '',
        color_hex: '7264D6',
        icon: '',
        id_crm: '',
        name_button_enter: '',
        name_button_complete: '',
        is_initial: false,
        is_completed: false,
        is_canceled: false
    }

    pagination = {
        total: 0,
        limit: 1000,
        page: 1
    }

    constructor() {
        makeAutoObservable(this)
    }

    getList() {
        openApi('get', `/order_status?limit=${this.pagination.limit}&page=${this.pagination.page}`)
            .then(result => {
                console.log(result)
                this.pagination.total = result.total
                this.pagination.limit = result.limit
                this.pagination.page = result.page
                this.list = result.data
            })
            .catch(error => console.log(error.response.data))
    }

    setStatus(e: FormEvent) {
        e.preventDefault()

        const data = new FormData()
        data.append('name', this.status.name)
        data.append('color_hex', this.status.color_hex)
        data.append('icon', this.status.icon)
        data.append('id_crm', this.status.id_crm)
        data.append('name_button_enter', this.status.name_button_enter)
        data.append('name_button_complete', this.status.name_button_complete)
        data.append('is_initial',`${+ this.status.is_initial}`)
        data.append('is_completed', `${+ this.status.is_completed}`)
        data.append('is_canceled', `${+ this.status.is_canceled}`)

        let url = `/order_status`
        if(this.status.id && this.status.id > 0) url += `/${this.status.id}`

        openApi('post', url, data)
            .then(result => {
                this.clear()
                this.getList()
            })
            .catch(error => {
                console.log(error.response)
            })

    }

    selectStatus(status_id: number) {
        this.status = this.list.find((el: any) => el.id === status_id)
    }

    onChangeStatus(key: string, value: any) {
        this.status[key] = value
    }

    deleteStatus(status_id: number) {
        openApi('delete', `/order_status/${status_id}`)
            .then(result => {
                this.clear()
                this.getList()
            })
            .catch(error => {
                console.log(error.response.data.data.message)
                notification.error({
                    message: i.t('def:errors.error'),
                    description: error.response.data.data.message
                })
            })
    }

    clear() {
        this.status = {
            id: 0,
            name: '',
            color_hex: '7264D6',
            icon: '',
            id_crm: '',
            name_button_enter: '',
            name_button_complete: '',
            is_initial: false,
            is_completed: false,
            is_canceled: false
        }
    }

    create() {
        this.clear()
        this.list.unshift(this.status)
    }

}

export default new OrderStatusController()