import { makeObservable, observable, runInAction } from 'mobx'
import { FormEvent } from 'react'
import interval from '../../../../modules/shop/delivery/controllers/interval.controller'
import { notification } from 'antd'
import i from '../../../../translations/i'
import { request, openApi } from '../../../../helpers/request'

class __delivery {

    delivery: any = {
        delivery: {
            pickup: [],
            typepay: [],
        },
        places: [],
        list_pay: [],
    }

    constructor() {
        makeObservable(this, {
            delivery: observable,
        })
    }

    get() {
        interval.getInterval()

        openApi('get', '/shop/delivery')
            .then(result => {
                console.log(result)
                result = result.data
                this.delivery = result
                this.delivery.yesPoints = result.delivery.pickup.length
            })
    }

    save(e: FormEvent) {
        e.preventDefault()

        if((interval.interval.viewintervaldelivery && interval.interval.timeintervalpickup <= 0) || (interval.interval.viewintervalpickup && interval.interval.timeinterval <= 0)) {
            notification.error({
                placement: "topRight",
                message: i.t('def:errors.error'),
                description: i.t('def:errors.intervals'),
            })
            return false
        }

        let data = new FormData()
        data.append('yesPoints', `${+ this.delivery.yesPoints}`)
        data.append('pickup', JSON.stringify(this.delivery.delivery.pickup))
        data.append('typepay', JSON.stringify(this.delivery.delivery.typepay))
        data.append('delivery', `${+ this.delivery.delivery.delivery}`)

        if(this.delivery.delivery.deliveryname)
            data.append('deliveryname', this.delivery.delivery.deliveryname)

        data.append('costdelivery', this.delivery.delivery.costdelivery)
        data.append('costorder', this.delivery.delivery.costorder)
        data.append('typedelivery', this.delivery.delivery.typedelivery)

        interval.onSubmit()

        openApi('post', '/shop/delivery', data)
            .then(response => {
                notification.success({
                    placement: "topRight",
                    message: i.t('delivery:success'),
                    description: i.t('delivery:settingSuccess'),
                });
                this.get()
            })
    }

    selectPickup(e: any) {
        let arr: any[] = []
        e.map((item: any) => {
            this.delivery.points.map((element: any) => {
                if(element.id === item) {
                    arr = [...arr, element];
                }
            });
        })

        this.delivery.pickup = arr
    }

    selectPay(e: any) {
        runInAction(() => this.delivery.delivery.typepay = e)
    }

    checkDelivery(e: any) {
        this.delivery.selfDelivery = e.target.checked
    }

    setPickup(id: number) {
        if(this.delivery.delivery.pickup.findIndex((el: number) => el === id) > -1)
            runInAction(() => {
                this.delivery.delivery.pickup = this.delivery.delivery.pickup.filter((el: number) => el !== id)
            })
        else runInAction(() => this.delivery.delivery.pickup.push(id))
    }

}

export default new __delivery()