import { observer } from "mobx-react-lite";
import i from "../../../translations/i";
import { Titles } from "../../../layouts/Titles";
import controller from './discount.controller'
import { Badge, Button, Space, Table, Tag } from "antd";
import React, { useEffect } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import projectController from "../project/project.controller";
import { returnParseProjectElement } from "../../../helpers/project.controller";

const getValue = (amount: Number, measurement: Number) => {
  if(measurement === 0) {
    return `${amount}%`
  } else {
    return `${amount} ${returnParseProjectElement('CURRENCY_CODE')}`
  }
}

export const DiscountList = observer(() => {

  useEffect(() => {
    controller.getList()
  }, [])

  const buttons = [
    { title: i.t('def:actions.create'), href: `/settings/discount/0`, type: 'primary' }
  ]

  const columns = [
    {
      title: '',
      dataIndex: 'enabled',
      key: 'enabled',
      render: (enabled: boolean) => (
        <Badge
          status={enabled ? 'success' : 'error'}
        />
      )
    },
    { title: 'ID', dataIndex: 'id', key: 'id' },
    { title: i.t('def:name'), dataIndex: 'name', key: 'name' },
    { title: i.t('def:Discount amount'), render: (el: any) => getValue(el.amount, el.measurement)},
    { title: i.t('def:Discount purpose'), dataIndex: 'purpose', key: 'purpose', render: (el: any) => i.t(`def:purposes.${el}`)},
    {
      key: 'action',
      render: (text: any, record: any) => (
        <Space size="middle">
          <Button
            type="dashed"
            shape="circle"
            icon={<EditOutlined />}
            href={`/settings/discount/${record.id}`}
          />
          <Button
            type="dashed"
            shape="circle"
            danger
            icon={<DeleteOutlined />}
            onClick={() => controller.delete(record.id)}
          />
        </Space>
      )
    }
  ]

  return(
    <>
      <Titles
        title={i.t('def:List of discounts')}
        buttons={buttons}
      />

      <Table
        columns={columns}
        dataSource={controller.list}
        pagination={false}
      />
    </>
  )
})