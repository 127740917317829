import { makeAutoObservable } from "mobx"
import { request, openApi } from '../../../../../helpers/request'
import __multiple from '../../../../../helpers/multiple_actions/__multiple'
import i from '../../../../../translations/i'

class productsWithoutCategoryController {

    constructor() {
        makeAutoObservable(this)
    }

    limit = 10
    offset = 0
    data = []
    count = 0
    pagination = {
        page: 1
    }

    setActiveProduct(productID, view) {
        const data = new FormData()
        data.append('view', `${+ view}`)

        openApi('post', `/shop/product/active/${productID}`, data)
            .then(() => {
                this.getProducts()
            })
    }

    getActionsProducts() {
        return [
            { label: i.t('def:actions.remove'), value: 'delete', action: () => this.deleteProducts() }
        ]
    }

    deleteProducts() {
        const data = new FormData()

        __multiple.selected.map(el => data.append('elements[]', `${el}`))

        openApi('post', '/shop/product/delete', data)
            .then(result => {
                console.log(result)
                this.getProducts()
                __multiple.clear()
            })
    }

    getProducts() {
        __multiple.setActions(this.getActionsProducts())

        request('get', `/product/product/0?limit=${this.limit}&offset=${this.offset}`)
            .then(result => {
                console.log(result)
                if(Array.isArray(result)) {
                    this.data = result[0]
                    this.count = result[1]
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    setPage(page) {
        this.offset = page > 1 ? (page * this.limit) - this.limit : 0
        this.pagination.page = page
        // this.offset = (page * this.limit) - this.limit
        this.getProducts()
    }
}

export default new productsWithoutCategoryController()