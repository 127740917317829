import { storeLocal, storeSession } from './stores'
import { getUnits } from './project.controller'
import userController from '../front/user/controllers/user.controller'

/**
 * Обработчик для авторизованного пользователя
 *
 * @param uri - URL текущей страницы
 */
const authMiddleware = async (uri: string) => {
    const auth = await userController.decrypt('user')

    console.log(auth)

    if(auth.enabled === 1) {
        if(storeSession.get('forgot_step') && uri !== '/forgot') {
            return { status: false, redirect: '/forgot' }
        } else if(!auth.setting_id) {

            if(uri !== '/select') {
                return { status: false, redirect: '/select' }
            }
        } else {
            // if (uri === '/wait') {
            //     return { status: false, redirect: '/' }
            // }
            if (!storeSession.get('project_units')) await getUnits()
        }
    } else {

        const middle = await RouterLoginMiddleware(uri)
        if(middle) return middle

    }

    return { status: true }
}

/**
 * Обработчик для неавторизованного пользователя
 *
 * @param uri - URL текущей страницы
 */
const RouterLoginMiddleware = async (uri: string) => {
    if(storeSession.get('verification_email')) {
        if(uri !== '/verify') {
            return { status: false, redirect: '/verify' }
        } else {
            return { status: true }
        }
    }

    if(uri === '/login') {
        return { status: true }
    } else if(uri === '/register') {
        return { status: true }
    } else if(uri === '/forgot') {
        return { status: true }
    } else {
        return { status: false, redirect: '/login' }
    }
}

export const middleware = async (uri: string) => {
    if (storeLocal.get('auth'))
        return await authMiddleware(uri)
    else
        return await RouterLoginMiddleware(uri)
}

export const utmMiddleware = async (uri: string) => {

    const url = new URL(uri)

    const typeParams = ['utm_referrer', 'fbclid', 'yclid', 'gclid', 'gclientid', 'openstat_source', 'openstat_ad', 'openstat_campaign',
        'openstat_service', 'referrer', '_ym_counter', '_ym_uid', 'from', 'utm_content', 'utm_term', 'utm_campaign', 'utm_medium', 'utm_source']

    let service_utm = {}
    if(storeLocal.get('service_utm'))
        service_utm = storeLocal.get('service_utm')

    typeParams.map((el: any) => {
        // @ts-ignore
        if(el in service_utm && service_utm.el !== '') {

        } else {
            if(url.searchParams.get(el))
                service_utm = { ...service_utm, [el]: url.searchParams.get(el) }
        }
    })

    storeLocal.set('service_utm', service_utm)
}
