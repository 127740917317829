import { observer } from 'mobx-react-lite'
import i from '../../../../translations/i'
import { Titles } from '../../../../layouts/Titles'
import __delivery from './__delivery'
import { Checkbox, Col, Input, InputNumber, Select, Row, Divider, Space, Button, Typography, Radio, Switch, TimePicker, Segmented } from 'antd'
import interval from '../../../../modules/shop/delivery/controllers/interval.controller'
import { useEffect } from 'react'
import dayjs from 'dayjs'
import { runInAction } from 'mobx'

export const SettingDeliveryBase = observer(() => {

    useEffect(() => {
        return () => {
            __delivery.get()
        }
    }, [])

    return(
        <>
            <form onSubmit={(e) => __delivery.save(e)}>
                <Titles
                    title={i.t('delivery:edit')}
                    buttons={[
                        { title: i.t('def:actions.save'), type: 'primary', htmlType: 'submit' }
                    ]}
                    breadcrumb={[
                        {title: i.t('def:settings'), href: '/settings'},
                        {title: i.t('delivery:edit')},
                    ]}
                />

                <Row justify="space-around" gutter={[20, 20]}>
                    <Col span={16}>
                        <Typography.Title level={4}>{i.t('delivery:pickup')}</Typography.Title>
                    </Col>
                    <Col span={8} className="right">
                        <Switch
                            checkedChildren={i.t('delivery:pickupOn')}
                            unCheckedChildren={i.t('delivery:pickupOff')}
                            onChange={e => __delivery.delivery.yesPoints = e}
                            checked={__delivery.delivery.yesPoints}
                        />
                    </Col>
                    <Col span={24}>
                        <Space wrap={true}>
                            {__delivery.delivery.places.map((el: any, index: number) => (
                                <Button
                                    key={`places_${index}`}
                                    size="small"
                                    type={__delivery.delivery.delivery.pickup.findIndex((place_id: number) => place_id === el.id) > -1 ? 'primary' : 'dashed'}
                                    onClick={() => __delivery.setPickup(el.id)}
                                >
                                    {el.name}
                                </Button>
                            ))}
                        </Space>
                    </Col>
                </Row>

                <Divider />

                <Row justify="space-around" align="middle" gutter={[20, 20]}>
                    <Col span={24}>
                        <Typography.Title level={4}>{i.t('delivery:pay')}</Typography.Title>
                    </Col>
                    <Col span={24}>
                        <Select
                            mode="multiple"
                            placeholder={i.t('delivery:selectPay')}
                            style={{ width: '100%' }}
                            onChange={(e: any) => __delivery.selectPay(e)}
                            value={__delivery.delivery.delivery.typepay}
                            options={__delivery.delivery.list_pay.map((item: any, index: number) => ({label: item.name, value: item.id, key: `select_option_${index}`}))}
                        />
                    </Col>
                </Row>

                <Divider />

                <Row justify="space-around" align="middle" gutter={[20, 20]}>
                    <Col span={16}>
                        <Typography.Title level={4}>{i.t('delivery:myDelivery')}</Typography.Title>
                    </Col>
                    <Col span={8} className="right">
                        <Switch
                            checkedChildren={i.t('delivery:on')}
                            unCheckedChildren={i.t('delivery:off')}
                            onChange={e => __delivery.delivery.delivery.delivery = e ? 1 : 0}
                            checked={__delivery.delivery.delivery.delivery}
                        />
                    </Col>
                    <Col span={24}>
                        <Input
                            value={__delivery.delivery.delivery.deliveryname}
                            onChange={(e) => runInAction(() => __delivery.delivery.delivery.deliveryname = e.target.value)}
                            disabled={__delivery.delivery.delivery.delivery < 1}
                        />
                    </Col>
                </Row>

                <Divider />

                {__delivery.delivery.delivery.delivery &&
                    <>
                        <Row justify="space-around" align="middle" gutter={[20, 20]}>
                            <Col span={24}>
                                <Typography.Title level={4}>{i.t('delivery:payMyDelivery')}</Typography.Title>
                            </Col>
                            <Col span={24}>
                                <div className="p-field-checkbox">
                                    <Segmented
                                        options={[
                                            {label: i.t('delivery:price'), value: 1},
                                            {label: i.t('delivery:zone'), value: 2},
                                        ]}
                                        value={__delivery.delivery.delivery.typedelivery}
                                        onChange={(e) => runInAction(() => __delivery.delivery.delivery.typedelivery = e)}
                                    />
                                </div>
                            </Col>
                            <Col span={12}>
                                <label className="label">{i.t('delivery:minPrice')}</label>
                                <InputNumber
                                    style={{ width: `100%` }}
                                    value={__delivery.delivery.delivery.costorder}
                                    onChange={(e) => runInAction(() => __delivery.delivery.delivery.costorder = e)}
                                />
                            </Col>
                            <Col span={12}>
                                {__delivery.delivery.delivery.typedelivery === 1 &&
                                    <>
                                        <label className="label">{i.t('delivery:payDelivery')}</label>
                                        <Input
                                            style={{ width: `100%` }}
                                            value={__delivery.delivery.delivery.costdelivery}
                                            onChange={(e) => runInAction(() => __delivery.delivery.delivery.costdelivery = e.target.value)}
                                        />
                                    </>
                                }
                            </Col>
                        </Row>
                    </>
                }
            </form>
        </>
    )
})