import { observer } from 'mobx-react-lite'
import { config } from '../config/app'

import { Row, Col, Space, Button, Tooltip, Dropdown } from 'antd'
import { LayoutInterface, UserAuthInterface } from './layout.interfaces'
import { Navigation } from './Navigation'
import { storeLocal, storeSession } from '../helpers/stores'
import { getNameLanguageFromCode, setLanguage } from '../helpers/language.helper'
import controller from './controllers/layout.controller'
import { LogoutOutlined, MenuOutlined, CloseOutlined } from '@ant-design/icons'
import i from '../translations/i'

import '../assets/styles/default.less'
import './style/header.less'
import './style/auth.less'
import { useEffect } from 'react'
import layoutController from './controllers/layout.controller'
import { Spinner } from '../helpers/spin/Spin'
import type { MenuProps } from 'antd'
import { MultipleActions } from '../helpers/multiple_actions/MultipleActions'
import io from 'socket.io-client'
import { utmMiddleware } from '../helpers/middleware.helper'

const items: MenuProps['items'] = config.languages.map((el, index) => ({ key: `select_language_${index}`, label: getNameLanguageFromCode(el), onClick: () => setLanguage(el) }))

const Header = observer(({ auth, logout }: UserAuthInterface) => {

    return(
        <>
            <header style={{ backgroundColor: layoutController.config.token.colorPrimary}} >
                <div className="default">
                    <Row justify="space-around" align="middle">
                        {auth &&
                            <Col xs={{ span: 2 }} md={{ span: 0 }}>
                                <Button
                                    icon={<MenuOutlined />}
                                    className="mobile"
                                    onClick={() => controller.setMobile()}
                                />
                            </Col>
                        }
                        <Col xs={{ span: 12 }} md={{ span: 12 }} className="logo">
                            <a href='/'>
                                <img src={layoutController.wl.logo_cloud_white} alt='' />
                            </a>
                        </Col>
                        <Col xs={{ span: 10 }} md={{ span: 12 }} className="user">
                            <Space>
                                {auth &&
                                    <Col xs={{ span: 0 }} md={{ span: 24 }}>
                                        <p className="name">
                                            {layoutController.user.firstname
                                                ? `${layoutController.user.firstname} ${layoutController.user.lastname}`
                                                : ''
                                            }
                                        </p>
                                    </Col>
                                }
                                {auth
                                    ? <Col xs={{ span: 0 }} md={{ span: 12 }}>
                                        <Button style={{ background: 'transparent', color: 'white' }} type="dashed" href="/select">{i.t('def:myProjects')}</Button>
                                    </Col>
                                    : <></>
                                }
                                <Dropdown menu={{ items }} trigger={['click']}>
                                    <Button type="dashed" shape="circle" style={{ background: 'transparent', color: 'white' }} className="lang-text">
                                        {storeLocal.get('lang') ? storeLocal.get('lang') : config.defaultLanguage}
                                    </Button>
                                </Dropdown>
                                {logout &&
                                    <Tooltip placement="bottomRight" title={i.t('def:actions.logout')}>
                                        <Button
                                            type="dashed"
                                            shape="circle"
                                            style={{ background: 'transparent', color: 'white' }}
                                            onClick={() => controller.logout()}
                                        >
                                            <LogoutOutlined />
                                        </Button>
                                    </Tooltip>
                                }
                            </Space>
                        </Col>
                    </Row>
                </div>
            </header>
        </>
    )
})

const AuthorizedUserLayout = observer(({ content, logout = false }: LayoutInterface) => {

    const socket = io(config.socket)

    useEffect(() => {
        socket.on('connect', () => {

        })

        socket.on('disconnect', () => {
            console.log('disconnect')
        })

        if(storeLocal.get('bundle'))
            socket.on(`s_${storeLocal.get('bundle')}`, (data: any) => {
                controller.openNotification(data)
            })

    }, [])

    return(
        <>
            <Header auth={true} logout={true} />

            {controller.mobile && (
                <div className='mobile-nav'>
                    <Button
                        icon={<CloseOutlined />}
                        type="link"
                        onClick={() => controller.setMobile()}
                        className="close"
                    />
                    <Navigation />
                </div>
            )}

            <div className="default">
                <Row>
                    <Col xs={{ span: 0 }} md={{ span: 5 }}>
                        <Navigation />
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 19 }} className="default-content">
                        <Spinner />
                        { content }
                    </Col>
                </Row>

                <MultipleActions />
            </div>
        </>
    )
})

const UnauthorizedUserLayout = observer(({ content, logout = false }: LayoutInterface) => {
    return(
        <>
            <Spinner />
            <Header auth={false} logout={true} />
            <Row justify="space-around" align="middle" className='auth'>
                <Col span={24}>
                    <div>
                        { content }
                    </div>
                </Col>
            </Row>
        </>
    )
})

export const Layout = observer(({ content }: LayoutInterface) => {

    useEffect(() => {
        if(!storeLocal.get('service_utm'))
            utmMiddleware(window.location.href)

        if(storeLocal.get('bundle')) {
            layoutController.getProject()
            layoutController.getMarkers()
        }
    }, [])

    return layoutController.project && layoutController.project.bandl && !storeSession.get('forgot_step')
        ? <AuthorizedUserLayout content={content} logout={true} />
        : <UnauthorizedUserLayout content={content} logout={layoutController.project.bandl !== ''} />
})


