import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { BranchesController } from './branchesController'
import { Button, Space, Table, Tooltip } from 'antd'
import i from '../../../translations/i'
import { DeleteOutlined, SettingOutlined } from '@ant-design/icons'
import { Titles } from '../../../layouts/Titles'

const controller = new BranchesController()

export const Branches = observer(() => {

    useEffect(() => {
        return () => {
            controller.get()
        }
    }, [])

    const columns = [
        {
            title: i.t('points:addressPoint'),
            dataIndex: 'address',
            key: 'address'
        },
        {
            title: i.t('points:actions'),
            key: 'action',
            render: (text: any, record: any) => (
                <>
                    <Space>
                        <Tooltip title={i.t('points:edit')}>
                            <Button type="dashed" shape="circle" icon={<SettingOutlined />} href={`/shop/branches/${record.id}`} />
                        </Tooltip>
                    </Space>
                </>
            ),
        },
    ]

    return(
        <>
            <Titles
                title={i.t('status:statuses')}
            />

            <Table columns={columns} dataSource={controller.list} rowKey="id" pagination={false} />
        </>
    )
})

export const Branch = observer(() => {

    return(
        <>

        </>
    )
})