import { observer } from 'mobx-react-lite'
import i from '../../../translations/i'
import controller from './controllers/export.controller'
import { Titles } from '../../../layouts/Titles'
import { Tabs } from 'antd'
import { ExportXML } from './modules/xml/ExportXML'

export const Export = observer(() => {

    const buttons = [
        { title: i.t('def:actions.save'), htmlType: `submit`, type: 'primary' }
    ]

    return(
        <form onSubmit={(e) => controller.save(e)}>
            <Titles
                title={i.t('export:title')}
                buttons={buttons}
            />

            <Tabs>
                <Tabs.TabPane tab={i.t('export:tabTitles.xml')} key="xml">
                    <ExportXML />
                </Tabs.TabPane>
            </Tabs>
        </form>
    )
})