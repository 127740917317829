import { observer } from 'mobx-react-lite'
import i from '../../../../translations/i'
import { Titles } from '../../../../layouts/Titles'
import {
    Alert,
    Row,
    Col,
    Button,
    Divider,
    Modal,
    Tabs,
    InputNumber,
    Switch,
    TimePicker,
    Space,
    Table,
    Badge,
    Tag, Select, DatePicker
} from 'antd'
import type { TabsProps } from 'antd'
import { DeleteOutlined, SettingOutlined } from '@ant-design/icons'
import controller from './__delivery_intervals'
import { runInAction } from 'mobx'
import dayjs from 'dayjs'
import React, { useEffect } from 'react'
import { ErrorByPricingPlan } from '../../../../components/ErrorByPricingPlan'
import { storeLocal } from '../../../../helpers/stores'
import projectController from '../../project/project.controller'

export const SettingDeliveryIntervals = observer(() => {
    useEffect(() => {
        projectController.get()
        controller.getListIntervals()
        controller.getSettings()
    }, [])

    const items: TabsProps['items'] = [
        {
            key: 'list',
            label: i.t('def:_intervals.intervals'),
            children: <DeliveryIntervalList />,
        },
        {
            key: 'default',
            label: i.t('def:_intervals.default'),
            children: <DeliveryIntervalsSettings />,
        },
        {
            key: 'not_working_days',
            label: i.t('def:_intervals.not_working_days'),
            children: <CalendarIntervals />,
        },
    ]



    return(
        <>
            <Titles
                title={i.t('def:_intervals.title')}
                breadcrumb={[ { title: i.t('def:settings'), href: '/settings' }, { title: i.t('def:_intervals.title') } ]}
            />

            <Row gutter={[20, 20]}>
                <Col xs={24} className="switch-block">
                    <form onSubmit={(e) => projectController.save(e)}>
                        <Row gutter={[20, 20]}>
                            <Col span={24}>
                                <Space>
                                    <div className="element">
                                        <div></div>
                                        <Switch
                                            checked={projectController.project.delivery_intervals_enabled}
                                            onChange={e => projectController.set('delivery_intervals_enabled', e)}
                                            size="small"
                                        />
                                        <div>
                                            <span>{i.t('settings:project.delivery_intervals_enabled')}</span>
                                        </div>
                                    </div>

                                    <div className="element">
                                        <div></div>
                                        <Switch
                                            checked={projectController.project.pickup_intervals_enabled}
                                            onChange={e => projectController.set('pickup_intervals_enabled', e)}
                                            size="small"
                                        />
                                        <div>
                                            <span>{i.t('settings:project.pickup_intervals_enabled')}</span>
                                        </div>
                                    </div>

                                    <div className="element">
                                        <div></div>
                                        <Switch
                                            checked={projectController.project.express_delivery_intervals_enabled}
                                            onChange={e => projectController.set('express_delivery_intervals_enabled', e)}
                                            size="small"
                                        />
                                        <div>
                                            <span>{i.t('settings:project.express_delivery_intervals_enabled')}</span>
                                        </div>
                                    </div>
                                </Space>
                            </Col>
                            <Col span={24}>
                                <Button htmlType="submit" type="primary">{i.t('def:actions.save')}</Button>
                            </Col>
                        </Row>
                    </form>
                </Col>
                <Col span={24}>
                    <Tabs
                        defaultActiveKey="list"
                        items={items}
                    />
                </Col>
            </Row>
        </>
    )
})

const DeliveryIntervalsSettings = observer(() => {

    return(
        <>
            <Row gutter={[20, 20]}>
                {storeLocal.get('pricing_plan') < 1
                    ? <Col span={24}><Alert message={i.t('def:errors.pricing_plan')} type="warning" /></Col>
                    : <></>
                }

                <Col span={24} className="right">
                    <ErrorByPricingPlan
                        plan={1}
                        content={<>
                            <Button type="primary" onClick={() => controller.save()} disabled={storeLocal.get('pricing_plan') < 1}>{i.t('def:actions.save')}</Button>
                        </>}
                    />
                </Col>
                {controller.days.map((el: any, index: number) => (
                    <>
                        <Col span={24}>
                            <Row gutter={[20, 20]} className="interval_day">
                                <Col span={24}>
                                    <p className="interval_day_name">{controller.getTitleDay(el.day)}</p>
                                </Col>
                                <Col span={24}>
                                    <p className="interval_day_type">{i.t('def:_intervals.delivery')}</p>

                                    <Row gutter={[15, 15]} justify="space-between" align="bottom">
                                        {el.delivery.map((delivery: any, i_index: number) => (
                                            <>
                                                <Col span={8}>
                                                    <label>{i.t('def:_intervals.selectInterval')}</label>
                                                    <Select
                                                        style={{width: '100%'}}
                                                        value={delivery.intervals}
                                                        options={controller.intervals.filter(int => int.type === 'delivery' && !int.express).map((e: any) => ({label: `(ID ${e.id}) ${dayjs(e.time_start, 'HH:mm').format('HH:mm')} - ${dayjs(e.time_end, 'HH:mm').format('HH:mm')}`, value: e.id}))}
                                                        onChange={(e) => controller.days[index].delivery[i_index].intervals = e}
                                                        mode="multiple"
                                                    />
                                                </Col>
                                                <Col span={8}>
                                                    <label>{i.t('def:_intervals.selectZones')}</label>
                                                    <Select
                                                        style={{width: '100%'}}
                                                        value={delivery.zones}
                                                        options={controller.zones.map((e: any) => ({label: e.name, value: e.id}))}
                                                        onChange={(e) => controller.days[index].delivery[i_index].zones = e}
                                                        mode="multiple"
                                                    />
                                                </Col>
                                                <Col span={4}>
                                                    <label>{i.t('def:_intervals.deliveryPrice')}</label>
                                                    <InputNumber
                                                        style={{width: '100%'}}
                                                        value={delivery.delivery_price}
                                                        onChange={(e) => controller.days[index].delivery[i_index].delivery_price = e}
                                                    />
                                                </Col>
                                                <Col span={4}>
                                                    <Space>
                                                        <Button
                                                            type="dashed"
                                                            icon={<DeleteOutlined />}
                                                            onClick={() => controller.deleteCondition('delivery', index, i_index)}
                                                            danger
                                                        />
                                                    </Space>
                                                </Col>
                                            </>
                                        ))}
                                        <Col span={24}>
                                            <Button type="dashed" onClick={() => controller.addIfElse('delivery', index)}>{i.t('def:actions.add')}</Button>
                                        </Col>
                                    </Row>
                                </Col>
                                <Divider />
                                <Col span={24}>
                                    <p className="interval_day_type">{i.t('def:_intervals.pickup')}</p>
                                    <Row gutter={[15, 15]} justify="space-between" align="bottom">
                                        {el.pickup.map((element: any, i_index: number) => (
                                            <>
                                                <Col span={8}>
                                                    <label>{i.t('def:_intervals.selectInterval')}</label>
                                                    <Select
                                                        style={{width: '100%'}}
                                                        value={element.intervals}
                                                        options={controller.intervals.filter(int => int.type === 'pickup' && !int.express).map((e: any) => ({label: `(ID ${e.id}) ${dayjs(e.time_start, 'HH:mm').format('HH:mm')} - ${dayjs(e.time_end, 'HH:mm').format('HH:mm')}`, value: e.id}))}
                                                        onChange={(e) => controller.days[index].pickup[i_index].intervals = e}
                                                        mode="multiple"
                                                    />
                                                </Col>
                                                <Col span={8}>
                                                    <label>{i.t('def:_intervals.selectPlaces')}</label>
                                                    <Select
                                                        style={{width: '100%'}}
                                                        value={element.places}
                                                        options={controller.places.map((e: any) => ({label: e.name, value: e.id}))}
                                                        onChange={(e) => controller.days[index].pickup[i_index].places = e}
                                                        mode="multiple"
                                                    />
                                                </Col>
                                                <Col span={4}>
                                                    <label>{i.t('def:_intervals.deliveryPrice')}</label>
                                                    <InputNumber
                                                        style={{width: '100%'}}
                                                        value={element.delivery_price}
                                                        onChange={(e) => controller.days[index].pickup[i_index].delivery_price = e}
                                                    />
                                                </Col>
                                                <Col span={4}>
                                                    <Space>
                                                        <Button
                                                            type="dashed"
                                                            icon={<DeleteOutlined />}
                                                            onClick={() => controller.deleteCondition('pickup', index, i_index)}
                                                            danger
                                                        />
                                                    </Space>
                                                </Col>
                                            </>
                                        ))}
                                        <Col span={24}>
                                            <Button onClick={() => controller.addIfElse('pickup', index)}>{i.t('def:actions.add')}</Button>
                                        </Col>
                                    </Row>
                                </Col>
                                <Divider />
                                <Col span={24}>
                                    <p className="interval_day_type">{i.t('def:_intervals.interval.express')}</p>
                                    <Row gutter={[15, 15]} justify="space-between" align="bottom">
                                        {el.express.map((element: any, i_index: number) => (
                                            <>
                                                <Col span={8}>
                                                    <label>{i.t('def:_intervals.selectInterval')}</label>
                                                    <Select
                                                        style={{width: '100%'}}
                                                        value={element.intervals}
                                                        options={controller.intervals.filter(int => int.type === 'delivery' && int.express).map((e: any) => ({label: `(ID ${e.id}) ${dayjs(e.time_start, 'HH:mm').format('HH:mm')} - ${dayjs(e.time_end, 'HH:mm').format('HH:mm')}`, value: e.id}))}
                                                        onChange={(e) => controller.days[index].express[i_index].intervals = e}
                                                        mode="multiple"
                                                    />
                                                </Col>
                                                <Col span={8}>
                                                    <label>{i.t('def:_intervals.selectZones')}</label>
                                                    <Select
                                                        style={{width: '100%'}}
                                                        value={element.zones}
                                                        options={controller.zones.map((e: any) => ({label: e.name, value: e.id}))}
                                                        onChange={(e) => controller.days[index].express[i_index].zones = e}
                                                        mode="multiple"
                                                    />
                                                </Col>
                                                <Col span={4}>
                                                    <label>{i.t('def:_intervals.deliveryPrice')}</label>
                                                    <InputNumber
                                                        style={{width: '100%'}}
                                                        value={element.delivery_price}
                                                        onChange={(e) => controller.days[index].express[i_index].delivery_price = e}
                                                    />
                                                </Col>
                                                <Col span={4}>
                                                    <Space>
                                                        <Button
                                                            type="dashed"
                                                            icon={<DeleteOutlined />}
                                                            onClick={() => controller.deleteCondition('express', index, i_index)}
                                                            danger
                                                        />
                                                    </Space>
                                                </Col>
                                            </>
                                        ))}
                                        <Col span={24}>
                                            <Button onClick={() => controller.addIfElse('express', index)}>{i.t('def:actions.add')}</Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </>
                ))}
            </Row>
        </>
    )
})

const DeliveryInterval = observer(() => {

    return(
        <>
            <Modal
                open={controller.modal}
                footer={null}
                onCancel={() => controller.closeModal()}
                maskClosable={false}
            >
                <form onSubmit={(e) => controller.saveInterval(e)}>
                    <Row gutter={[20, 20]} justify="space-between" align="bottom">
                        <Col span={24}>
                            <h3>{i.t('def:_intervals.interval.title')}</h3>
                        </Col>
                        <Col span={24} className="switch-block">
                            <Row gutter={[10, 10]}>
                                <Col>
                                    <div className="element">
                                        <div>
                                            <Switch
                                                size="small"
                                                checked={controller.interval.enabled}
                                                onChange={(e) => runInAction(() => controller.interval.enabled = e)}
                                            />
                                        </div>
                                        <div>
                                            <span>{i.t('def:_intervals.interval.enabled')}</span>
                                        </div>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="element">
                                        <div>
                                            <Switch
                                                size="small"
                                                checked={controller.interval.express}
                                                onChange={(e) => runInAction(() => controller.interval.express = e)}
                                            />
                                        </div>
                                        <div>
                                            <span>{i.t('def:_intervals.interval.express')}</span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24}>
                            <label>{i.t('def:_intervals.interval.type')}</label>
                            <Select
                                style={{width: '100%'}}
                                options={[
                                    {label: i.t('def:_intervals.interval.types.delivery'), value: 'delivery'},
                                    {label: i.t('def:_intervals.interval.types.pickup'), value: 'pickup'},
                                ]}
                                value={controller.interval.type}
                                onChange={(e: any) => runInAction(() => controller.interval.type = e)}
                            />
                        </Col>
                        <Col span={12}>
                            <label>{i.t('def:_intervals.interval.time_start')}</label>
                            <TimePicker
                                style={{width: '100%'}}
                                onChange={(e, timeString) => {
                                    if(e) { // @ts-ignore
                                        controller.interval.time_start = timeString
                                    }
                                }}
                                format="HH:mm"
                                value={controller.interval.time_start ? dayjs(controller.interval.time_start, 'HH:mm') : null}
                                minuteStep={10}
                            />
                        </Col>
                        <Col span={12}>
                            <label>{i.t('def:_intervals.interval.time_end')}</label>
                            <TimePicker
                                style={{width: '100%'}}
                                onChange={(e, timeString) => runInAction(() => {
                                    // @ts-ignore
                                    controller.interval.time_end = timeString
                                })}
                                format="HH:mm"
                                value={controller.interval.time_end ? dayjs(controller.interval.time_end, 'HH:mm') : null}
                                minuteStep={10}
                            />
                        </Col>
                        <Col span={12}>
                            <label>{i.t('def:_intervals.interval.minimum_order_value')}</label>
                            <InputNumber
                                style={{width: '100%'}}
                                value={controller.interval.minimum_order_value ?? ''}
                                onChange={(e) => runInAction(() => controller.interval.minimum_order_value = e ? e : 0)}
                            />
                        </Col>
                        <Col span={12}>
                            <label>{i.t('def:_intervals.interval.free_cart_value')}</label>
                            <InputNumber
                                style={{width: '100%'}}
                                value={controller.interval.free_cart_value ?? ''}
                                onChange={(e) => runInAction(() => controller.interval.free_cart_value = e ? e : null)}
                            />
                        </Col>
                        <Col span={12}>
                            <label>{i.t('def:_intervals.interval.availability_in_days')}</label>
                            <InputNumber
                                style={{width: '100%'}}
                                value={controller.interval.availability_in_days ?? ''}
                                onChange={(e) => runInAction(() => controller.interval.availability_in_days = e ? e : null)}
                            />
                        </Col>
                        <Col span={12}>
                            <label>{i.t('def:_intervals.interval.assembly_time')}</label>
                            <InputNumber
                                style={{width: '100%'}}
                                value={controller.interval.assembly_time ?? ''}
                                onChange={(e) => runInAction(() => controller.interval.assembly_time = e ? e : 0)}
                            />
                        </Col>
                        <Col span={24}>
                            <label>{i.t('def:_intervals.interval.delivery_price')}</label>
                            <InputNumber
                                style={{width: '100%'}}
                                value={controller.interval.delivery_price ?? ''}
                                onChange={(e) => runInAction(() => controller.interval.delivery_price = e ? e : null)}
                            />
                        </Col>
                        <Divider />
                        {controller.interval.express
                            ? <>
                                <Col span={12}>
                                    <label>{i.t('def:_intervals.interval.max_cart_value')}</label>
                                    <InputNumber
                                        style={{width: '100%'}}
                                        value={controller.interval.max_cart_value ?? ''}
                                        onChange={(e) => runInAction(() => controller.interval.max_cart_value = e ? e : null)}
                                    />
                                </Col>
                                <Col span={12}>
                                    <label>{i.t('def:_intervals.interval.in_what_time')}</label>
                                    <InputNumber
                                        style={{width: '100%'}}
                                        value={controller.interval.in_what_time ?? ''}
                                        onChange={(e) => runInAction(() => controller.interval.in_what_time = e ? e : null)}
                                    />
                                </Col>
                            </>
                            : <></>
                        }
                        <Col span={24}>
                            <Row gutter={[15, 15]}>
                                <Col span={12}>
                                    <Button htmlType="submit" type="primary" block>{i.t('def:actions.save')}</Button>
                                </Col>
                                <Col span={12}>
                                    <Button onClick={() => controller.closeModal()} type="dashed" block>{i.t('def:actions.backList')}</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </form>
            </Modal>
        </>
    )
})

const DeliveryIntervalList = observer(() => {

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: i.t('def:_intervals.interval.enabled'),
            dataIndex: 'enabled',
            key: 'enabled',
            render: (e: boolean) => e ? <Badge status="success" /> : <Badge status="error" />
        },
        {
            title: i.t('def:_intervals.interval.name'),
            key: 'name',
            render: (e: any) => `${dayjs(e.time_start, 'HH:mm').format('HH:mm')} - ${dayjs(e.time_end, 'HH:mm').format('HH:mm')}`
        },
        {
            title: i.t('def:_intervals.interval.type'),
            key: 'name',
            dataIndex: 'type',
            render: (e: string) => i.t(`def:_intervals.interval.types.${e}`)
        },
        {
            title: i.t('def:_intervals.interval.delivery_price'),
            dataIndex: 'delivery_price',
            key: 'delivery_price',
        },
        {
            title: i.t('def:_intervals.interval.express'),
            dataIndex: 'express',
            key: 'express',
            render: (e: boolean) => e ? <Tag color="success">{i.t('def:yes')}</Tag> : <Tag color="error">{i.t('def:no')}</Tag>
        },
        {
            title: i.t('orders:actions'),
            key: 'action',
            render: (el: any) => (
                <>
                    <Space>
                        <Button
                            onClick={() => controller.createInterval(el.id)}
                            icon={<SettingOutlined />}
                            type="dashed"
                        />

                        <Button
                            onClick={() => controller.deleteInterval(el.id)}
                            icon={<DeleteOutlined />}
                            type="dashed"
                            danger
                        />
                    </Space>
                </>
            )
        }
    ]

    return(
        <>
            <Row gutter={[20, 20]}>
                <Col span={24} className="right">
                    <Button type="dashed" onClick={() => controller.createInterval()}>{i.t('def:actions.create')}</Button>
                </Col>
                <Col span={24}>
                    <Table
                        rowKey="id"
                        columns={columns}
                        dataSource={controller.intervals}
                        pagination={false}
                    />
                </Col>
            </Row>

            <DeliveryInterval />
        </>
    )
})

const CalendarIntervals = observer(() => {

    useEffect(() => {
        controller.getNotWorkingDays()
    }, [])

    return(
        <>
            <Row gutter={[20, 20]}>
                <Col span={24}>
                    <Space>
                        <DatePicker onChange={(e: any) => {
                            controller.not_working_day = {
                                day: null,
                                date: e.format('D'),
                                month: e.format('M'),
                                year: e.format('YYYY'),
                            }
                        }}
                                    placeholder={i.t('def:select_date')}
                                    style={{width: "200px"}}
                        />

                        {controller.not_working_day.year
                            ? <>
                                <Button
                                    onClick={() => controller.setWorkingDay(null, controller.not_working_day.date, controller.not_working_day.month, null, true)}
                                    type="dashed"
                                >
                                    {i.t('def:every_year')} {controller.not_working_day.date}.{controller.not_working_day.month}
                                </Button>

                                <Button
                                    onClick={() => controller.setWorkingDay(null, controller.not_working_day.date, null, null, true)}
                                    type="dashed"
                                >
                                    {i.t('def:every_month', {day: controller.not_working_day.date})}
                                </Button>

                                <Button
                                    onClick={() => controller.setWorkingDay(null, controller.not_working_day.date, controller.not_working_day.month, controller.not_working_day.year, false)}
                                    type="dashed"
                                >
                                    {i.t('def:not_every')} {controller.not_working_day.date}.{controller.not_working_day.month}.{controller.not_working_day.year}
                                </Button>
                            </>
                            : <><Button onClick={() => controller.openDay()}>{i.t('def:select_day_week')}</Button></>
                        }

                    </Space>
                </Col>
                {controller.not_working_days.map((el: any) => (
                    <Col span={24}>
                        <Space>
                            {i.t(`def:_intervals.day_off`)}: {el.day
                            ? <>
                                {i.t(`def:_intervals.days.every${el.day}`)}
                            </>
                            : <>
                                {el.year
                                    ? <>{i.t(`def:_intervals.days.full`, {date: el.date, month: el.month, year: el.year})}</>
                                    : <>
                                        {el.month
                                            ? <>{i.t(`def:_intervals.days.month`, {date: el.date, month: el.month})}</>
                                            : <>{i.t(`def:_intervals.days.day`, {date: el.date})}</>
                                        }
                                    </>
                                }
                            </>
                        }

                            <Button type="dashed" danger size="small" icon={<DeleteOutlined />} onClick={() => controller.deleteWorkingDay(el.id)} />
                        </Space>
                    </Col>
                ))}
            </Row>

            <Modal
                open={controller.modal_days}
                footer={null}
                onCancel={() => controller.closeModal()}
                maskClosable={false}
            >
                <Row gutter={[10, 10]}>
                    <Col span={24}>
                        <h3>{i.t('def:make_day_off')}</h3>
                    </Col>
                    <Col span={12}>
                        <Button onClick={() => controller.setWorkingDay(1, null, null, null, true)} type="dashed" block>{i.t('def:_intervals.days.every1')}</Button>
                    </Col>
                    <Col span={12}>
                        <Button onClick={() => controller.setWorkingDay(2, null, null, null, true)} type="dashed" block>{i.t('def:_intervals.days.every2')}</Button>
                    </Col>
                    <Col span={12}>
                        <Button onClick={() => controller.setWorkingDay(3, null, null, null, true)} type="dashed" block>{i.t('def:_intervals.days.every3')}</Button>
                    </Col>
                    <Col span={12}>
                        <Button onClick={() => controller.setWorkingDay(4, null, null, null, true)} type="dashed" block>{i.t('def:_intervals.days.every4')}</Button>
                    </Col>
                    <Col span={12}>
                        <Button onClick={() => controller.setWorkingDay(5, null, null, null, true)} type="dashed" block>{i.t('def:_intervals.days.every5')}</Button>
                    </Col>
                    <Col span={12}>
                        <Button onClick={() => controller.setWorkingDay(6, null, null, null, true)} type="dashed" block>{i.t('def:_intervals.days.every6')}</Button>
                    </Col>
                    <Col span={12}>
                        <Button onClick={() => controller.setWorkingDay(7, null, null, null, true)} type="dashed" block>{i.t('def:_intervals.days.every7')}</Button>
                    </Col>
                </Row>
            </Modal>
        </>
    )
})