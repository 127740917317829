import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef } from 'react'
import { __product } from './__product'
import { Titles } from '../../../layouts/Titles'
import i from '../../../translations/i'
import { Row, Col, Table, Space, Button, Input } from 'antd'
import { IProduct } from '../../../components/list/types'
import { SettingOutlined } from '@ant-design/icons'
import { ProductsList } from '../../../components/list/ProductsList'
import { runInAction } from 'mobx'
import __multiple from '../../../helpers/multiple_actions/__multiple'

export const ProductList = observer(() => {

    useEffect(() => {
        __multiple.setActions(__product.getActions())
        __product.getList()
    }, [])

    return(
        <>
            <Titles
                title={i.t('search:searchProducts')}
                breadcrumb={[
                    { title: i.t('search:searchProducts') }
                ]}
                buttons={[
                    {title: i.t('categories:createProduct'), href: `/shop/catalog/product/0`, type: `primary`}
                ]}
            />

            <Row gutter={[20, 20]}>
                <Col span={24}>
                    <Row gutter={[10, 10]}>
                        <Col span={6}>
                            <Input
                                placeholder={i.t('search:changeName')}
                                value={__product.filter_list.name ?? ''}
                                onChange={(e) => runInAction(() => __product.filter_list.name = e.target.value)}
                            />
                        </Col>
                        <Col span={6}>
                            <Input
                                placeholder="ID"
                                value={__product.filter_list.id ?? ''}
                                onChange={(e) => runInAction(() => __product.filter_list.id = e.target.value)}
                            />
                        </Col>
                        <Col span={6}>
                            <Input
                                placeholder="ID CRM"
                                value={__product.filter_list.id_crm ?? ''}
                                onChange={(e) => runInAction(() => __product.filter_list.id_crm = e.target.value)}
                            />
                        </Col>
                        <Col span={6}>
                            <Input
                                placeholder={i.t('def:vendor')}
                                value={__product.filter_list.vendor_code ?? ''}
                                onChange={(e) => runInAction(() => __product.filter_list.vendor_code = e.target.value)}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <ProductsList
                        products={__product.list}
                        pagination={{
                            position: ['bottomLeft'],
                            hideOnSinglePage: true,
                            current: __product.pagination.page,
                            pageSize: __product.pagination.limit,
                            total: __product.pagination.total,
                            showSizeChanger: false,
                            onChange: (e: number) => __product.setPage(e)
                        }}
                        actions={{
                            title: i.t('orders:actions'),
                            key: 'action',
                            align: 'right',
                            render: (record: IProduct) => (
                                <Space>
                                    <Button
                                        href={`/shop/catalog/product/${record.id}`}
                                        target="_blank"
                                        type="dashed"
                                        icon={<SettingOutlined />}
                                    />
                                </Space>
                            )
                        }}
                        setActive={(productID: number, view: boolean) => __product.setActive(productID, view)}
                    />
                </Col>
            </Row>
        </>
    )
})