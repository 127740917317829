import moment from 'moment'

// import 'moment/locale/ru'
// import 'moment/locale/uk'
// import 'moment/locale/pl'
// import 'moment/locale/ar'

import { storeLocal } from '../stores'

/**
 * Получение строкового представления времени в формате HH:MM
 */

export const getString = (timestamp: number) => {
    let date = new Date(timestamp * 1000)
    return `${('0' + (date.getUTCHours())).slice(-2)}:${('0' + (date.getUTCMinutes())).slice(-2)}`
}

/**
 * Получить секунды из строкового представления времени
 */
export const getSeconds = (time: string) => {
    const workTime = time.split(":")

    let seconds = 0

    if(Number(workTime[0]) > 0) {
        seconds = seconds + Number(workTime[0]) * 60 * 60
    }

    if(Number(workTime[1]) > 0) {
        seconds = seconds + Number(workTime[1]) * 60
    }

    return seconds
}

export const moment_custom = (value: number, format: string) => {
    let local = storeLocal.get('lang') ? storeLocal.get('lang') : process.env.REACT_APP_DEFAULT_LANGUAGE ?? 'en'
    if(local == 'ua') local = 'uk'
    try {
        require(`moment/locale/${local}`)
    } catch (e) {}
    return moment(value).locale(local).format(format)
}