import { observer } from 'mobx-react-lite'
import __apps, { AppInterface } from './__apps'
import React, { useEffect } from 'react'
import { Row, Col, Button, Space, Input, Modal, Select, Switch, Typography, Tag, Popconfirm } from 'antd'
import { StopOutlined } from '@ant-design/icons'
import { Titles } from '../../layouts/Titles'
import { runInAction } from 'mobx'
import i from '../../translations/i'
import { storeLocal } from '../../helpers/stores'

export const Apps = observer(() => {

    useEffect(() => {
        return () => __apps.getList()
    }, [])

    return(
        <>
            <Titles title={i.t('def:apps.title')} />
            <Row gutter={[40, 40]}>
                <Col span={24}>
                    <Row gutter={[20, 20]}>
                        {__apps.list.length
                            ? __apps.list.map((el: AppInterface, index: number) => <AppsElement el={el} key={`app_${index}`} />)
                            : <></>
                        }
                    </Row>
                </Col>
            </Row>

            <AppsDetail />
        </>
    )
})

const AppsElement = ({ el }: { el: AppInterface }) => {

    return(
        <Col xs={24} md={12} lg={8}>
            <Row>
                <Col span={24} className="app">
                    {el.icon
                        ? <><img src={el.icon} alt='' style={{ width: '50px' }} /><br/><br/></>
                        : <></>}

                    <p className="name">{el.config.local.name}</p>
                    <p>{el.config.local.annotation}</p>

                    <div className="tags">
                        {el.category === ''
                            ? <Tag color="processing">{i.t('def:apps.development')}</Tag>
                            : <>
                                {el.installed ? <Tag color="success">{i.t('def:apps.installed')}</Tag> : <></>}
                                {el.installed ?
                                    el.enabled ? <Tag color="success">{i.t('def:states.included')}</Tag> : <Tag color="warning">{i.t('def:states.turned')}</Tag>
                                    : <></>}
                            </>
                        }
                    </div>

                    <Space>
                        {el.category === ''
                            ?   <Button disabled>{i.t('def:apps.later')}</Button>
                            : <>
                                {Number(storeLocal.get('pricing_plan') < el.min_rate)
                                    ? <Button disabled>{i.t('def:apps.forbidden')}</Button>
                                    : <>
                                        <Button type={el.installed ? "dashed" : "primary"} onClick={() => __apps.openModal(el.id)}>{el.installed ? i.t('def:settings') : i.t('def:actions.connect')}</Button>
                                        {(el.installed) ? <Popconfirm
                                            title={i.t('def:confirms.delete')}
                                            onConfirm={() => __apps.delete(el.id)}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button type="dashed" icon={<StopOutlined />} danger />
                                        </Popconfirm> : <></>}
                                    </>
                                }
                            </>
                        }

                    </Space>
                </Col>
            </Row>
        </Col>
    )
}

export const AppsDetail = observer(() => {

    return(
        <Modal open={__apps.modal} footer={null} onCancel={() => __apps.closeModal()} maskClosable={false}>
            <form onSubmit={(e) => __apps.save(e)}>
                {__apps.data &&
                    <Row gutter={[20, 20]}>
                        <Col span={24}>
                            <Typography.Title level={2}>{__apps.data.config.local.name ?? 'name'}</Typography.Title>
                        </Col>
                        <Col span={24}>
                            <Typography.Text>{__apps.data.config.local.description ?? ''}</Typography.Text>
                        </Col>
                        <Col span={24}>
                            <label>{i.t('def:active')}</label>
                            <Switch
                                checked={__apps.data.enabled}
                                onChange={(e) => runInAction(() => __apps.data.enabled = e)}
                                size="small"
                            />
                        </Col>
                        {__apps.data.installed ? <Col span={24}><Tag color="success">{i.t('def:apps.installed')}</Tag></Col> : <></>}

                        <Col span={24}>
                          <Row>
                            <Col span={24}>{i.t(`def:appEvents.events`)}</Col>
                            {__apps.data.config.events.split(',').map((el: any) => (
                              <Col span={24}>
                                <label>{i.t(`def:appEvents.${el}`)}</label>
                                <Switch
                                  checked={!(__apps.data.data.eventsDisabled && __apps.data.data.eventsDisabled.find((e: string) => e === el))}
                                  onChange={() => __apps.setEvent(el)}
                                  size="small"
                                />
                              </Col>
                            ))}
                          </Row>
                        </Col>

                        {Object.keys(__apps.data.data).map((key: string, index: number) => {

                            const data = __apps.data.data[key]

                            if(!__apps.data.installed && data.then) return ''

                            return (
                                <Col span={24} key={`setting_application_${index}`}>
                                    <label>{__apps.data.config.local.settings[data.key] ?? data.key}</label>
                                    {data.type === 'string'
                                        ? <Input
                                            value={data.value}
                                            onChange={(e) => runInAction(() => data.value = e.target.value)}
                                            required={data.required === "true"}
                                        /> : <></>}

                                    {data.type === 'switch'
                                        ? <Switch
                                            checked={data.value}
                                            onChange={(e) => runInAction(() => data.value = e)}
                                            size="small"
                                        /> : <></>}

                                    {data.type === 'select_array'
                                        ? <Select
                                            mode="multiple"
                                            style={{ width: '100%' }}
                                            value={data.value ?? []}
                                            options={data.options}
                                            onChange={(e) => {
                                                runInAction(() => data.value = e)
                                                console.log(data)
                                                if(data.save) __apps.saveApplication()
                                            }}
                                        />
                                        : <></>}

                                    {data.type === 'select'
                                        ? <Select
                                            style={{ width: '100%' }}
                                            value={data.value ?? null}
                                            options={data.options}
                                            onChange={(e) => runInAction(() => data.value = e)}
                                        />
                                        : <></>}
                                </Col>
                            )
                        })}
                        <Col span={24}>
                            <Button htmlType="submit">{i.t('def:actions.save')}</Button>
                        </Col>
                    </Row>
                }
            </form>
        </Modal>
    )
})