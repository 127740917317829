import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import i from '../../../translations/i'
import __order from './__order'
import { paymentTypesController } from '../payment/types/controller'
import { Titles } from '../../../layouts/Titles'
import { FilterOutlined } from '@ant-design/icons'
import {
    Badge,
    Button,
    Col,
    Divider,
    Drawer,
    Input,
    InputNumber,
    Row,
    Segmented,
    Select,
    Space,
    Table,
    Tag
} from 'antd'
import dayjs from 'dayjs'
import __multiple from '../../../helpers/multiple_actions/__multiple'
import { WarehousesController } from '../../settings/warehouses/warehouses.controller'
import { runInAction } from 'mobx'
import { getPrice } from "../../../helpers/price";

const payments = new paymentTypesController()
const warehouses = new WarehousesController()

export const OrdersList = observer(() => {

    useEffect(() => {
        __order.getList()
        payments.getList()
        __order.getStatuses()
        warehouses.pagination.limit = 9999999
        warehouses.get()
        __multiple.setActions(__order.getActions())
    }, [])

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            render: (id: number, record: any) => <Badge count={record.viewed ? 0 : 1} style={{ backgroundColor: '#52c41a' }} dot={true}>
                <Button
                    type="link"
                    href={'/shop/orders/' + record.id}
                >
                    {id}
                </Button>
            </Badge>
        },
        {
            title: i.t('orders:status'),
            dataIndex: 'status',
            key: 'status',
            render: (status: any, record: any) => {
                let statusObject = __order.statuses.find(el => el.id === status)
                if(!statusObject) return <></>
                return <Tag color={`#${statusObject?.color_hex ?? 'fff'}`}>{statusObject?.name ?? ''}</Tag>
            }
        },
        {
            title: i.t('orders:client'),
            dataIndex: 'user_id',
            key: 'user_id',
            render: (user_id: any, record: any) => user_id && user_id > 0 ? <a href={`/users/${user_id}`}>{record?.firstname ?? ''}</a> : ''
        },
        {
            title: i.t('orders:price'),
            dataIndex: 'sum',
            key: 'sum',
            render: (e: number) => getPrice((e).toFixed(2))
        },
        {
            title: i.t('orders:statusPay'),
            dataIndex: 'payment_status',
            key: 'payment_status',
            render: (text: string) => Number(text) > 0 ? payments.list.length > 0 && payments.list.find(el => `${el.id}` === `${text}`).name : '-'
        },
        {
            title: i.t('def:warehouse'),
            dataIndex: 'warehouse_id',
            key: 'warehouse_id',
            render: (warehouse_id: any, record: any) => warehouse_id > 0 ? <a href={`/settings/warehouses/${warehouse_id}`} target="_blank">{warehouses.list.find((el: any) => el.id === warehouse_id)?.name ?? ''}</a> : <></>
        },
        {
            title: i.t('orders:created_at'),
            dataIndex: 'created_at',
            key: 'created_at',
            render: (item: any) => item ? dayjs(item).format('DD.MM.YYYY HH:mm') : <></>
        }
    ]

    const buttons: any[] = [
        { title: i.t('def:flag_read_all'), click: () => __order.viewed(), type: 'dashed' },
        { title: i.t('def:Create Order'), href: '/shop/orders/0', type: 'primary' },
        { title: <FilterOutlined />, click: () => __order.filterOrders.open = true }
    ]

    return (
        <>
            <Titles
                title={i.t('orders:orders')}
                buttons={buttons}
                breadcrumb={[
                    { title: i.t('order:orders'), href: '/shop/orders' }
                ]}
            />

            <Row gutter={[20,20]}>
                <Col span={24}>
                    <OrdersFilter />
                </Col>

                <Col span={24}>
                    <Segmented
                        value={__order.pagination.is_archive}
                        options={[
                            { label: i.t('def:ordersActive'), value: false },
                            { label: i.t('def:ordersArchive'), value: true },
                        ]}
                        onChange={(e) => {
                            __order.pagination.is_archive = e
                            __order.pagination.page = 1
                            __order.getList()
                            __multiple.setActions(__order.getActions())
                        }}
                    />
                </Col>

                {payments.list.length > 0 &&
                    <Col span={24}>
                        <Table
                            rowKey="id"
                            rowSelection={{
                                onSelect: (record, selected, selectedRows) => {
                                    __multiple.set(selectedRows)
                                },
                                onSelectAll: (selected, selectedRows) => {
                                    __multiple.set(selectedRows)
                                },
                                type: 'checkbox',
                                selectedRowKeys: __multiple.selected
                            }}
                            columns={columns}
                            dataSource={__order.list}
                            pagination={{
                                position: ['bottomLeft'],
                                hideOnSinglePage: true,
                                current: __order.pagination.page,
                                pageSize: __order.pagination.limit,
                                total: __order.pagination.total,
                                showSizeChanger: false,
                                onChange: (e) => __order.setPage(e)
                            }}
                        />
                    </Col>
                }
            </Row>
        </>
    )
})

export const OrdersFilter = observer(() => {

    return(
        <>
            <Drawer
                title={i.t('def:filters')}
                placement="right"
                closable={false}
                onClose={() => __order.filterOrders.open = false}
                open={__order.filterOrders.open}
            >
                <Row gutter={[20, 20]}>
                    <Col span={24}>
                        <label>{i.t('def:orders.number')}</label>
                        <InputNumber
                            style={{ width: '100%' }}
                            value={__order.filterOrders.filters.id}
                            onChange={(e: any) => e ? runInAction(() => __order.filterOrders.filters.id = e) : runInAction(() => __order.filterOrders.filters.id = null)}
                        />
                    </Col>

                    <Col span={24}>
                        <label>{i.t('def:orders.status')}</label>
                        <Select
                            style={{ width: '100%' }}
                            options={__order.statuses.map((el, index) => ({
                                label: el.name,
                                value: el.id,
                                key: `status_${index}`
                            }))}
                            value={__order.filterOrders.filters.status}
                            onChange={(e) => e ? runInAction(() => __order.filterOrders.filters.status = e) : runInAction(() => __order.filterOrders.filters.status = [])}
                            mode="multiple"
                        />
                    </Col>

                    <Divider/>

                    <Col span={24}>
                        <label>{i.t('def:orders.client_phone')}</label>
                        <Input
                            value={__order.filterOrders.filters.client_phone}
                            onChange={(e: any) => e.target.value ? runInAction(() => __order.filterOrders.filters.client_phone = e.target.value) : runInAction(() => __order.filterOrders.filters.client_phone = '')}
                        />
                    </Col>

                    <Col span={24}>
                        <label>{i.t('def:orders.client_email')}</label>
                        <Input
                            value={__order.filterOrders.filters.client_email}
                            onChange={(e: any) => e.target.value ? runInAction(() => __order.filterOrders.filters.client_email = e.target.value) : runInAction(() => __order.filterOrders.filters.client_email = '')}
                        />
                    </Col>

                    <Col span={24}>
                        <label>{i.t('def:orders.recipient_phone')}</label>
                        <Input
                            value={__order.filterOrders.filters.recipient_phone}
                            onChange={(e) => e.target.value ? runInAction(() => __order.filterOrders.filters.recipient_phone = e.target.value) : runInAction(() => __order.filterOrders.filters.recipient_phone = '')}
                        />
                    </Col>
                    <Col span={24}>
                        <Space>
                            <Button type="primary" onClick={() => __order.getList()}>{i.t('def:actions.apply')}</Button>
                            {/*<Button onClick={() => __order.filterOrders.open = false} type="dashed">Cancel</Button>*/}
                            <Button onClick={() => __order.filterClear()} type="dashed">{i.t('def:clear')}</Button>
                        </Space>
                    </Col>
                </Row>
            </Drawer>
        </>
    )
})