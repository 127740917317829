export const getLanguageConfig = (lang: string) => {
    return {
        crumb:              require(`./default/crumb.${lang}.json`),
        layout:             require(`../layouts/translations/layout.${lang}.json`),
        header:             require(`../layouts/translations/header.${lang}.json`),
        nav:                require(`../layouts/translations/navigation.${lang}.json`),
        dashboard:          require(`../front/dashboard/translations/dashboard.${lang}.json`),
        contacts:           require(`../front/contacts/translations/contacts.${lang}.json`),
        def:                require(`./default/default.${lang}.json`),
        categories:         require(`./locales/categories.${lang}.json`),
        category:           require(`./locales/category.${lang}.json`),
        code:               require(`./locales/code.${lang}.json`),
        delivery:           require(`./locales/delivery.${lang}.json`),
        forgot:             require(`./locales/forgot.${lang}.json`),
        home:               require(`./locales/home.${lang}.json`),
        import:             require(`./locales/import.${lang}.json`),
        news:               require(`./locales/news.${lang}.json`),
        newsItem:           require(`./locales/newsItem.${lang}.json`),
        newsType:           require(`./locales/newsType.${lang}.json`),
        order:              require(`./locales/order.${lang}.json`),
        orders:             require(`./locales/orders.${lang}.json`),
        page:               require(`./locales/page.${lang}.json`),
        pages:              require(`./locales/pages.${lang}.json`),
        points:             require(`./locales/points.${lang}.json`),
        product:            require(`./locales/product.${lang}.json`),
        props:              require(`./locales/props.${lang}.json`),
        search:             require(`./locales/search.${lang}.json`),
        status:             require(`./locales/status.${lang}.json`),
        sub:                require(`./locales/sub.${lang}.json`),
        user:               require(`./locales/user.${lang}.json`),
        users:              require(`./locales/users.${lang}.json`),
        constructor:        require(`../front/screen_designer/translations/screens.${lang}.json`),
        onboarding:         require(`../front/screen_designer/translations/onboarding.${lang}.json`),
        operators:          require(`../front/settings/operators/translations/operators.${lang}.json`),
        promo:              require(`./locales/promo.${lang}.json`),
        unit:               require(`../modules/shop/units/translations/unit.${lang}.json`),
        settings:           require(`../front/settings/translations/settings.${lang}.json`),
        auth:               require(`../front/user/translations/auth.${lang}.json`),
        export:             require(`../front/shop/export/translations/export.${lang}.json`),
        wait:               require(`../front/user/wait/translations/wait.${lang}.json`),
        admin:              require(`../front/user/translations/admin.${lang}.json`),
        integrations:       require(`../front/integrations/translations/integrations.${lang}.json`),
        payment:       require(`../front/shop/payment/translations/payment.${lang}.json`),
        pushes:       require(`../front/marketing/pushes/translations/pushes.${lang}.json`)
    }
}