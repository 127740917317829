import { makeAutoObservable } from 'mobx'
import deleteFile from '../../../../store/deleteFile.controller'
import hashController from '../../../template/controllers/hash.controller'
import pc from '../../properties/controllers/properties.controller'
import i from '../../../../translations/i'
import { openApi, request } from "../../../../helpers/request";
import { notification } from 'antd'

class categoryController {
    constructor() {
        makeAutoObservable(this)
    }

    baseUrl = '/product/categoryedit/'

    categories = {
        data: [],
        page: 1
    }

    deleteProps = []
    updatedProps = []

    category = {}

    breadcrumb = [
        { link: '/shop', value: i.t('crumb:shop') },
        { link: '/shop/catalog', value: i.t('crumb:catalog') }
    ]

    tree = []

    setPage(page) {
        this.categories.page = page
        this.getCategories()
    }

    getCategories(parentID = 0) {

        request(
            'get',
            this.baseUrl + parentID + '?page=' + this.categories.page,
            {},
            true
        )
            .then((success) => {
                this.categories.data = success.data
            })
            .catch((error) => {
            })
    }

    activatedCategoryByCategoryID(categoryID, value) {}

    upOrDownCategoryByCategoryID(categoryID, value) {}

    pushToBreadcrumb(breadcrumb) {
        breadcrumb.map((item) =>
            this.breadcrumb.push({
                link:
                    item[2] === 'category'
                        ? '/shop/catalog/category/' + item[0]
                        : '/shop/catalog/product/' + item[0],
                value: item[1]
            })
        )
    }

    getCategoryByCategoryID(type, categoryID) {
        if (type === 'category') {
            openApi('get', `/category/${categoryID}`)
                .then((success) => {
                    console.log(success.data.breadcrumbs)
                    this.category = success.data

                    this.breadcrumb = [
                        { link: '/shop', value: i.t('crumb:shop') },
                        { link: '/shop/catalog', value: i.t('crumb:catalog') }
                    ]

                    success.data.breadcrumbs.map((el) => {
                        console.log('eeee')
                        this.breadcrumb.push({
                            value: el.name,
                            link: el.subcategories ? `/shop/catalog/category/${el.id}` : `/shop/products/${el.id}`
                        })
                    })
                })
                .catch((error) => console.log(error.response))
        } else {
            this.category.parentid = categoryID
            // request('get', '/product/categoryview/?parentid=' + categoryID)
            //     .then((success) => {
            //         this.category = {
            //             breadcrumb: success.breadcrumb,
            //             parentid: categoryID,
            //             categorys:
            //                 success.categorys && success.categorys[0]
            //                     ? success.categorys[0]
            //                     : [],
            //             view: 1
            //         }
            //     })
            //     .catch((error) => console.log(error))
        }
    }

    deleteCategoryByCategoryID(categoryID, action) {
        if (window.confirm(i.t('categories:confirmElement'))) {
            openApi('delete', `/shop/category/${categoryID}`)
                .then(result => {
                    console.log(result)
                    action()
                })
        }
    }

    saveCategory() {
        let facts = new FormData()

        facts.append('name', this.category.name ?? '')
        if(this.category.id_crm) facts.append('id_crm', this.category.id_crm)
        facts.append('view', `${+ this.category.view}`)
        facts.append('parentid', this.category.parentid || '0')

        if(!this.category.picture) facts.append('picture_delete', 'true')
        else if(!this.category.picture.path) {
            facts.append('picture', this.category.picture)
        }

        if (
            this.category.filters &&
            this.category.filters.length > 0
        ) {
            this.category.filters.map((item) =>
                facts.append('filters[]', item)
            )
        }

        facts.append('text', this.category.text || '')

        if(this.category.name_encode) facts.append('name_encode', this.category.name_encode)
        if(this.category.seo_title) facts.append('seo_title', this.category.seo_title)
        if(this.category.seo_description) facts.append('seo_description', this.category.seo_description)
        if(this.category.seo_keywords) facts.append('seo_keywords', this.category.seo_keywords)

        let url = '/category/'
        if (
            this.category.id &&
            this.category.id > 0
        ) {
            url = url + this.category.id
        } else {
            url = url + 0
        }

        facts.append('set_filters', `1`)

        openApi('post', url, facts)
            .then((success) => {
                console.log(success)
                if(this.category.id && this.category.id > 0) {
                    this.getCategoryByCategoryID('category', this.category.id)
                    notification.success({
                        message: i.t('def:success.complete'),
                        description: i.t('def:success.edit')
                    })
                } else {
                    document.location.href =
                        '/shop/catalog/category/' + success.parentid
                }

            })
            .catch((error) => console.log(error.response))
    }

    postCategory(e) {
        e.preventDefault()
        this.saveCategory()
    }

    setFilters(filterCategoryID) {}

    setCategory(name, value) {
        console.log(name, value)
        if (name === 'filters') {
            if(this.category.filters && this.category.filters.length > 0) {
                if(this.category.filters.findIndex((el) => el === value) > -1) {
                    if (this.category.filters.length === 1) {
                        this.category.filters = []
                    } else {
                        this.category.filters = this.category.filters.filter((el) => el !== value)
                    }
                } else {
                    this.category.filters.push(value)
                }
            } else {
                let arr = []
                arr.push(value)
                this.category.filters = arr
            }
        } else {
            this.category[name] = value
        }
    }

    deletePicture(url) {
        deleteFile('category', url, Number(this.category.id))
        this.setCategory('picture', [])
    }

    getCategoryTree(parentID = 0) {
        openApi('get', `/category/tree/${parentID}`)
          .then(result => {
              console.log(result)
              this.tree = result.data
          })
          .catch(error => console.log(error.response))
    }

    getInfoByTab(tab) {
        switch (tab) {
            case 'parent':
                this.getCategoryTree()
                break
            case 'filter':
                pc.getAllProperties()
                break
        }
    }

    setTabs(value) {
        this.getInfoByTab(value)
    }

    setStatus(categoryId, status) {
        console.log(categoryId, status)

        const data = new FormData()
        data.append('status', `${+ status}`)

        openApi('post', `/shop/category/active/${categoryId}`, data)
            .then(result => {

            })
    }
}

export default new categoryController()
