import {observer} from "mobx-react-lite";
import _promo from "../controllers/promo.controller";
import TemplateTitle from "../../../template/title/TemplateTitle";
import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import { Col, DatePicker, Pagination, Row, Space, Table } from 'antd'
import moment from "moment";
import { newDateTimeHelper } from "../../../template/helpers/templateHelpers"
import i from '../../../../translations/i'

const ShopPromoStatistics = observer(() => {

    let { couponID } = useParams();

    useEffect(() => {
        _promo.setPageNull()
        _promo.getCoupon(couponID)
        _promo.getCouponStatistics(couponID)
    }, [])

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: i.t('promo:use'),
            dataIndex: 'description',
            key: 'description'
        },
        {
            title: i.t('promo:datetime'),
            dataIndex: 'date',
            key: 'date',
            render: item => newDateTimeHelper(item)
        },
        {
            title: "",
            dataIndex: 'orderid',
            key: 'orderid',
            render: item => <>{item > 0 && <a href={`/shop/orders/${item}`}>
                {i.t('promo:order')}
            </a>}</>
        },
        {
            title: "",
            dataIndex: 'userid',
            key: 'userid',
            render: item => <>{item > 0 && <a href={`/users/${item}`}>
                {i.t('promo:user')}
            </a>}</>
        }
    ];

    return(
        <>
            <TemplateTitle
                title={i.t('promo:stat')}
                breadcrumb={_promo.breadcrumb}
                buttons={[
                    { type: "link", title: i.t('def:actions.backList'), link: "/shop/promo" }
                ]}
            />

            <Row gutter={[16, 16]}>
                <Col span={6}>
                    <label className="all-label">{i.t('promo:start')}</label>
                    <DatePicker
                        showTime
                        style={{ width: 100 + "%" }}
                        format="YYYY-MM-DD HH:mm"
                        minuteStep={10}
                        value={_promo.interval.from > 0 ? moment(new Date(_promo.interval.from), "HH:mm") : ""}
                        onChange={(time, timeString) => _promo.onChangeInterval("from", timeString)}
                    />
                </Col>
                <Col span={6}>
                    <label className="all-label">{i.t('promo:end')}</label>
                    <DatePicker
                        showTime
                        style={{ width: 100 + "%" }}
                        format="YYYY-MM-DD HH:mm"
                        minuteStep={10}
                        value={_promo.interval.to > 0 ? moment(new Date(_promo.interval.to), "HH:mm") : ""}
                        onChange={(time, timeString) => _promo.onChangeInterval("to", timeString)}
                    />
                </Col>
                <Col span={24}>
                    {i.t('promo:select')}
                </Col>
                <Col span={24} style={{ height: 300 + "px" }}>
                    <Table columns={columns} dataSource={_promo.statistics} pagination={false} />

                    <br />
                    <Pagination
                        defaultCurrent={_promo.pagination.page}
                        current={_promo.pagination.page}
                        defaultPageSize={_promo.pagination.limit}
                        pageSize={_promo.pagination.limit}
                        total={_promo.pagination.total}
                        showSizeChanger={false}
                        onChange={(e) => _promo.setPageStat(e, couponID)}
                    />
                </Col>
            </Row>
        </>
    )
})

export default ShopPromoStatistics