import { observer } from 'mobx-react-lite'
import { paymentTypesController } from './controller'
import React, { useEffect } from 'react'
import i from '../../../../translations/i'
import { Titles } from '../../../../layouts/Titles'
import { Button, Col, Input, Row, Space, Table, Tooltip } from 'antd'
import { SettingOutlined, DeleteOutlined } from '@ant-design/icons'
import { useParams } from 'react-router-dom'
import _promo from '../../../../modules/shop/promo/controllers/promo.controller'

const controller = new paymentTypesController()

export const PaymentTypes = observer(() => {

    useEffect(() => {
        return () => controller.getList()
    }, [])

    const buttons = [
        { title: i.t('def:actions.create'), href: `/shop/payment/types/0`, type: 'primary' },
    ]

    const columns = [
        {
            title: i.t('def:name'),
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: i.t('def:actions.actions'),
            key: 'action',
            render: (text: any, record: any) => (
                <>
                    <Space>
                        <Tooltip title={i.t('def:actions.edit')}>
                            <Button type="dashed" shape="circle" icon={<SettingOutlined />} href={`/shop/payment/types/${record.id}`} />
                        </Tooltip>
                        <Tooltip title={i.t('def:actions.remove')}>
                            <Button type="dashed" shape="circle" danger icon={<DeleteOutlined />} onClick={() => controller.delete(record.id)} />
                        </Tooltip>
                    </Space>
                </>
            ),
        },
    ]

    return(
        <>
            <Titles
                title={i.t('payment:types.title')}
                buttons={buttons}
            />

            <Table columns={columns} dataSource={controller.list} rowKey="id" pagination={false} />

        </>
    )
})

export const PaymentType = observer(() => {

    let { entityId }: any = useParams()

    useEffect(() => {
        if(entityId && entityId > 0)
            return () => controller.getOne(entityId)
    }, [])

    const buttons = [
        { title: i.t('def:actions.backList'), href: `/shop/payment/types`, type: 'dashed' },
        { title: i.t('def:actions.save'), htmlType: `submit`, type: 'primary' }
    ]

    return(
        <form onSubmit={(e) => controller.save(e)}>
            <Titles
                title={i.t('payment:type.title')}
                buttons={buttons}
            />

            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <label htmlFor="name" className="all-label">
                        {i.t('def:name')}
                    </label>
                    <Input
                        value={controller.element.name}
                        id="name"
                        onChange={(e) => controller.onChange("name", e.target.value)}
                        required
                    />
                </Col>
            </Row>
        </form>
    )
})