import { observer } from "mobx-react-lite"
import TemplateTitle from '../../template/title/TemplateTitle'
import i from '../../../translations/i'
import { Row, Col, Button, Select, InputNumber } from "antd"
import massController from './mass.controller'

const MassPriceChange = observer(() => {

    return(
        <>
            <TemplateTitle
                title={i.t('product:mass.title')}
            />

            <form onSubmit={(e) => massController.onSubmit(e)}>
                <Row gutter={[20, 20]}>
                    <Col span={24}>
                        <label>
                            {i.t('product:mass.actions')}
                        </label><br/>
                        <Select style={{ width: 240 }} onChange={(e) => massController.setAction(e)} value={massController.action}>
                            <Select.Option value="multiplying">
                                {i.t('product:mass.multiply')}
                            </Select.Option>
                            <Select.Option value="dividing">
                                {i.t('product:mass.divide')}
                            </Select.Option>
                        </Select>
                    </Col>
                    <Col span={24}>
                        <label>Коэфициент</label><br/>
                        <InputNumber style={{ width: 240 }} onChange={(e) => massController.setValue(e)} placeholder={i.t('product:mass.coefficient')} value={massController.value} />
                    </Col>
                    <Col span={24}>
                        <Button type="primary" htmlType="submit">
                            {i.t('def:actions.apply')}
                        </Button>
                    </Col>
                </Row>
            </form>

        </>
    )
})

export default MassPriceChange