import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { __cpl } from './__cpl'
import i from '../../../translations/i'
import { Titles } from '../../../layouts/Titles'
import { CustomProductListsList } from './CustomProductListsList'

export const CustomProductListsPage = observer(() => {

    useEffect(() => {
        __cpl.getList()
    }, [])

    return <>
        <Titles
            title={i.t('def:Products Lists')}
            breadcrumb={[
                { title: i.t('def:Products Lists'), href: `/catalog/lists` }
            ]}
            buttons={[
                { title: i.t('def:Add List'), href: `/catalog/lists/0`, type: 'dashed' },
            ]}
        />

        <CustomProductListsList type="list" />
    </>
})