import { observer } from "mobx-react-lite"
import roadmap from "./roadmap.controller";
import React, { useEffect } from "react";
import { Titles } from "../../../layouts/Titles";
import i from "../../../translations/i";
import { Button, Space, Switch, Table, Row, Col, Input, Select } from "antd";
import { DeleteOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import projectController from "../project/project.controller";
import statuses from "../../shop/order_status/controller";

export const Roadmap = observer(() => {

  useEffect(() => {
    statuses.getList()
    projectController.get()
    roadmap.getRoadmap()
  }, [])

  const buttons = [
    { title: i.t('def:actions.save'), type: 'primary', click: () => roadmap.saveRoadmap() }
  ]

  return(
    <>
      <Titles
        title={i.t('def:Roadmap')}
        buttons={buttons}
      />

      <Row gutter={[20, 20]}>
        <Col span={24}>
          <label>{i.t('def:Roadmap')}</label>
          <div className="switch-block">
            <div className="element">
              <div></div>
              <Switch
                checked={projectController.project.roadmap}
                onChange={e => projectController.set('roadmap', e, true)}
                size="small"
              />
              <div>
                <span>{projectController.project.roadmap ? i.t('def:states.included') : i.t('def:states.turned')}</span>
              </div>
            </div>
          </div>
        </Col>
        <Col span={24}>
          <Row gutter={[20, 20]}>
            {roadmap.list.map((el: any, index: number) => (
              <Col span={24} key={`roadmap_list_${index}`}>
                <Row gutter={[10, 10]} className="order-status">
                  <Col span={2}>
                    <p>#{index+1}</p>
                  </Col>
                  <Col span={12}>
                    <label>{i.t('def:Order Status')}</label>
                    <Select
                      style={{ width: '100%' }}
                      options={statuses.list.map((e: any) => ({ label: e.name, value: e.id }))}
                      value={el.status_id}
                      onChange={e => roadmap.list[index].status_id = e}
                    />
                  </Col>
                  <Col span={10}>
                    <label>{i.t('def:For whom is it available')}?</label>
                    <Select
                      style={{ width: '100%' }}
                      options={[
                        { label: '', value: '' },
                        { label: i.t('def:Courier'), value: 'courier' },
                        { label: i.t('def:Collector'), value: 'collector' },
                      ]}
                      value={el.available_for}
                      onChange={e => roadmap.list[index].available_for = e}
                    />
                  </Col>
                  <Col span={6}>
                    <label>{i.t('def:Wait for payment')}</label>
                    <div className="switch-block">
                      <div className="element">
                        <div></div>
                        <Switch
                          checked={el.waiting_for_payment}
                          onChange={e => roadmap.list[index].waiting_for_payment = e}
                          size="small"
                        />
                        <div>
                          <span>{el.waiting_for_payment ? i.t('def:states.included') : i.t('def:states.turned')}</span>
                        </div>
                      </div>
                    </div>

                  </Col>
                  <Col span={6}>
                    <label>{i.t('def:Editable')}</label>
                    <div className="switch-block">
                      <div className="element">
                        <div></div>
                        <Switch
                          checked={el.editable}
                          onChange={e => roadmap.list[index].editable = e}
                          size="small"
                        />
                        <div>
                          <span>{el.editable ? i.t('def:states.included') : i.t('def:states.turned')}</span>
                        </div>
                      </div>
                    </div>

                  </Col>
                  <Col span={6}>
                    <label>{i.t('def:User Editing')}</label>
                    <div className="switch-block">
                      <div className="element">
                        <div></div>
                        <Switch
                          checked={el.editable_user}
                          onChange={e => roadmap.list[index].editable_user = e}
                          size="small"
                        />
                        <div>
                          <span>{el.editable_user ? i.t('def:states.included') : i.t('def:states.turned')}</span>
                        </div>
                      </div>
                    </div>

                  </Col>
                  <Col span={6}>
                    <Space>
                      <Button
                        icon={<UpOutlined />}
                        shape="circle"
                        type="dashed"
                        onClick={() => roadmap.setPosition(index, 'up')}
                      />
                      <Button
                        icon={<DownOutlined />}
                        shape="circle"
                        type="dashed"
                        onClick={() => roadmap.setPosition(index, 'down')}
                      />
                      <Button
                        icon={<DeleteOutlined />}
                        shape="circle"
                        type="dashed"
                        onClick={() => roadmap.remove(index)}
                        danger
                      />
                    </Space>
                  </Col>
                </Row>
              </Col>
            ))}
            <Col span={24}>
              <Button type="dashed" onClick={() => roadmap.add()}>{i.t('def:actions.create')}</Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
})