import React from 'react'
import { observer } from 'mobx-react-lite'
import {Row, Col, Button, Input, Tooltip, Popconfirm, Upload} from 'antd'
import i from '../../../../translations/i'
import { __product } from '../__product'
import { DeleteOutlined, EditOutlined, FileOutlined, InboxOutlined, SaveOutlined } from '@ant-design/icons'
import { storeLocal } from "../../../../helpers/stores";
import { ErrorByPricingPlan } from "../../../../components/ErrorByPricingPlan";

export const ProductFiles = observer(() => {

    return(
        <>
            <Row gutter={[20,20]}>
                <Col span={24}>
                    <ErrorByPricingPlan
                      plan={1}
                      content={
                          <Button
                            type="primary"
                            onClick={() => __product.addFilesGroup()}
                            disabled={storeLocal.get('pricing_plan') < 1}
                          >{i.t('def:Add Files Group')}</Button>
                      }
                    />
                </Col>

                <Col span={24}>
                    <Row gutter={[15,15]}>
                        {__product.item.docs.map((group: any, index: number) => {
                            return (
                                <Col span={24} key={index}>
                                    <div className="designer__block">
                                        <div className="block__title">
                                            <label className="formLabel">{i.t('def:name')}*</label>
                                            <Input
                                                value={__product.item.docs[index]['label']}
                                                onChange={(e) => __product.item.docs[index]['label'] = e.target.value}
                                            />
                                        </div>

                                        <div className="block__actions">
                                            <Tooltip title={i.t('categories:delete')}>
                                                <Popconfirm
                                                    title={i.t('def:confirms.delete')}
                                                    onConfirm={() => __product.removeFilesGroup(index)}
                                                    okText={i.t('def:yes')}
                                                    cancelText={i.t('def:no')}
                                                >
                                                    <Button
                                                        type="dashed"
                                                        shape="circle"
                                                        icon={<DeleteOutlined />}
                                                        danger
                                                    />
                                                </Popconfirm>
                                            </Tooltip>
                                        </div>

                                        <div className="block__elements">
                                            <label className="formLabel">{i.t('def:Files')}*</label>
                                            <Upload
                                                multiple={true}
                                                beforeUpload={(file: any) => {
                                                    __product.toggleFile(index, file)
                                                    return false
                                                }}
                                                onRemove={(file: any) => __product.toggleFile(index, file)}
                                                fileList={__product.item.docs[index].files}
                                                showUploadList={false}
                                            >
                                                <Button icon={<InboxOutlined />}>{i.t('def:actions.upload')}</Button>
                                            </Upload>
                                        </div>

                                        {__product.item.docs[index].files.length > 0 &&
                                            <div className="block__elements block__elements-files">
                                                {__product.item.docs[index].files.map((file: any, k: number) => {

                                                    let canEdit = false
                                                    let fileEditing = false
                                                    let editingFileIndex = -1
                                                    let className = 'designer__block designer__block-file'

                                                    if(file.id > 0) {
                                                        canEdit = true

                                                        editingFileIndex = __product.filesToEdit.findIndex((el: any) => el.id === file.id)
                                                        if(editingFileIndex > -1) {
                                                            fileEditing = true
                                                            className += ' designer__block-file_editing'
                                                        }
                                                    }

                                                    return (
                                                        <div className={className} key={k}>
                                                            <div className="block__file">
                                                                <div className="file__icon">
                                                                    <FileOutlined />
                                                                </div>

                                                                <div className="file__name">
                                                                    {fileEditing ? (
                                                                        <Input
                                                                            id="button_text"
                                                                            value={__product.filesToEdit[editingFileIndex].name}
                                                                            onChange={(e) => __product.filesToEdit[editingFileIndex].name = e.target.value}
                                                                        />
                                                                    ) : (
                                                                        <a href={file.url} target="_blank">{file?.name ?? ''}</a>
                                                                    )}

                                                                </div>

                                                                <div className="block__actions">
                                                                    {canEdit &&
                                                                        <>
                                                                            {fileEditing ? (
                                                                                <Tooltip title={i.t('def:actions.save')}>
                                                                                    <Button
                                                                                        type="dashed"
                                                                                        shape="circle"
                                                                                        icon={<SaveOutlined />}
                                                                                        onClick={() => __product.toggleEditFile(file, 'save', index, editingFileIndex)}
                                                                                    />
                                                                                </Tooltip>
                                                                            ) : (
                                                                                <Tooltip title={i.t('def:actions.edit')}>
                                                                                    <Button
                                                                                        type="dashed"
                                                                                        shape="circle"
                                                                                        icon={<EditOutlined />}
                                                                                        onClick={() => __product.toggleEditFile(file)}
                                                                                    />
                                                                                </Tooltip>
                                                                            )}
                                                                        </>
                                                                    }
                                                                    <Tooltip title={i.t('categories:delete')}>
                                                                        <Button
                                                                            type="dashed"
                                                                            shape="circle"
                                                                            icon={<DeleteOutlined />}
                                                                            onClick={() => __product.toggleFile(index, file)}
                                                                            danger
                                                                        />
                                                                    </Tooltip>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        }
                                    </div>
                                </Col>
                            )
                        })}
                    </Row>
                </Col>
            </Row>
        </>
    )
})