import { ReactComponentElement } from 'react'
import { Switch, Route } from 'react-router-dom'
import { Login, LoginSelectProject } from '../front/user/login/Login'
import { Register } from '../front/user/register/Register'
import { Forgot } from '../front/user/forgot/Forgot'
import { Verify } from '../front/user/verify/Verify'
import { Dashboard } from '../front/dashboard/Dashboard'
import { Contact, Contacts } from '../front/contacts/Contacts'
import { ShopSettingsOrder } from '../front/settings/orders/ShopSettingsOrder'
import { ImportStatistic } from '../front/settings/imports/ImportStatistic'
import { Export } from '../front/shop/export/Export'
import { UserDetail } from '../front/users/Users'
import { Pages, Page } from '../front/pages/Pages'
import { Operator, Operators } from '../front/settings/operators/Operatord'
import ProductsWithoutCategory from '../modules/shop/catalog/productsWithoutCategory/ProductsWithoutCategory'
import { Product } from '../front/catalog/product/Product'
import ShopCategoryEdited from '../modules/shop/catalog/category/ShopCategoryEdited'
import ShopProductStatuses from '../modules/shop/product/modules/statuses/ShopProductStatuses'
import { Order } from '../front/shop/order/Order'
import ShopProperties from '../modules/shop/properties/ShopProperties'
import ShopProperty from '../modules/shop/properties/modules/ShopProperty'
import ShopPromoList from '../modules/shop/promo/modules/ShopPromoList'
import ShopPromo from '../modules/shop/promo/modules/ShopPromo'
import ShopPromoStatistics from '../modules/shop/promo/modules/ShopPromoStatistics'
import MassPriceChange from '../modules/shop/massPriceChange/MassPriceChange'
import { Unit, Units } from '../modules/shop/units/Units'
import { StorageElement, Storages } from '../front/settings/warehouses/Warehouses'
import { Branches } from '../front/shop/branches/Branches'
import { SettingDeliveryZones } from '../front/settings/delivery/zones/SettingDeliveryZones'
import { SettingDeliveryBase } from '../front/settings/delivery/base/SettingDeliveryBase'
import { SettingsWebhook } from '../front/settings/notifications/webhook/SettingsWebhook'
import { Integrations } from '../front/integrations/Integrations'
import { PaymentType, PaymentTypes } from '../front/shop/payment/types/PaymentTypes'
import { Pushes } from '../front/marketing/pushes/Pushes'
import { Push } from '../front/marketing/pushes/Push'
import { PushesTemplate, PushesTemplates } from '../front/marketing/pushes/PushesTemplates'
import { Users } from '../front/users/Users'
import { City, Cities } from '../front/settings/cities/Cities'
import { SettingsFeedback, FeedbackPage, FeedbackElement } from '../front/shop/feedback/SettingsFeedback'
import { AnswersPage, AnswersList } from '../front/shop/feedback/answers/FeedbackAnswers'
import { SettingsProject } from '../front/settings/project/SettingsProject'
import { SettingsForms, FormsPage, FormsElement } from '../front/shop/forms/SettingsForms'
import { FormsAnswersPage, FormsAnswersList } from '../front/shop/forms/answers/FormsAnswers'
import { Settings } from '../front/settings/Settings'
import { Navigation, NavigationElement } from '../front/settings/navigation/Navigation'
import { ImportSchedule } from '../front/settings/imports/ImportSchedule'
import { Import } from '../front/settings/imports/Import'
import { PointsList } from '../front/settings/points/PointsList'
import { Place } from '../front/settings/points/Place'
import { NewsList } from '../front/news/NewsList'
import { NewsElement } from '../front/news/NewsElement'
import { NewsType, NewsTypes } from '../front/news/NewsTypes'
import { CatalogList } from '../front/catalog/CatalogList'
import { Apps, AppsDetail } from '../front/apps/Apps'
import { AdminDetail, AdminList } from '../front/settings/admin/AdminList'
import { WebSettings } from '../front/settings/web/WebSettings'
import { BonusProgram } from '../front/bonus_program/BonusProgram'
import { CartDetail, Carts } from '../front/marketing/carts/Carts'
import { SettingDeliveryIntervals } from '../front/settings/delivery/intervals/SettingDeliveryIntervals'
import { DiscountList } from "../front/settings/discount/DiscountList";
import { Discount } from "../front/settings/discount/Discount";
import { ProductList } from '../front/catalog/product/ProductList'
import {OrderStatuses} from "../front/shop/order_status/OrderStatuses";
import { CatalogProductList } from "../front/catalog/CatalogProductList";
import { Roadmap } from "../front/settings/roadmap/Roadmap";
import { GroupsList } from '../front/catalog/groups/GroupsList'
import { Group } from '../front/catalog/groups/Group'
import { CustomProductListEdit } from '../front/catalog/custom_product_lists/CustomProductListEdit'
import { CustomProductListsPage } from '../front/catalog/custom_product_lists/CustomProductListsPage'
import { Designer } from '../front/screen_designer/Designer'
import { PlacesGroupsList } from '../front/settings/points/groups/PlacesGroupsList'
import { PlacesGroup } from '../front/settings/points/groups/PlacesGroup'
import { PlacesPropertiesList } from '../front/settings/points/properties/PlacesPropertiesList'
import { PlacesProperty } from '../front/settings/points/properties/PlacesProperty'
import { OnBoarding } from '../front/screen_designer/OnBoarding'
import { OrdersList } from '../front/shop/order/OrdersList'
import { Referral } from "../front/settings/referral/Referral";

interface RouteInterface {
    path: string
    component: ReactComponentElement<any>
}

const routes: RouteInterface[] = [
    { path: '/users', component: <Users /> },
    { path: '/users/:userID', component: <UserDetail /> },
    { path: '/pages', component: <Pages /> },
    { path: '/pages/:pageID', component: <Page /> },
    { path: '/news', component: <NewsList /> },
    { path: '/news/:newsID', component: <NewsElement /> },
    { path: '/news_types', component: <NewsTypes /> },
    { path: '/news_types/:id', component: <NewsType /> },
    { path: '/screens', component: <Designer /> },
    { path: '/settings/operators', component: <Operators /> },
    { path: '/settings/operators/:elementID', component: <Operator /> },

    { path: '/login', component: <Login /> },
    { path: '/select', component: <LoginSelectProject /> },
    { path: '/register', component: <Register /> },
    { path: '/forgot', component: <Forgot /> },
    { path: '/verify', component: <Verify /> },

    { path: '/', component: <Dashboard /> },
    { path: '/contacts', component: <Contacts /> },
    { path: '/contacts/:elementID', component: <Contact /> },

    { path: '/settings', component: <Settings /> },

    { path: '/settings/navigation', component: <Navigation /> },
    { path: '/settings/navigation/:type', component: <Navigation /> },
    { path: '/settings/navigation/:type/:id', component: <NavigationElement /> },

    { path: '/settings/onboarding', component: <OnBoarding onBoarding={true} /> },
    { path: '/promo_slides', component: <OnBoarding onBoarding={false} /> },

    { path: '/settings/cities', component: <Cities /> },
    { path: '/settings/cities/:elementID', component: <City /> },

    { path: '/settings/orders', component: <ShopSettingsOrder /> },
    { path: '/settings/import', component: <Import /> },
    { path: '/settings/import/schedule', component: <ImportSchedule /> },
    { path: '/settings/import/stat', component: <ImportStatistic /> },

    { path: '/settings/warehouses', component: <Storages /> },
    { path: '/settings/warehouses/:elementID', component: <StorageElement /> },

    { path: '/settings/notifications', component: <SettingsWebhook /> },

    { path: '/shop/feedback', component: <SettingsFeedback />},
    { path: '/shop/feedback/:formID', component: <FeedbackPage />},
    { path: '/shop/feedback/:formID/elements/:elementID', component: <FeedbackElement />},
    { path: '/shop/feedback/:formID/answers', component: <AnswersList /> },
    { path: '/shop/feedback/:formID/answers/:elementID', component: <AnswersPage /> },

    { path: '/shop/forms', component: <SettingsForms />},
    { path: '/shop/forms/:formID', component: <FormsPage />},
    { path: '/shop/forms/:formID/elements/:elementID', component: <FormsElement />},
    { path: '/shop/forms/:formID/answers', component: <FormsAnswersList /> },
    { path: '/shop/forms/:formID/answers/:elementID', component: <FormsAnswersPage /> },

    { path: '/settings/project', component: <SettingsProject />},

    { path: '/settings/delivery/zones', component: <SettingDeliveryZones /> },
    { path: '/settings/delivery/base', component: <SettingDeliveryBase /> },

    { path: '/shop/export', component: <Export /> },

    { path: '/shop/without', component: <ProductsWithoutCategory /> },
    { path: '/shop/catalog/product/:productID', component: <Product /> },
    { path: '/shop/catalog/product/:productID/:categoryID', component: <Product /> },
    { path: '/shop/catalog/product/create/:createID', component: <Product /> },

    { path: '/shop', component: <CatalogList /> },
    { path: '/shop/catalog', component: <CatalogList /> },
    { path: '/shop/catalog/category', component: <CatalogList /> },
    { path: '/shop/catalog/category/:categoryID', component: <CatalogList /> },
    { path: '/shop/products/:categoryID', component: <CatalogProductList /> },

    { path: '/catalog/groups', component: <GroupsList /> },
    { path: '/catalog/group/:groupID', component: <Group /> },
    { path: '/catalog/lists', component: <CustomProductListsPage /> },
    { path: '/catalog/lists/:listID', component: <CustomProductListEdit /> },

    { path: '/shop/catalog/category/edit/:categoryID', component: <ShopCategoryEdited /> },
    { path: '/shop/catalog/category/create/:createID', component: <ShopCategoryEdited /> },
    { path: '/shop/status', component: <ShopProductStatuses /> },
    { path: '/catalog/products', component: <ProductList /> },
    { path: '/shop/orders', component: <OrdersList /> },
    { path: '/shop/orders/:id', component: <Order />},
    { path: '/shop/statuses', component: <OrderStatuses /> },

    { path: '/settings/places', component: <PointsList /> },
    { path: '/settings/places/:id', component: <Place /> },
    { path: '/settings/places_groups', component: <PlacesGroupsList /> },
    { path: '/settings/places_groups/:groupID', component: <PlacesGroup /> },
    { path: '/settings/places_properties', component: <PlacesPropertiesList /> },
    { path: '/settings/places_properties/:propertyID', component: <PlacesProperty /> },

    { path: '/shop/properties', component: <ShopProperties /> },
    { path: '/shop/properties/:propertyID', component: <ShopProperty /> },
    { path: '/shop/promo', component: <ShopPromoList /> },
    { path: '/shop/promo/:couponID', component: <ShopPromo /> },
    { path: '/shop/promo/:couponID/statistics', component: <ShopPromoStatistics /> },
    { path: '/shop/mass', component: <MassPriceChange /> },
    { path: '/shop/units', component: <Units /> },
    { path: '/shop/units/:elementID', component: <Unit /> },

    { path: '/shop/payment/types', component: <PaymentTypes /> },
    { path: '/shop/payment/types/:entityId', component: <PaymentType /> },

    { path: '/shop/branches', component: <Branches /> },

    { path: '/pushes', component: <Pushes /> },
    { path: '/pushes/templates', component: <PushesTemplates /> },
    { path: '/pushes/templates/:id', component: <PushesTemplate /> },
    { path: '/pushes/:id', component: <Push /> },

    { path: '/settings/integrations', component: <Integrations /> },

    { path: '/apps', component: <Apps /> },
    { path: '/apps/:id', component: <AppsDetail /> },

    { path: '/settings/managers', component: <AdminList /> },
    { path: '/settings/managers/:id', component: <AdminDetail /> },

    { path: '/web_settings', component: <WebSettings /> },

    { path: '/settings/bonus_program', component: <BonusProgram /> },
    { path: '/settings/referral', component: <Referral /> },
    { path: '/carts', component: <Carts /> },
    { path: '/carts/:id', component: <CartDetail /> },

    { path: '/settings/delivery_intervals', component: <SettingDeliveryIntervals /> },

    { path: '/settings/roadmap', component: <Roadmap /> },

    { path: '/settings/discount', component: <DiscountList /> },
    { path: '/settings/discount/:id', component: <Discount /> },
]

export const Routes = (
    <Switch>
        {routes.map((el: RouteInterface, i: number) => <Route key={`router_${i}`} exact path={el.path} children={el.component} />)}
    </Switch>
)