import { Row, Col } from 'antd'

export const Block = ({ title, slot }: any) => {

    return(
        <Row className="components-block">
            <Col span={24}>
                <p className="components-block-title">{title}</p>

                {slot}
            </Col>
        </Row>
    )
}