import { makeAutoObservable } from 'mobx'
import { request } from '../../../helpers/request'
import hashController from '../../../modules/template/controllers/hash.controller'
import validator from 'validator'
import { notification } from 'antd'
import i from '../../../translations/i'

interface WebHook {
    id: number
    type: string
    url: string
}

class webhookController {

    webhooks: WebHook[] = []

    webhookNewOrder: WebHook = {
        id: 0,
        type: 'neworder',
        url: ''
    }

    webhookPayOrder: WebHook = {
        id: 0,
        type: 'payorder',
        url: ''
    }

    constructor() {
        makeAutoObservable(this)
    }

    get() {
        request('get', '/webhook/webhook')
            .then(result => {
                console.log(result)
                this.webhooks = result[0]

                if(result[0] && Array.isArray(result[0])) {
                    if(result[0].findIndex(el => el.type === 'neworder') > -1) {
                        this.webhookNewOrder = result[0].find(el => el.type === 'neworder')
                    }
                    if(result[0].findIndex(el => el.type === 'payorder') > -1) {
                        this.webhookPayOrder = result[0].find(el => el.type === 'payorder')
                    }
                }
            })
            .catch(err => {

            })
    }

    setUrl(type: string, url: string) {
        if(type === 'neworder') {
            this.webhookNewOrder.url = url
        } else if(type === 'payorder') {
            this.webhookPayOrder.url = url
        }
    }

    setWebHook(type: string) {
        let webhook: WebHook = {
            id: 0,
            type: '',
            url: ''
        }
        if(type === 'neworder') {
            webhook = this.webhookNewOrder
        } else if (type === 'payorder') {
            webhook = this.webhookPayOrder
        }

        if(webhook.url !== '' && !validator.isURL(webhook.url, { protocols: ['https'] })) {
            notification.error({
                message: i.t('def:errors.error'),
                description: i.t('def:errors.webhookURL')
            })
            return false
        }

        const data = new FormData()
        data.append('type', webhook.type)
        data.append('url', webhook.url)
        data.append('hash', hashController.setHash(data))

        request('post', `/webhook/webhook/${webhook.id}`, data)
            .then(result => {
                console.log(result)
            })
            .catch(err => {

            })
    }

    save() {
        this.setWebHook('neworder')
        this.setWebHook('payorder')
    }
}

export default new webhookController()