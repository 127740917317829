import { makeAutoObservable } from 'mobx'
import { FormEvent } from 'react'
import { request } from '../../../../helpers/request'
import hashController from '../../../../modules/template/controllers/hash.controller'
import { notification } from 'antd'
import i from '../../../../translations/i'

class importExcelController {

    exelFile = {
        name: undefined
    }

    exel = {
        id: 0,
        settingid: 0,
        file: '',
        type: 'xls',
        lineexclusion: 0,
        name_categorys: 0,
        name_category: 0,
        parentid_category: 0,
        id_crm_category: 0,
        picture_category: 0,
        price_offer: 0,
        oldprice_offer: 0,
        name_offers: 0,
        name_offer: 0,
        picture_offer: 0,
        parentid_offer: 0,
        description_offer: 0,
        vendorcode_offer: 0,
        id_crm_offer: 0,
        count_offer: 0,
        param: [],
        oferid_offer: 0
    }

    constructor() {
        makeAutoObservable(this)
    }

    getUniqueKey() {
        let chrs = 'abdehkmnpswxzABDEFGHKMNPQRSTWXZ123456789'
        let str = ''
        for (let i = 0; i < 10; i++) {
            let pos = Math.floor(Math.random() * chrs.length)
            str += chrs.substring(pos, pos + 1)
        }

        return str
    }

    addNewParam() {
        let chrs = 'abdehkmnpswxzABDEFGHKMNPQRSTWXZ123456789'
        let str = ''
        for (let i = 0; i < 10; i++) {
            let pos = Math.floor(Math.random() * chrs.length)
            str += chrs.substring(pos, pos + 1)
        }

        // @ts-ignore
        this.exel.param.push({ key: str, name: '', value: '' })
    }

    setParam(key: string, name: string, value: any) {
        const index = this.exel.param.findIndex((el: any) => el.key === key)
        if (index > -1) {
            // @ts-ignore
            this.exel.param[index][name] = value
        }
    }

    removeParam(paramKey: string) {
        this.exel.param = this.exel.param.filter((el: any) => el.key !== paramKey)
    }

    setExel(name: string, value: any) {
        // @ts-ignore
        this.exel[name] = value ?? 0

        console.log(value)
    }

    getExel() {
        this.exelFile = { name: undefined }

        request('get', `/product/exportfile`)
            .then((result) => {
                console.log(result)
                if(result) {
                    let params: any[] = []

                    result.param.map((el: any) => params.push({
                        name: el.name,
                        value: el.value,
                        key: this.getUniqueKey()
                    }))

                    result.param = params

                    this.exel = result
                }

            })
    }

    saveExelFile(file: any) {

        let data = new FormData()
        data.append('test', '')
        data.append('hash', hashController.setHash(data))
        // @ts-ignore
        data.append('file', file)

        request('post', `/product/export/${this.exel.id}`, data)
            .then((success) => {
                console.log(success)
                notification.success({
                    message: i.t('def:success.import')
                })
            })
            .catch((error) => {
                console.log(error.response)
                notification.error({
                    message: error.response.data.message
                })
            })
    }

    saveExel() {
        let facts = new FormData()
        facts.append('settingid', `${this.exel.settingid}`)
        facts.append('file', `${this.exel.file}`)
        facts.append('type', `${this.exel.type}`)
        facts.append('lineexclusion', `${this.exel.lineexclusion}`)
        facts.append('name_categorys', `${this.exel.name_categorys}`)
        facts.append('name_category', `${this.exel.name_category}`)
        facts.append('parentid_category', `${this.exel.parentid_category}`)
        facts.append('id_crm_category', `${this.exel.id_crm_category}`)
        facts.append('picture_category', `${this.exel.picture_category}`)
        facts.append('price_offer', `${this.exel.price_offer}`)
        facts.append('oldprice_offer', `${this.exel.oldprice_offer}`)
        facts.append('name_offers', `${this.exel.name_offers}`)
        facts.append('name_offer', `${this.exel.name_offer}`)
        facts.append('picture_offer', `${this.exel.picture_offer}`)
        facts.append('parentid_offer', `${this.exel.parentid_offer}`)
        facts.append('description_offer', `${this.exel.description_offer}`)
        facts.append('vendorcode_offer', `${this.exel.vendorcode_offer}`)

        facts.append('id_crm_offer', `${this.exel.id_crm_offer}` ?? '0')

        facts.append('count_offer', `${this.exel.count_offer}`)
        facts.append('oferid_offer', `${this.exel.oferid_offer}`)
        facts.append('param', this.exel.param.length > 0 ? JSON.stringify(this.exel.param) : JSON.stringify([]))

        facts.append('hash', hashController.setHash(facts))

        request('post', `/product/exportfile`, facts).then((result) => {
            console.log(result)
            notification.success({
                message: 'Настройки импорта Excel сохранены'
            })
        })
    }

}

export default new importExcelController()