import { makeAutoObservable, runInAction } from 'mobx'
import { ContactsInterface } from './contacts.interfaces'
import { request } from '../../helpers/request'
import i from '../../translations/i'
import { FormEvent } from 'react'
import { message } from 'antd'
import { remove } from '../../helpers/remove'

class contactsController {

    list: ContactsInterface[] = []
    element: ContactsInterface = {
        id: 0,
        title: '',
        type: '',
        value: ''
    }

    pagination = {
        total: 0,
        limit: 10,
        page: 1,
        offset: 0
    }

    types = [
        { name: 'phone',        description: i.t('contacts:types.phone') },
        { name: 'email',        description: i.t('contacts:types.email') },
        { name: 'address',      description: i.t('contacts:types.address') },
        { name: 'schedule',     description: i.t('contacts:types.schedule') },
        { name: 'vk',           description: i.t('contacts:types.vk') },
        { name: 'instagram',    description: i.t('contacts:types.instagram') },
        { name: 'facebook',     description: i.t('contacts:types.facebook') },
        { name: 'twitter',      description: i.t('contacts:types.twitter') },
        { name: 'telegram',     description: i.t('contacts:types.telegram') },
        { name: 'website',      description: i.t('contacts:types.website') },
        { name: 'whatsapp',     description: i.t('contacts:types.whatsapp') },
        { name: 'viber',        description: i.t('contacts:types.viber') },
        { name: 'text',         description: i.t('contacts:types.text') },
    ]

    constructor() {
        makeAutoObservable(this)
    }

    getTypeByName(name: string): string {
        const index = this.types.findIndex(el => el.name === name)
        return index > -1 ? this.types[index].description : ''
    }

    async get(elementID: string | number = 0) {
        if(Number(elementID) > 0) {
            const result = await request('get', `/info/contacts/${elementID}`, {}, true)
            if(result && result.data) {
                runInAction(() => {
                    this.element = result.data
                })
            }
        } else {
            const result = await request('get', '/info/contacts', {}, true)
            if(result && result.data) {
                runInAction(() => {
                    this.list = result.data
                })
            }
        }
    }

    set(name: string, value: any) {
        //@ts-ignore
        this.element[name] = value
    }

    setPage(page: number) {
        this.pagination.page = page
        if (page > 0) {
            this.pagination.offset =
                this.pagination.limit * page - this.pagination.limit
        } else {
            this.pagination.offset = 0
        }
        this.get()
    }

    async remove(elementID: string | number) {
        if (window.confirm(i.t('def:confirms.delete'))) {
            const result = await remove('ksContact', `${elementID}`)
            if(result) this.get()
            else {
                message.error(i.t('def:errors.remove'))
            }
        }
    }

    async save(e: FormEvent) {
        e.preventDefault()

        let data = new FormData()
        data.append('id', `${this.element.id}`)
        data.append('title', this.element.title)
        data.append('type', this.element.type)
        data.append('value', this.element.value)

        const result = await request('post', `/info/contacts`, data, true)
        if(result) {
            console.log(result)
            window.location.href = `/contacts`
        }
    }

}

export default new contactsController()